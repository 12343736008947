export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Account = {
  __typename: 'Account';
  addresses: Array<Address>;
  billingAccountId: Scalars['String'];
  closedReason?: Maybe<AccountClosedReason>;
  currencyCode: CurrencyCode;
  emailAddresses: Array<Email>;
  finance?: Maybe<AccountFinance>;
  labOrigin: Scalars['String'];
  labOriginId: Scalars['Int'];
  phoneNumbers: Array<Phone>;
  practiceId: Scalars['Int'];
  practiceName: Scalars['String'];
  practiceType: PracticeType;
  providers: Array<AccountProvider>;
  standing: AccountStanding;
  status: AccountStatus;
  type?: Maybe<Scalars['String']>;
};

export type AccountProvidersArgs = {
  filter?: InputMaybe<ProviderFilter>;
};

export enum AccountClosedReason {
  Bankruptcy = 'Bankruptcy',
  Collection = 'Collection',
  CustomerRequest = 'CustomerRequest',
  DuplicateAccount = 'DuplicateAccount',
  Inactive = 'Inactive',
  OtherAccount = 'OtherAccount',
  ReferToManagement = 'ReferToManagement',
  Sold = 'Sold',
}

export type AccountFinance = {
  __typename: 'AccountFinance';
  agingBuckets?: Maybe<AgingBucket>;
  billingAccountId: Scalars['String'];
  codAmount?: Maybe<Scalars['Float']>;
  codAmountPerAdditionalCase?: Maybe<Scalars['Float']>;
  lastPaymentAmount?: Maybe<Scalars['Float']>;
  standing?: Maybe<AccountStanding>;
  unpostedCash?: Maybe<Scalars['Float']>;
  unpostedSales?: Maybe<Scalars['Float']>;
};

export type AccountFinanceResult = AccountFinance | GenericError | NotFoundError;

export type AccountProvider = BaseProvider & {
  __typename: 'AccountProvider';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  providerId: Scalars['Int'];
  providerStatus: ProviderStatus;
  salutation?: Maybe<Salutation>;
  suffix?: Maybe<Scalars['String']>;
};

export type AccountRelation = {
  __typename: 'AccountRelation';
  billingAccountId: Scalars['String'];
  providerStatus: ProviderStatus;
  role: AccountRole;
};

export type AccountResult = Account | GenericError | NotFoundError;

export enum AccountRole {
  Associate = 'Associate',
  ContactAp = 'ContactAp',
  Owner = 'Owner',
}

export type AccountSearchPhone = {
  __typename: 'AccountSearchPhone';
  number: Scalars['String'];
  type: Scalars['String'];
};

export type AccountSearchRecord = {
  __typename: 'AccountSearchRecord';
  billingAccountId: Scalars['String'];
  phoneNumbers: Array<AccountSearchPhone>;
  practice: PracticeSearchRecord;
  shippingAddress: Scalars['String'];
  standing: AccountStanding;
  status: AccountStatus;
};

export enum AccountStanding {
  AlwaysOnCod = 'AlwaysOnCOD',
  AlwaysOnCreditHold = 'AlwaysOnCreditHold',
  Good = 'Good',
  NeverOnCod = 'NeverOnCOD',
  NeverOnCreditHold = 'NeverOnCreditHold',
  None = 'None',
  OnCod = 'OnCOD',
  OnCreditHold = 'OnCreditHold',
  PaymentPlan = 'PaymentPlan',
  PendingCod = 'PendingCOD',
  PendingCreditHold = 'PendingCreditHold',
}

export enum AccountStatus {
  Active = 'Active',
  Closed = 'Closed',
  Deleted = 'Deleted',
  Prospect = 'Prospect',
}

export type Address = BaseAddress & {
  __typename: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  division?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  type: AddressType;
  zipcode: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  division?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  street3?: InputMaybe<Scalars['String']>;
  type: AddressType;
  zipcode: Scalars['String'];
};

export enum AddressType {
  Alternate = 'Alternate',
  Billing = 'Billing',
  Main = 'Main',
  Marketing = 'Marketing',
  Pickup = 'Pickup',
  Shipping = 'Shipping',
}

export type AddressValidationInput = {
  city: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type AddressValidationResponse = {
  __typename: 'AddressValidationResponse';
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  object: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  residential?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  verifications: AddressValidationVerificationResponse;
  zip: Scalars['String'];
};

export type AddressValidationResult = AddressValidationResponse | GenericError;

export type AddressValidationVerificationDelivery = {
  __typename: 'AddressValidationVerificationDelivery';
  details: AddressValidationVerificationDeliveryDetails;
  success: Scalars['Boolean'];
};

export type AddressValidationVerificationDeliveryDetails = {
  __typename: 'AddressValidationVerificationDeliveryDetails';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  time_zone?: Maybe<Scalars['String']>;
};

export type AddressValidationVerificationResponse = {
  __typename: 'AddressValidationVerificationResponse';
  delivery: AddressValidationVerificationDelivery;
};

export type AgingBucket = {
  __typename: 'AgingBucket';
  currentBalance: Scalars['Float'];
  day30Period: Scalars['Float'];
  day60Period: Scalars['Float'];
  day90Period: Scalars['Float'];
  over90Days: Scalars['Float'];
  totalBalance: Scalars['Float'];
};

export type AlertId = {
  alertId: Scalars['String'];
  status: CaseAlertStatus;
};

export type AlertUpdateItem = {
  __typename: 'AlertUpdateItem';
  new: Scalars['String'];
  original: Scalars['String'];
};

export type AttachmentUploadProperties = {
  __typename: 'AttachmentUploadProperties';
  fileName?: Maybe<Scalars['String']>;
  filePath: Scalars['String'];
  fileType?: Maybe<Scalars['String']>;
};

export type AttachmentUploadPropertiesInput = {
  fileName?: InputMaybe<Scalars['String']>;
  filePath: Scalars['String'];
  fileType?: InputMaybe<Scalars['String']>;
};

export type Attribute = {
  __typename: 'Attribute';
  attributeOptions: Array<Scalars['String']>;
  attributeRules?: Maybe<Array<AttributeRule>>;
  attributeValueType: ProductAttributeValueType;
  isPricing?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export enum AttributeDisplayType {
  ToothDown = 'ToothDown',
}

export type AttributeResult = Attribute | GenericError | NotFoundError;

export type AttributeRule = {
  __typename: 'AttributeRule';
  attributeValue: Scalars['String'];
  displayAttributes: Array<DisplayAttribute>;
};

export type AvailableCreditsData = {
  __typename: 'AvailableCreditsData';
  creditItems: Array<AvailableDeduction>;
  orderNumber: Scalars['String'];
};

export type AvailableCreditsInput = {
  orderNumber: Scalars['String'];
};

export type AvailableCreditsResult = AvailableCreditsData | GenericError | NotFoundError;

export type AvailableDeduction = {
  __typename: 'AvailableDeduction';
  availableAmount: Scalars['Float'];
  description: Scalars['String'];
  orderItemId: Scalars['String'];
  productCode: Scalars['String'];
};

export type AvailableDiscountsData = {
  __typename: 'AvailableDiscountsData';
  deductions: Array<AvailableDeduction>;
  orderNumber: Scalars['String'];
};

export type AvailableDiscountsInput = {
  orderNumber: Scalars['String'];
};

export type AvailableDiscountsResult = AvailableDiscountsData | GenericError | NotFoundError;

export type BadRequestError = LmsError & {
  __typename: 'BadRequestError';
  message: Scalars['String'];
};

export type BaseAddress = {
  city: Scalars['String'];
  country: Scalars['String'];
  division?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type BaseEmail = {
  emailAddress?: Maybe<Scalars['String']>;
};

export type BaseOrder = {
  enclosedItems: Array<OrderEnclosedItem>;
  fileAttachments: Array<OrderAttachment>;
  isCTScanEligible?: Maybe<Scalars['Boolean']>;
  orderItems: Array<BaseOrderItem>;
  orderNumber: Scalars['String'];
  patientFirstName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  shipping?: Maybe<ShippingResult>;
  status: OrderStatus;
};

export type BaseOrderItem = {
  addOns?: Maybe<Array<OrderProductAttribute>>;
  attributes: Array<OrderProductAttribute>;
  itemId: Scalars['String'];
  productCode: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  services?: Maybe<Array<OrderProductAttribute>>;
};

export type BasePhone = {
  country?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type BaseProvider = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  providerId: Scalars['Int'];
};

export type Brand = {
  __typename: 'Brand';
  brandName: Scalars['String'];
  products: Array<BrandProduct>;
  siteId: Scalars['String'];
};

export type BrandAttribute = {
  __typename: 'BrandAttribute';
  daysInLab?: Maybe<BrandDaysInLab>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type BrandDaysInLab = {
  __typename: 'BrandDaysInLab';
  digital?: Maybe<Scalars['Int']>;
  external: Scalars['Int'];
  internal: Scalars['Int'];
};

export type BrandProduct = {
  __typename: 'BrandProduct';
  attributes: Array<BrandAttribute>;
  daysInLab?: Maybe<BrandDaysInLab>;
  productCode: Scalars['String'];
};

export type BrandResult = BrandWrapper | GenericError | NotFoundError;

export type BrandWrapper = {
  __typename: 'BrandWrapper';
  brands: Array<Brand>;
};

export type BundleSplitOrder = {
  __typename: 'BundleSplitOrder';
  orders: Array<Order>;
  rmaOrders: Array<RmaOrder>;
};

export type BundleSplitOrderInput = {
  orders: Array<CreateOrderInput>;
  rmaOrders: Array<CreateRmaOrderInput>;
};

export type BundleSplitOrderResult = BadRequestError | BundleSplitOrder | GenericError | NotFoundError;

export type CancelCaseAlertDetail = {
  __typename: 'CancelCaseAlertDetail';
  caseContent: CaseContentsType;
  notes?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
};

export type CaseAddress = {
  __typename: 'CaseAddress';
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type CaseAlert = {
  __typename: 'CaseAlert';
  alertId?: Maybe<Scalars['String']>;
  appliedBy?: Maybe<Scalars['String']>;
  appliedDate?: Maybe<Scalars['String']>;
  billingAccountId: Scalars['String'];
  cancelCase?: Maybe<Array<CancelCaseAlertDetail>>;
  createdBy: Scalars['String'];
  createdDate?: Maybe<Scalars['String']>;
  expireDate: Scalars['String'];
  forwardCase?: Maybe<Array<ForwardCaseAlertDetail>>;
  isPatientInfoIncomplete: Scalars['Boolean'];
  orderNumber?: Maybe<Scalars['String']>;
  other?: Maybe<Array<OtherCaseAlertDetail>>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  providerId: Scalars['String'];
  spokeTo: Scalars['String'];
  status?: Maybe<CaseAlertStatus>;
  updateCase?: Maybe<Array<UpdateCaseAlertDetail>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export enum CaseAlertStatus {
  Active = 'Active',
  Applied = 'Applied',
  Cancelled = 'Cancelled',
  UnApplied = 'UnApplied',
}

export type CaseAlertStatusItem = {
  __typename: 'CaseAlertStatusItem';
  alertId: Scalars['String'];
  status: CaseAlertStatus;
};

export type CaseAlertStatuses = {
  __typename: 'CaseAlertStatuses';
  alerts: Array<CaseAlertStatusItem>;
  billingAccountId: Scalars['String'];
  providerId: Scalars['String'];
};

export type CaseAlerts = {
  __typename: 'CaseAlerts';
  caseAlerts: Array<CaseAlert>;
};

export type CaseAlertsResult = CaseAlerts | GenericError | NotFoundError;

export type CaseAlertsSearchInput = {
  billingAccountId: Scalars['String'];
  providerId?: InputMaybe<Scalars['String']>;
};

export enum CaseContentsType {
  Discard = 'Discard',
  Return = 'Return',
}

export enum CaseDiscountType {
  EntireCase = 'EntireCase',
  PartOfCase = 'PartOfCase',
}

export enum CaseHistoryActionType {
  CaseCreated = 'CaseCreated',
  CaseEdit = 'CaseEdit',
  Deductions = 'Deductions',
  LocationScan = 'LocationScan',
  ManagerEvaluation = 'ManagerEvaluation',
  ManufacturingStep = 'ManufacturingStep',
  PreferenceOverride = 'PreferenceOverride',
  Rejection = 'Rejection',
  TechnicianTask = 'TechnicianTask',
}

export type CaseHistoryAdditionalDetails = {
  __typename: 'CaseHistoryAdditionalDetails';
  new?: Maybe<Scalars['String']>;
  old?: Maybe<Scalars['String']>;
};

export type CaseHistoryItem = {
  __typename: 'CaseHistoryItem';
  actionType?: Maybe<CaseHistoryActionType>;
  additionalDetails?: Maybe<CaseHistoryAdditionalDetails>;
  completedBy?: Maybe<Scalars['String']>;
  completedByTechId?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  eventType: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isProcessOverride?: Maybe<Scalars['Boolean']>;
  isTestResource?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  manufacturingStatus?: Maybe<ManufacturingStatus>;
  orderNumber: Scalars['String'];
  process?: Maybe<Scalars['String']>;
  productionFacility?: Maybe<Scalars['String']>;
  products?: Maybe<Array<CaseHistoryProductItem>>;
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timeStamp: Scalars['String'];
  utcConversionTimeZoneCode?: Maybe<Scalars['String']>;
};

export type CaseHistoryProductItem = {
  __typename: 'CaseHistoryProductItem';
  department?: Maybe<Scalars['String']>;
  productCode: Scalars['String'];
  productName: Scalars['String'];
  toothString?: Maybe<Scalars['String']>;
};

export type CaseHistoryResult = GenericError | HistoryResults | NotFoundError;

export type Charge = {
  __typename: 'Charge';
  currencies: Array<Currency>;
  isTaxable: Scalars['Boolean'];
  service: Scalars['String'];
  shippingChargeId: Scalars['String'];
  taxCode?: Maybe<Scalars['String']>;
};

export type ClassificationRestoration = {
  __typename: 'ClassificationRestoration';
  productCode: Scalars['String'];
  restorationName: Scalars['String'];
};

export type Classifications = {
  __typename: 'Classifications';
  materialName: Scalars['String'];
  restorations: Array<ClassificationRestoration>;
};

export type ClassificationsResult = BadRequestError | ClassificationsWrapper | GenericError;

export type ClassificationsWrapper = {
  __typename: 'ClassificationsWrapper';
  classifications: Array<Classifications>;
};

export type CollectOnDelivery = {
  collectionAmount: Scalars['Float'];
  collectionMethod: CollectionType;
};

export enum CollectionType {
  Cash = 'Cash',
  Check = 'Check',
  MoneyOrder = 'MoneyOrder',
}

export type ComputerGeneratedHandle = {
  __typename: 'ComputerGeneratedHandle';
  handleType?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type Config = {
  __typename: 'Config';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  settings: Array<ConfigSetting>;
};

export type ConfigData = {
  __typename: 'ConfigData';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastUpdatedDateUTC: Scalars['String'];
  sections: Array<Config>;
};

export type ConfigResult = ConfigData | GenericError;

export type ConfigSetting = {
  __typename: 'ConfigSetting';
  description?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  overrides?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Coupon = {
  __typename: 'Coupon';
  activationDate: Scalars['String'];
  code: Scalars['String'];
  components: Array<ProductComponent>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CurrencyCode>;
  discountAmount: Scalars['Float'];
  discountType: DiscountType;
  expirationDate: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  status?: Maybe<CouponStatus>;
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  usageLimit?: Maybe<UsageLimit>;
};

export type CouponResult = Coupon | GenericError | NotFoundError;

export enum CouponStatus {
  Applied = 'Applied',
  NotApplied = 'NotApplied',
}

export type CreateCreditInput = {
  caseDiscountType: CaseDiscountType;
  couponCode?: InputMaybe<Scalars['String']>;
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  creditId?: InputMaybe<Scalars['String']>;
  creditItems?: InputMaybe<Array<CreditItemInput>>;
  notes?: InputMaybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  reason: Scalars['String'];
  totalDiscountAmount?: InputMaybe<Scalars['Float']>;
  utcConversionTimeZoneCode: Scalars['String'];
};

export type CreateCreditResult = Credit | GenericError | NotFoundError;

export type CreateDeductionInput = {
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  deductions: Array<DiscountInput>;
  notes?: InputMaybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  reason: Scalars['String'];
  type: CaseDiscountType;
  utcConversionTimeZoneCode: Scalars['String'];
};

export type CreateDeductionResult = Deduction | GenericError | NotFoundError;

export type CreateOrderInput = {
  billingAccountId: Scalars['String'];
  coupons: Array<Scalars['String']>;
  createdBy: Scalars['String'];
  customerDueDate?: InputMaybe<Scalars['String']>;
  enclosedItems: Array<OrderEnclosedItemInput>;
  estimatedShipDate: Scalars['String'];
  externalOrderNumber?: InputMaybe<Scalars['String']>;
  fileAttachments: Array<OrderAttachmentInput>;
  hasAppliedAlerts?: InputMaybe<Scalars['Boolean']>;
  inboundTrackingNumbers: Array<TrackingNumberItemInput>;
  linkedOrder?: InputMaybe<LinkedOrderInput>;
  notes?: InputMaybe<Scalars['String']>;
  orderItems: Array<OrderItemInput>;
  orderNumber?: InputMaybe<Scalars['String']>;
  orderSource?: InputMaybe<Scalars['String']>;
  originFacility: Scalars['String'];
  originFacilityId: Scalars['Int'];
  originalOrderNumber?: InputMaybe<Scalars['String']>;
  patientFirstName?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  patientLastName?: InputMaybe<Scalars['String']>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  shippingAddress: AddressInput;
  utcConversionTimeZone: Scalars['String'];
};

export type CreateRmaOrderInput = {
  billingAccountId: Scalars['String'];
  coupons: Array<Scalars['String']>;
  createdBy: Scalars['String'];
  customerDueDate?: InputMaybe<Scalars['String']>;
  enclosedItems: Array<OrderEnclosedItemInput>;
  estimatedShipDate: Scalars['String'];
  externalOrderNumber?: InputMaybe<Scalars['String']>;
  fileAttachments: Array<OrderAttachmentInput>;
  hasAppliedAlerts?: InputMaybe<Scalars['Boolean']>;
  inboundTrackingNumbers: Array<TrackingNumberItemInput>;
  linkedOrder?: InputMaybe<LinkedOrderInput>;
  notes?: InputMaybe<Scalars['String']>;
  orderItems: Array<RmaOrderItemInput>;
  orderNumber: Scalars['String'];
  orderSource?: InputMaybe<Scalars['String']>;
  originFacility: Scalars['String'];
  originFacilityId: Scalars['Int'];
  originalOrderNumber: Scalars['String'];
  patientFirstName?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  patientLastName?: InputMaybe<Scalars['String']>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  shippingAddress: AddressInput;
  updatedBy: Scalars['String'];
  utcConversionTimeZone: Scalars['String'];
};

export type Credit = {
  __typename: 'Credit';
  billingAccountId: Scalars['String'];
  caseDiscountType: CaseDiscountType;
  couponCode?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  creditItems: Array<CreditItem>;
  creditNumber: Scalars['String'];
  currencyCode: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  originFacilityId: Scalars['Int'];
  patientFirstName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  totalAmount: Scalars['Float'];
  totalDiscountAmount: Scalars['Float'];
  totalTaxAmount: Scalars['Float'];
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type CreditItem = {
  __typename: 'CreditItem';
  couponCode?: Maybe<Scalars['String']>;
  creditType: CreditType;
  description: Scalars['String'];
  discountAmount: Scalars['Float'];
  orderItemId: Scalars['String'];
  productCode: Scalars['String'];
  productName: Scalars['String'];
  productQuantity?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  toothString?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Float'];
  totalTaxAmount: Scalars['Float'];
  value: Scalars['Float'];
};

export type CreditItemInput = {
  creditType: CreditType;
  description: Scalars['String'];
  discountAmount: Scalars['Float'];
  orderItemId: Scalars['String'];
  productCode: Scalars['String'];
  value: Scalars['Float'];
};

export type CreditResults = {
  __typename: 'CreditResults';
  credits: Array<Credit>;
};

export enum CreditType {
  Dollar = 'Dollar',
  Percentage = 'Percentage',
  Quantity = 'Quantity',
}

export type Currency = {
  __typename: 'Currency';
  currencyCode: CurrencyCode;
  price: Scalars['Float'];
};

export enum CurrencyCode {
  Cad = 'CAD',
  Clp = 'CLP',
  Cop = 'COP',
  Crc = 'CRC',
  Eur = 'EUR',
  Gbp = 'GBP',
  Mxn = 'MXN',
  Usd = 'USD',
}

export type CustomerAccountSearchInput = {
  accountRole?: InputMaybe<Array<AccountRole>>;
  providerStatus?: InputMaybe<Array<ProviderStatus>>;
  searchTerm: Scalars['String'];
};

export type CustomerAccountSearchResponse = {
  __typename: 'CustomerAccountSearchResponse';
  accounts: Array<CustomerSearchRecord>;
};

export type CustomerAccountSearchResult = CustomerAccountSearchResponse | GenericError;

export type CustomerSearchRecord = {
  __typename: 'CustomerSearchRecord';
  account: AccountSearchRecord;
  fullName: Scalars['String'];
  licenses: Array<Scalars['String']>;
  phoneNumbers: Array<AccountSearchPhone>;
  providerId: Scalars['Int'];
  salutation?: Maybe<Scalars['String']>;
};

export type Dashboard = {
  __typename: 'Dashboard';
  userCases: Array<SearchedOrder>;
};

export type DashboardInput = {
  originFacilityId?: InputMaybe<Scalars['Int']>;
};

export type DashboardResult = Dashboard | GenericError;

export type Deduction = {
  __typename: 'Deduction';
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  deductions: Array<Discount>;
  discountId: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  reason: Scalars['String'];
  type: CaseDiscountType;
};

export type DeductionList = {
  __typename: 'DeductionList';
  deductions: Array<Deduction>;
};

export type DeleteCreditInput = {
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type DeleteCreditResponse = {
  __typename: 'DeleteCreditResponse';
  result: Scalars['String'];
};

export type DeleteCreditResult = DeleteCreditResponse | GenericError | NotFoundError;

export type DeleteDeductionResponse = {
  __typename: 'DeleteDeductionResponse';
  result: Scalars['String'];
};

export type DeleteDeductionsResult = DeleteDeductionResponse | GenericError | NotFoundError;

export enum DeliveryConfirmation {
  AdultSignature = 'AdultSignature',
  NoSignature = 'NoSignature',
  Signature = 'Signature',
}

export enum DeliveryMethod {
  Carrier = 'Carrier',
  DoctorPickup = 'DoctorPickup',
}

export type Details = {
  __typename: 'Details';
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  time_zone: Scalars['String'];
};

export type Discount = {
  __typename: 'Discount';
  description?: Maybe<Scalars['String']>;
  discountAmount: Scalars['Float'];
  discountType: DiscountType;
  orderItemId?: Maybe<Scalars['String']>;
  productCode: Scalars['String'];
  value: Scalars['Float'];
};

export type DiscountInput = {
  description: Scalars['String'];
  discountAmount: Scalars['Float'];
  discountType: DiscountType;
  orderItemId: Scalars['String'];
  productCode: Scalars['String'];
  value: Scalars['Float'];
};

export enum DiscountType {
  Amount = 'Amount',
  Percentage = 'Percentage',
}

export type DisplayAttribute = {
  __typename: 'DisplayAttribute';
  name: Scalars['String'];
  type: Scalars['String'];
};

export type DoNotShip = {
  __typename: 'DoNotShip';
  day?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  start: Scalars['String'];
};

export type DoctorPickupLabel = {
  __typename: 'DoctorPickupLabel';
  html: Scalars['String'];
};

export type DoctorPickupLabelAvailable = {
  __typename: 'DoctorPickupLabelAvailable';
  CreatedDate: Scalars['String'];
  OrderNumber: Scalars['String'];
  PickupLabelHtml: Scalars['String'];
  SessionId: Scalars['String'];
};

export type DoctorPickupLabelResult = DoctorPickupLabel | GenericError | NotFoundError;

export type DoctorPickupLocation = {
  __typename: 'DoctorPickupLocation';
  address: Address;
  labId: Scalars['Int'];
  labName: Scalars['String'];
  phone: Phone;
};

export type Email = BaseEmail & {
  __typename: 'Email';
  emailAddress: Scalars['String'];
  type: EmailType;
};

export enum EmailType {
  Office = 'Office',
  Personal = 'Personal',
  PersonalCompany = 'PersonalCompany',
}

export type EnclosedItem = {
  __typename: 'EnclosedItem';
  category: EnclosedItemCategory;
  itemCode: Scalars['String'];
  type: Scalars['String'];
};

export enum EnclosedItemCategory {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}

export type EnclosedItemsResult = EnclosedItemsWrapper | GenericError;

export type EnclosedItemsWrapper = {
  __typename: 'EnclosedItemsWrapper';
  enclosedItems: Array<EnclosedItem>;
};

export type File = {
  __typename: 'File';
  lastModified: Scalars['Int'];
  name: Scalars['String'];
  webkitRelativePath: Scalars['String'];
};

export type ForwardCaseAlertDetail = {
  __typename: 'ForwardCaseAlertDetail';
  reason?: Maybe<Scalars['String']>;
  routeTo: Scalars['String'];
  routeType: RouteType;
  sendToPlastering: Scalars['Boolean'];
};

export type GenericError = LmsError & {
  __typename: 'GenericError';
  message: Scalars['String'];
};

export type HistoryResults = {
  __typename: 'HistoryResults';
  caseHistoryItems: Array<CaseHistoryItem>;
  orderNumber: Scalars['String'];
};

export type InputAddress = {
  city: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type InputEmail = {
  emailAddress: Scalars['String'];
};

export type InputPhone = {
  number: Scalars['String'];
};

export type Invoice = {
  __typename: 'Invoice';
  html: Scalars['String'];
};

export type InvoiceAvailable = {
  __typename: 'InvoiceAvailable';
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
};

export type InvoiceInput = {
  bundledOrderNumber?: InputMaybe<Scalars['String']>;
  invoiceBy: Scalars['String'];
  orderInvoices: Array<OrderInvoice>;
  overrides?: InputMaybe<Array<OverrideFieldInput>>;
  uTCConversionTimeZone: Scalars['String'];
};

export type InvoiceResult = GenericError | Invoice | NotFoundError;

export type InvoiceShipping = {
  codAmount?: InputMaybe<Scalars['Float']>;
  deliveryMethod?: InputMaybe<DeliveryMethod>;
  doctorPickupLocationId?: InputMaybe<Scalars['Int']>;
  packageType?: InputMaybe<PackageType>;
  phone?: InputMaybe<InvoiceShippingPhone>;
  rateId?: InputMaybe<Scalars['String']>;
  shipmentId?: InputMaybe<Scalars['String']>;
  shippingCharges?: InputMaybe<Array<InvoiceShippingCharge>>;
};

export type InvoiceShippingCharge = {
  amount: Scalars['Float'];
  carrier: Scalars['String'];
  discountAmount: Scalars['Float'];
  id: Scalars['String'];
  salesTaxAmount: Scalars['Float'];
  service: Scalars['String'];
  taxCode?: InputMaybe<Scalars['String']>;
  totalAmount: Scalars['Float'];
  type: ShippingChargeType;
};

export type InvoiceShippingPhone = {
  number: Scalars['String'];
  type: PhoneType;
};

export type Item = {
  __typename: 'Item';
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['String']>;
  Id: Scalars['String'];
  Itemcode?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Int']>;
};

export type LmsError = {
  message: Scalars['String'];
};

export type LegacyAddress = {
  __typename: 'LegacyAddress';
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  type: AddressType;
  zipcode: Scalars['String'];
};

export type LegacyDoctorPickupLocation = {
  __typename: 'LegacyDoctorPickupLocation';
  address: LegacyAddress;
  labId: Scalars['Int'];
  labName: Scalars['String'];
  phone: Phone;
};

export type LegacyOrder = BaseOrder & {
  __typename: 'LegacyOrder';
  addresses: Array<LegacyAddress>;
  billingAccountId?: Maybe<Scalars['String']>;
  coupons: Array<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CurrencyCode>;
  customerDueDate?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  enclosedItems: Array<OrderEnclosedItem>;
  externalOrderNumber?: Maybe<Scalars['String']>;
  fileAttachments: Array<OrderAttachment>;
  inbundleOrders: Array<Scalars['String']>;
  invoiceBy?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  isCTScanEligible?: Maybe<Scalars['Boolean']>;
  labReceivedDate?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orderItems: Array<LegacyOrderItem>;
  orderNumber: Scalars['String'];
  orderSource?: Maybe<Scalars['String']>;
  originFacility?: Maybe<Scalars['String']>;
  originFacilityId?: Maybe<Scalars['Int']>;
  originatingSystem?: Maybe<OrderOriginatingSystem>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
  shipping?: Maybe<LegacyShipping>;
  status: OrderStatus;
  statusReason?: Maybe<Scalars['String']>;
  subtotalAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  totalFreightCharges?: Maybe<Scalars['Float']>;
  totalTaxAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<OrderType>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
  utcConversionTimeZoneCode?: Maybe<Scalars['String']>;
};

export type LegacyOrderItem = BaseOrderItem & {
  __typename: 'LegacyOrderItem';
  addOns: Array<OrderProductAttribute>;
  attributes: Array<OrderProductAttribute>;
  couponAmount?: Maybe<Scalars['Float']>;
  coupons: Array<Scalars['String']>;
  daysInLab?: Maybe<Scalars['Int']>;
  department?: Maybe<Scalars['String']>;
  itemId: Scalars['String'];
  lineItemNumber?: Maybe<Scalars['Int']>;
  manufacturingLocation?: Maybe<Scalars['String']>;
  manufacturingLocationId: Scalars['Int'];
  productCode: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  restorationType?: Maybe<Scalars['String']>;
  services: Array<OrderProductAttribute>;
  specialDiscountAmount?: Maybe<Scalars['Float']>;
  subtotalAmount?: Maybe<Scalars['Float']>;
  toothUnits?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  totalTaxAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<ProductType>;
  unitPrice?: Maybe<Scalars['Float']>;
  units?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type LegacyShipping = {
  __typename: 'LegacyShipping';
  address?: Maybe<LegacyAddress>;
  codAmount: Scalars['Float'];
  deliveryMethod?: Maybe<DeliveryMethod>;
  doctorPickupLocation?: Maybe<LegacyDoctorPickupLocation>;
  estimatedShipDate?: Maybe<Scalars['String']>;
  inboundTrackingNumbers?: Maybe<Array<TrackingNumberItem>>;
  outboundTrackingNumbers?: Maybe<Array<TrackingNumberItem>>;
  packageType?: Maybe<PackageType>;
  shippingCharges?: Maybe<Array<ShippingCharge>>;
  shippingMethod?: Maybe<ShippingMethod>;
};

export type LinkedOrderInput = {
  linkType: OrderLinkType;
  orderNumber: Scalars['String'];
};

export type List = {
  __typename: 'List';
  listItems: Array<ListItem>;
};

export enum ListCategory {
  CaseOnHoldReason = 'CaseOnHoldReason',
  ForwardReasons = 'ForwardReasons',
  RejectionReasons = 'RejectionReasons',
  RemoveFromBundleReasons = 'RemoveFromBundleReasons',
  ReturnType = 'ReturnType',
  RmaReturnReasons = 'RmaReturnReasons',
  SpecialDiscountReason = 'SpecialDiscountReason',
}

export type ListItem = {
  __typename: 'ListItem';
  category: ListCategory;
  name: Scalars['String'];
};

export type ListResult = GenericError | List;

export type LmsOrder = Order | PendingOrder | RmaOrder;

export type ManufacturingLocation = {
  __typename: 'ManufacturingLocation';
  addresses: Array<Address>;
  emailAddresses: Array<Email>;
  invoiceCutOffTime: Scalars['String'];
  /** @deprecated Replaced with invoiceCutOffTime, which is local lab time (please see LMS1-7978 */
  invoiceCutoffTimeUtc?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  locationId: Scalars['Int'];
  locationName: Scalars['String'];
  manufacturingFacilityTimeZoneId: Scalars['String'];
  phoneNumbers: Array<Phone>;
  /** @deprecated Replaced with manufacturingFacilityTimeZoneId */
  tzIdentifier?: Maybe<Scalars['String']>;
};

export type ManufacturingLocationResult = GenericError | ManufacturingLocation | NotFoundError;

export type ManufacturingLocationsResult = GenericError | ManufacturingLocationsWrapper;

export type ManufacturingLocationsWrapper = {
  __typename: 'ManufacturingLocationsWrapper';
  locations: Array<ManufacturingLocation>;
};

export enum ManufacturingStatus {
  AlreadyInvoiced = 'AlreadyInvoiced',
  Approved = 'Approved',
  Duplicate = 'Duplicate',
  Evaluated = 'Evaluated',
  Evaluation = 'Evaluation',
  Forwarded = 'Forwarded',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

export type Mutation = {
  __typename: 'Mutation';
  createBundleSplitOrder: BundleSplitOrderResult;
  createCredit: CreateCreditResult;
  createDeduction: CreateDeductionResult;
  createOrder: OrderResult;
  createRmaOrder: RmaOrderResult;
  deleteCredit: DeleteCreditResult;
  deleteDeduction: DeleteDeductionsResult;
  doctorPickupLabelAvailability: DoctorPickupLabelAvailable;
  invoice: OrdersResult;
  invoiceAvailability: InvoiceAvailable;
  orderNotificationAvailability: OrderNotificationAvailable;
  passOrderCreated: OrderCreatedTopic;
  removeBundledOrder: RemoveBundledOrderResult;
  shippingLabelAvailability: ShippingLabelAvailable;
  uninvoice: UnInvoiceResult;
  updateCaseAlertStatuses: UpdateCaseAlertsResult;
  updateDeduction: UpdateDeductionResult;
  updateOrder: OrderResult;
  updateOrderStatus: OrderResult;
  workOrderAvailability: WorkOrderAvailable;
};

export type MutationCreateBundleSplitOrderArgs = {
  input: BundleSplitOrderInput;
};

export type MutationCreateCreditArgs = {
  input: CreateCreditInput;
};

export type MutationCreateDeductionArgs = {
  input: CreateDeductionInput;
};

export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationCreateRmaOrderArgs = {
  input: CreateRmaOrderInput;
};

export type MutationDeleteCreditArgs = {
  creditNumber: Scalars['String'];
  input?: InputMaybe<DeleteCreditInput>;
};

export type MutationDeleteDeductionArgs = {
  discountId: Scalars['String'];
};

export type MutationDoctorPickupLabelAvailabilityArgs = {
  input: Scalars['String'];
};

export type MutationInvoiceArgs = {
  input: InvoiceInput;
};

export type MutationInvoiceAvailabilityArgs = {
  input: Scalars['String'];
};

export type MutationOrderNotificationAvailabilityArgs = {
  input: Scalars['String'];
};

export type MutationPassOrderCreatedArgs = {
  input: Scalars['String'];
};

export type MutationRemoveBundledOrderArgs = {
  bundleId: Scalars['String'];
  orderNumber: Scalars['String'];
};

export type MutationShippingLabelAvailabilityArgs = {
  input: Scalars['String'];
};

export type MutationUninvoiceArgs = {
  input: UnInvoiceOrderInput;
};

export type MutationUpdateCaseAlertStatusesArgs = {
  input: UpdateCaseAlertStatusesInput;
};

export type MutationUpdateDeductionArgs = {
  discountId: Scalars['String'];
  input: CreateDeductionInput;
};

export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};

export type MutationUpdateOrderStatusArgs = {
  input: UpdateOrderStatusInput;
};

export type MutationWorkOrderAvailabilityArgs = {
  input: Scalars['String'];
};

export type NextRmaOrder = {
  __typename: 'NextRmaOrder';
  orderNumber: Scalars['String'];
};

export type NextRmaOrderResult = GenericError | NextRmaOrder | NotFoundError;

export type NotFoundError = LmsError & {
  __typename: 'NotFoundError';
  message: Scalars['String'];
};

export type OpenSearchSort = {
  direction: SortOrder;
  field: Scalars['String'];
};

export type Order = BaseOrder & {
  __typename: 'Order';
  addresses: Array<Address>;
  appliedCoupons?: Maybe<Array<Coupon>>;
  appliedCredits?: Maybe<Array<Credit>>;
  appliedDiscounts?: Maybe<Array<Deduction>>;
  authorizationAmount?: Maybe<Scalars['Float']>;
  authorizationId?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
  bundles?: Maybe<OrderBundle>;
  coupons: Array<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  creditRequestedDate?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CurrencyCode>;
  customerDueDate?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  enclosedItems: Array<OrderEnclosedItem>;
  externalOrderNumber?: Maybe<Scalars['String']>;
  fileAttachments: Array<OrderAttachment>;
  invoiceBy?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  isCTScanEligible?: Maybe<Scalars['Boolean']>;
  labReceivedDate?: Maybe<Scalars['String']>;
  linkedOrderNumber?: Maybe<Scalars['String']>;
  metadata?: Maybe<OrderMetaData>;
  notes?: Maybe<Scalars['String']>;
  orderItems: Array<OrderItem>;
  orderNumber: Scalars['String'];
  orderSource?: Maybe<Scalars['String']>;
  originFacility: Scalars['String'];
  originFacilityId: Scalars['Int'];
  originalOrderNumber?: Maybe<Scalars['String']>;
  originatingSystem?: Maybe<OrderOriginatingSystem>;
  pONumber?: Maybe<Scalars['String']>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  paymentTerms?: Maybe<OrderPaymentTerms>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  sCINumber?: Maybe<Scalars['String']>;
  shipping?: Maybe<Shipping>;
  status: OrderStatus;
  statusReason?: Maybe<Scalars['String']>;
  subtotalAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  totalFreightCharges?: Maybe<Scalars['Float']>;
  totalTaxAmount?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  type?: Maybe<OrderType>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
  utcConversionTimeZoneCode: Scalars['String'];
};

export type OrderAttachment = {
  __typename: 'OrderAttachment';
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  extension: Scalars['String'];
  fileUrl: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  uploadProperties: AttachmentUploadProperties;
};

export type OrderAttachmentInput = {
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  extension?: InputMaybe<Scalars['String']>;
  fileUrl: Scalars['String'];
  name: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  uploadProperties: AttachmentUploadPropertiesInput;
};

export type OrderBundle = {
  __typename: 'OrderBundle';
  inboundBundle?: Maybe<OrderInboundOutboundBundle>;
  outboundBundle?: Maybe<OrderInboundOutboundBundle>;
  splitBundle?: Maybe<OrderSplitBundle>;
};

export type OrderCreatedTopic = {
  __typename: 'OrderCreatedTopic';
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
  Topic: Scalars['String'];
};

export type OrderEnclosedItem = {
  __typename: 'OrderEnclosedItem';
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  id: Scalars['String'];
  itemCode: Scalars['String'];
  quantity: Scalars['Int'];
  shippingType: EnclosedItemCategory;
  type: Scalars['String'];
};

export type OrderEnclosedItemInput = {
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  itemCode: Scalars['String'];
  quantity: Scalars['Int'];
  shippingType: EnclosedItemCategory;
  type: Scalars['String'];
};

export type OrderInboundOutboundBundle = {
  __typename: 'OrderInboundOutboundBundle';
  bundledOrderNumbers: Array<SearchedOrder>;
  chargedOrderNumber: Scalars['String'];
};

export type OrderInvoice = {
  coupons?: InputMaybe<Array<Scalars['String']>>;
  enclosedItems?: InputMaybe<Array<OrderEnclosedItemInput>>;
  orderNumber?: InputMaybe<Scalars['String']>;
  shipping: InvoiceShipping;
};

export type OrderItem = BaseOrderItem & {
  __typename: 'OrderItem';
  addOns: Array<OrderProductAttribute>;
  attributes: Array<OrderProductAttribute>;
  couponAmount?: Maybe<Scalars['Float']>;
  coupons: Array<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  daysInLab: Scalars['Int'];
  department: Scalars['String'];
  itemId: Scalars['String'];
  lineItemNumber?: Maybe<Scalars['Int']>;
  manufacturingLocation: Scalars['String'];
  manufacturingLocationId: Scalars['Int'];
  orderNumber?: Maybe<Scalars['String']>;
  overrides?: Maybe<Array<OverrideField>>;
  productCode: Scalars['String'];
  productDescription?: Maybe<Scalars['String']>;
  productName: Scalars['String'];
  quantity: Scalars['Float'];
  quantityBackordered?: Maybe<Scalars['Int']>;
  services: Array<OrderProductAttribute>;
  specialDiscountAmount?: Maybe<Scalars['Float']>;
  subtotalAmount?: Maybe<Scalars['Float']>;
  toothUnits?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  totalTaxAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<ProductType>;
  unitPrice?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type OrderItemInput = {
  addOns: Array<OrderProductAttributeInput>;
  attributes: Array<OrderProductAttributeInput>;
  coupons: Array<Scalars['String']>;
  daysInLab: Scalars['Int'];
  department: Scalars['String'];
  itemId?: InputMaybe<Scalars['String']>;
  manufacturingLocation: Scalars['String'];
  manufacturingLocationId: Scalars['Int'];
  material: Scalars['String'];
  overrides?: InputMaybe<Array<OverrideFieldInput>>;
  productCode: Scalars['String'];
  productName: Scalars['String'];
  quantity: Scalars['Int'];
  restorationType: Scalars['String'];
  services: Array<OrderProductAttributeInput>;
  toothUnits?: InputMaybe<Scalars['Float']>;
};

export enum OrderLinkType {
  ExchangeNewCase = 'ExchangeNewCase',
  Mp = 'MP',
  Rma = 'RMA',
}

export type OrderMetaData = {
  __typename: 'OrderMetaData';
  isPricingBeingCalculated: Scalars['Boolean'];
  isTaxBeingCalculated: Scalars['Boolean'];
};

export type OrderNotificationAvailable = {
  __typename: 'OrderNotificationAvailable';
  NotificationEvent: OrderNotificationType;
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
};

export enum OrderNotificationType {
  PricingCalculated = 'PricingCalculated',
  TaxCalculated = 'TaxCalculated',
}

export enum OrderOriginatingSystem {
  Crm = 'CRM',
  DlPlus = 'DLPlus',
  Direct = 'Direct',
  Elektra = 'Elektra',
  Lms = 'LMS',
}

export enum OrderPaymentTerms {
  CreditCard = 'CreditCard',
  Net30 = 'Net30',
}

export type OrderProductAttribute = {
  __typename: 'OrderProductAttribute';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  unitPrice?: Maybe<Scalars['Float']>;
  value: Scalars['String'];
};

export type OrderProductAttributeInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
  value: Scalars['String'];
};

export type OrderResult = GenericError | LegacyOrder | NotFoundError | Order | PendingOrder | RmaOrder;

export type OrderSearchInput = {
  billingAccountId?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortOrder>;
  orderNumber?: InputMaybe<Scalars['String']>;
  originalOrderNumber?: InputMaybe<Scalars['String']>;
  searchFields?: InputMaybe<Array<OrderSearchInputFields>>;
  size?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<OrderSearchSortColumn>;
  status?: InputMaybe<Array<OrderStatus>>;
  term?: InputMaybe<Scalars['String']>;
};

export enum OrderSearchInputFields {
  AccountNumber = 'AccountNumber',
  CaseNumber = 'CaseNumber',
  PatientName = 'PatientName',
  ProviderName = 'ProviderName',
}

export type OrderSearchResult = GenericError | SearchedOrderWrapper;

export enum OrderSearchSortColumn {
  InvoicedDate = 'InvoicedDate',
  PatientName = 'PatientName',
  ProviderName = 'ProviderName',
  ReceivedDate = 'ReceivedDate',
}

export type OrderSplitBundle = {
  __typename: 'OrderSplitBundle';
  bundleId: Scalars['String'];
  bundledOrderNumbers: Array<SearchedOrder>;
  detachedOrderNumbers: Array<SearchedOrder>;
};

export enum OrderStatus {
  CallCenter = 'CallCenter',
  Cancelled = 'Cancelled',
  CreditPending = 'CreditPending',
  Credited = 'Credited',
  Deleted = 'Deleted',
  Forwarded = 'Forwarded',
  InLab = 'InLab',
  InvoicePending = 'InvoicePending',
  Invoiced = 'Invoiced',
  Manufacturing = 'Manufacturing',
  OnHold = 'OnHold',
  Pending = 'Pending',
  Processing = 'Processing',
  Shipped = 'Shipped',
  UnInvoiced = 'UnInvoiced',
}

export type OrderTransactionSearchInput = {
  endDate?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<OrderStatus>>;
  trackingNumber?: InputMaybe<Scalars['String']>;
};

export type OrderTransactionSearchResult = GenericError | NotFoundError | OrderTransactionWrapper;

export type OrderTransactionWrapper = {
  __typename: 'OrderTransactionWrapper';
  transactions: Array<LmsOrder>;
};

export enum OrderType {
  Digital = 'Digital',
  Physical = 'Physical',
}

export type OrderWrapper = {
  __typename: 'OrderWrapper';
  orders: Array<Order>;
};

export type OrdersResult = GenericError | NotFoundError | OrderWrapper;

export type OtherCaseAlertDetail = {
  __typename: 'OtherCaseAlertDetail';
  notes?: Maybe<Scalars['String']>;
};

export type OverrideField = {
  __typename: 'OverrideField';
  field: Scalars['String'];
  new: Scalars['String'];
  old: Scalars['String'];
  type: OverrideFieldType;
};

export type OverrideFieldInput = {
  field: Scalars['String'];
  new: Scalars['String'];
  old: Scalars['String'];
  type: OverrideFieldType;
};

export enum OverrideFieldType {
  Default = 'Default',
  ShippingPreference = 'ShippingPreference',
  TechnicalPreference = 'TechnicalPreference',
}

export enum PackageType {
  Box = 'Box',
  Envelope = 'Envelope',
  PredefinedPackage = 'PredefinedPackage',
}

export type PendingOrder = BaseOrder & {
  __typename: 'PendingOrder';
  billingAccountId?: Maybe<Scalars['String']>;
  enclosedItems: Array<OrderEnclosedItem>;
  externalOrderNumber?: Maybe<Scalars['String']>;
  fileAttachments: Array<OrderAttachment>;
  isCTScanEligible?: Maybe<Scalars['Boolean']>;
  linkedOrderNumber?: Maybe<Scalars['String']>;
  orderItems: Array<PendingOrderItem>;
  orderNumber: Scalars['String'];
  orderSource?: Maybe<Scalars['String']>;
  originalOrderNumber?: Maybe<Scalars['String']>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
  shipping?: Maybe<Shipping>;
  status: OrderStatus;
};

export type PendingOrderItem = BaseOrderItem & {
  __typename: 'PendingOrderItem';
  addOns?: Maybe<Array<OrderProductAttribute>>;
  attributes: Array<OrderProductAttribute>;
  itemId: Scalars['String'];
  productCode: Scalars['String'];
  productName: Scalars['String'];
  services?: Maybe<Array<OrderProductAttribute>>;
  toothUnits?: Maybe<Scalars['Float']>;
};

export type Phone = BasePhone & {
  __typename: 'Phone';
  country?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  type: PhoneType;
};

export enum PhoneType {
  Fax = 'Fax',
  Home = 'Home',
  Mobile = 'Mobile',
  Office = 'Office',
}

export type PracticeSearchRecord = {
  __typename: 'PracticeSearchRecord';
  practiceId: Scalars['Int'];
  practiceName: Scalars['String'];
};

export enum PracticeType {
  Clinic = 'Clinic',
  Hospital = 'Hospital',
  Lab = 'Lab',
  MilitaryBase = 'MilitaryBase',
  Practice = 'Practice',
  PublicHealthGovernment = 'PublicHealthGovernment',
  SchoolUniversity = 'SchoolUniversity',
}

export type PreSignedUrl = {
  __typename: 'PreSignedUrl';
  fileName: Scalars['String'];
  location: Scalars['String'];
  url: Scalars['String'];
};

export type PreSignedUrlFileInput = {
  category: Scalars['String'];
  createdBy: Scalars['String'];
  fileNames: Array<Scalars['String']>;
};

export type PreSignedUrlResult = GenericError | PreSignedUrlWrapper;

export type PreSignedUrlWrapper = {
  __typename: 'PreSignedUrlWrapper';
  preSignedUrls: Array<PreSignedUrl>;
};

export enum PreferenceType {
  Communication = 'Communication',
  Marketing = 'Marketing',
  Shipping = 'Shipping',
  Technical = 'Technical',
}

export type PreferencesByAccountAndProvider = {
  __typename: 'PreferencesByAccountAndProvider';
  billingAccountId: Scalars['String'];
  preferences: Array<TechnicalPreference>;
  providerId: Scalars['String'];
};

export type PreferredCarrier = {
  __typename: 'PreferredCarrier';
  carrier: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  options?: Maybe<PreferredCarrierOptions>;
  service?: Maybe<Scalars['String']>;
  start: Scalars['String'];
};

export type PreferredCarrierOptions = {
  __typename: 'PreferredCarrierOptions';
  signatureRequired: Scalars['Boolean'];
};

export type Product = {
  __typename: 'Product';
  attributes: Array<ProductAttribute>;
  components: Array<ProductComponent>;
  productCode: Scalars['String'];
  productName: Scalars['String'];
  type: ProductType;
};

export type ProductAttribute = {
  __typename: 'ProductAttribute';
  attribute: Attribute;
  defaultValue?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  rank?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export enum ProductAttributeValueType {
  Bool = 'Bool',
  Diagram = 'Diagram',
  Enum = 'Enum',
  Number = 'Number',
  Radio = 'Radio',
  String = 'String',
  ToothSelection = 'ToothSelection',
}

export type ProductComponent = {
  __typename: 'ProductComponent';
  abbreviation: Scalars['String'];
  type: ProductComponentType;
  value: Scalars['String'];
};

export enum ProductComponentType {
  Material = 'Material',
  MaterialClass = 'MaterialClass',
  ProsthesisType = 'ProsthesisType',
  RestorationType = 'RestorationType',
}

export type ProductFull = {
  __typename: 'ProductFull';
  attributes: Array<ProductFullAttribute>;
  components: Array<ProductComponent>;
  productCode: Scalars['String'];
  productName: Scalars['String'];
  type: ProductType;
};

export type ProductFullAttribute = {
  __typename: 'ProductFullAttribute';
  attributeGroupName?: Maybe<Scalars['String']>;
  attributeOptions: Array<Scalars['String']>;
  attributeRules?: Maybe<Array<AttributeRule>>;
  attributeValueType: ProductAttributeValueType;
  defaultValue?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  displayType?: Maybe<AttributeDisplayType>;
  isCustomAttribute: Scalars['Boolean'];
  isPricing: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  rank?: Maybe<Scalars['Int']>;
  reportingAttributes?: Maybe<Array<ReportingAttribute>>;
  type: Scalars['String'];
};

export type ProductFullResult = GenericError | NotFoundError | ProductFull;

export type ProductResult = GenericError | NotFoundError | Product;

export type ProductReturnRequirement = {
  __typename: 'ProductReturnRequirement';
  isReturnRequired: Scalars['Boolean'];
  productCode: Scalars['String'];
};

export type ProductReturnRequirementResult = GenericError | NotFoundError | ProductReturnRequirement;

export enum ProductType {
  Product = 'Product',
  Service = 'Service',
}

export type Provider = BaseProvider & {
  __typename: 'Provider';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  providerId: Scalars['Int'];
  salutation?: Maybe<Salutation>;
  suffix?: Maybe<Scalars['String']>;
};

export type ProviderFilter = {
  role?: InputMaybe<AccountRole>;
};

export type ProviderSearchResult = BaseProvider & {
  __typename: 'ProviderSearchResult';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  license: Scalars['String'];
  providerId: Scalars['Int'];
};

export enum ProviderStatus {
  Active = 'Active',
  Deceased = 'Deceased',
  Moved = 'Moved',
  Retired = 'Retired',
}

export type Query = {
  __typename: 'Query';
  account: AccountResult;
  accountFinance: AccountFinanceResult;
  availableCredits: AvailableCreditsResult;
  availableDiscounts: AvailableDiscountsResult;
  caseAlerts: CaseAlertsResult;
  caseHistory: CaseHistoryResult;
  classifications: ClassificationsResult;
  coupon: CouponResult;
  customerAccountSearch: CustomerAccountSearchResult;
  dashboard: DashboardResult;
  doctorPickupLabel: DoctorPickupLabelResult;
  enclosedItems: EnclosedItemsResult;
  getConfigs: ConfigResult;
  list: ListResult;
  manufacturingLocation: ManufacturingLocationResult;
  manufacturingLocations: ManufacturingLocationsResult;
  nextRmaOrder: NextRmaOrderResult;
  order: OrderResult;
  orderInvoice: InvoiceResult;
  orderSearch: OrderSearchResult;
  orderTransactionSearch: OrderTransactionSearchResult;
  preSignedUploadUrl: PreSignedUrlResult;
  product: ProductResult;
  productAttribute: AttributeResult;
  productBrand: BrandResult;
  productFull: ProductFullResult;
  productReturnRequirement: ProductReturnRequirementResult;
  relatedCases: RelatedCasesSearchResult;
  relatedCasesSearch: OrderSearchResult;
  shippingOptions: ShippingOptionsResult;
  shippingOrder: ShippingOrderResult;
  shippingPreferences: ShippingPreferencesResult;
  technicalPreferences: TechnicalPreferencesResult;
  validateAddress: AddressValidationResult;
  workOrder: WorkOrderResult;
};

export type QueryAccountArgs = {
  billingAccountId: Scalars['String'];
};

export type QueryAccountFinanceArgs = {
  billingAccountId: Scalars['String'];
};

export type QueryAvailableCreditsArgs = {
  input: AvailableCreditsInput;
};

export type QueryAvailableDiscountsArgs = {
  input: AvailableDiscountsInput;
};

export type QueryCaseAlertsArgs = {
  input: CaseAlertsSearchInput;
};

export type QueryCaseHistoryArgs = {
  orderNumber: Scalars['String'];
};

export type QueryCouponArgs = {
  couponCode: Scalars['String'];
};

export type QueryCustomerAccountSearchArgs = {
  input: CustomerAccountSearchInput;
};

export type QueryDashboardArgs = {
  input: DashboardInput;
};

export type QueryDoctorPickupLabelArgs = {
  orderNumber: Scalars['String'];
};

export type QueryEnclosedItemsArgs = {
  category?: InputMaybe<EnclosedItemCategory>;
};

export type QueryListArgs = {
  category: ListCategory;
};

export type QueryManufacturingLocationArgs = {
  locationId: Scalars['String'];
};

export type QueryNextRmaOrderArgs = {
  orderNumber: Scalars['String'];
};

export type QueryOrderArgs = {
  orderNumber: Scalars['String'];
};

export type QueryOrderInvoiceArgs = {
  orderNumber: Scalars['String'];
};

export type QueryOrderSearchArgs = {
  input: OrderSearchInput;
};

export type QueryOrderTransactionSearchArgs = {
  input: OrderTransactionSearchInput;
};

export type QueryPreSignedUploadUrlArgs = {
  files: PreSignedUrlFileInput;
};

export type QueryProductArgs = {
  productCode: Scalars['String'];
};

export type QueryProductAttributeArgs = {
  attributeName: Scalars['String'];
  attributeType?: InputMaybe<Scalars['String']>;
};

export type QueryProductBrandArgs = {
  productCode: Scalars['String'];
};

export type QueryProductFullArgs = {
  productCode: Scalars['String'];
};

export type QueryProductReturnRequirementArgs = {
  productCode: Scalars['String'];
};

export type QueryRelatedCasesArgs = {
  input: RelatedCasesInput;
};

export type QueryRelatedCasesSearchArgs = {
  input: RelatedCasesSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<OpenSearchSort>>;
};

export type QueryShippingOptionsArgs = {
  input: ShippingOptionsInput;
};

export type QueryShippingOrderArgs = {
  orderNumber: Scalars['String'];
};

export type QueryShippingPreferencesArgs = {
  billingAccountId: Scalars['String'];
};

export type QueryTechnicalPreferencesArgs = {
  input: TechnicalPreferencesInput;
};

export type QueryValidateAddressArgs = {
  input: AddressValidationInput;
};

export type QueryWorkOrderArgs = {
  orderNumber: Scalars['String'];
};

export type Recipient = {
  address: InputAddress;
  company?: InputMaybe<Scalars['String']>;
  email: InputEmail;
  name: Scalars['String'];
  phone: InputPhone;
};

export type RelatedCasesInput = {
  orderNumber?: InputMaybe<Scalars['String']>;
  originalOrderNumber: Scalars['String'];
};

export type RelatedCasesSearchInput = {
  billingAccountId: Scalars['String'];
  patientFirstName?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  patientLastName?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<OrderStatus>>;
};

export type RelatedCasesSearchResult = GenericError | NotFoundError | RelatedCasesWrapper;

export type RelatedCasesWrapper = {
  __typename: 'RelatedCasesWrapper';
  orders: Array<RelatedOrders>;
};

export type RelatedOrders = LegacyOrder | Order | PendingOrder | RmaOrder;

export type RemoveBundledOrderResponse = {
  __typename: 'RemoveBundledOrderResponse';
  result: Scalars['String'];
};

export type RemoveBundledOrderResult = GenericError | NotFoundError | RemoveBundledOrderResponse;

export type ReportingAttribute = {
  __typename: 'ReportingAttribute';
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum ReturnType {
  Adjust = 'Adjust',
  Exchange = 'Exchange',
  Remake = 'Remake',
  ReturnForCredit = 'ReturnForCredit',
}

export type RmaExchangeNewProduct = {
  __typename: 'RmaExchangeNewProduct';
  productCode: Scalars['String'];
  productName: Scalars['String'];
};

export type RmaExchangeNewProductInput = {
  productCode: Scalars['String'];
  productName: Scalars['String'];
};

export type RmaOrder = BaseOrder & {
  __typename: 'RmaOrder';
  addresses: Array<Address>;
  appliedCoupons?: Maybe<Array<Coupon>>;
  appliedCredits?: Maybe<Array<Credit>>;
  appliedDiscounts?: Maybe<Array<Deduction>>;
  authorizationAmount?: Maybe<Scalars['Float']>;
  authorizationId?: Maybe<Scalars['String']>;
  billingAccountId?: Maybe<Scalars['String']>;
  bundles?: Maybe<OrderBundle>;
  coupons: Array<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  creditRequestedDate?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<CurrencyCode>;
  customerDueDate?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  enclosedItems: Array<OrderEnclosedItem>;
  externalOrderNumber?: Maybe<Scalars['String']>;
  fileAttachments: Array<OrderAttachment>;
  invoiceBy?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  isCTScanEligible?: Maybe<Scalars['Boolean']>;
  labReceivedDate?: Maybe<Scalars['String']>;
  linkedOrderNumber?: Maybe<Scalars['String']>;
  metadata?: Maybe<OrderMetaData>;
  notes?: Maybe<Scalars['String']>;
  orderItems: Array<RmaOrderItem>;
  orderNumber: Scalars['String'];
  orderSource?: Maybe<Scalars['String']>;
  originFacility: Scalars['String'];
  originFacilityId: Scalars['Int'];
  originalOrderNumber: Scalars['String'];
  originatingSystem?: Maybe<OrderOriginatingSystem>;
  pONumber?: Maybe<Scalars['String']>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  paymentTerms?: Maybe<OrderPaymentTerms>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  sCINumber?: Maybe<Scalars['String']>;
  shipping?: Maybe<Shipping>;
  status: OrderStatus;
  statusReason?: Maybe<Scalars['String']>;
  subtotalAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  totalFreightCharges?: Maybe<Scalars['Float']>;
  totalTaxAmount?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  type?: Maybe<OrderType>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
  utcConversionTimeZoneCode: Scalars['String'];
};

export type RmaOrderItem = BaseOrderItem & {
  __typename: 'RmaOrderItem';
  addOns: Array<OrderProductAttribute>;
  attributes: Array<OrderProductAttribute>;
  couponAmount?: Maybe<Scalars['Float']>;
  coupons: Array<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  daysInLab: Scalars['Int'];
  department: Scalars['String'];
  isOldProductReturned?: Maybe<Scalars['Boolean']>;
  isProcessSwap?: Maybe<Scalars['Boolean']>;
  itemId: Scalars['String'];
  lineItemNumber?: Maybe<Scalars['Int']>;
  manufacturingLocation: Scalars['String'];
  manufacturingLocationId: Scalars['Int'];
  newProduct?: Maybe<RmaExchangeNewProduct>;
  orderNumber?: Maybe<Scalars['String']>;
  overrides?: Maybe<Array<OverrideField>>;
  productCode: Scalars['String'];
  productDescription?: Maybe<Scalars['String']>;
  productName: Scalars['String'];
  quantity: Scalars['Float'];
  quantityBackordered?: Maybe<Scalars['Int']>;
  returnReasons?: Maybe<Array<Scalars['String']>>;
  returnType?: Maybe<ReturnType>;
  services: Array<OrderProductAttribute>;
  specialDiscountAmount?: Maybe<Scalars['Float']>;
  subtotalAmount?: Maybe<Scalars['Float']>;
  toothUnits?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalDiscountAmount?: Maybe<Scalars['Float']>;
  totalTaxAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<ProductType>;
  unitPrice?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type RmaOrderItemInput = {
  addOns: Array<OrderProductAttributeInput>;
  attributes: Array<OrderProductAttributeInput>;
  coupons: Array<Scalars['String']>;
  daysInLab: Scalars['Int'];
  department: Scalars['String'];
  isOldProductReturned: Scalars['Boolean'];
  itemId?: InputMaybe<Scalars['String']>;
  manufacturingLocation: Scalars['String'];
  manufacturingLocationId: Scalars['Int'];
  material: Scalars['String'];
  newProduct?: InputMaybe<RmaExchangeNewProductInput>;
  overrides?: InputMaybe<Array<OverrideFieldInput>>;
  productCode: Scalars['String'];
  productName: Scalars['String'];
  quantity: Scalars['Int'];
  restorationType: Scalars['String'];
  returnReasons: Array<Scalars['String']>;
  returnType: ReturnType;
  services: Array<OrderProductAttributeInput>;
  toothUnits?: InputMaybe<Scalars['Float']>;
};

export type RmaOrderResult = BadRequestError | GenericError | NotFoundError | RmaOrder;

export enum RouteType {
  Self = 'Self',
  TechnicalAdvisor = 'TechnicalAdvisor',
  Workbasket = 'Workbasket',
}

export enum Salutation {
  Dr = 'Dr',
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Prof = 'Prof',
}

export type SearchedOrder = {
  __typename: 'SearchedOrder';
  billingAccountId?: Maybe<Scalars['String']>;
  createdByTechId?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  labReceivedDate?: Maybe<Scalars['String']>;
  orderItems: Array<SearchedOrderItem>;
  orderNumber: Scalars['String'];
  originFacilityId?: Maybe<Scalars['Int']>;
  originatingSystem: OrderOriginatingSystem;
  patientFirstName?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
  status: OrderStatus;
  updatedByTechId?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
  utcConversionTimeZoneCode?: Maybe<Scalars['String']>;
};

export type SearchedOrderItem = {
  __typename: 'SearchedOrderItem';
  attributes: Array<OrderProductAttribute>;
  productCode?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  returnReasons?: Maybe<Array<Scalars['String']>>;
  returnType?: Maybe<ReturnType>;
};

export type SearchedOrderWrapper = {
  __typename: 'SearchedOrderWrapper';
  orders: Array<SearchedOrder>;
  total: Scalars['Int'];
};

export type Shipping = ShippingInterface & {
  __typename: 'Shipping';
  address?: Maybe<Address>;
  codAmount: Scalars['Float'];
  deliveryMethod?: Maybe<DeliveryMethod>;
  doctorPickupLocation?: Maybe<DoctorPickupLocation>;
  estimatedShipDate?: Maybe<Scalars['String']>;
  inboundTrackingNumbers: Array<TrackingNumberItem>;
  outboundTrackingNumbers: Array<TrackingNumberItem>;
  packageType?: Maybe<PackageType>;
  rateId?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['String']>;
  shippingCharges?: Maybe<Array<ShippingCharge>>;
  shippingMethod?: Maybe<ShippingMethod>;
};

export type ShippingAddress = BaseAddress & {
  __typename: 'ShippingAddress';
  city: Scalars['String'];
  country: Scalars['String'];
  division?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  street3?: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type ShippingCharge = {
  __typename: 'ShippingCharge';
  amount: Scalars['Float'];
  carrier: Scalars['String'];
  discountAmount: Scalars['Float'];
  id: Scalars['String'];
  salesTaxAmount?: Maybe<Scalars['Float']>;
  service: Scalars['String'];
  taxCode?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Float'];
  type: ShippingChargeType;
};

export enum ShippingChargeType {
  Cod = 'COD',
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

export type ShippingEmail = BaseEmail & {
  __typename: 'ShippingEmail';
  emailAddress?: Maybe<Scalars['String']>;
};

export type ShippingInterface = {
  address?: Maybe<Address>;
  codAmount: Scalars['Float'];
  deliveryMethod?: Maybe<DeliveryMethod>;
  doctorPickupLocation?: Maybe<DoctorPickupLocation>;
  estimatedShipDate?: Maybe<Scalars['String']>;
  inboundTrackingNumbers?: Maybe<Array<TrackingNumberItem>>;
  outboundTrackingNumbers?: Maybe<Array<TrackingNumberItem>>;
  packageType?: Maybe<PackageType>;
  shippingCharges?: Maybe<Array<ShippingCharge>>;
  shippingMethod?: Maybe<ShippingMethod>;
};

export type ShippingLabel = {
  __typename: 'ShippingLabel';
  type: Scalars['String'];
  url: Scalars['String'];
};

export type ShippingLabelAvailable = {
  __typename: 'ShippingLabelAvailable';
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
};

export type ShippingLabelUrl = {
  __typename: 'ShippingLabelUrl';
  type: ShippingLabelUrlType;
  url?: Maybe<Scalars['String']>;
};

export enum ShippingLabelUrlType {
  Epl2 = 'EPL2',
  Pdf = 'PDF',
  Png = 'PNG',
  Zpl = 'ZPL',
}

export enum ShippingMethod {
  Ground = 'GROUND',
  International = 'INTERNATIONAL',
  Localpickup = 'LOCALPICKUP',
  Nextday = 'NEXTDAY',
  Nextdayam = 'NEXTDAYAM',
  Nextdaypm = 'NEXTDAYPM',
  Nomedia = 'NOMEDIA',
  Pickupdupont = 'PICKUPDUPONT',
  Pickupfrom18 = 'PICKUPFROM18',
  Pickupfrom21 = 'PICKUPFROM21',
  Pickupfrom41 = 'PICKUPFROM41',
  Pickupmacarth = 'PICKUPMACARTH',
  Pickupvk = 'PICKUPVK',
  Saturday = 'SATURDAY',
  Threeday = 'THREEDAY',
  Twoday = 'TWODAY',
}

export type ShippingOption = {
  __typename: 'ShippingOption';
  carrier: Scalars['String'];
  charges?: Maybe<Array<Charge>>;
  cost: Scalars['Float'];
  isSaturdayDelivery: Scalars['Boolean'];
  package?: Maybe<PackageType>;
  predefinedPackage?: Maybe<Scalars['String']>;
  rateId: Scalars['String'];
  requiresSignature: Scalars['Boolean'];
  service: Scalars['String'];
  shipmentId: Scalars['String'];
};

export type ShippingOptionsInput = {
  collectOnDelivery?: InputMaybe<CollectOnDelivery>;
  invoiceNumber: Scalars['String'];
  originFacilityId: Scalars['Int'];
  primaryReferenceField?: InputMaybe<Scalars['String']>;
  recipient: Recipient;
  secondaryReferenceField?: InputMaybe<Scalars['String']>;
};

export type ShippingOptionsResult = GenericError | ShippingOptionsWrapper;

export type ShippingOptionsWrapper = {
  __typename: 'ShippingOptionsWrapper';
  shippingOptions: Array<ShippingOption>;
};

export type ShippingOrder = {
  __typename: 'ShippingOrder';
  carrier: Scalars['String'];
  cost: Scalars['Float'];
  createdBy: Scalars['String'];
  createdDate: Scalars['String'];
  isReturn: Scalars['Boolean'];
  orderNumber: Scalars['String'];
  packageDimensions: ShippingPackageDimension;
  recipient: ShippingOrderAddress;
  sender: ShippingOrderAddress;
  serviceName: Scalars['String'];
  shipmentDate: Scalars['String'];
  shipmentId: Scalars['String'];
  shipmentType: Scalars['String'];
  shippingLabelUrls: Array<ShippingLabelUrl>;
  status: ShippingStatus;
  trackingNumber: Scalars['String'];
};

export type ShippingOrderAddress = {
  __typename: 'ShippingOrderAddress';
  address: ShippingAddress;
  company: Scalars['String'];
  email: ShippingEmail;
  name?: Maybe<Scalars['String']>;
  phone: ShippingPhone;
};

export type ShippingOrderResult = GenericError | NotFoundError | ShippingOrderWrapper;

export type ShippingOrderWrapper = {
  __typename: 'ShippingOrderWrapper';
  items: Array<ShippingOrder>;
};

export type ShippingPackageDimension = {
  __typename: 'ShippingPackageDimension';
  declaredValue: Scalars['Int'];
  height: Scalars['Int'];
  length: Scalars['Int'];
  weight: Scalars['Int'];
  width: Scalars['Int'];
};

export type ShippingPhone = BasePhone & {
  __typename: 'ShippingPhone';
  country?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type ShippingPreferences = {
  __typename: 'ShippingPreferences';
  billingAccountId: Scalars['String'];
  doNotShip: Array<DoNotShip>;
  preferredCarrier?: Maybe<PreferredCarrier>;
  type: PreferenceType;
};

export type ShippingPreferencesResult = GenericError | NotFoundError | ShippingPreferences;

export type ShippingResult = LegacyShipping | Shipping;

export enum ShippingStatus {
  Active = 'Active',
  Voided = 'Voided',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Subscription = {
  __typename: 'Subscription';
  doctorLabelAvailable?: Maybe<DoctorPickupLabelAvailable>;
  invoiceAvailable?: Maybe<InvoiceAvailable>;
  orderNotificationAvailable?: Maybe<OrderNotificationAvailable>;
  shippingLabelAvailable?: Maybe<ShippingLabelAvailable>;
  subscribeToOrderCreated?: Maybe<OrderCreatedTopic>;
  workOrderAvailable?: Maybe<WorkOrderAvailable>;
};

export type SubscriptionDoctorLabelAvailableArgs = {
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
};

export type SubscriptionInvoiceAvailableArgs = {
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
};

export type SubscriptionOrderNotificationAvailableArgs = {
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
};

export type SubscriptionShippingLabelAvailableArgs = {
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
};

export type SubscriptionSubscribeToOrderCreatedArgs = {
  OrderNumber?: InputMaybe<Scalars['String']>;
  SessionId: Scalars['String'];
};

export type SubscriptionWorkOrderAvailableArgs = {
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
};

export type TechnicalPreference = {
  __typename: 'TechnicalPreference';
  attributeName: Scalars['String'];
  attributeValue: Scalars['String'];
  productCode: Scalars['String'];
};

export type TechnicalPreferences = {
  __typename: 'TechnicalPreferences';
  preferencesByAccountAndProvider: PreferencesByAccountAndProvider;
};

export type TechnicalPreferencesInput = {
  billingAccountId: Scalars['String'];
  providerId: Scalars['String'];
};

export type TechnicalPreferencesResult = GenericError | NotFoundError | TechnicalPreferences;

export type TrackingNumberItem = {
  __typename: 'TrackingNumberItem';
  carrier?: Maybe<Scalars['String']>;
  insertedBy: Scalars['String'];
  insertionDate: Scalars['String'];
  service?: Maybe<Scalars['String']>;
  shippingLabelUrls?: Maybe<Array<ShippingLabel>>;
  trackingNumber: Scalars['String'];
};

export type TrackingNumberItemInput = {
  insertedBy: Scalars['String'];
  insertionDate: Scalars['String'];
  trackingNumber: Scalars['String'];
};

export type UnInvoiceOrderInput = {
  orderNumber: Scalars['String'];
  updatedBy: Scalars['String'];
  utcConversionTimeZone: Scalars['String'];
};

export type UnInvoiceResponse = {
  __typename: 'UnInvoiceResponse';
  success: Scalars['Boolean'];
};

export type UnInvoiceResult = GenericError | UnInvoiceResponse;

export type UpdateCaseAlertDetail = {
  __typename: 'UpdateCaseAlertDetail';
  notes?: Maybe<Scalars['String']>;
  product?: Maybe<AlertUpdateItem>;
  shade?: Maybe<AlertUpdateItem>;
  shippingAddress?: Maybe<CaseAddress>;
  toothNumber?: Maybe<AlertUpdateItem>;
};

export type UpdateCaseAlertStatusesInput = {
  alertIds: Array<AlertId>;
  appliedBy: Scalars['String'];
  appliedByTechId: Scalars['String'];
  orderNumber: Scalars['String'];
};

export type UpdateCaseAlertsResult = GenericError | NotFoundError | UpdateCaseAlertsSuccess;

export type UpdateCaseAlertsSuccess = {
  __typename: 'UpdateCaseAlertsSuccess';
  status: Scalars['Int'];
};

export type UpdateDeductionResult = Deduction | GenericError | NotFoundError;

export type UpdateOrderInput = {
  billingAccountId: Scalars['String'];
  coupons: Array<Scalars['String']>;
  customerDueDate?: InputMaybe<Scalars['String']>;
  enclosedItems: Array<OrderEnclosedItemInput>;
  estimatedShipDate: Scalars['String'];
  externalOrderNumber?: InputMaybe<Scalars['String']>;
  fileAttachments: Array<OrderAttachmentInput>;
  hasAppliedAlerts?: InputMaybe<Scalars['Boolean']>;
  inboundTrackingNumbers: Array<TrackingNumberItemInput>;
  notes?: InputMaybe<Scalars['String']>;
  orderItems: Array<OrderItemInput>;
  orderNumber: Scalars['String'];
  orderSource?: InputMaybe<Scalars['String']>;
  originFacility: Scalars['String'];
  originFacilityId: Scalars['Int'];
  patientFirstName?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  patientLastName?: InputMaybe<Scalars['String']>;
  providerId: Scalars['String'];
  providerName: Scalars['String'];
  shippingAddress: AddressInput;
  updatedBy: Scalars['String'];
  updatedDate: Scalars['String'];
  utcConversionTimeZone: Scalars['String'];
};

export type UpdateOrderStatusInput = {
  orderNumber: Scalars['String'];
  orderStatus: OrderStatus;
  statusReason?: InputMaybe<Scalars['String']>;
  updatedBy: Scalars['String'];
};

export type UsageLimit = {
  __typename: 'UsageLimit';
  accountLimit?: Maybe<Scalars['Int']>;
  orderLimit: Scalars['Int'];
  rxLimit: Scalars['Int'];
};

export type WorkOrder = {
  __typename: 'WorkOrder';
  html: Scalars['String'];
};

export type WorkOrderAvailable = {
  __typename: 'WorkOrderAvailable';
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
};

export type WorkOrderResult = GenericError | NotFoundError | WorkOrder;

export type Handle = {
  __typename: 'handle';
  computerGeneratedHandle?: Maybe<ComputerGeneratedHandle>;
  enclosedItemReference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  impressionType?: Maybe<Scalars['String']>;
  isCTScanEligible?: Maybe<Scalars['Boolean']>;
  material?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
};

export type OrderMetadataFragmentFragment = {
  __typename: 'OrderMetaData';
  isPricingBeingCalculated: boolean;
  isTaxBeingCalculated: boolean;
};

export type TrackingNumberItemFragmentFragment = {
  __typename: 'TrackingNumberItem';
  trackingNumber: string;
  insertionDate: string;
  insertedBy: string;
  carrier?: string | null;
  service?: string | null;
  shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
};

type BaseOrderItemFragment_LegacyOrderItem_Fragment = {
  __typename: 'LegacyOrderItem';
  itemId: string;
  productName?: string | null;
  productCode: string;
  attributes: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
  }>;
};

type BaseOrderItemFragment_OrderItem_Fragment = {
  __typename: 'OrderItem';
  itemId: string;
  productName: string;
  productCode: string;
  attributes: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
  }>;
};

type BaseOrderItemFragment_PendingOrderItem_Fragment = {
  __typename: 'PendingOrderItem';
  itemId: string;
  productName: string;
  productCode: string;
  attributes: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
  }>;
};

type BaseOrderItemFragment_RmaOrderItem_Fragment = {
  __typename: 'RmaOrderItem';
  itemId: string;
  productName: string;
  productCode: string;
  attributes: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
  }>;
};

export type BaseOrderItemFragmentFragment =
  | BaseOrderItemFragment_LegacyOrderItem_Fragment
  | BaseOrderItemFragment_OrderItem_Fragment
  | BaseOrderItemFragment_PendingOrderItem_Fragment
  | BaseOrderItemFragment_RmaOrderItem_Fragment;

export type OrderProductAttributeFragmentFragment = {
  __typename: 'OrderProductAttribute';
  type: string;
  name: string;
  value: string;
  quantity?: number | null;
  unitPrice?: number | null;
  id?: string | null;
};

export type OverridesFragmentFragment = {
  __typename: 'OverrideField';
  field: string;
  type: OverrideFieldType;
  old: string;
  new: string;
};

export type OrderItemFragmentFragment = {
  __typename: 'OrderItem';
  quantity: number;
  quantityBackordered?: number | null;
  unitPrice?: number | null;
  toothUnits?: number | null;
  lineItemNumber?: number | null;
  type?: ProductType | null;
  department: string;
  productDescription?: string | null;
  subtotalAmount?: number | null;
  totalTaxAmount?: number | null;
  totalDiscountAmount?: number | null;
  totalAmount?: number | null;
  coupons: Array<string>;
  couponAmount?: number | null;
  specialDiscountAmount?: number | null;
  daysInLab: number;
  orderNumber?: string | null;
  manufacturingLocation: string;
  manufacturingLocationId: number;
  createdDate?: string | null;
  createdBy?: string | null;
  updatedDate?: string | null;
  updatedBy?: string | null;
  itemId: string;
  productName: string;
  productCode: string;
  services: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
    unitPrice?: number | null;
    id?: string | null;
  }>;
  addOns: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
    unitPrice?: number | null;
    id?: string | null;
  }>;
  attributes: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
    unitPrice?: number | null;
    id?: string | null;
  }>;
  overrides?: Array<{
    __typename: 'OverrideField';
    field: string;
    type: OverrideFieldType;
    old: string;
    new: string;
  }> | null;
};

export type LegacyOrderItemFragmentFragment = {
  __typename: 'LegacyOrderItem';
  units?: Array<string | null> | null;
  quantity: number;
  unitPrice?: number | null;
  toothUnits?: number | null;
  lineItemNumber?: number | null;
  type?: ProductType | null;
  department?: string | null;
  restorationType?: string | null;
  subtotalAmount?: number | null;
  totalTaxAmount?: number | null;
  totalDiscountAmount?: number | null;
  totalAmount?: number | null;
  coupons: Array<string>;
  couponAmount?: number | null;
  specialDiscountAmount?: number | null;
  daysInLab?: number | null;
  manufacturingLocation?: string | null;
  manufacturingLocationId: number;
  updatedBy?: string | null;
  updatedDate?: string | null;
  itemId: string;
  productName?: string | null;
  productCode: string;
  services: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
    unitPrice?: number | null;
    id?: string | null;
  }>;
  addOns: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
    unitPrice?: number | null;
    id?: string | null;
  }>;
  attributes: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
  }>;
};

export type RmaOrderItemFragmentFragment = {
  __typename: 'RmaOrderItem';
  itemId: string;
  quantity: number;
  quantityBackordered?: number | null;
  unitPrice?: number | null;
  toothUnits?: number | null;
  lineItemNumber?: number | null;
  type?: ProductType | null;
  department: string;
  productDescription?: string | null;
  subtotalAmount?: number | null;
  totalTaxAmount?: number | null;
  totalDiscountAmount?: number | null;
  totalAmount?: number | null;
  coupons: Array<string>;
  couponAmount?: number | null;
  specialDiscountAmount?: number | null;
  daysInLab: number;
  orderNumber?: string | null;
  manufacturingLocation: string;
  manufacturingLocationId: number;
  createdDate?: string | null;
  createdBy?: string | null;
  updatedDate?: string | null;
  updatedBy?: string | null;
  isOldProductReturned?: boolean | null;
  returnReasons?: Array<string> | null;
  returnType?: ReturnType | null;
  isProcessSwap?: boolean | null;
  productName: string;
  productCode: string;
  attributes: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
    unitPrice?: number | null;
    id?: string | null;
  }>;
  services: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
    unitPrice?: number | null;
    id?: string | null;
  }>;
  addOns: Array<{
    __typename: 'OrderProductAttribute';
    type: string;
    name: string;
    value: string;
    quantity?: number | null;
    unitPrice?: number | null;
    id?: string | null;
  }>;
  newProduct?: { __typename: 'RmaExchangeNewProduct'; productName: string; productCode: string } | null;
  overrides?: Array<{
    __typename: 'OverrideField';
    field: string;
    type: OverrideFieldType;
    old: string;
    new: string;
  }> | null;
};

export type OrderEnclosedItemFragmentFragment = {
  __typename: 'OrderEnclosedItem';
  id: string;
  type: string;
  itemCode: string;
  quantity: number;
  createdBy: string;
  createdDate: string;
  shippingType: EnclosedItemCategory;
};

export type OrderAttachmentFragmentFragment = {
  __typename: 'OrderAttachment';
  id: string;
  name: string;
  extension: string;
  size?: number | null;
  fileUrl: string;
  createdBy: string;
  createdDate: string;
  uploadProperties: {
    __typename: 'AttachmentUploadProperties';
    fileName?: string | null;
    fileType?: string | null;
    filePath: string;
  };
};

export type AddressFragmentFragment = {
  __typename: 'Address';
  type: AddressType;
  street1: string;
  street2?: string | null;
  street3?: string | null;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  neighborhood?: string | null;
  division?: string | null;
};

export type LegacyAddressFragmentFragment = {
  __typename: 'LegacyAddress';
  type: AddressType;
  street1: string;
  street2?: string | null;
  street3?: string | null;
  city: string;
  state: string;
  zipcode: string;
  country?: string | null;
  neighborhood?: string | null;
  division?: string | null;
};

export type PhoneFragmentFragment = {
  __typename: 'Phone';
  type: PhoneType;
  number: string;
  extension?: string | null;
  country?: string | null;
};

export type InvoiceShippingFragmentFragment = {
  __typename: 'Shipping';
  shippingMethod?: ShippingMethod | null;
  estimatedShipDate?: string | null;
  packageType?: PackageType | null;
  shipmentId?: string | null;
  rateId?: string | null;
  address?: {
    __typename: 'Address';
    type: AddressType;
    street1: string;
    street2?: string | null;
    street3?: string | null;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    neighborhood?: string | null;
    division?: string | null;
  } | null;
  shippingCharges?: Array<{
    __typename: 'ShippingCharge';
    id: string;
    type: ShippingChargeType;
    amount: number;
    discountAmount: number;
    salesTaxAmount?: number | null;
    totalAmount: number;
    carrier: string;
    service: string;
    taxCode?: string | null;
  }> | null;
};

export type ShippingFragmentFragment = {
  __typename: 'Shipping';
  codAmount: number;
  shippingMethod?: ShippingMethod | null;
  packageType?: PackageType | null;
  estimatedShipDate?: string | null;
  deliveryMethod?: DeliveryMethod | null;
  address?: {
    __typename: 'Address';
    type: AddressType;
    street1: string;
    street2?: string | null;
    street3?: string | null;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    neighborhood?: string | null;
    division?: string | null;
  } | null;
  shippingCharges?: Array<{
    __typename: 'ShippingCharge';
    id: string;
    type: ShippingChargeType;
    amount: number;
    discountAmount: number;
    salesTaxAmount?: number | null;
    totalAmount: number;
    carrier: string;
    service: string;
  }> | null;
  inboundTrackingNumbers: Array<{
    __typename: 'TrackingNumberItem';
    trackingNumber: string;
    insertionDate: string;
    insertedBy: string;
    carrier?: string | null;
    service?: string | null;
    shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
  }>;
  outboundTrackingNumbers: Array<{
    __typename: 'TrackingNumberItem';
    trackingNumber: string;
    insertionDate: string;
    insertedBy: string;
    carrier?: string | null;
    service?: string | null;
    shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
  }>;
  doctorPickupLocation?: {
    __typename: 'DoctorPickupLocation';
    labId: number;
    labName: string;
    address: {
      __typename: 'Address';
      type: AddressType;
      street1: string;
      street2?: string | null;
      street3?: string | null;
      city: string;
      state: string;
      zipcode: string;
      country: string;
      neighborhood?: string | null;
      division?: string | null;
    };
    phone: { __typename: 'Phone'; type: PhoneType; number: string; extension?: string | null; country?: string | null };
  } | null;
};

export type LegacyShippingFragmentFragment = {
  __typename: 'LegacyShipping';
  shippingMethod?: ShippingMethod | null;
  packageType?: PackageType | null;
  estimatedShipDate?: string | null;
  deliveryMethod?: DeliveryMethod | null;
  codAmount: number;
  legacyAddress?: {
    __typename: 'LegacyAddress';
    type: AddressType;
    street1: string;
    street2?: string | null;
    street3?: string | null;
    city: string;
    state: string;
    zipcode: string;
    country?: string | null;
    neighborhood?: string | null;
    division?: string | null;
  } | null;
  shippingCharges?: Array<{
    __typename: 'ShippingCharge';
    id: string;
    type: ShippingChargeType;
    amount: number;
    discountAmount: number;
    salesTaxAmount?: number | null;
    totalAmount: number;
    carrier: string;
    service: string;
  }> | null;
  legacyInboundTrackingNumbers?: Array<{
    __typename: 'TrackingNumberItem';
    trackingNumber: string;
    insertionDate: string;
    insertedBy: string;
    carrier?: string | null;
    service?: string | null;
    shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
  }> | null;
  legacyOutboundTrackingNumbers?: Array<{
    __typename: 'TrackingNumberItem';
    trackingNumber: string;
    insertionDate: string;
    insertedBy: string;
    carrier?: string | null;
    service?: string | null;
    shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
  }> | null;
  legacyDoctorPickupLocation?: {
    __typename: 'LegacyDoctorPickupLocation';
    labId: number;
    labName: string;
    address: {
      __typename: 'LegacyAddress';
      type: AddressType;
      street1: string;
      street2?: string | null;
      street3?: string | null;
      city: string;
      state: string;
      zipcode: string;
      country?: string | null;
      neighborhood?: string | null;
      division?: string | null;
    };
    phone: { __typename: 'Phone'; type: PhoneType; number: string; extension?: string | null; country?: string | null };
  } | null;
};

export type SearchedOrderFragmentFragment = {
  __typename: 'SearchedOrder';
  orderNumber: string;
  billingAccountId?: string | null;
  status: OrderStatus;
  providerId?: string | null;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  patientId?: string | null;
  providerName?: string | null;
  createdDate?: string | null;
  utcConversionTimeZoneCode?: string | null;
  invoiceDate?: string | null;
  originatingSystem: OrderOriginatingSystem;
  labReceivedDate?: string | null;
  orderItems: Array<{
    __typename: 'SearchedOrderItem';
    productCode?: string | null;
    productName?: string | null;
    returnReasons?: Array<string> | null;
    returnType?: ReturnType | null;
    attributes: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
  }>;
};

export type RelatedCasesOrderFragmentFragment = {
  __typename: 'Order';
  orderNumber: string;
  status: OrderStatus;
  createdDate: string;
  utcConversionTimeZoneCode: string;
  invoiceDate?: string | null;
  originatingSystem?: OrderOriginatingSystem | null;
  labReceivedDate?: string | null;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  patientId?: string | null;
  providerName: string;
  orderItems: Array<{
    __typename: 'OrderItem';
    productName: string;
    attributes: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    addOns: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    services: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
  }>;
};

export type RelatedCasesRmaOrderFragmentFragment = {
  __typename: 'RmaOrder';
  orderNumber: string;
  status: OrderStatus;
  createdDate: string;
  utcConversionTimeZoneCode: string;
  invoiceDate?: string | null;
  originatingSystem?: OrderOriginatingSystem | null;
  labReceivedDate?: string | null;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  patientId?: string | null;
  providerName: string;
  orderItems: Array<{
    __typename: 'RmaOrderItem';
    productName: string;
    returnType?: ReturnType | null;
    returnReasons?: Array<string> | null;
    attributes: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    addOns: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    services: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
  }>;
};

export type RelatedCasesLegacyOrderFragmentFragment = {
  __typename: 'LegacyOrder';
  orderNumber: string;
  status: OrderStatus;
  invoiceDate?: string | null;
  originatingSystem?: OrderOriginatingSystem | null;
  labReceivedDate?: string | null;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  patientId?: string | null;
  legacyCreatedDate?: string | null;
  legacyUtcConversionTimeZoneCode?: string | null;
  legacyProviderName?: string | null;
  legacyOrderItems: Array<{
    __typename: 'LegacyOrderItem';
    units?: Array<string | null> | null;
    quantity: number;
    unitPrice?: number | null;
    toothUnits?: number | null;
    lineItemNumber?: number | null;
    type?: ProductType | null;
    department?: string | null;
    restorationType?: string | null;
    subtotalAmount?: number | null;
    totalTaxAmount?: number | null;
    totalDiscountAmount?: number | null;
    totalAmount?: number | null;
    coupons: Array<string>;
    couponAmount?: number | null;
    specialDiscountAmount?: number | null;
    daysInLab?: number | null;
    manufacturingLocation?: string | null;
    manufacturingLocationId: number;
    updatedBy?: string | null;
    updatedDate?: string | null;
    itemId: string;
    productName?: string | null;
    productCode: string;
    services: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    addOns: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    attributes: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
    }>;
  }>;
};

type BaseOrderFragment_LegacyOrder_Fragment = {
  __typename: 'LegacyOrder';
  orderNumber: string;
  status: OrderStatus;
  patientFirstName?: string | null;
  patientId?: string | null;
  patientLastName?: string | null;
  isCTScanEligible?: boolean | null;
  fileAttachments: Array<{
    __typename: 'OrderAttachment';
    id: string;
    name: string;
    extension: string;
    size?: number | null;
    fileUrl: string;
    createdBy: string;
    createdDate: string;
    uploadProperties: {
      __typename: 'AttachmentUploadProperties';
      fileName?: string | null;
      fileType?: string | null;
      filePath: string;
    };
  }>;
  enclosedItems: Array<{
    __typename: 'OrderEnclosedItem';
    id: string;
    type: string;
    itemCode: string;
    quantity: number;
    createdBy: string;
    createdDate: string;
    shippingType: EnclosedItemCategory;
  }>;
};

type BaseOrderFragment_Order_Fragment = {
  __typename: 'Order';
  orderNumber: string;
  status: OrderStatus;
  patientFirstName?: string | null;
  patientId?: string | null;
  patientLastName?: string | null;
  isCTScanEligible?: boolean | null;
  fileAttachments: Array<{
    __typename: 'OrderAttachment';
    id: string;
    name: string;
    extension: string;
    size?: number | null;
    fileUrl: string;
    createdBy: string;
    createdDate: string;
    uploadProperties: {
      __typename: 'AttachmentUploadProperties';
      fileName?: string | null;
      fileType?: string | null;
      filePath: string;
    };
  }>;
  enclosedItems: Array<{
    __typename: 'OrderEnclosedItem';
    id: string;
    type: string;
    itemCode: string;
    quantity: number;
    createdBy: string;
    createdDate: string;
    shippingType: EnclosedItemCategory;
  }>;
};

type BaseOrderFragment_PendingOrder_Fragment = {
  __typename: 'PendingOrder';
  orderNumber: string;
  status: OrderStatus;
  patientFirstName?: string | null;
  patientId?: string | null;
  patientLastName?: string | null;
  isCTScanEligible?: boolean | null;
  fileAttachments: Array<{
    __typename: 'OrderAttachment';
    id: string;
    name: string;
    extension: string;
    size?: number | null;
    fileUrl: string;
    createdBy: string;
    createdDate: string;
    uploadProperties: {
      __typename: 'AttachmentUploadProperties';
      fileName?: string | null;
      fileType?: string | null;
      filePath: string;
    };
  }>;
  enclosedItems: Array<{
    __typename: 'OrderEnclosedItem';
    id: string;
    type: string;
    itemCode: string;
    quantity: number;
    createdBy: string;
    createdDate: string;
    shippingType: EnclosedItemCategory;
  }>;
};

type BaseOrderFragment_RmaOrder_Fragment = {
  __typename: 'RmaOrder';
  orderNumber: string;
  status: OrderStatus;
  patientFirstName?: string | null;
  patientId?: string | null;
  patientLastName?: string | null;
  isCTScanEligible?: boolean | null;
  fileAttachments: Array<{
    __typename: 'OrderAttachment';
    id: string;
    name: string;
    extension: string;
    size?: number | null;
    fileUrl: string;
    createdBy: string;
    createdDate: string;
    uploadProperties: {
      __typename: 'AttachmentUploadProperties';
      fileName?: string | null;
      fileType?: string | null;
      filePath: string;
    };
  }>;
  enclosedItems: Array<{
    __typename: 'OrderEnclosedItem';
    id: string;
    type: string;
    itemCode: string;
    quantity: number;
    createdBy: string;
    createdDate: string;
    shippingType: EnclosedItemCategory;
  }>;
};

export type BaseOrderFragmentFragment =
  | BaseOrderFragment_LegacyOrder_Fragment
  | BaseOrderFragment_Order_Fragment
  | BaseOrderFragment_PendingOrder_Fragment
  | BaseOrderFragment_RmaOrder_Fragment;

export type OrderFragmentFragment = {
  __typename: 'Order';
  billingAccountId?: string | null;
  statusReason?: string | null;
  pONumber?: string | null;
  sCINumber?: string | null;
  type?: OrderType | null;
  paymentTerms?: OrderPaymentTerms | null;
  transactionId?: string | null;
  authorizationId?: string | null;
  authorizationAmount?: number | null;
  creditRequestedDate?: string | null;
  originatingSystem?: OrderOriginatingSystem | null;
  orderSource?: string | null;
  providerId: string;
  providerName: string;
  labReceivedDate?: string | null;
  customerDueDate?: string | null;
  subtotalAmount?: number | null;
  totalDiscountAmount?: number | null;
  totalFreightCharges?: number | null;
  totalTaxAmount?: number | null;
  totalAmount?: number | null;
  currencyCode?: CurrencyCode | null;
  invoiceBy?: string | null;
  invoiceDate?: string | null;
  originFacility: string;
  originFacilityId: number;
  departmentId?: number | null;
  externalOrderNumber?: string | null;
  coupons: Array<string>;
  notes?: string | null;
  utcConversionTimeZoneCode: string;
  createdBy?: string | null;
  createdDate: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  linkedOrderNumber?: string | null;
  originalOrderNumber?: string | null;
  orderNumber: string;
  status: OrderStatus;
  patientFirstName?: string | null;
  patientId?: string | null;
  patientLastName?: string | null;
  isCTScanEligible?: boolean | null;
  shipping?: {
    __typename: 'Shipping';
    codAmount: number;
    shippingMethod?: ShippingMethod | null;
    packageType?: PackageType | null;
    estimatedShipDate?: string | null;
    deliveryMethod?: DeliveryMethod | null;
    address?: {
      __typename: 'Address';
      type: AddressType;
      street1: string;
      street2?: string | null;
      street3?: string | null;
      city: string;
      state: string;
      zipcode: string;
      country: string;
      neighborhood?: string | null;
      division?: string | null;
    } | null;
    shippingCharges?: Array<{
      __typename: 'ShippingCharge';
      id: string;
      type: ShippingChargeType;
      amount: number;
      discountAmount: number;
      salesTaxAmount?: number | null;
      totalAmount: number;
      carrier: string;
      service: string;
    }> | null;
    inboundTrackingNumbers: Array<{
      __typename: 'TrackingNumberItem';
      trackingNumber: string;
      insertionDate: string;
      insertedBy: string;
      carrier?: string | null;
      service?: string | null;
      shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
    }>;
    outboundTrackingNumbers: Array<{
      __typename: 'TrackingNumberItem';
      trackingNumber: string;
      insertionDate: string;
      insertedBy: string;
      carrier?: string | null;
      service?: string | null;
      shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
    }>;
    doctorPickupLocation?: {
      __typename: 'DoctorPickupLocation';
      labId: number;
      labName: string;
      address: {
        __typename: 'Address';
        type: AddressType;
        street1: string;
        street2?: string | null;
        street3?: string | null;
        city: string;
        state: string;
        zipcode: string;
        country: string;
        neighborhood?: string | null;
        division?: string | null;
      };
      phone: {
        __typename: 'Phone';
        type: PhoneType;
        number: string;
        extension?: string | null;
        country?: string | null;
      };
    } | null;
  } | null;
  addresses: Array<{
    __typename: 'Address';
    type: AddressType;
    street1: string;
    street2?: string | null;
    street3?: string | null;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    neighborhood?: string | null;
    division?: string | null;
  }>;
  orderItems: Array<{
    __typename: 'OrderItem';
    quantity: number;
    quantityBackordered?: number | null;
    unitPrice?: number | null;
    toothUnits?: number | null;
    lineItemNumber?: number | null;
    type?: ProductType | null;
    department: string;
    productDescription?: string | null;
    subtotalAmount?: number | null;
    totalTaxAmount?: number | null;
    totalDiscountAmount?: number | null;
    totalAmount?: number | null;
    coupons: Array<string>;
    couponAmount?: number | null;
    specialDiscountAmount?: number | null;
    daysInLab: number;
    orderNumber?: string | null;
    manufacturingLocation: string;
    manufacturingLocationId: number;
    createdDate?: string | null;
    createdBy?: string | null;
    updatedDate?: string | null;
    updatedBy?: string | null;
    itemId: string;
    productName: string;
    productCode: string;
    services: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    addOns: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    attributes: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    overrides?: Array<{
      __typename: 'OverrideField';
      field: string;
      type: OverrideFieldType;
      old: string;
      new: string;
    }> | null;
  }>;
  metadata?: { __typename: 'OrderMetaData'; isPricingBeingCalculated: boolean; isTaxBeingCalculated: boolean } | null;
  fileAttachments: Array<{
    __typename: 'OrderAttachment';
    id: string;
    name: string;
    extension: string;
    size?: number | null;
    fileUrl: string;
    createdBy: string;
    createdDate: string;
    uploadProperties: {
      __typename: 'AttachmentUploadProperties';
      fileName?: string | null;
      fileType?: string | null;
      filePath: string;
    };
  }>;
  enclosedItems: Array<{
    __typename: 'OrderEnclosedItem';
    id: string;
    type: string;
    itemCode: string;
    quantity: number;
    createdBy: string;
    createdDate: string;
    shippingType: EnclosedItemCategory;
  }>;
};

export type RmaOrderFragmentFragment = {
  __typename: 'RmaOrder';
  billingAccountId?: string | null;
  statusReason?: string | null;
  pONumber?: string | null;
  sCINumber?: string | null;
  type?: OrderType | null;
  paymentTerms?: OrderPaymentTerms | null;
  transactionId?: string | null;
  authorizationId?: string | null;
  authorizationAmount?: number | null;
  creditRequestedDate?: string | null;
  originatingSystem?: OrderOriginatingSystem | null;
  orderSource?: string | null;
  providerId: string;
  providerName: string;
  labReceivedDate?: string | null;
  customerDueDate?: string | null;
  subtotalAmount?: number | null;
  totalDiscountAmount?: number | null;
  totalFreightCharges?: number | null;
  totalTaxAmount?: number | null;
  totalAmount?: number | null;
  currencyCode?: CurrencyCode | null;
  invoiceBy?: string | null;
  invoiceDate?: string | null;
  originFacility: string;
  originFacilityId: number;
  departmentId?: number | null;
  externalOrderNumber?: string | null;
  coupons: Array<string>;
  notes?: string | null;
  utcConversionTimeZoneCode: string;
  createdBy?: string | null;
  createdDate: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  linkedOrderNumber?: string | null;
  orderNumber: string;
  status: OrderStatus;
  patientFirstName?: string | null;
  patientId?: string | null;
  patientLastName?: string | null;
  isCTScanEligible?: boolean | null;
  rmaOriginalOrderNumber: string;
  shipping?: {
    __typename: 'Shipping';
    codAmount: number;
    shippingMethod?: ShippingMethod | null;
    packageType?: PackageType | null;
    estimatedShipDate?: string | null;
    deliveryMethod?: DeliveryMethod | null;
    address?: {
      __typename: 'Address';
      type: AddressType;
      street1: string;
      street2?: string | null;
      street3?: string | null;
      city: string;
      state: string;
      zipcode: string;
      country: string;
      neighborhood?: string | null;
      division?: string | null;
    } | null;
    shippingCharges?: Array<{
      __typename: 'ShippingCharge';
      id: string;
      type: ShippingChargeType;
      amount: number;
      discountAmount: number;
      salesTaxAmount?: number | null;
      totalAmount: number;
      carrier: string;
      service: string;
    }> | null;
    inboundTrackingNumbers: Array<{
      __typename: 'TrackingNumberItem';
      trackingNumber: string;
      insertionDate: string;
      insertedBy: string;
      carrier?: string | null;
      service?: string | null;
      shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
    }>;
    outboundTrackingNumbers: Array<{
      __typename: 'TrackingNumberItem';
      trackingNumber: string;
      insertionDate: string;
      insertedBy: string;
      carrier?: string | null;
      service?: string | null;
      shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
    }>;
    doctorPickupLocation?: {
      __typename: 'DoctorPickupLocation';
      labId: number;
      labName: string;
      address: {
        __typename: 'Address';
        type: AddressType;
        street1: string;
        street2?: string | null;
        street3?: string | null;
        city: string;
        state: string;
        zipcode: string;
        country: string;
        neighborhood?: string | null;
        division?: string | null;
      };
      phone: {
        __typename: 'Phone';
        type: PhoneType;
        number: string;
        extension?: string | null;
        country?: string | null;
      };
    } | null;
  } | null;
  addresses: Array<{
    __typename: 'Address';
    type: AddressType;
    street1: string;
    street2?: string | null;
    street3?: string | null;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    neighborhood?: string | null;
    division?: string | null;
  }>;
  orderItems: Array<{
    __typename: 'RmaOrderItem';
    itemId: string;
    quantity: number;
    quantityBackordered?: number | null;
    unitPrice?: number | null;
    toothUnits?: number | null;
    lineItemNumber?: number | null;
    type?: ProductType | null;
    department: string;
    productDescription?: string | null;
    subtotalAmount?: number | null;
    totalTaxAmount?: number | null;
    totalDiscountAmount?: number | null;
    totalAmount?: number | null;
    coupons: Array<string>;
    couponAmount?: number | null;
    specialDiscountAmount?: number | null;
    daysInLab: number;
    orderNumber?: string | null;
    manufacturingLocation: string;
    manufacturingLocationId: number;
    createdDate?: string | null;
    createdBy?: string | null;
    updatedDate?: string | null;
    updatedBy?: string | null;
    isOldProductReturned?: boolean | null;
    returnReasons?: Array<string> | null;
    returnType?: ReturnType | null;
    isProcessSwap?: boolean | null;
    productName: string;
    productCode: string;
    attributes: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    services: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    addOns: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    newProduct?: { __typename: 'RmaExchangeNewProduct'; productName: string; productCode: string } | null;
    overrides?: Array<{
      __typename: 'OverrideField';
      field: string;
      type: OverrideFieldType;
      old: string;
      new: string;
    }> | null;
  }>;
  metadata?: { __typename: 'OrderMetaData'; isPricingBeingCalculated: boolean; isTaxBeingCalculated: boolean } | null;
  fileAttachments: Array<{
    __typename: 'OrderAttachment';
    id: string;
    name: string;
    extension: string;
    size?: number | null;
    fileUrl: string;
    createdBy: string;
    createdDate: string;
    uploadProperties: {
      __typename: 'AttachmentUploadProperties';
      fileName?: string | null;
      fileType?: string | null;
      filePath: string;
    };
  }>;
  enclosedItems: Array<{
    __typename: 'OrderEnclosedItem';
    id: string;
    type: string;
    itemCode: string;
    quantity: number;
    createdBy: string;
    createdDate: string;
    shippingType: EnclosedItemCategory;
  }>;
};

export type LegacyOrderFragmentFragment = {
  __typename: 'LegacyOrder';
  billingAccountId?: string | null;
  statusReason?: string | null;
  type?: OrderType | null;
  originatingSystem?: OrderOriginatingSystem | null;
  orderSource?: string | null;
  labReceivedDate?: string | null;
  customerDueDate?: string | null;
  subtotalAmount?: number | null;
  totalDiscountAmount?: number | null;
  totalFreightCharges?: number | null;
  totalTaxAmount?: number | null;
  totalAmount?: number | null;
  currencyCode?: CurrencyCode | null;
  invoiceBy?: string | null;
  invoiceDate?: string | null;
  externalOrderNumber?: string | null;
  coupons: Array<string>;
  notes?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  updatedDate?: string | null;
  inbundleOrders: Array<string>;
  orderNumber: string;
  status: OrderStatus;
  patientFirstName?: string | null;
  patientId?: string | null;
  patientLastName?: string | null;
  isCTScanEligible?: boolean | null;
  legacyProviderId?: string | null;
  legacyProviderName?: string | null;
  legacyOriginFacility?: string | null;
  legacyOriginFacilityId?: number | null;
  legacyDepartmentId?: string | null;
  legacyUtcConversionTimeZoneCode?: string | null;
  legacyCreatedDate?: string | null;
  shipping?: {
    __typename: 'LegacyShipping';
    shippingMethod?: ShippingMethod | null;
    packageType?: PackageType | null;
    estimatedShipDate?: string | null;
    deliveryMethod?: DeliveryMethod | null;
    codAmount: number;
    legacyAddress?: {
      __typename: 'LegacyAddress';
      type: AddressType;
      street1: string;
      street2?: string | null;
      street3?: string | null;
      city: string;
      state: string;
      zipcode: string;
      country?: string | null;
      neighborhood?: string | null;
      division?: string | null;
    } | null;
    shippingCharges?: Array<{
      __typename: 'ShippingCharge';
      id: string;
      type: ShippingChargeType;
      amount: number;
      discountAmount: number;
      salesTaxAmount?: number | null;
      totalAmount: number;
      carrier: string;
      service: string;
    }> | null;
    legacyInboundTrackingNumbers?: Array<{
      __typename: 'TrackingNumberItem';
      trackingNumber: string;
      insertionDate: string;
      insertedBy: string;
      carrier?: string | null;
      service?: string | null;
      shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
    }> | null;
    legacyOutboundTrackingNumbers?: Array<{
      __typename: 'TrackingNumberItem';
      trackingNumber: string;
      insertionDate: string;
      insertedBy: string;
      carrier?: string | null;
      service?: string | null;
      shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
    }> | null;
    legacyDoctorPickupLocation?: {
      __typename: 'LegacyDoctorPickupLocation';
      labId: number;
      labName: string;
      address: {
        __typename: 'LegacyAddress';
        type: AddressType;
        street1: string;
        street2?: string | null;
        street3?: string | null;
        city: string;
        state: string;
        zipcode: string;
        country?: string | null;
        neighborhood?: string | null;
        division?: string | null;
      };
      phone: {
        __typename: 'Phone';
        type: PhoneType;
        number: string;
        extension?: string | null;
        country?: string | null;
      };
    } | null;
  } | null;
  legacyAddresses: Array<{
    __typename: 'LegacyAddress';
    type: AddressType;
    street1: string;
    street2?: string | null;
    street3?: string | null;
    city: string;
    state: string;
    zipcode: string;
    country?: string | null;
    neighborhood?: string | null;
    division?: string | null;
  }>;
  legacyOrderItems: Array<{
    __typename: 'LegacyOrderItem';
    units?: Array<string | null> | null;
    quantity: number;
    unitPrice?: number | null;
    toothUnits?: number | null;
    lineItemNumber?: number | null;
    type?: ProductType | null;
    department?: string | null;
    restorationType?: string | null;
    subtotalAmount?: number | null;
    totalTaxAmount?: number | null;
    totalDiscountAmount?: number | null;
    totalAmount?: number | null;
    coupons: Array<string>;
    couponAmount?: number | null;
    specialDiscountAmount?: number | null;
    daysInLab?: number | null;
    manufacturingLocation?: string | null;
    manufacturingLocationId: number;
    updatedBy?: string | null;
    updatedDate?: string | null;
    itemId: string;
    productName?: string | null;
    productCode: string;
    services: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    addOns: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }>;
    attributes: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
    }>;
  }>;
  fileAttachments: Array<{
    __typename: 'OrderAttachment';
    id: string;
    name: string;
    extension: string;
    size?: number | null;
    fileUrl: string;
    createdBy: string;
    createdDate: string;
    uploadProperties: {
      __typename: 'AttachmentUploadProperties';
      fileName?: string | null;
      fileType?: string | null;
      filePath: string;
    };
  }>;
  enclosedItems: Array<{
    __typename: 'OrderEnclosedItem';
    id: string;
    type: string;
    itemCode: string;
    quantity: number;
    createdBy: string;
    createdDate: string;
    shippingType: EnclosedItemCategory;
  }>;
};

export type PendingOrderFullFragmentFragment = {
  __typename: 'PendingOrder';
  billingAccountId?: string | null;
  linkedOrderNumber?: string | null;
  externalOrderNumber?: string | null;
  orderSource?: string | null;
  orderNumber: string;
  status: OrderStatus;
  patientFirstName?: string | null;
  patientId?: string | null;
  patientLastName?: string | null;
  isCTScanEligible?: boolean | null;
  pendingProviderId?: string | null;
  pendingProviderName?: string | null;
  pendingOriginalOrderNumber?: string | null;
  shipping?: {
    __typename: 'Shipping';
    codAmount: number;
    shippingMethod?: ShippingMethod | null;
    packageType?: PackageType | null;
    estimatedShipDate?: string | null;
    deliveryMethod?: DeliveryMethod | null;
    address?: {
      __typename: 'Address';
      type: AddressType;
      street1: string;
      street2?: string | null;
      street3?: string | null;
      city: string;
      state: string;
      zipcode: string;
      country: string;
      neighborhood?: string | null;
      division?: string | null;
    } | null;
    shippingCharges?: Array<{
      __typename: 'ShippingCharge';
      id: string;
      type: ShippingChargeType;
      amount: number;
      discountAmount: number;
      salesTaxAmount?: number | null;
      totalAmount: number;
      carrier: string;
      service: string;
    }> | null;
    inboundTrackingNumbers: Array<{
      __typename: 'TrackingNumberItem';
      trackingNumber: string;
      insertionDate: string;
      insertedBy: string;
      carrier?: string | null;
      service?: string | null;
      shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
    }>;
    outboundTrackingNumbers: Array<{
      __typename: 'TrackingNumberItem';
      trackingNumber: string;
      insertionDate: string;
      insertedBy: string;
      carrier?: string | null;
      service?: string | null;
      shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
    }>;
    doctorPickupLocation?: {
      __typename: 'DoctorPickupLocation';
      labId: number;
      labName: string;
      address: {
        __typename: 'Address';
        type: AddressType;
        street1: string;
        street2?: string | null;
        street3?: string | null;
        city: string;
        state: string;
        zipcode: string;
        country: string;
        neighborhood?: string | null;
        division?: string | null;
      };
      phone: {
        __typename: 'Phone';
        type: PhoneType;
        number: string;
        extension?: string | null;
        country?: string | null;
      };
    } | null;
  } | null;
  orderItems: Array<{
    __typename: 'PendingOrderItem';
    itemId: string;
    productName: string;
    productCode: string;
    pendingServices?: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }> | null;
    pendingAddOns?: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
      unitPrice?: number | null;
      id?: string | null;
    }> | null;
    attributes: Array<{
      __typename: 'OrderProductAttribute';
      type: string;
      name: string;
      value: string;
      quantity?: number | null;
    }>;
  }>;
  fileAttachments: Array<{
    __typename: 'OrderAttachment';
    id: string;
    name: string;
    extension: string;
    size?: number | null;
    fileUrl: string;
    createdBy: string;
    createdDate: string;
    uploadProperties: {
      __typename: 'AttachmentUploadProperties';
      fileName?: string | null;
      fileType?: string | null;
      filePath: string;
    };
  }>;
  enclosedItems: Array<{
    __typename: 'OrderEnclosedItem';
    id: string;
    type: string;
    itemCode: string;
    quantity: number;
    createdBy: string;
    createdDate: string;
    shippingType: EnclosedItemCategory;
  }>;
};

export type BundleOrderFragmentFragment = {
  __typename: 'OrderBundle';
  inboundBundle?: {
    __typename: 'OrderInboundOutboundBundle';
    chargedOrderNumber: string;
    bundledOrderNumbers: Array<{
      __typename: 'SearchedOrder';
      orderNumber: string;
      billingAccountId?: string | null;
      status: OrderStatus;
      providerId?: string | null;
      patientFirstName?: string | null;
      patientLastName?: string | null;
      patientId?: string | null;
      providerName?: string | null;
      createdDate?: string | null;
      utcConversionTimeZoneCode?: string | null;
      invoiceDate?: string | null;
      originatingSystem: OrderOriginatingSystem;
      labReceivedDate?: string | null;
      orderItems: Array<{
        __typename: 'SearchedOrderItem';
        productCode?: string | null;
        productName?: string | null;
        returnReasons?: Array<string> | null;
        returnType?: ReturnType | null;
        attributes: Array<{
          __typename: 'OrderProductAttribute';
          type: string;
          name: string;
          value: string;
          quantity?: number | null;
          unitPrice?: number | null;
          id?: string | null;
        }>;
      }>;
    }>;
  } | null;
  outboundBundle?: {
    __typename: 'OrderInboundOutboundBundle';
    chargedOrderNumber: string;
    bundledOrderNumbers: Array<{
      __typename: 'SearchedOrder';
      orderNumber: string;
      billingAccountId?: string | null;
      status: OrderStatus;
      providerId?: string | null;
      patientFirstName?: string | null;
      patientLastName?: string | null;
      patientId?: string | null;
      providerName?: string | null;
      createdDate?: string | null;
      utcConversionTimeZoneCode?: string | null;
      invoiceDate?: string | null;
      originatingSystem: OrderOriginatingSystem;
      labReceivedDate?: string | null;
      orderItems: Array<{
        __typename: 'SearchedOrderItem';
        productCode?: string | null;
        productName?: string | null;
        returnReasons?: Array<string> | null;
        returnType?: ReturnType | null;
        attributes: Array<{
          __typename: 'OrderProductAttribute';
          type: string;
          name: string;
          value: string;
          quantity?: number | null;
          unitPrice?: number | null;
          id?: string | null;
        }>;
      }>;
    }>;
  } | null;
  splitBundle?: {
    __typename: 'OrderSplitBundle';
    bundleId: string;
    bundledOrderNumbers: Array<{
      __typename: 'SearchedOrder';
      orderNumber: string;
      billingAccountId?: string | null;
      status: OrderStatus;
      providerId?: string | null;
      patientFirstName?: string | null;
      patientLastName?: string | null;
      patientId?: string | null;
      providerName?: string | null;
      createdDate?: string | null;
      utcConversionTimeZoneCode?: string | null;
      invoiceDate?: string | null;
      originatingSystem: OrderOriginatingSystem;
      labReceivedDate?: string | null;
      orderItems: Array<{
        __typename: 'SearchedOrderItem';
        productCode?: string | null;
        productName?: string | null;
        returnReasons?: Array<string> | null;
        returnType?: ReturnType | null;
        attributes: Array<{
          __typename: 'OrderProductAttribute';
          type: string;
          name: string;
          value: string;
          quantity?: number | null;
          unitPrice?: number | null;
          id?: string | null;
        }>;
      }>;
    }>;
    detachedOrderNumbers: Array<{
      __typename: 'SearchedOrder';
      orderNumber: string;
      billingAccountId?: string | null;
      status: OrderStatus;
      providerId?: string | null;
      patientFirstName?: string | null;
      patientLastName?: string | null;
      patientId?: string | null;
      providerName?: string | null;
      createdDate?: string | null;
      utcConversionTimeZoneCode?: string | null;
      invoiceDate?: string | null;
      originatingSystem: OrderOriginatingSystem;
      labReceivedDate?: string | null;
      orderItems: Array<{
        __typename: 'SearchedOrderItem';
        productCode?: string | null;
        productName?: string | null;
        returnReasons?: Array<string> | null;
        returnType?: ReturnType | null;
        attributes: Array<{
          __typename: 'OrderProductAttribute';
          type: string;
          name: string;
          value: string;
          quantity?: number | null;
          unitPrice?: number | null;
          id?: string | null;
        }>;
      }>;
    }>;
  } | null;
};

export type CaseAlertFragmentFragment = {
  __typename: 'CaseAlert';
  alertId?: string | null;
  appliedBy?: string | null;
  appliedDate?: string | null;
  status?: CaseAlertStatus | null;
  createdBy: string;
  createdDate?: string | null;
  updatedBy?: string | null;
  updatedDate?: string | null;
  providerId: string;
  billingAccountId: string;
  expireDate: string;
  spokeTo: string;
  orderNumber?: string | null;
  patientId?: string | null;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  isPatientInfoIncomplete: boolean;
  updateCase?: Array<{
    __typename: 'UpdateCaseAlertDetail';
    notes?: string | null;
    product?: { __typename: 'AlertUpdateItem'; original: string; new: string } | null;
    toothNumber?: { __typename: 'AlertUpdateItem'; original: string; new: string } | null;
    shade?: { __typename: 'AlertUpdateItem'; original: string; new: string } | null;
    shippingAddress?: {
      __typename: 'CaseAddress';
      street1: string;
      street2?: string | null;
      street3?: string | null;
      city: string;
      state: string;
      zipcode: string;
      country?: string | null;
    } | null;
  }> | null;
  cancelCase?: Array<{
    __typename: 'CancelCaseAlertDetail';
    product?: string | null;
    reason: string;
    caseContent: CaseContentsType;
    notes?: string | null;
  }> | null;
  forwardCase?: Array<{
    __typename: 'ForwardCaseAlertDetail';
    sendToPlastering: boolean;
    routeType: RouteType;
    routeTo: string;
    reason?: string | null;
  }> | null;
  other?: Array<{ __typename: 'OtherCaseAlertDetail'; notes?: string | null }> | null;
};

export type AppliedCouponsFragmentFragment = {
  __typename: 'Coupon';
  activationDate: string;
  code: string;
  createdDate?: string | null;
  createdBy?: string | null;
  currencyCode?: CurrencyCode | null;
  discountAmount: number;
  discountType: DiscountType;
  expirationDate: string;
  status?: CouponStatus | null;
  reason?: string | null;
  totalDiscountAmount?: number | null;
  components: Array<{
    __typename: 'ProductComponent';
    abbreviation: string;
    type: ProductComponentType;
    value: string;
  }>;
  usageLimit?: { __typename: 'UsageLimit'; accountLimit?: number | null; orderLimit: number; rxLimit: number } | null;
};

export type AppliedDiscountsFragmentFragment = {
  __typename: 'Deduction';
  discountId: string;
  orderNumber: string;
  reason: string;
  type: CaseDiscountType;
  createdBy: string;
  createdDate: string;
  notes?: string | null;
  deductions: Array<{
    __typename: 'Discount';
    productCode: string;
    discountType: DiscountType;
    value: number;
    discountAmount: number;
    orderItemId?: string | null;
    description?: string | null;
  }>;
};

export type AppliedCreditsFragmentFragment = {
  __typename: 'Credit';
  creditNumber: string;
  orderNumber: string;
  billingAccountId: string;
  patientId?: string | null;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  currencyCode: string;
  originFacilityId: number;
  totalAmount: number;
  totalDiscountAmount: number;
  totalTaxAmount: number;
  notes?: string | null;
  createdBy: string;
  createdDate: string;
  caseDiscountType: CaseDiscountType;
  reason?: string | null;
  couponCode?: string | null;
  creditItems: Array<{
    __typename: 'CreditItem';
    productName: string;
    productCode: string;
    creditType: CreditType;
    totalAmount: number;
    totalTaxAmount: number;
    couponCode?: string | null;
    quantity?: number | null;
    discountAmount: number;
    value: number;
    orderItemId: string;
    description: string;
    reason?: string | null;
  }>;
};

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;

export type CreateOrderMutation = {
  __typename: 'Mutation';
  createOrder:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'LegacyOrder' }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'Order';
        billingAccountId?: string | null;
        statusReason?: string | null;
        pONumber?: string | null;
        sCINumber?: string | null;
        type?: OrderType | null;
        paymentTerms?: OrderPaymentTerms | null;
        transactionId?: string | null;
        authorizationId?: string | null;
        authorizationAmount?: number | null;
        creditRequestedDate?: string | null;
        originatingSystem?: OrderOriginatingSystem | null;
        orderSource?: string | null;
        providerId: string;
        providerName: string;
        labReceivedDate?: string | null;
        customerDueDate?: string | null;
        subtotalAmount?: number | null;
        totalDiscountAmount?: number | null;
        totalFreightCharges?: number | null;
        totalTaxAmount?: number | null;
        totalAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        invoiceBy?: string | null;
        invoiceDate?: string | null;
        originFacility: string;
        originFacilityId: number;
        departmentId?: number | null;
        externalOrderNumber?: string | null;
        coupons: Array<string>;
        notes?: string | null;
        utcConversionTimeZoneCode: string;
        createdBy?: string | null;
        createdDate: string;
        updatedBy?: string | null;
        updatedDate?: string | null;
        linkedOrderNumber?: string | null;
        originalOrderNumber?: string | null;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        orderItems: Array<{
          __typename: 'OrderItem';
          quantity: number;
          quantityBackordered?: number | null;
          unitPrice?: number | null;
          toothUnits?: number | null;
          lineItemNumber?: number | null;
          type?: ProductType | null;
          department: string;
          productDescription?: string | null;
          subtotalAmount?: number | null;
          totalTaxAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalAmount?: number | null;
          coupons: Array<string>;
          couponAmount?: number | null;
          specialDiscountAmount?: number | null;
          daysInLab: number;
          orderNumber?: string | null;
          manufacturingLocation: string;
          manufacturingLocationId: number;
          createdDate?: string | null;
          createdBy?: string | null;
          updatedDate?: string | null;
          updatedBy?: string | null;
          itemId: string;
          productName: string;
          productCode: string;
          services: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          overrides?: Array<{
            __typename: 'OverrideField';
            field: string;
            type: OverrideFieldType;
            old: string;
            new: string;
          }> | null;
        }>;
        metadata?: {
          __typename: 'OrderMetaData';
          isPricingBeingCalculated: boolean;
          isTaxBeingCalculated: boolean;
        } | null;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | {
        __typename: 'PendingOrder';
        orderNumber: string;
        billingAccountId?: string | null;
        status: OrderStatus;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        orderItems: Array<{
          __typename: 'PendingOrderItem';
          itemId: string;
          productName: string;
          productCode: string;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
          }>;
        }>;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | { __typename: 'RmaOrder' };
};

export type UpdateOrderStatusMutationVariables = Exact<{
  input: UpdateOrderStatusInput;
}>;

export type UpdateOrderStatusMutation = {
  __typename: 'Mutation';
  updateOrderStatus:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'LegacyOrder' }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'Order';
        orderNumber: string;
        billingAccountId?: string | null;
        status: OrderStatus;
        statusReason?: string | null;
        pONumber?: string | null;
        sCINumber?: string | null;
        type?: OrderType | null;
        paymentTerms?: OrderPaymentTerms | null;
        transactionId?: string | null;
        authorizationId?: string | null;
        authorizationAmount?: number | null;
        creditRequestedDate?: string | null;
        originatingSystem?: OrderOriginatingSystem | null;
        orderSource?: string | null;
        providerId: string;
        providerName: string;
        patientId?: string | null;
        patientFirstName?: string | null;
        patientLastName?: string | null;
        labReceivedDate?: string | null;
        customerDueDate?: string | null;
        subtotalAmount?: number | null;
        totalDiscountAmount?: number | null;
        totalFreightCharges?: number | null;
        totalTaxAmount?: number | null;
        totalAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        invoiceBy?: string | null;
        invoiceDate?: string | null;
        originFacilityId: number;
        departmentId?: number | null;
        externalOrderNumber?: string | null;
        coupons: Array<string>;
        notes?: string | null;
        utcConversionTimeZoneCode: string;
        createdBy?: string | null;
        createdDate: string;
        updatedBy?: string | null;
        updatedDate?: string | null;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        orderItems: Array<{
          __typename: 'OrderItem';
          itemId: string;
          productName: string;
          productCode: string;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
          }>;
        }>;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | { __typename: 'PendingOrder' }
    | { __typename: 'RmaOrder' };
};

export type UpdateOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type UpdateOrderMutation = {
  __typename: 'Mutation';
  updateOrder:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'LegacyOrder' }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'Order';
        billingAccountId?: string | null;
        statusReason?: string | null;
        pONumber?: string | null;
        sCINumber?: string | null;
        type?: OrderType | null;
        paymentTerms?: OrderPaymentTerms | null;
        transactionId?: string | null;
        authorizationId?: string | null;
        authorizationAmount?: number | null;
        creditRequestedDate?: string | null;
        originatingSystem?: OrderOriginatingSystem | null;
        orderSource?: string | null;
        providerId: string;
        providerName: string;
        labReceivedDate?: string | null;
        customerDueDate?: string | null;
        subtotalAmount?: number | null;
        totalDiscountAmount?: number | null;
        totalFreightCharges?: number | null;
        totalTaxAmount?: number | null;
        totalAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        invoiceBy?: string | null;
        invoiceDate?: string | null;
        originFacility: string;
        originFacilityId: number;
        departmentId?: number | null;
        externalOrderNumber?: string | null;
        coupons: Array<string>;
        notes?: string | null;
        utcConversionTimeZoneCode: string;
        createdBy?: string | null;
        createdDate: string;
        updatedBy?: string | null;
        updatedDate?: string | null;
        linkedOrderNumber?: string | null;
        originalOrderNumber?: string | null;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        orderItems: Array<{
          __typename: 'OrderItem';
          quantity: number;
          quantityBackordered?: number | null;
          unitPrice?: number | null;
          toothUnits?: number | null;
          lineItemNumber?: number | null;
          type?: ProductType | null;
          department: string;
          productDescription?: string | null;
          subtotalAmount?: number | null;
          totalTaxAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalAmount?: number | null;
          coupons: Array<string>;
          couponAmount?: number | null;
          specialDiscountAmount?: number | null;
          daysInLab: number;
          orderNumber?: string | null;
          manufacturingLocation: string;
          manufacturingLocationId: number;
          createdDate?: string | null;
          createdBy?: string | null;
          updatedDate?: string | null;
          updatedBy?: string | null;
          itemId: string;
          productName: string;
          productCode: string;
          services: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          overrides?: Array<{
            __typename: 'OverrideField';
            field: string;
            type: OverrideFieldType;
            old: string;
            new: string;
          }> | null;
        }>;
        metadata?: {
          __typename: 'OrderMetaData';
          isPricingBeingCalculated: boolean;
          isTaxBeingCalculated: boolean;
        } | null;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | {
        __typename: 'PendingOrder';
        orderNumber: string;
        billingAccountId?: string | null;
        status: OrderStatus;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        orderItems: Array<{
          __typename: 'PendingOrderItem';
          itemId: string;
          productName: string;
          productCode: string;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
          }>;
        }>;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | { __typename: 'RmaOrder' };
};

export type InvoiceMutationVariables = Exact<{
  input: InvoiceInput;
}>;

export type InvoiceMutation = {
  __typename: 'Mutation';
  invoice:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'OrderWrapper';
        orders: Array<{
          __typename: 'Order';
          billingAccountId?: string | null;
          statusReason?: string | null;
          pONumber?: string | null;
          sCINumber?: string | null;
          type?: OrderType | null;
          paymentTerms?: OrderPaymentTerms | null;
          transactionId?: string | null;
          authorizationId?: string | null;
          authorizationAmount?: number | null;
          creditRequestedDate?: string | null;
          originatingSystem?: OrderOriginatingSystem | null;
          orderSource?: string | null;
          providerId: string;
          providerName: string;
          labReceivedDate?: string | null;
          customerDueDate?: string | null;
          subtotalAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalFreightCharges?: number | null;
          totalTaxAmount?: number | null;
          totalAmount?: number | null;
          currencyCode?: CurrencyCode | null;
          invoiceBy?: string | null;
          invoiceDate?: string | null;
          originFacility: string;
          originFacilityId: number;
          departmentId?: number | null;
          externalOrderNumber?: string | null;
          coupons: Array<string>;
          notes?: string | null;
          utcConversionTimeZoneCode: string;
          createdBy?: string | null;
          createdDate: string;
          updatedBy?: string | null;
          updatedDate?: string | null;
          linkedOrderNumber?: string | null;
          originalOrderNumber?: string | null;
          orderNumber: string;
          status: OrderStatus;
          patientFirstName?: string | null;
          patientId?: string | null;
          patientLastName?: string | null;
          isCTScanEligible?: boolean | null;
          shipping?: {
            __typename: 'Shipping';
            codAmount: number;
            shippingMethod?: ShippingMethod | null;
            packageType?: PackageType | null;
            estimatedShipDate?: string | null;
            deliveryMethod?: DeliveryMethod | null;
            address?: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            } | null;
            shippingCharges?: Array<{
              __typename: 'ShippingCharge';
              id: string;
              type: ShippingChargeType;
              amount: number;
              discountAmount: number;
              salesTaxAmount?: number | null;
              totalAmount: number;
              carrier: string;
              service: string;
            }> | null;
            inboundTrackingNumbers: Array<{
              __typename: 'TrackingNumberItem';
              trackingNumber: string;
              insertionDate: string;
              insertedBy: string;
              carrier?: string | null;
              service?: string | null;
              shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
            }>;
            outboundTrackingNumbers: Array<{
              __typename: 'TrackingNumberItem';
              trackingNumber: string;
              insertionDate: string;
              insertedBy: string;
              carrier?: string | null;
              service?: string | null;
              shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
            }>;
            doctorPickupLocation?: {
              __typename: 'DoctorPickupLocation';
              labId: number;
              labName: string;
              address: {
                __typename: 'Address';
                type: AddressType;
                street1: string;
                street2?: string | null;
                street3?: string | null;
                city: string;
                state: string;
                zipcode: string;
                country: string;
                neighborhood?: string | null;
                division?: string | null;
              };
              phone: {
                __typename: 'Phone';
                type: PhoneType;
                number: string;
                extension?: string | null;
                country?: string | null;
              };
            } | null;
          } | null;
          addresses: Array<{
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          }>;
          orderItems: Array<{
            __typename: 'OrderItem';
            quantity: number;
            quantityBackordered?: number | null;
            unitPrice?: number | null;
            toothUnits?: number | null;
            lineItemNumber?: number | null;
            type?: ProductType | null;
            department: string;
            productDescription?: string | null;
            subtotalAmount?: number | null;
            totalTaxAmount?: number | null;
            totalDiscountAmount?: number | null;
            totalAmount?: number | null;
            coupons: Array<string>;
            couponAmount?: number | null;
            specialDiscountAmount?: number | null;
            daysInLab: number;
            orderNumber?: string | null;
            manufacturingLocation: string;
            manufacturingLocationId: number;
            createdDate?: string | null;
            createdBy?: string | null;
            updatedDate?: string | null;
            updatedBy?: string | null;
            itemId: string;
            productName: string;
            productCode: string;
            services: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
            addOns: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
            attributes: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
            overrides?: Array<{
              __typename: 'OverrideField';
              field: string;
              type: OverrideFieldType;
              old: string;
              new: string;
            }> | null;
          }>;
          metadata?: {
            __typename: 'OrderMetaData';
            isPricingBeingCalculated: boolean;
            isTaxBeingCalculated: boolean;
          } | null;
          fileAttachments: Array<{
            __typename: 'OrderAttachment';
            id: string;
            name: string;
            extension: string;
            size?: number | null;
            fileUrl: string;
            createdBy: string;
            createdDate: string;
            uploadProperties: {
              __typename: 'AttachmentUploadProperties';
              fileName?: string | null;
              fileType?: string | null;
              filePath: string;
            };
          }>;
          enclosedItems: Array<{
            __typename: 'OrderEnclosedItem';
            id: string;
            type: string;
            itemCode: string;
            quantity: number;
            createdBy: string;
            createdDate: string;
            shippingType: EnclosedItemCategory;
          }>;
        }>;
      };
};

export type UninvoiceMutationVariables = Exact<{
  input: UnInvoiceOrderInput;
}>;

export type UninvoiceMutation = {
  __typename: 'Mutation';
  uninvoice: { __typename: 'GenericError'; message: string } | { __typename: 'UnInvoiceResponse'; success: boolean };
};

export type UpdateCaseAlertStatusesMutationVariables = Exact<{
  input: UpdateCaseAlertStatusesInput;
}>;

export type UpdateCaseAlertStatusesMutation = {
  __typename: 'Mutation';
  updateCaseAlertStatuses:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'UpdateCaseAlertsSuccess'; status: number };
};

export type CreateRmaOrderMutationVariables = Exact<{
  input: CreateRmaOrderInput;
}>;

export type CreateRmaOrderMutation = {
  __typename: 'Mutation';
  createRmaOrder:
    | { __typename: 'BadRequestError' }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'RmaOrder';
        billingAccountId?: string | null;
        statusReason?: string | null;
        pONumber?: string | null;
        sCINumber?: string | null;
        type?: OrderType | null;
        paymentTerms?: OrderPaymentTerms | null;
        transactionId?: string | null;
        authorizationId?: string | null;
        authorizationAmount?: number | null;
        creditRequestedDate?: string | null;
        originatingSystem?: OrderOriginatingSystem | null;
        orderSource?: string | null;
        providerId: string;
        providerName: string;
        labReceivedDate?: string | null;
        customerDueDate?: string | null;
        subtotalAmount?: number | null;
        totalDiscountAmount?: number | null;
        totalFreightCharges?: number | null;
        totalTaxAmount?: number | null;
        totalAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        invoiceBy?: string | null;
        invoiceDate?: string | null;
        originFacility: string;
        originFacilityId: number;
        departmentId?: number | null;
        externalOrderNumber?: string | null;
        coupons: Array<string>;
        notes?: string | null;
        utcConversionTimeZoneCode: string;
        createdBy?: string | null;
        createdDate: string;
        updatedBy?: string | null;
        updatedDate?: string | null;
        linkedOrderNumber?: string | null;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        rmaOriginalOrderNumber: string;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        orderItems: Array<{
          __typename: 'RmaOrderItem';
          itemId: string;
          quantity: number;
          quantityBackordered?: number | null;
          unitPrice?: number | null;
          toothUnits?: number | null;
          lineItemNumber?: number | null;
          type?: ProductType | null;
          department: string;
          productDescription?: string | null;
          subtotalAmount?: number | null;
          totalTaxAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalAmount?: number | null;
          coupons: Array<string>;
          couponAmount?: number | null;
          specialDiscountAmount?: number | null;
          daysInLab: number;
          orderNumber?: string | null;
          manufacturingLocation: string;
          manufacturingLocationId: number;
          createdDate?: string | null;
          createdBy?: string | null;
          updatedDate?: string | null;
          updatedBy?: string | null;
          isOldProductReturned?: boolean | null;
          returnReasons?: Array<string> | null;
          returnType?: ReturnType | null;
          isProcessSwap?: boolean | null;
          productName: string;
          productCode: string;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          services: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          newProduct?: { __typename: 'RmaExchangeNewProduct'; productName: string; productCode: string } | null;
          overrides?: Array<{
            __typename: 'OverrideField';
            field: string;
            type: OverrideFieldType;
            old: string;
            new: string;
          }> | null;
        }>;
        metadata?: {
          __typename: 'OrderMetaData';
          isPricingBeingCalculated: boolean;
          isTaxBeingCalculated: boolean;
        } | null;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      };
};

export type CreateDeductionMutationVariables = Exact<{
  input: CreateDeductionInput;
}>;

export type CreateDeductionMutation = {
  __typename: 'Mutation';
  createDeduction:
    | {
        __typename: 'Deduction';
        discountId: string;
        orderNumber: string;
        reason: string;
        type: CaseDiscountType;
        createdBy: string;
        notes?: string | null;
        deductions: Array<{
          __typename: 'Discount';
          productCode: string;
          discountType: DiscountType;
          value: number;
          discountAmount: number;
          orderItemId?: string | null;
        }>;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type DeleteDeductionMutationVariables = Exact<{
  discountId: Scalars['String'];
}>;

export type DeleteDeductionMutation = {
  __typename: 'Mutation';
  deleteDeduction:
    | { __typename: 'DeleteDeductionResponse'; result: string }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type UpdateDeductionMutationVariables = Exact<{
  discountId: Scalars['String'];
  input: CreateDeductionInput;
}>;

export type UpdateDeductionMutation = {
  __typename: 'Mutation';
  updateDeduction:
    | {
        __typename: 'Deduction';
        discountId: string;
        orderNumber: string;
        reason: string;
        createdBy: string;
        notes?: string | null;
        deductions: Array<{
          __typename: 'Discount';
          productCode: string;
          discountType: DiscountType;
          value: number;
          discountAmount: number;
        }>;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type CreateCreditMutationVariables = Exact<{
  input: CreateCreditInput;
}>;

export type CreateCreditMutation = {
  __typename: 'Mutation';
  createCredit:
    | {
        __typename: 'Credit';
        creditNumber: string;
        orderNumber: string;
        billingAccountId: string;
        patientId?: string | null;
        patientFirstName?: string | null;
        patientLastName?: string | null;
        currencyCode: string;
        originFacilityId: number;
        totalAmount: number;
        totalDiscountAmount: number;
        totalTaxAmount: number;
        notes?: string | null;
        createdBy: string;
        createdDate: string;
        caseDiscountType: CaseDiscountType;
        reason?: string | null;
        couponCode?: string | null;
        creditItems: Array<{
          __typename: 'CreditItem';
          productName: string;
          productCode: string;
          creditType: CreditType;
          totalAmount: number;
          totalTaxAmount: number;
          couponCode?: string | null;
          quantity?: number | null;
          discountAmount: number;
          value: number;
          orderItemId: string;
          description: string;
          reason?: string | null;
        }>;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type DeleteCreditMutationVariables = Exact<{
  creditNumber: Scalars['String'];
  input: DeleteCreditInput;
}>;

export type DeleteCreditMutation = {
  __typename: 'Mutation';
  deleteCredit:
    | { __typename: 'DeleteCreditResponse'; result: string }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type CreateBundleSplitOrderMutationVariables = Exact<{
  input: BundleSplitOrderInput;
}>;

export type CreateBundleSplitOrderMutation = {
  __typename: 'Mutation';
  createBundleSplitOrder:
    | { __typename: 'BadRequestError'; message: string }
    | {
        __typename: 'BundleSplitOrder';
        orders: Array<{
          __typename: 'Order';
          billingAccountId?: string | null;
          statusReason?: string | null;
          pONumber?: string | null;
          sCINumber?: string | null;
          type?: OrderType | null;
          paymentTerms?: OrderPaymentTerms | null;
          transactionId?: string | null;
          authorizationId?: string | null;
          authorizationAmount?: number | null;
          creditRequestedDate?: string | null;
          originatingSystem?: OrderOriginatingSystem | null;
          orderSource?: string | null;
          providerId: string;
          providerName: string;
          labReceivedDate?: string | null;
          customerDueDate?: string | null;
          subtotalAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalFreightCharges?: number | null;
          totalTaxAmount?: number | null;
          totalAmount?: number | null;
          currencyCode?: CurrencyCode | null;
          invoiceBy?: string | null;
          invoiceDate?: string | null;
          originFacility: string;
          originFacilityId: number;
          departmentId?: number | null;
          externalOrderNumber?: string | null;
          coupons: Array<string>;
          notes?: string | null;
          utcConversionTimeZoneCode: string;
          createdBy?: string | null;
          createdDate: string;
          updatedBy?: string | null;
          updatedDate?: string | null;
          linkedOrderNumber?: string | null;
          originalOrderNumber?: string | null;
          orderNumber: string;
          status: OrderStatus;
          patientFirstName?: string | null;
          patientId?: string | null;
          patientLastName?: string | null;
          isCTScanEligible?: boolean | null;
          shipping?: {
            __typename: 'Shipping';
            codAmount: number;
            shippingMethod?: ShippingMethod | null;
            packageType?: PackageType | null;
            estimatedShipDate?: string | null;
            deliveryMethod?: DeliveryMethod | null;
            address?: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            } | null;
            shippingCharges?: Array<{
              __typename: 'ShippingCharge';
              id: string;
              type: ShippingChargeType;
              amount: number;
              discountAmount: number;
              salesTaxAmount?: number | null;
              totalAmount: number;
              carrier: string;
              service: string;
            }> | null;
            inboundTrackingNumbers: Array<{
              __typename: 'TrackingNumberItem';
              trackingNumber: string;
              insertionDate: string;
              insertedBy: string;
              carrier?: string | null;
              service?: string | null;
              shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
            }>;
            outboundTrackingNumbers: Array<{
              __typename: 'TrackingNumberItem';
              trackingNumber: string;
              insertionDate: string;
              insertedBy: string;
              carrier?: string | null;
              service?: string | null;
              shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
            }>;
            doctorPickupLocation?: {
              __typename: 'DoctorPickupLocation';
              labId: number;
              labName: string;
              address: {
                __typename: 'Address';
                type: AddressType;
                street1: string;
                street2?: string | null;
                street3?: string | null;
                city: string;
                state: string;
                zipcode: string;
                country: string;
                neighborhood?: string | null;
                division?: string | null;
              };
              phone: {
                __typename: 'Phone';
                type: PhoneType;
                number: string;
                extension?: string | null;
                country?: string | null;
              };
            } | null;
          } | null;
          addresses: Array<{
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          }>;
          orderItems: Array<{
            __typename: 'OrderItem';
            quantity: number;
            quantityBackordered?: number | null;
            unitPrice?: number | null;
            toothUnits?: number | null;
            lineItemNumber?: number | null;
            type?: ProductType | null;
            department: string;
            productDescription?: string | null;
            subtotalAmount?: number | null;
            totalTaxAmount?: number | null;
            totalDiscountAmount?: number | null;
            totalAmount?: number | null;
            coupons: Array<string>;
            couponAmount?: number | null;
            specialDiscountAmount?: number | null;
            daysInLab: number;
            orderNumber?: string | null;
            manufacturingLocation: string;
            manufacturingLocationId: number;
            createdDate?: string | null;
            createdBy?: string | null;
            updatedDate?: string | null;
            updatedBy?: string | null;
            itemId: string;
            productName: string;
            productCode: string;
            services: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
            addOns: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
            attributes: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
            overrides?: Array<{
              __typename: 'OverrideField';
              field: string;
              type: OverrideFieldType;
              old: string;
              new: string;
            }> | null;
          }>;
          metadata?: {
            __typename: 'OrderMetaData';
            isPricingBeingCalculated: boolean;
            isTaxBeingCalculated: boolean;
          } | null;
          fileAttachments: Array<{
            __typename: 'OrderAttachment';
            id: string;
            name: string;
            extension: string;
            size?: number | null;
            fileUrl: string;
            createdBy: string;
            createdDate: string;
            uploadProperties: {
              __typename: 'AttachmentUploadProperties';
              fileName?: string | null;
              fileType?: string | null;
              filePath: string;
            };
          }>;
          enclosedItems: Array<{
            __typename: 'OrderEnclosedItem';
            id: string;
            type: string;
            itemCode: string;
            quantity: number;
            createdBy: string;
            createdDate: string;
            shippingType: EnclosedItemCategory;
          }>;
        }>;
        rmaOrders: Array<{
          __typename: 'RmaOrder';
          billingAccountId?: string | null;
          statusReason?: string | null;
          pONumber?: string | null;
          sCINumber?: string | null;
          type?: OrderType | null;
          paymentTerms?: OrderPaymentTerms | null;
          transactionId?: string | null;
          authorizationId?: string | null;
          authorizationAmount?: number | null;
          creditRequestedDate?: string | null;
          originatingSystem?: OrderOriginatingSystem | null;
          orderSource?: string | null;
          providerId: string;
          providerName: string;
          labReceivedDate?: string | null;
          customerDueDate?: string | null;
          subtotalAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalFreightCharges?: number | null;
          totalTaxAmount?: number | null;
          totalAmount?: number | null;
          currencyCode?: CurrencyCode | null;
          invoiceBy?: string | null;
          invoiceDate?: string | null;
          originFacility: string;
          originFacilityId: number;
          departmentId?: number | null;
          externalOrderNumber?: string | null;
          coupons: Array<string>;
          notes?: string | null;
          utcConversionTimeZoneCode: string;
          createdBy?: string | null;
          createdDate: string;
          updatedBy?: string | null;
          updatedDate?: string | null;
          linkedOrderNumber?: string | null;
          orderNumber: string;
          status: OrderStatus;
          patientFirstName?: string | null;
          patientId?: string | null;
          patientLastName?: string | null;
          isCTScanEligible?: boolean | null;
          rmaOriginalOrderNumber: string;
          shipping?: {
            __typename: 'Shipping';
            codAmount: number;
            shippingMethod?: ShippingMethod | null;
            packageType?: PackageType | null;
            estimatedShipDate?: string | null;
            deliveryMethod?: DeliveryMethod | null;
            address?: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            } | null;
            shippingCharges?: Array<{
              __typename: 'ShippingCharge';
              id: string;
              type: ShippingChargeType;
              amount: number;
              discountAmount: number;
              salesTaxAmount?: number | null;
              totalAmount: number;
              carrier: string;
              service: string;
            }> | null;
            inboundTrackingNumbers: Array<{
              __typename: 'TrackingNumberItem';
              trackingNumber: string;
              insertionDate: string;
              insertedBy: string;
              carrier?: string | null;
              service?: string | null;
              shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
            }>;
            outboundTrackingNumbers: Array<{
              __typename: 'TrackingNumberItem';
              trackingNumber: string;
              insertionDate: string;
              insertedBy: string;
              carrier?: string | null;
              service?: string | null;
              shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
            }>;
            doctorPickupLocation?: {
              __typename: 'DoctorPickupLocation';
              labId: number;
              labName: string;
              address: {
                __typename: 'Address';
                type: AddressType;
                street1: string;
                street2?: string | null;
                street3?: string | null;
                city: string;
                state: string;
                zipcode: string;
                country: string;
                neighborhood?: string | null;
                division?: string | null;
              };
              phone: {
                __typename: 'Phone';
                type: PhoneType;
                number: string;
                extension?: string | null;
                country?: string | null;
              };
            } | null;
          } | null;
          addresses: Array<{
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          }>;
          orderItems: Array<{
            __typename: 'RmaOrderItem';
            itemId: string;
            quantity: number;
            quantityBackordered?: number | null;
            unitPrice?: number | null;
            toothUnits?: number | null;
            lineItemNumber?: number | null;
            type?: ProductType | null;
            department: string;
            productDescription?: string | null;
            subtotalAmount?: number | null;
            totalTaxAmount?: number | null;
            totalDiscountAmount?: number | null;
            totalAmount?: number | null;
            coupons: Array<string>;
            couponAmount?: number | null;
            specialDiscountAmount?: number | null;
            daysInLab: number;
            orderNumber?: string | null;
            manufacturingLocation: string;
            manufacturingLocationId: number;
            createdDate?: string | null;
            createdBy?: string | null;
            updatedDate?: string | null;
            updatedBy?: string | null;
            isOldProductReturned?: boolean | null;
            returnReasons?: Array<string> | null;
            returnType?: ReturnType | null;
            isProcessSwap?: boolean | null;
            productName: string;
            productCode: string;
            attributes: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
            services: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
            addOns: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
            newProduct?: { __typename: 'RmaExchangeNewProduct'; productName: string; productCode: string } | null;
            overrides?: Array<{
              __typename: 'OverrideField';
              field: string;
              type: OverrideFieldType;
              old: string;
              new: string;
            }> | null;
          }>;
          metadata?: {
            __typename: 'OrderMetaData';
            isPricingBeingCalculated: boolean;
            isTaxBeingCalculated: boolean;
          } | null;
          fileAttachments: Array<{
            __typename: 'OrderAttachment';
            id: string;
            name: string;
            extension: string;
            size?: number | null;
            fileUrl: string;
            createdBy: string;
            createdDate: string;
            uploadProperties: {
              __typename: 'AttachmentUploadProperties';
              fileName?: string | null;
              fileType?: string | null;
              filePath: string;
            };
          }>;
          enclosedItems: Array<{
            __typename: 'OrderEnclosedItem';
            id: string;
            type: string;
            itemCode: string;
            quantity: number;
            createdBy: string;
            createdDate: string;
            shippingType: EnclosedItemCategory;
          }>;
        }>;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type RemoveBundledOrderMutationVariables = Exact<{
  bundleId: Scalars['String'];
  orderNumber: Scalars['String'];
}>;

export type RemoveBundledOrderMutation = {
  __typename: 'Mutation';
  removeBundledOrder:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'RemoveBundledOrderResponse'; result: string };
};

export type AccountQueryVariables = Exact<{
  billingAccountId: Scalars['String'];
}>;

export type AccountQuery = {
  __typename: 'Query';
  account:
    | {
        __typename: 'Account';
        type?: string | null;
        billingAccountId: string;
        closedReason?: AccountClosedReason | null;
        currencyCode: CurrencyCode;
        status: AccountStatus;
        standing: AccountStanding;
        labOrigin: string;
        labOriginId: number;
        practiceId: number;
        practiceName: string;
        practiceType: PracticeType;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        phoneNumbers: Array<{
          __typename: 'Phone';
          country?: string | null;
          number: string;
          type: PhoneType;
          extension?: string | null;
        }>;
        emailAddresses: Array<{ __typename: 'Email'; type: EmailType; emailAddress: string }>;
        providers: Array<{
          __typename: 'AccountProvider';
          providerId: number;
          firstName: string;
          lastName: string;
          middleName?: string | null;
          suffix?: string | null;
          salutation?: Salutation | null;
          providerStatus: ProviderStatus;
        }>;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type InvoiceAccountQueryVariables = Exact<{
  billingAccountId: Scalars['String'];
}>;

export type InvoiceAccountQuery = {
  __typename: 'Query';
  account:
    | {
        __typename: 'Account';
        billingAccountId: string;
        currencyCode: CurrencyCode;
        status: AccountStatus;
        standing: AccountStanding;
        practiceName: string;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        phoneNumbers: Array<{
          __typename: 'Phone';
          type: PhoneType;
          number: string;
          extension?: string | null;
          country?: string | null;
        }>;
        emailAddresses: Array<{ __typename: 'Email'; type: EmailType; emailAddress: string }>;
        providers: Array<{
          __typename: 'AccountProvider';
          providerId: number;
          firstName: string;
          lastName: string;
          middleName?: string | null;
          suffix?: string | null;
          salutation?: Salutation | null;
          providerStatus: ProviderStatus;
        }>;
        finance?: {
          __typename: 'AccountFinance';
          billingAccountId: string;
          codAmount?: number | null;
          codAmountPerAdditionalCase?: number | null;
        } | null;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type CustomerAccountSearchQueryVariables = Exact<{
  input: CustomerAccountSearchInput;
}>;

export type CustomerAccountSearchQuery = {
  __typename: 'Query';
  customerAccountSearch:
    | {
        __typename: 'CustomerAccountSearchResponse';
        accounts: Array<{
          __typename: 'CustomerSearchRecord';
          fullName: string;
          licenses: Array<string>;
          providerId: number;
          account: {
            __typename: 'AccountSearchRecord';
            billingAccountId: string;
            shippingAddress: string;
            standing: AccountStanding;
            status: AccountStatus;
            phoneNumbers: Array<{ __typename: 'AccountSearchPhone'; number: string; type: string }>;
            practice: { __typename: 'PracticeSearchRecord'; practiceId: number; practiceName: string };
          };
          phoneNumbers: Array<{ __typename: 'AccountSearchPhone'; number: string; type: string }>;
        }>;
      }
    | { __typename: 'GenericError'; message: string };
};

export type ClassificationsQueryVariables = Exact<{ [key: string]: never }>;

export type ClassificationsQuery = {
  __typename: 'Query';
  classifications:
    | { __typename: 'BadRequestError'; message: string }
    | {
        __typename: 'ClassificationsWrapper';
        classifications: Array<{
          __typename: 'Classifications';
          materialName: string;
          restorations: Array<{
            __typename: 'ClassificationRestoration';
            restorationName: string;
            productCode: string;
          }>;
        }>;
      }
    | { __typename: 'GenericError'; message: string };
};

export type GetConfigsQueryVariables = Exact<{ [key: string]: never }>;

export type GetConfigsQuery = {
  __typename: 'Query';
  getConfigs:
    | {
        __typename: 'ConfigData';
        id?: string | null;
        description?: string | null;
        lastUpdatedDateUTC: string;
        sections: Array<{
          __typename: 'Config';
          name: string;
          description?: string | null;
          settings: Array<{
            __typename: 'ConfigSetting';
            key?: string | null;
            value?: string | null;
            description?: string | null;
            overrides?: string | null;
          }>;
        }>;
      }
    | { __typename: 'GenericError'; message: string };
};

export type CouponQueryVariables = Exact<{
  couponCode: Scalars['String'];
}>;

export type CouponQuery = {
  __typename: 'Query';
  coupon:
    | {
        __typename: 'Coupon';
        currencyCode?: CurrencyCode | null;
        code: string;
        activationDate: string;
        createdDate?: string | null;
        createdBy?: string | null;
        discountAmount: number;
        discountType: DiscountType;
        expirationDate: string;
        status?: CouponStatus | null;
        components: Array<{
          __typename: 'ProductComponent';
          abbreviation: string;
          type: ProductComponentType;
          value: string;
        }>;
        usageLimit?: {
          __typename: 'UsageLimit';
          accountLimit?: number | null;
          orderLimit: number;
          rxLimit: number;
        } | null;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type EnclosedItemsQueryVariables = Exact<{
  category?: InputMaybe<EnclosedItemCategory>;
}>;

export type EnclosedItemsQuery = {
  __typename: 'Query';
  enclosedItems:
    | {
        __typename: 'EnclosedItemsWrapper';
        enclosedItems: Array<{
          __typename: 'EnclosedItem';
          type: string;
          itemCode: string;
          category: EnclosedItemCategory;
        }>;
      }
    | { __typename: 'GenericError'; message: string };
};

export type OrderQueryVariables = Exact<{
  orderNumber: Scalars['String'];
}>;

export type OrderQuery = {
  __typename: 'Query';
  order:
    | { __typename: 'GenericError'; message: string }
    | {
        __typename: 'LegacyOrder';
        billingAccountId?: string | null;
        statusReason?: string | null;
        type?: OrderType | null;
        originatingSystem?: OrderOriginatingSystem | null;
        orderSource?: string | null;
        labReceivedDate?: string | null;
        customerDueDate?: string | null;
        subtotalAmount?: number | null;
        totalDiscountAmount?: number | null;
        totalFreightCharges?: number | null;
        totalTaxAmount?: number | null;
        totalAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        invoiceBy?: string | null;
        invoiceDate?: string | null;
        externalOrderNumber?: string | null;
        coupons: Array<string>;
        notes?: string | null;
        createdBy?: string | null;
        updatedBy?: string | null;
        updatedDate?: string | null;
        inbundleOrders: Array<string>;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        legacyProviderId?: string | null;
        legacyProviderName?: string | null;
        legacyOriginFacility?: string | null;
        legacyOriginFacilityId?: number | null;
        legacyDepartmentId?: string | null;
        legacyUtcConversionTimeZoneCode?: string | null;
        legacyCreatedDate?: string | null;
        shipping?: {
          __typename: 'LegacyShipping';
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          codAmount: number;
          legacyAddress?: {
            __typename: 'LegacyAddress';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country?: string | null;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          legacyInboundTrackingNumbers?: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }> | null;
          legacyOutboundTrackingNumbers?: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }> | null;
          legacyDoctorPickupLocation?: {
            __typename: 'LegacyDoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'LegacyAddress';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country?: string | null;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        legacyAddresses: Array<{
          __typename: 'LegacyAddress';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country?: string | null;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        legacyOrderItems: Array<{
          __typename: 'LegacyOrderItem';
          units?: Array<string | null> | null;
          quantity: number;
          unitPrice?: number | null;
          toothUnits?: number | null;
          lineItemNumber?: number | null;
          type?: ProductType | null;
          department?: string | null;
          restorationType?: string | null;
          subtotalAmount?: number | null;
          totalTaxAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalAmount?: number | null;
          coupons: Array<string>;
          couponAmount?: number | null;
          specialDiscountAmount?: number | null;
          daysInLab?: number | null;
          manufacturingLocation?: string | null;
          manufacturingLocationId: number;
          updatedBy?: string | null;
          updatedDate?: string | null;
          itemId: string;
          productName?: string | null;
          productCode: string;
          services: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
          }>;
        }>;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'Order';
        billingAccountId?: string | null;
        statusReason?: string | null;
        pONumber?: string | null;
        sCINumber?: string | null;
        type?: OrderType | null;
        paymentTerms?: OrderPaymentTerms | null;
        transactionId?: string | null;
        authorizationId?: string | null;
        authorizationAmount?: number | null;
        creditRequestedDate?: string | null;
        originatingSystem?: OrderOriginatingSystem | null;
        orderSource?: string | null;
        providerId: string;
        providerName: string;
        labReceivedDate?: string | null;
        customerDueDate?: string | null;
        subtotalAmount?: number | null;
        totalDiscountAmount?: number | null;
        totalFreightCharges?: number | null;
        totalTaxAmount?: number | null;
        totalAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        invoiceBy?: string | null;
        invoiceDate?: string | null;
        originFacility: string;
        originFacilityId: number;
        departmentId?: number | null;
        externalOrderNumber?: string | null;
        coupons: Array<string>;
        notes?: string | null;
        utcConversionTimeZoneCode: string;
        createdBy?: string | null;
        createdDate: string;
        updatedBy?: string | null;
        updatedDate?: string | null;
        linkedOrderNumber?: string | null;
        originalOrderNumber?: string | null;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        bundles?: {
          __typename: 'OrderBundle';
          inboundBundle?: {
            __typename: 'OrderInboundOutboundBundle';
            chargedOrderNumber: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
          outboundBundle?: {
            __typename: 'OrderInboundOutboundBundle';
            chargedOrderNumber: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
          splitBundle?: {
            __typename: 'OrderSplitBundle';
            bundleId: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
            detachedOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
        } | null;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        orderItems: Array<{
          __typename: 'OrderItem';
          quantity: number;
          quantityBackordered?: number | null;
          unitPrice?: number | null;
          toothUnits?: number | null;
          lineItemNumber?: number | null;
          type?: ProductType | null;
          department: string;
          productDescription?: string | null;
          subtotalAmount?: number | null;
          totalTaxAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalAmount?: number | null;
          coupons: Array<string>;
          couponAmount?: number | null;
          specialDiscountAmount?: number | null;
          daysInLab: number;
          orderNumber?: string | null;
          manufacturingLocation: string;
          manufacturingLocationId: number;
          createdDate?: string | null;
          createdBy?: string | null;
          updatedDate?: string | null;
          updatedBy?: string | null;
          itemId: string;
          productName: string;
          productCode: string;
          services: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          overrides?: Array<{
            __typename: 'OverrideField';
            field: string;
            type: OverrideFieldType;
            old: string;
            new: string;
          }> | null;
        }>;
        metadata?: {
          __typename: 'OrderMetaData';
          isPricingBeingCalculated: boolean;
          isTaxBeingCalculated: boolean;
        } | null;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | {
        __typename: 'PendingOrder';
        billingAccountId?: string | null;
        linkedOrderNumber?: string | null;
        externalOrderNumber?: string | null;
        orderSource?: string | null;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        pendingProviderId?: string | null;
        pendingProviderName?: string | null;
        pendingOriginalOrderNumber?: string | null;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        orderItems: Array<{
          __typename: 'PendingOrderItem';
          itemId: string;
          productName: string;
          productCode: string;
          pendingServices?: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }> | null;
          pendingAddOns?: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }> | null;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
          }>;
        }>;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | {
        __typename: 'RmaOrder';
        billingAccountId?: string | null;
        statusReason?: string | null;
        pONumber?: string | null;
        sCINumber?: string | null;
        type?: OrderType | null;
        paymentTerms?: OrderPaymentTerms | null;
        transactionId?: string | null;
        authorizationId?: string | null;
        authorizationAmount?: number | null;
        creditRequestedDate?: string | null;
        originatingSystem?: OrderOriginatingSystem | null;
        orderSource?: string | null;
        providerId: string;
        providerName: string;
        labReceivedDate?: string | null;
        customerDueDate?: string | null;
        subtotalAmount?: number | null;
        totalDiscountAmount?: number | null;
        totalFreightCharges?: number | null;
        totalTaxAmount?: number | null;
        totalAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        invoiceBy?: string | null;
        invoiceDate?: string | null;
        originFacility: string;
        originFacilityId: number;
        departmentId?: number | null;
        externalOrderNumber?: string | null;
        coupons: Array<string>;
        notes?: string | null;
        utcConversionTimeZoneCode: string;
        createdBy?: string | null;
        createdDate: string;
        updatedBy?: string | null;
        updatedDate?: string | null;
        linkedOrderNumber?: string | null;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        rmaOriginalOrderNumber: string;
        bundles?: {
          __typename: 'OrderBundle';
          inboundBundle?: {
            __typename: 'OrderInboundOutboundBundle';
            chargedOrderNumber: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
          outboundBundle?: {
            __typename: 'OrderInboundOutboundBundle';
            chargedOrderNumber: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
          splitBundle?: {
            __typename: 'OrderSplitBundle';
            bundleId: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
            detachedOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
        } | null;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        orderItems: Array<{
          __typename: 'RmaOrderItem';
          itemId: string;
          quantity: number;
          quantityBackordered?: number | null;
          unitPrice?: number | null;
          toothUnits?: number | null;
          lineItemNumber?: number | null;
          type?: ProductType | null;
          department: string;
          productDescription?: string | null;
          subtotalAmount?: number | null;
          totalTaxAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalAmount?: number | null;
          coupons: Array<string>;
          couponAmount?: number | null;
          specialDiscountAmount?: number | null;
          daysInLab: number;
          orderNumber?: string | null;
          manufacturingLocation: string;
          manufacturingLocationId: number;
          createdDate?: string | null;
          createdBy?: string | null;
          updatedDate?: string | null;
          updatedBy?: string | null;
          isOldProductReturned?: boolean | null;
          returnReasons?: Array<string> | null;
          returnType?: ReturnType | null;
          isProcessSwap?: boolean | null;
          productName: string;
          productCode: string;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          services: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          newProduct?: { __typename: 'RmaExchangeNewProduct'; productName: string; productCode: string } | null;
          overrides?: Array<{
            __typename: 'OverrideField';
            field: string;
            type: OverrideFieldType;
            old: string;
            new: string;
          }> | null;
        }>;
        metadata?: {
          __typename: 'OrderMetaData';
          isPricingBeingCalculated: boolean;
          isTaxBeingCalculated: boolean;
        } | null;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      };
};

export type OrderFullQueryVariables = Exact<{
  orderNumber: Scalars['String'];
}>;

export type OrderFullQuery = {
  __typename: 'Query';
  order:
    | { __typename: 'GenericError'; message: string }
    | {
        __typename: 'LegacyOrder';
        billingAccountId?: string | null;
        statusReason?: string | null;
        type?: OrderType | null;
        originatingSystem?: OrderOriginatingSystem | null;
        orderSource?: string | null;
        labReceivedDate?: string | null;
        customerDueDate?: string | null;
        subtotalAmount?: number | null;
        totalDiscountAmount?: number | null;
        totalFreightCharges?: number | null;
        totalTaxAmount?: number | null;
        totalAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        invoiceBy?: string | null;
        invoiceDate?: string | null;
        externalOrderNumber?: string | null;
        coupons: Array<string>;
        notes?: string | null;
        createdBy?: string | null;
        updatedBy?: string | null;
        updatedDate?: string | null;
        inbundleOrders: Array<string>;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        legacyProviderId?: string | null;
        legacyProviderName?: string | null;
        legacyOriginFacility?: string | null;
        legacyOriginFacilityId?: number | null;
        legacyDepartmentId?: string | null;
        legacyUtcConversionTimeZoneCode?: string | null;
        legacyCreatedDate?: string | null;
        shipping?: {
          __typename: 'LegacyShipping';
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          codAmount: number;
          legacyAddress?: {
            __typename: 'LegacyAddress';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country?: string | null;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          legacyInboundTrackingNumbers?: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }> | null;
          legacyOutboundTrackingNumbers?: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }> | null;
          legacyDoctorPickupLocation?: {
            __typename: 'LegacyDoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'LegacyAddress';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country?: string | null;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        legacyAddresses: Array<{
          __typename: 'LegacyAddress';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country?: string | null;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        legacyOrderItems: Array<{
          __typename: 'LegacyOrderItem';
          units?: Array<string | null> | null;
          quantity: number;
          unitPrice?: number | null;
          toothUnits?: number | null;
          lineItemNumber?: number | null;
          type?: ProductType | null;
          department?: string | null;
          restorationType?: string | null;
          subtotalAmount?: number | null;
          totalTaxAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalAmount?: number | null;
          coupons: Array<string>;
          couponAmount?: number | null;
          specialDiscountAmount?: number | null;
          daysInLab?: number | null;
          manufacturingLocation?: string | null;
          manufacturingLocationId: number;
          updatedBy?: string | null;
          updatedDate?: string | null;
          itemId: string;
          productName?: string | null;
          productCode: string;
          services: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
          }>;
        }>;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'Order';
        billingAccountId?: string | null;
        statusReason?: string | null;
        pONumber?: string | null;
        sCINumber?: string | null;
        type?: OrderType | null;
        paymentTerms?: OrderPaymentTerms | null;
        transactionId?: string | null;
        authorizationId?: string | null;
        authorizationAmount?: number | null;
        creditRequestedDate?: string | null;
        originatingSystem?: OrderOriginatingSystem | null;
        orderSource?: string | null;
        providerId: string;
        providerName: string;
        labReceivedDate?: string | null;
        customerDueDate?: string | null;
        subtotalAmount?: number | null;
        totalDiscountAmount?: number | null;
        totalFreightCharges?: number | null;
        totalTaxAmount?: number | null;
        totalAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        invoiceBy?: string | null;
        invoiceDate?: string | null;
        originFacility: string;
        originFacilityId: number;
        departmentId?: number | null;
        externalOrderNumber?: string | null;
        coupons: Array<string>;
        notes?: string | null;
        utcConversionTimeZoneCode: string;
        createdBy?: string | null;
        createdDate: string;
        updatedBy?: string | null;
        updatedDate?: string | null;
        linkedOrderNumber?: string | null;
        originalOrderNumber?: string | null;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        bundles?: {
          __typename: 'OrderBundle';
          inboundBundle?: {
            __typename: 'OrderInboundOutboundBundle';
            chargedOrderNumber: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
          outboundBundle?: {
            __typename: 'OrderInboundOutboundBundle';
            chargedOrderNumber: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
          splitBundle?: {
            __typename: 'OrderSplitBundle';
            bundleId: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
            detachedOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
        } | null;
        appliedCoupons?: Array<{
          __typename: 'Coupon';
          activationDate: string;
          code: string;
          createdDate?: string | null;
          createdBy?: string | null;
          currencyCode?: CurrencyCode | null;
          discountAmount: number;
          discountType: DiscountType;
          expirationDate: string;
          status?: CouponStatus | null;
          reason?: string | null;
          totalDiscountAmount?: number | null;
          components: Array<{
            __typename: 'ProductComponent';
            abbreviation: string;
            type: ProductComponentType;
            value: string;
          }>;
          usageLimit?: {
            __typename: 'UsageLimit';
            accountLimit?: number | null;
            orderLimit: number;
            rxLimit: number;
          } | null;
        }> | null;
        appliedDiscounts?: Array<{
          __typename: 'Deduction';
          discountId: string;
          orderNumber: string;
          reason: string;
          type: CaseDiscountType;
          createdBy: string;
          createdDate: string;
          notes?: string | null;
          deductions: Array<{
            __typename: 'Discount';
            productCode: string;
            discountType: DiscountType;
            value: number;
            discountAmount: number;
            orderItemId?: string | null;
            description?: string | null;
          }>;
        }> | null;
        appliedCredits?: Array<{
          __typename: 'Credit';
          creditNumber: string;
          orderNumber: string;
          billingAccountId: string;
          patientId?: string | null;
          patientFirstName?: string | null;
          patientLastName?: string | null;
          currencyCode: string;
          originFacilityId: number;
          totalAmount: number;
          totalDiscountAmount: number;
          totalTaxAmount: number;
          notes?: string | null;
          createdBy: string;
          createdDate: string;
          caseDiscountType: CaseDiscountType;
          reason?: string | null;
          couponCode?: string | null;
          creditItems: Array<{
            __typename: 'CreditItem';
            productName: string;
            productCode: string;
            creditType: CreditType;
            totalAmount: number;
            totalTaxAmount: number;
            couponCode?: string | null;
            quantity?: number | null;
            discountAmount: number;
            value: number;
            orderItemId: string;
            description: string;
            reason?: string | null;
          }>;
        }> | null;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        orderItems: Array<{
          __typename: 'OrderItem';
          quantity: number;
          quantityBackordered?: number | null;
          unitPrice?: number | null;
          toothUnits?: number | null;
          lineItemNumber?: number | null;
          type?: ProductType | null;
          department: string;
          productDescription?: string | null;
          subtotalAmount?: number | null;
          totalTaxAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalAmount?: number | null;
          coupons: Array<string>;
          couponAmount?: number | null;
          specialDiscountAmount?: number | null;
          daysInLab: number;
          orderNumber?: string | null;
          manufacturingLocation: string;
          manufacturingLocationId: number;
          createdDate?: string | null;
          createdBy?: string | null;
          updatedDate?: string | null;
          updatedBy?: string | null;
          itemId: string;
          productName: string;
          productCode: string;
          services: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          overrides?: Array<{
            __typename: 'OverrideField';
            field: string;
            type: OverrideFieldType;
            old: string;
            new: string;
          }> | null;
        }>;
        metadata?: {
          __typename: 'OrderMetaData';
          isPricingBeingCalculated: boolean;
          isTaxBeingCalculated: boolean;
        } | null;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | {
        __typename: 'PendingOrder';
        billingAccountId?: string | null;
        linkedOrderNumber?: string | null;
        externalOrderNumber?: string | null;
        orderSource?: string | null;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        pendingProviderId?: string | null;
        pendingProviderName?: string | null;
        pendingOriginalOrderNumber?: string | null;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        orderItems: Array<{
          __typename: 'PendingOrderItem';
          itemId: string;
          productName: string;
          productCode: string;
          pendingServices?: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }> | null;
          pendingAddOns?: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }> | null;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
          }>;
        }>;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      }
    | {
        __typename: 'RmaOrder';
        billingAccountId?: string | null;
        statusReason?: string | null;
        pONumber?: string | null;
        sCINumber?: string | null;
        type?: OrderType | null;
        paymentTerms?: OrderPaymentTerms | null;
        transactionId?: string | null;
        authorizationId?: string | null;
        authorizationAmount?: number | null;
        creditRequestedDate?: string | null;
        originatingSystem?: OrderOriginatingSystem | null;
        orderSource?: string | null;
        providerId: string;
        providerName: string;
        labReceivedDate?: string | null;
        customerDueDate?: string | null;
        subtotalAmount?: number | null;
        totalDiscountAmount?: number | null;
        totalFreightCharges?: number | null;
        totalTaxAmount?: number | null;
        totalAmount?: number | null;
        currencyCode?: CurrencyCode | null;
        invoiceBy?: string | null;
        invoiceDate?: string | null;
        originFacility: string;
        originFacilityId: number;
        departmentId?: number | null;
        externalOrderNumber?: string | null;
        coupons: Array<string>;
        notes?: string | null;
        utcConversionTimeZoneCode: string;
        createdBy?: string | null;
        createdDate: string;
        updatedBy?: string | null;
        updatedDate?: string | null;
        linkedOrderNumber?: string | null;
        orderNumber: string;
        status: OrderStatus;
        patientFirstName?: string | null;
        patientId?: string | null;
        patientLastName?: string | null;
        isCTScanEligible?: boolean | null;
        rmaOriginalOrderNumber: string;
        bundles?: {
          __typename: 'OrderBundle';
          inboundBundle?: {
            __typename: 'OrderInboundOutboundBundle';
            chargedOrderNumber: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
          outboundBundle?: {
            __typename: 'OrderInboundOutboundBundle';
            chargedOrderNumber: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
          splitBundle?: {
            __typename: 'OrderSplitBundle';
            bundleId: string;
            bundledOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
            detachedOrderNumbers: Array<{
              __typename: 'SearchedOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              providerId?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName?: string | null;
              createdDate?: string | null;
              utcConversionTimeZoneCode?: string | null;
              invoiceDate?: string | null;
              originatingSystem: OrderOriginatingSystem;
              labReceivedDate?: string | null;
              orderItems: Array<{
                __typename: 'SearchedOrderItem';
                productCode?: string | null;
                productName?: string | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }>;
          } | null;
        } | null;
        appliedCoupons?: Array<{
          __typename: 'Coupon';
          activationDate: string;
          code: string;
          createdDate?: string | null;
          createdBy?: string | null;
          currencyCode?: CurrencyCode | null;
          discountAmount: number;
          discountType: DiscountType;
          expirationDate: string;
          status?: CouponStatus | null;
          reason?: string | null;
          totalDiscountAmount?: number | null;
          components: Array<{
            __typename: 'ProductComponent';
            abbreviation: string;
            type: ProductComponentType;
            value: string;
          }>;
          usageLimit?: {
            __typename: 'UsageLimit';
            accountLimit?: number | null;
            orderLimit: number;
            rxLimit: number;
          } | null;
        }> | null;
        appliedDiscounts?: Array<{
          __typename: 'Deduction';
          discountId: string;
          orderNumber: string;
          reason: string;
          type: CaseDiscountType;
          createdBy: string;
          createdDate: string;
          notes?: string | null;
          deductions: Array<{
            __typename: 'Discount';
            productCode: string;
            discountType: DiscountType;
            value: number;
            discountAmount: number;
            orderItemId?: string | null;
            description?: string | null;
          }>;
        }> | null;
        appliedCredits?: Array<{
          __typename: 'Credit';
          creditNumber: string;
          orderNumber: string;
          billingAccountId: string;
          patientId?: string | null;
          patientFirstName?: string | null;
          patientLastName?: string | null;
          currencyCode: string;
          originFacilityId: number;
          totalAmount: number;
          totalDiscountAmount: number;
          totalTaxAmount: number;
          notes?: string | null;
          createdBy: string;
          createdDate: string;
          caseDiscountType: CaseDiscountType;
          reason?: string | null;
          couponCode?: string | null;
          creditItems: Array<{
            __typename: 'CreditItem';
            productName: string;
            productCode: string;
            creditType: CreditType;
            totalAmount: number;
            totalTaxAmount: number;
            couponCode?: string | null;
            quantity?: number | null;
            discountAmount: number;
            value: number;
            orderItemId: string;
            description: string;
            reason?: string | null;
          }>;
        }> | null;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        orderItems: Array<{
          __typename: 'RmaOrderItem';
          itemId: string;
          quantity: number;
          quantityBackordered?: number | null;
          unitPrice?: number | null;
          toothUnits?: number | null;
          lineItemNumber?: number | null;
          type?: ProductType | null;
          department: string;
          productDescription?: string | null;
          subtotalAmount?: number | null;
          totalTaxAmount?: number | null;
          totalDiscountAmount?: number | null;
          totalAmount?: number | null;
          coupons: Array<string>;
          couponAmount?: number | null;
          specialDiscountAmount?: number | null;
          daysInLab: number;
          orderNumber?: string | null;
          manufacturingLocation: string;
          manufacturingLocationId: number;
          createdDate?: string | null;
          createdBy?: string | null;
          updatedDate?: string | null;
          updatedBy?: string | null;
          isOldProductReturned?: boolean | null;
          returnReasons?: Array<string> | null;
          returnType?: ReturnType | null;
          isProcessSwap?: boolean | null;
          productName: string;
          productCode: string;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          services: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
            id?: string | null;
          }>;
          newProduct?: { __typename: 'RmaExchangeNewProduct'; productName: string; productCode: string } | null;
          overrides?: Array<{
            __typename: 'OverrideField';
            field: string;
            type: OverrideFieldType;
            old: string;
            new: string;
          }> | null;
        }>;
        metadata?: {
          __typename: 'OrderMetaData';
          isPricingBeingCalculated: boolean;
          isTaxBeingCalculated: boolean;
        } | null;
        fileAttachments: Array<{
          __typename: 'OrderAttachment';
          id: string;
          name: string;
          extension: string;
          size?: number | null;
          fileUrl: string;
          createdBy: string;
          createdDate: string;
          uploadProperties: {
            __typename: 'AttachmentUploadProperties';
            fileName?: string | null;
            fileType?: string | null;
            filePath: string;
          };
        }>;
        enclosedItems: Array<{
          __typename: 'OrderEnclosedItem';
          id: string;
          type: string;
          itemCode: string;
          quantity: number;
          createdBy: string;
          createdDate: string;
          shippingType: EnclosedItemCategory;
        }>;
      };
};

export type OrderInvoiceLookupQueryVariables = Exact<{
  orderNumber: Scalars['String'];
}>;

export type OrderInvoiceLookupQuery = {
  __typename: 'Query';
  order:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'LegacyOrder' }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'Order';
        orderNumber: string;
        status: OrderStatus;
        statusReason?: string | null;
        patientFirstName?: string | null;
        patientLastName?: string | null;
        patientId?: string | null;
        billingAccountId?: string | null;
        totalDiscountAmount?: number | null;
        subtotalAmount?: number | null;
        bundles?: {
          __typename: 'OrderBundle';
          splitBundle?: {
            __typename: 'OrderSplitBundle';
            bundleId: string;
            bundledOrderNumbers: Array<{ __typename: 'SearchedOrder'; orderNumber: string }>;
            detachedOrderNumbers: Array<{ __typename: 'SearchedOrder'; orderNumber: string }>;
          } | null;
        } | null;
        orderItems: Array<{
          __typename: 'OrderItem';
          itemId: string;
          productName: string;
          productCode: string;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
          }>;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
          }>;
        }>;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
      }
    | {
        __typename: 'PendingOrder';
        orderNumber: string;
        status: OrderStatus;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
      }
    | {
        __typename: 'RmaOrder';
        orderNumber: string;
        status: OrderStatus;
        statusReason?: string | null;
        patientFirstName?: string | null;
        patientLastName?: string | null;
        patientId?: string | null;
        billingAccountId?: string | null;
        totalDiscountAmount?: number | null;
        subtotalAmount?: number | null;
        bundles?: {
          __typename: 'OrderBundle';
          splitBundle?: {
            __typename: 'OrderSplitBundle';
            bundleId: string;
            bundledOrderNumbers: Array<{ __typename: 'SearchedOrder'; orderNumber: string }>;
            detachedOrderNumbers: Array<{ __typename: 'SearchedOrder'; orderNumber: string }>;
          } | null;
        } | null;
        orderItems: Array<{
          __typename: 'RmaOrderItem';
          returnType?: ReturnType | null;
          itemId: string;
          productName: string;
          productCode: string;
          addOns: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
            unitPrice?: number | null;
          }>;
          attributes: Array<{
            __typename: 'OrderProductAttribute';
            type: string;
            name: string;
            value: string;
            quantity?: number | null;
          }>;
        }>;
        shipping?: {
          __typename: 'Shipping';
          codAmount: number;
          shippingMethod?: ShippingMethod | null;
          packageType?: PackageType | null;
          estimatedShipDate?: string | null;
          deliveryMethod?: DeliveryMethod | null;
          address?: {
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          } | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
      };
};

export type OrderSearchQueryVariables = Exact<{
  input: OrderSearchInput;
}>;

export type OrderSearchQuery = {
  __typename: 'Query';
  orderSearch:
    | { __typename: 'GenericError'; message: string }
    | {
        __typename: 'SearchedOrderWrapper';
        total: number;
        orders: Array<{
          __typename: 'SearchedOrder';
          orderNumber: string;
          billingAccountId?: string | null;
          status: OrderStatus;
          providerId?: string | null;
          patientFirstName?: string | null;
          patientLastName?: string | null;
          patientId?: string | null;
          providerName?: string | null;
          createdDate?: string | null;
          utcConversionTimeZoneCode?: string | null;
          invoiceDate?: string | null;
          originatingSystem: OrderOriginatingSystem;
          labReceivedDate?: string | null;
          orderItems: Array<{
            __typename: 'SearchedOrderItem';
            productCode?: string | null;
            productName?: string | null;
            returnReasons?: Array<string> | null;
            returnType?: ReturnType | null;
            attributes: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
          }>;
        }>;
      };
};

export type RelatedCasesQueryVariables = Exact<{
  input: RelatedCasesInput;
}>;

export type RelatedCasesQuery = {
  __typename: 'Query';
  relatedCases:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError' }
    | {
        __typename: 'RelatedCasesWrapper';
        orders: Array<
          | {
              __typename: 'LegacyOrder';
              orderNumber: string;
              status: OrderStatus;
              invoiceDate?: string | null;
              originatingSystem?: OrderOriginatingSystem | null;
              labReceivedDate?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              legacyCreatedDate?: string | null;
              legacyUtcConversionTimeZoneCode?: string | null;
              legacyProviderName?: string | null;
              legacyOrderItems: Array<{
                __typename: 'LegacyOrderItem';
                units?: Array<string | null> | null;
                quantity: number;
                unitPrice?: number | null;
                toothUnits?: number | null;
                lineItemNumber?: number | null;
                type?: ProductType | null;
                department?: string | null;
                restorationType?: string | null;
                subtotalAmount?: number | null;
                totalTaxAmount?: number | null;
                totalDiscountAmount?: number | null;
                totalAmount?: number | null;
                coupons: Array<string>;
                couponAmount?: number | null;
                specialDiscountAmount?: number | null;
                daysInLab?: number | null;
                manufacturingLocation?: string | null;
                manufacturingLocationId: number;
                updatedBy?: string | null;
                updatedDate?: string | null;
                itemId: string;
                productName?: string | null;
                productCode: string;
                services: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
                addOns: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                }>;
              }>;
            }
          | {
              __typename: 'Order';
              orderNumber: string;
              status: OrderStatus;
              createdDate: string;
              utcConversionTimeZoneCode: string;
              invoiceDate?: string | null;
              originatingSystem?: OrderOriginatingSystem | null;
              labReceivedDate?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName: string;
              orderItems: Array<{
                __typename: 'OrderItem';
                productName: string;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
                addOns: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
                services: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }
          | { __typename: 'PendingOrder'; orderNumber: string; status: OrderStatus }
          | {
              __typename: 'RmaOrder';
              orderNumber: string;
              status: OrderStatus;
              createdDate: string;
              utcConversionTimeZoneCode: string;
              invoiceDate?: string | null;
              originatingSystem?: OrderOriginatingSystem | null;
              labReceivedDate?: string | null;
              patientFirstName?: string | null;
              patientLastName?: string | null;
              patientId?: string | null;
              providerName: string;
              orderItems: Array<{
                __typename: 'RmaOrderItem';
                productName: string;
                returnType?: ReturnType | null;
                returnReasons?: Array<string> | null;
                attributes: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
                addOns: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
                services: Array<{
                  __typename: 'OrderProductAttribute';
                  type: string;
                  name: string;
                  value: string;
                  quantity?: number | null;
                  unitPrice?: number | null;
                  id?: string | null;
                }>;
              }>;
            }
        >;
      };
};

export type RelatedCasesSearchQueryVariables = Exact<{
  input: RelatedCasesSearchInput;
  sort?: InputMaybe<Array<OpenSearchSort> | OpenSearchSort>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type RelatedCasesSearchQuery = {
  __typename: 'Query';
  relatedCasesSearch:
    | { __typename: 'GenericError'; message: string }
    | {
        __typename: 'SearchedOrderWrapper';
        total: number;
        orders: Array<{
          __typename: 'SearchedOrder';
          orderNumber: string;
          billingAccountId?: string | null;
          status: OrderStatus;
          providerId?: string | null;
          patientFirstName?: string | null;
          patientLastName?: string | null;
          patientId?: string | null;
          providerName?: string | null;
          createdDate?: string | null;
          utcConversionTimeZoneCode?: string | null;
          invoiceDate?: string | null;
          originatingSystem: OrderOriginatingSystem;
          labReceivedDate?: string | null;
          orderItems: Array<{
            __typename: 'SearchedOrderItem';
            productCode?: string | null;
            productName?: string | null;
            returnReasons?: Array<string> | null;
            returnType?: ReturnType | null;
            attributes: Array<{
              __typename: 'OrderProductAttribute';
              type: string;
              name: string;
              value: string;
              quantity?: number | null;
              unitPrice?: number | null;
              id?: string | null;
            }>;
          }>;
        }>;
      };
};

export type ProductQueryVariables = Exact<{
  productCode: Scalars['String'];
}>;

export type ProductQuery = {
  __typename: 'Query';
  product:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'Product';
        productCode: string;
        productName: string;
        type: ProductType;
        attributes: Array<{
          __typename: 'ProductAttribute';
          defaultValue?: string | null;
          isRequired?: boolean | null;
          name: string;
          type: string;
          rank?: number | null;
          attribute: {
            __typename: 'Attribute';
            attributeOptions: Array<string>;
            attributeValueType: ProductAttributeValueType;
            isPricing?: boolean | null;
            name: string;
            type: string;
            attributeRules?: Array<{
              __typename: 'AttributeRule';
              attributeValue: string;
              displayAttributes: Array<{ __typename: 'DisplayAttribute'; name: string; type: string }>;
            }> | null;
          };
        }>;
        components: Array<{
          __typename: 'ProductComponent';
          abbreviation: string;
          type: ProductComponentType;
          value: string;
        }>;
      };
};

export type ProductFullQueryVariables = Exact<{
  productCode: Scalars['String'];
}>;

export type ProductFullQuery = {
  __typename: 'Query';
  productFull:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'ProductFull';
        productCode: string;
        productName: string;
        type: ProductType;
        attributes: Array<{
          __typename: 'ProductFullAttribute';
          name: string;
          type: string;
          attributeOptions: Array<string>;
          attributeValueType: ProductAttributeValueType;
          defaultValue?: string | null;
          displayName: string;
          rank?: number | null;
          attributeGroupName?: string | null;
          displayType?: AttributeDisplayType | null;
          isPricing: boolean;
          isRequired: boolean;
          isVisible: boolean;
          isCustomAttribute: boolean;
          attributeRules?: Array<{
            __typename: 'AttributeRule';
            attributeValue: string;
            displayAttributes: Array<{ __typename: 'DisplayAttribute'; name: string; type: string }>;
          }> | null;
          reportingAttributes?: Array<{
            __typename: 'ReportingAttribute';
            key?: string | null;
            value?: string | null;
            type?: string | null;
          }> | null;
        }>;
        components: Array<{
          __typename: 'ProductComponent';
          abbreviation: string;
          type: ProductComponentType;
          value: string;
        }>;
      };
};

export type ProductAttributeQueryVariables = Exact<{
  attributeName: Scalars['String'];
}>;

export type ProductAttributeQuery = {
  __typename: 'Query';
  productAttribute:
    | {
        __typename: 'Attribute';
        name: string;
        type: string;
        attributeValueType: ProductAttributeValueType;
        attributeOptions: Array<string>;
        isPricing?: boolean | null;
        attributeRules?: Array<{
          __typename: 'AttributeRule';
          attributeValue: string;
          displayAttributes: Array<{ __typename: 'DisplayAttribute'; name: string; type: string }>;
        }> | null;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type ProductBrandQueryVariables = Exact<{
  productCode: Scalars['String'];
}>;

export type ProductBrandQuery = {
  __typename: 'Query';
  productBrand:
    | {
        __typename: 'BrandWrapper';
        brands: Array<{
          __typename: 'Brand';
          siteId: string;
          brandName: string;
          products: Array<{
            __typename: 'BrandProduct';
            productCode: string;
            daysInLab?: {
              __typename: 'BrandDaysInLab';
              internal: number;
              external: number;
              digital?: number | null;
            } | null;
            attributes: Array<{
              __typename: 'BrandAttribute';
              name: string;
              type: string;
              daysInLab?: {
                __typename: 'BrandDaysInLab';
                internal: number;
                external: number;
                digital?: number | null;
              } | null;
            }>;
          }>;
        }>;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type ValidateAddressQueryVariables = Exact<{
  input: AddressValidationInput;
}>;

export type ValidateAddressQuery = {
  __typename: 'Query';
  validateAddress:
    | {
        __typename: 'AddressValidationResponse';
        street1: string;
        street2?: string | null;
        city: string;
        state?: string | null;
        zip: string;
        company?: string | null;
        country: string;
        object: string;
        phone?: string | null;
        residential?: boolean | null;
        verifications: {
          __typename: 'AddressValidationVerificationResponse';
          delivery: {
            __typename: 'AddressValidationVerificationDelivery';
            success: boolean;
            details: {
              __typename: 'AddressValidationVerificationDeliveryDetails';
              latitude?: number | null;
              longitude?: number | null;
              time_zone?: string | null;
            };
          };
        };
      }
    | { __typename: 'GenericError'; message: string };
};

export type PreSignedUploadUrlQueryVariables = Exact<{
  files: PreSignedUrlFileInput;
}>;

export type PreSignedUploadUrlQuery = {
  __typename: 'Query';
  preSignedUploadUrl:
    | { __typename: 'GenericError'; message: string }
    | {
        __typename: 'PreSignedUrlWrapper';
        preSignedUrls: Array<{ __typename: 'PreSignedUrl'; location: string; fileName: string; url: string }>;
      };
};

export type WorkOrderQueryVariables = Exact<{
  orderNumber: Scalars['String'];
}>;

export type WorkOrderQuery = {
  __typename: 'Query';
  workOrder:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'WorkOrder'; html: string };
};

export type OrderInvoiceQueryVariables = Exact<{
  orderNumber: Scalars['String'];
}>;

export type OrderInvoiceQuery = {
  __typename: 'Query';
  orderInvoice:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'Invoice'; html: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type DoctorPickupLabelQueryVariables = Exact<{
  orderNumber: Scalars['String'];
}>;

export type DoctorPickupLabelQuery = {
  __typename: 'Query';
  doctorPickupLabel:
    | { __typename: 'DoctorPickupLabel'; html: string }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type ListQueryVariables = Exact<{
  category: ListCategory;
}>;

export type ListQuery = {
  __typename: 'Query';
  list:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'List'; listItems: Array<{ __typename: 'ListItem'; category: ListCategory; name: string }> };
};

export type ShippingOptionsQueryVariables = Exact<{
  input: ShippingOptionsInput;
}>;

export type ShippingOptionsQuery = {
  __typename: 'Query';
  shippingOptions:
    | { __typename: 'GenericError'; message: string }
    | {
        __typename: 'ShippingOptionsWrapper';
        shippingOptions: Array<{
          __typename: 'ShippingOption';
          carrier: string;
          cost: number;
          rateId: string;
          shipmentId: string;
          service: string;
          requiresSignature: boolean;
          isSaturdayDelivery: boolean;
          package?: PackageType | null;
          predefinedPackage?: string | null;
          charges?: Array<{
            __typename: 'Charge';
            shippingChargeId: string;
            service: string;
            taxCode?: string | null;
            isTaxable: boolean;
            currencies: Array<{ __typename: 'Currency'; currencyCode: CurrencyCode; price: number }>;
          }> | null;
        }>;
      };
};

export type ManufacturingLocationQueryVariables = Exact<{
  locationId: Scalars['String'];
}>;

export type ManufacturingLocationQuery = {
  __typename: 'Query';
  manufacturingLocation:
    | { __typename: 'GenericError'; message: string }
    | {
        __typename: 'ManufacturingLocation';
        isActive: boolean;
        locationName: string;
        locationId: number;
        manufacturingFacilityTimeZoneId: string;
        invoiceCutOffTime: string;
        addresses: Array<{
          __typename: 'Address';
          type: AddressType;
          street1: string;
          street2?: string | null;
          street3?: string | null;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          neighborhood?: string | null;
          division?: string | null;
        }>;
        phoneNumbers: Array<{
          __typename: 'Phone';
          type: PhoneType;
          number: string;
          extension?: string | null;
          country?: string | null;
        }>;
        emailAddresses: Array<{ __typename: 'Email'; type: EmailType; emailAddress: string }>;
      }
    | { __typename: 'NotFoundError'; message: string };
};

export type ManufacturingLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type ManufacturingLocationsQuery = {
  __typename: 'Query';
  manufacturingLocations:
    | { __typename: 'GenericError'; message: string }
    | {
        __typename: 'ManufacturingLocationsWrapper';
        locations: Array<{
          __typename: 'ManufacturingLocation';
          isActive: boolean;
          locationName: string;
          locationId: number;
          manufacturingFacilityTimeZoneId: string;
          invoiceCutOffTime: string;
          addresses: Array<{
            __typename: 'Address';
            type: AddressType;
            street1: string;
            street2?: string | null;
            street3?: string | null;
            city: string;
            state: string;
            zipcode: string;
            country: string;
            neighborhood?: string | null;
            division?: string | null;
          }>;
          phoneNumbers: Array<{
            __typename: 'Phone';
            type: PhoneType;
            number: string;
            extension?: string | null;
            country?: string | null;
          }>;
          emailAddresses: Array<{ __typename: 'Email'; type: EmailType; emailAddress: string }>;
        }>;
      };
};

export type CaseAlertsQueryVariables = Exact<{
  input: CaseAlertsSearchInput;
}>;

export type CaseAlertsQuery = {
  __typename: 'Query';
  caseAlerts:
    | {
        __typename: 'CaseAlerts';
        caseAlerts: Array<{
          __typename: 'CaseAlert';
          alertId?: string | null;
          appliedBy?: string | null;
          appliedDate?: string | null;
          status?: CaseAlertStatus | null;
          createdBy: string;
          createdDate?: string | null;
          updatedBy?: string | null;
          updatedDate?: string | null;
          providerId: string;
          billingAccountId: string;
          expireDate: string;
          spokeTo: string;
          orderNumber?: string | null;
          patientId?: string | null;
          patientFirstName?: string | null;
          patientLastName?: string | null;
          isPatientInfoIncomplete: boolean;
          updateCase?: Array<{
            __typename: 'UpdateCaseAlertDetail';
            notes?: string | null;
            product?: { __typename: 'AlertUpdateItem'; original: string; new: string } | null;
            toothNumber?: { __typename: 'AlertUpdateItem'; original: string; new: string } | null;
            shade?: { __typename: 'AlertUpdateItem'; original: string; new: string } | null;
            shippingAddress?: {
              __typename: 'CaseAddress';
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country?: string | null;
            } | null;
          }> | null;
          cancelCase?: Array<{
            __typename: 'CancelCaseAlertDetail';
            product?: string | null;
            reason: string;
            caseContent: CaseContentsType;
            notes?: string | null;
          }> | null;
          forwardCase?: Array<{
            __typename: 'ForwardCaseAlertDetail';
            sendToPlastering: boolean;
            routeType: RouteType;
            routeTo: string;
            reason?: string | null;
          }> | null;
          other?: Array<{ __typename: 'OtherCaseAlertDetail'; notes?: string | null }> | null;
        }>;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type OrderShippingQueryVariables = Exact<{
  orderNumber: Scalars['String'];
}>;

export type OrderShippingQuery = {
  __typename: 'Query';
  order:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'LegacyOrder' }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'Order';
        totalAmount?: number | null;
        shipping?: {
          __typename: 'Shipping';
          deliveryMethod?: DeliveryMethod | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
      }
    | { __typename: 'PendingOrder' }
    | {
        __typename: 'RmaOrder';
        totalAmount?: number | null;
        shipping?: {
          __typename: 'Shipping';
          deliveryMethod?: DeliveryMethod | null;
          shippingCharges?: Array<{
            __typename: 'ShippingCharge';
            id: string;
            type: ShippingChargeType;
            amount: number;
            discountAmount: number;
            salesTaxAmount?: number | null;
            totalAmount: number;
            carrier: string;
            service: string;
          }> | null;
          outboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          inboundTrackingNumbers: Array<{
            __typename: 'TrackingNumberItem';
            trackingNumber: string;
            insertionDate: string;
            insertedBy: string;
            carrier?: string | null;
            service?: string | null;
            shippingLabelUrls?: Array<{ __typename: 'ShippingLabel'; type: string; url: string }> | null;
          }>;
          doctorPickupLocation?: {
            __typename: 'DoctorPickupLocation';
            labId: number;
            labName: string;
            address: {
              __typename: 'Address';
              type: AddressType;
              street1: string;
              street2?: string | null;
              street3?: string | null;
              city: string;
              state: string;
              zipcode: string;
              country: string;
              neighborhood?: string | null;
              division?: string | null;
            };
            phone: {
              __typename: 'Phone';
              type: PhoneType;
              number: string;
              extension?: string | null;
              country?: string | null;
            };
          } | null;
        } | null;
      };
};

export type TechnicalPreferencesQueryVariables = Exact<{
  input: TechnicalPreferencesInput;
}>;

export type TechnicalPreferencesQuery = {
  __typename: 'Query';
  technicalPreferences:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'TechnicalPreferences';
        preferencesByAccountAndProvider: {
          __typename: 'PreferencesByAccountAndProvider';
          billingAccountId: string;
          providerId: string;
          preferences: Array<{
            __typename: 'TechnicalPreference';
            attributeName: string;
            attributeValue: string;
            productCode: string;
          }>;
        };
      };
};

export type OrderTransactionSearchQueryVariables = Exact<{
  input: OrderTransactionSearchInput;
}>;

export type OrderTransactionSearchQuery = {
  __typename: 'Query';
  orderTransactionSearch:
    | { __typename: 'GenericError' }
    | { __typename: 'NotFoundError' }
    | {
        __typename: 'OrderTransactionWrapper';
        transactions: Array<
          | {
              __typename: 'Order';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              statusReason?: string | null;
              invoiceDate?: string | null;
              originFacilityId: number;
              utcConversionTimeZoneCode: string;
              shipping?: {
                __typename: 'Shipping';
                shippingMethod?: ShippingMethod | null;
                estimatedShipDate?: string | null;
                packageType?: PackageType | null;
                shipmentId?: string | null;
                rateId?: string | null;
                address?: {
                  __typename: 'Address';
                  type: AddressType;
                  street1: string;
                  street2?: string | null;
                  street3?: string | null;
                  city: string;
                  state: string;
                  zipcode: string;
                  country: string;
                  neighborhood?: string | null;
                  division?: string | null;
                } | null;
                shippingCharges?: Array<{
                  __typename: 'ShippingCharge';
                  id: string;
                  type: ShippingChargeType;
                  amount: number;
                  discountAmount: number;
                  salesTaxAmount?: number | null;
                  totalAmount: number;
                  carrier: string;
                  service: string;
                  taxCode?: string | null;
                }> | null;
              } | null;
              orderItems: Array<{ __typename: 'OrderItem'; productCode: string }>;
              enclosedItems: Array<{
                __typename: 'OrderEnclosedItem';
                id: string;
                type: string;
                itemCode: string;
                quantity: number;
                createdBy: string;
                createdDate: string;
                shippingType: EnclosedItemCategory;
              }>;
            }
          | { __typename: 'PendingOrder'; status: OrderStatus }
          | {
              __typename: 'RmaOrder';
              orderNumber: string;
              billingAccountId?: string | null;
              status: OrderStatus;
              statusReason?: string | null;
              invoiceDate?: string | null;
              originFacilityId: number;
              utcConversionTimeZoneCode: string;
              shipping?: {
                __typename: 'Shipping';
                shippingMethod?: ShippingMethod | null;
                estimatedShipDate?: string | null;
                packageType?: PackageType | null;
                shipmentId?: string | null;
                rateId?: string | null;
                address?: {
                  __typename: 'Address';
                  type: AddressType;
                  street1: string;
                  street2?: string | null;
                  street3?: string | null;
                  city: string;
                  state: string;
                  zipcode: string;
                  country: string;
                  neighborhood?: string | null;
                  division?: string | null;
                } | null;
                shippingCharges?: Array<{
                  __typename: 'ShippingCharge';
                  id: string;
                  type: ShippingChargeType;
                  amount: number;
                  discountAmount: number;
                  salesTaxAmount?: number | null;
                  totalAmount: number;
                  carrier: string;
                  service: string;
                  taxCode?: string | null;
                }> | null;
              } | null;
              orderItems: Array<{
                __typename: 'RmaOrderItem';
                isOldProductReturned?: boolean | null;
                returnReasons?: Array<string> | null;
                returnType?: ReturnType | null;
                productCode: string;
              }>;
              enclosedItems: Array<{
                __typename: 'OrderEnclosedItem';
                id: string;
                type: string;
                itemCode: string;
                quantity: number;
                createdBy: string;
                createdDate: string;
                shippingType: EnclosedItemCategory;
              }>;
            }
        >;
      };
};

export type ShippingPreferencesQueryVariables = Exact<{
  billingAccountId: Scalars['String'];
}>;

export type ShippingPreferencesQuery = {
  __typename: 'Query';
  shippingPreferences:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'ShippingPreferences';
        billingAccountId: string;
        type: PreferenceType;
        doNotShip: Array<{ __typename: 'DoNotShip'; day?: string | null; start: string; end?: string | null }>;
        preferredCarrier?: {
          __typename: 'PreferredCarrier';
          carrier: string;
          service?: string | null;
          start: string;
          end?: string | null;
          options?: { __typename: 'PreferredCarrierOptions'; signatureRequired: boolean } | null;
        } | null;
      };
};

export type CaseHistoryQueryVariables = Exact<{
  orderNumber: Scalars['String'];
}>;

export type CaseHistoryQuery = {
  __typename: 'Query';
  caseHistory:
    | { __typename: 'GenericError'; message: string }
    | {
        __typename: 'HistoryResults';
        orderNumber: string;
        caseHistoryItems: Array<{
          __typename: 'CaseHistoryItem';
          completedBy?: string | null;
          completedByTechId?: string | null;
          timeStamp: string;
          process?: string | null;
          isProcessOverride?: boolean | null;
          managerId?: string | null;
          managerName?: string | null;
          manufacturingStatus?: ManufacturingStatus | null;
          orderNumber: string;
          productionFacility?: string | null;
          locationId?: string | null;
          utcConversionTimeZoneCode?: string | null;
          reason?: string | null;
          status?: string | null;
          eventType: string;
          actionType?: CaseHistoryActionType | null;
          additionalDetails?: {
            __typename: 'CaseHistoryAdditionalDetails';
            new?: string | null;
            old?: string | null;
          } | null;
          products?: Array<{
            __typename: 'CaseHistoryProductItem';
            toothString?: string | null;
            productName: string;
            productCode: string;
            department?: string | null;
          }> | null;
        }>;
      }
    | { __typename: 'NotFoundError'; message: string };
};

export type ShippingOrderTrackingNumberQueryVariables = Exact<{
  orderNumber: Scalars['String'];
}>;

export type ShippingOrderTrackingNumberQuery = {
  __typename: 'Query';
  shippingOrder:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | {
        __typename: 'ShippingOrderWrapper';
        items: Array<{
          __typename: 'ShippingOrder';
          status: ShippingStatus;
          trackingNumber: string;
          createdBy: string;
          createdDate: string;
          carrier: string;
          serviceName: string;
          shippingLabelUrls: Array<{ __typename: 'ShippingLabelUrl'; type: ShippingLabelUrlType; url?: string | null }>;
        }>;
      };
};

export type NextRmaOrderQueryVariables = Exact<{
  orderNumber: Scalars['String'];
}>;

export type NextRmaOrderQuery = {
  __typename: 'Query';
  nextRmaOrder:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NextRmaOrder'; orderNumber: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type ProductReturnRequirementQueryVariables = Exact<{
  productCode: Scalars['String'];
}>;

export type ProductReturnRequirementQuery = {
  __typename: 'Query';
  productReturnRequirement:
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string }
    | { __typename: 'ProductReturnRequirement'; productCode: string; isReturnRequired: boolean };
};

export type AvailableDiscountsQueryVariables = Exact<{
  input: AvailableDiscountsInput;
}>;

export type AvailableDiscountsQuery = {
  __typename: 'Query';
  availableDiscounts:
    | {
        __typename: 'AvailableDiscountsData';
        orderNumber: string;
        deductions: Array<{
          __typename: 'AvailableDeduction';
          orderItemId: string;
          description: string;
          productCode: string;
          availableAmount: number;
        }>;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type AvailableCreditsQueryVariables = Exact<{
  input: AvailableCreditsInput;
}>;

export type AvailableCreditsQuery = {
  __typename: 'Query';
  availableCredits:
    | {
        __typename: 'AvailableCreditsData';
        orderNumber: string;
        creditItems: Array<{
          __typename: 'AvailableDeduction';
          orderItemId: string;
          description: string;
          productCode: string;
          availableAmount: number;
        }>;
      }
    | { __typename: 'GenericError'; message: string }
    | { __typename: 'NotFoundError'; message: string };
};

export type DashboardQueryVariables = Exact<{
  input: DashboardInput;
}>;

export type DashboardQuery = {
  __typename: 'Query';
  dashboard:
    | {
        __typename: 'Dashboard';
        userCases: Array<{
          __typename: 'SearchedOrder';
          orderNumber: string;
          createdDate?: string | null;
          updatedDate?: string | null;
          status: OrderStatus;
          utcConversionTimeZoneCode?: string | null;
        }>;
      }
    | { __typename: 'GenericError'; message: string };
};

export type WorkOrderAvailableSubscriptionVariables = Exact<{
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
}>;

export type WorkOrderAvailableSubscription = {
  __typename: 'Subscription';
  workOrderAvailable?: { __typename: 'WorkOrderAvailable'; OrderNumber: string; SessionId: string } | null;
};

export type InvoiceAvailableSubscriptionVariables = Exact<{
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
}>;

export type InvoiceAvailableSubscription = {
  __typename: 'Subscription';
  invoiceAvailable?: { __typename: 'InvoiceAvailable'; OrderNumber: string; SessionId: string } | null;
};

export type ShippingLabelAvailableSubscriptionVariables = Exact<{
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
}>;

export type ShippingLabelAvailableSubscription = {
  __typename: 'Subscription';
  shippingLabelAvailable?: { __typename: 'ShippingLabelAvailable'; OrderNumber: string; SessionId: string } | null;
};

export type DoctorLabelAvailableSubscriptionVariables = Exact<{
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
}>;

export type DoctorLabelAvailableSubscription = {
  __typename: 'Subscription';
  doctorLabelAvailable?: {
    __typename: 'DoctorPickupLabelAvailable';
    OrderNumber: string;
    SessionId: string;
    PickupLabelHtml: string;
    CreatedDate: string;
  } | null;
};

export type OrderNotificationAvailableSubscriptionVariables = Exact<{
  OrderNumber: Scalars['String'];
  SessionId: Scalars['String'];
}>;

export type OrderNotificationAvailableSubscription = {
  __typename: 'Subscription';
  orderNotificationAvailable?: {
    __typename: 'OrderNotificationAvailable';
    OrderNumber: string;
    SessionId: string;
    NotificationEvent: OrderNotificationType;
  } | null;
};
