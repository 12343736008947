import SearchableDropdown from 'components/common/SearchableDropdown';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { DiscountModalTypeLabelMapping } from '../../types';

/**
 * Props for the ReasonForDiscount component.
 */
interface ReasonForDiscountProps {
  /**
   * List of reasons for discount.
   */
  reasons: string[];

  isBundle?: boolean;
}

/**
 * Component for selecting reason for discount.
 * @param reasons - List of reasons for discount.
 * @returns JSX.Element
 */
export const ReasonForDiscount: FC<ReasonForDiscountProps> = ({ reasons, isBundle }) => {
  const discountReason = useAddDiscountModalStore(state => state.specialDiscount.reason);
  const setDiscountReason = useAddDiscountModalStore(state => state.specialDiscount.setReason);
  const discountModalType = useAddDiscountModalStore(state => state.discountModalType);
  const mountedRef = useRef(false);
  const isInitial = useRef(true);
  const currentOrder = useAddDiscountModalStore(state => state.currentOrder);
  const label = useMemo(() => {
    const labelType = DiscountModalTypeLabelMapping[discountModalType];
    return `Reason for ${labelType}`;
  }, [discountModalType]);

  /**
   * Set default selected discount reason if not selected once component is mounted.
   */
  useEffect(() => {
    if (discountReason.value || mountedRef.current) return;
    setDiscountReason({ value: '', error: false });

    mountedRef.current = true;
  }, [discountReason, reasons, setDiscountReason]);
  useEffect(() => {
    if (!isInitial.current && !currentOrder?.orderNumber) {
      setDiscountReason({ value: '', error: true });
    }
    isInitial.current = false;
  }, [currentOrder, setDiscountReason]);

  /**
   * Determines the appropriate error message based on the discount reason error state and order details.
   *
   * - If discountReason.error is true and an order number exists, prompts the user to select a discount reason.
   * - If discountReason.error is true, no order number is present, and it's a bundle case, prompts the user to select a case first.
   * - Otherwise, returns an empty string (no error).
   */
  const errorMessage =
    discountReason.error && currentOrder?.orderNumber
      ? 'Please select a reason for discount'
      : discountReason.error && !currentOrder?.orderNumber && isBundle
      ? 'Please select a case first.'
      : '';
  return (
    <div>
      <SearchableDropdown
        label={label}
        options={reasons}
        onSelectedChange={value => {
          if (!currentOrder?.orderNumber) {
            setDiscountReason({ value: '', error: true });
            return;
          } else {
            setDiscountReason({ value, error: false });
          }
        }}
        selected={discountReason.value}
        id="discountFor"
        isRequired
        dataQa="reason-for-discount"
        errorMessage={errorMessage}
        placeholder="Select"
        isAbleToReset
      />
    </div>
  );
};
