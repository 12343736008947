import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import {
  AttributeDisplayType,
  Brand,
  ListCategory,
  OrderProductAttributeInput,
  OrderStatus,
  OverrideFieldType,
  ProductAttributeValueType,
  ProductFull,
  ProductFullAttribute,
  ReturnType,
} from 'API';
import classNames from 'classnames';
import AddButton from 'components/common/AddButton';
import { Badge } from 'components/common/Badge/Badge';
import DropdownMenuButton from 'components/common/DropdownMenuButton';
import Loader from 'components/common/Loader/Loader';
import { ProcessSwapBadge } from 'components/common/ProcessSwapBadge';
import { ReturnTypeBadge } from 'components/common/ReturnTypeBadge';
import { ROUTES } from 'components/navigation/Constants';
import { RMAType } from 'components/order-entry/RMA/RMAType/RMAType';
import { usePrevious } from 'hooks/use-previous';
import _, { sortBy } from 'lodash';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCategoryList } from 'shared/api/category-list.api';
import { getProductBrand } from 'shared/api/product.api';
import { AttributeName, AttributeType, ReportingAttributeKey } from 'shared/enums';
import { ACTIONS } from 'shared/enums/permission';
import { ProductState } from 'shared/enums/product';
import {
  getAttributeQuantity,
  isQuantityAttribute,
  isTierPricingAttribute,
} from 'shared/helpers/attribute/attribute.helper';
import {
  getDefaultOrderProductAttributeInput,
  getProductAttribute,
  getProductName,
} from 'shared/helpers/order-entry/order-entry.helper';
import { isRmaLocalOrder } from 'shared/helpers/util.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { DiagramAttribute, LocalOrderItemInput, LocalOrderProductAttributeInput } from 'shared/models';
import { getSelectedToothRange, isRestorationTypeBridge } from 'shared/utils';
import { useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';
import { useFetchCacheStore } from 'stores/useFetchCacheStore';
import { v4 as uuidv4 } from 'uuid';
import Label from '../../../common/Label';
import AddOn from '../AddOnAndService/AddOnAndService';
import Alloy from '../Alloy/Alloy';
import FooterLegend from '../FooterLegend';
import Layered from '../Layered/Layered';
import ProductionFacility from '../ProductionFacility/ProductionFacility';
import ProductSearch from '../ProductSearch/ProductSearch';
import Pontic from '../ToothInput/Pontic/Pontic';
import ToothRange from '../ToothInput/ToothRange/ToothRange';
import ToothSingle from '../ToothInput/ToothSingle/ToothSingle';
import Attribute from './Attribute/Attribute';
import { AttributeCustomized } from './Attribute/AttributeCustomized/AttributeCustomized';
import styles from './Product.module.css';
import Quantity from './Quantity/Quantity';

/**
 * Props for the Product component.
 */
interface ProductProps {
  /**
   * The ID of the billing account associated with the product.
   */
  billingAccountId: string;
  /**
   * The order item information.
   */
  orderItem: LocalOrderItemInput;
  /**
   * The ID of the selected product.
   */
  selectedProductId: string;
  /**
   * Callback function to remove the product.
   */
  onProductRemove: () => void;
  /**
   * Indicates whether the component is used for updating an existing order.
   */
  isOrderUpdate?: boolean;
  /**
   * Indicates whether product information is currently being loaded.
   */
  isLoadingProductInformation?: boolean;

  setAllProductInfoCached: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        {
          salesCategoryAndBackLogGroupAttributeNames: string[];
          productBrands: Brand[];
        }
      >
    >
  >;
}

/**
 * The Product component represents a product in the orderEntry.
 * @param billingAccountId - The ID of the billing account associated with the product.
 * @param orderItem - The order item information.
 * @param selectedProductId - The ID of the selected product.
 * @param onProductRemove - Callback function to remove the product.
 * @param isOrderUpdate - Indicates whether the component is used for updating an existing order. (optional)
 * @param isLoadingProductInformation - Indicates whether product information is currently being loaded. (optional)
 * @returns JSX element representing the Product component.
 */
const Product: React.FC<ProductProps> = ({
  billingAccountId,
  orderItem,
  selectedProductId,
  onProductRemove,
  isOrderUpdate,
  isLoadingProductInformation,
  setAllProductInfoCached,
}) => {
  const { updateInitialOrderData } = useBundleSplitCaseStore(state => ({
    updateInitialOrderData: state.updateInitialOrderData,
  }));
  const [productBrands, setProductBrands] = useState<Brand[]>([]);
  const [productState, setProductState] = useState<ProductState>(ProductState.ProductSelection);
  const footerElementRef = useRef<HTMLDivElement>(null);
  const {
    getMaterialAndResorationType,
    patchOrder,
    updateOrderItem,
    setRequiredAttributes,
    setTierPricingAttributes,
    applyTechnicalPreferencesToTargetOrderItem,
    getOrderItemSelectedTeeth,
    setRequiredImplantsItems,
    isOrderItemExchangeReturnType,
  } = useContext(OrderModuleActionsContext);
  const { fetcher: getProductBrandFetcher } = useLazyQueryFetcher(getProductBrand);

  const [attributes, setAttributes] = useState<ProductFullAttribute[]>([]);
  const [diagramAttribute, setDiagramAttribute] = useState<DiagramAttribute>();
  const [ponticAttributes, setPonticAttributes] = useState<ProductFullAttribute[]>();
  const [customAttributes, setCustomAttributes] = useState<ProductFullAttribute[]>([]);
  // const [availablePontics, setAvailablePontics] = useState<ProductAttribute[]>();
  const [departmentAttribute, setDepartmentAttribute] = useState<ProductFullAttribute>(getProductAttribute());
  const {
    order,
    orderStatus,
    requiredAttributes,
    tierPricingAttributes,
    technicalPreferencesByProductCode,
    requiredImplantItems,
    orderId,
    originalOrderIsLegacy: isLegacyCase,
  } = useContext(OrderModuleContext);

  const alert = useContext(AlertModalContext);
  const location = useLocation();
  const [alloys, setAlloys] = useState<ProductFullAttribute[]>([]);
  const [showLayered, setShowLayered] = useState<boolean>(false);
  const editAlloyPermission = useRoleBasedAccessControl(ACTIONS.EDIT_ALLOY_TYPE);
  const disableToothInput = orderItem.attributes.find(data => data.name === AttributeName.NoTooth)?.value === 'Yes';

  const isNewOrder = location.pathname.includes(ROUTES.NEW_ORDER);
  const isPendingOrder = orderStatus === OrderStatus.Pending;
  const isNewOrPendingOrder = isNewOrder || isPendingOrder;
  const ponticsInOrder = useMemo(() => {
    return orderItem.attributes.filter(att => att.type === AttributeType.PonticDesign);
  }, [orderItem.attributes]);
  const alloyInOrder = useMemo(() => {
    return orderItem.attributes.filter(att => att.type === AttributeType.Alloy);
  }, [orderItem.attributes]);
  const productTechnicalPreferences = useMemo(
    () => technicalPreferencesByProductCode[orderItem.productCode],
    [orderItem.productCode, technicalPreferencesByProductCode]
  );

  // Keeps track of the add-on and service attributes, which will have their default values set to their technical preference, if they exist.
  const [addOnsAndServices, setAddOnsAndServices] = useState<ProductFullAttribute[]>([]);
  const [productInfo, setProductInfo] = useState<ProductFull>();
  const currentOrderRef = useRef(order);

  // Get the sales category and backlog group attribute names
  const salesCategoryAndBackLogGroupAttributeRecord = useMemo(() => {
    const result: Record<'sameAttributeValues' | 'differentAttributeValues', string[]> = {
      sameAttributeValues: [],
      differentAttributeValues: [],
    };
    if (!productInfo) return result;

    productInfo.attributes.forEach(attribute => {
      const salesCategory = attribute.reportingAttributes?.find(
        reportingAttribute => reportingAttribute.key === ReportingAttributeKey.SalesCategory
      );
      const backLogGroup = attribute.reportingAttributes?.find(
        reportingAttribute => reportingAttribute.key === ReportingAttributeKey.BacklogGroup
      );
      // If the sales category or backlog group is missing, skip the attribute
      if (!salesCategory || !backLogGroup) return;

      // If the sales category and backlog group are the same, add the attribute name to the list
      if (salesCategory.value === backLogGroup.value) {
        result.sameAttributeValues.push(attribute.name);
      } else if (salesCategory.value !== backLogGroup.value) {
        result.differentAttributeValues.push(attribute.name);
      }
    });
    return result;
  }, [productInfo]);

  useEffect(() => {
    const productCode = orderItem.productCode;
    if (!productCode) return;

    setAllProductInfoCached(prev => {
      return {
        ...prev,
        [productCode]: {
          salesCategoryAndBackLogGroupAttributeNames: salesCategoryAndBackLogGroupAttributeRecord.sameAttributeValues,
          productBrands: productBrands || [],
        },
      };
    });
  }, [setAllProductInfoCached, orderItem.productCode, salesCategoryAndBackLogGroupAttributeRecord, productBrands]);

  // set the current order to the ref
  useEffect(() => {
    currentOrderRef.current = order;
  }, [order]);
  // set the current order to the ref

  // Tooth string converted to array of teeth values.
  const itemTeethUnfiltered = useMemo(() => {
    return getOrderItemSelectedTeeth(orderItem.id);
  }, [getOrderItemSelectedTeeth, orderItem.id]);

  // Tooth string array with missing teeth filtered out.
  // to be used with a useEffect hook to reset tooth selection attributes when tooth selection changes
  const itemTeeth = useMemo(() => {
    return getSelectedToothRange(orderItem?.addOns, itemTeethUnfiltered);
  }, [itemTeethUnfiltered, orderItem?.addOns]);

  const prevSelectedTeeth = usePrevious(itemTeeth);

  // Fetches return reasons and maps them into a dropdown option format.
  const {
    loading: isReturnReasonsLoading,
    data: returnReasons,
    error: returnReasonsError,
  } = useQueryFetcher(getCategoryList, {
    category: ListCategory.RmaReturnReasons,
    skip: !isRmaLocalOrder(order),
  });

  const fetchProductFull = useFetchCacheStore(state => state.fetchProductFull);
  const { fetcher: getProductFullFetcher } = useLazyQueryFetcher(fetchProductFull);

  /**
   * Sorts attributes by rank.
   * The lower the rank, the higher the priority.
   * If no rank is given, the attribute will appear towards the end of the array.
   * @param unsortedAttributes - Attributes to sort.
   * @returns attributes sorted by rank.
   */
  const sortByRank = (unsortedAttributes: ProductFullAttribute[]) => {
    return sortBy(unsortedAttributes, unsortedAttribute => unsortedAttribute.rank);
  };

  /**
   * Loads attribute data for a the product detail and base order item stored in the state above, if any.
   */
  const loadAttributeData = useCallback(
    (productDetail: ProductFull, baseOrderItem: LocalOrderItemInput) => {
      setProductInfo(productDetail);
      // If required data is not available (i.e. still loading), returns without loading attributes.
      if (!productDetail || !baseOrderItem) return;

      const newOrderItem = baseOrderItem;

      const orderItemAttributes: LocalOrderProductAttributeInput[] = [];

      // Sets the add-on and service attributes;
      const unsortedAddOnsAndServices = productDetail.attributes.filter(
        att => att.type === AttributeType.AddOn || att.type === AttributeType.Service
      );
      const sortedAddonsAndServices = sortByRank(unsortedAddOnsAndServices);
      setAddOnsAndServices(sortedAddonsAndServices);

      const isDiagramAttribute = (attribute: ProductFullAttribute) => {
        return (
          attribute.name === AttributeName.ToothString &&
          attribute.attributeValueType === ProductAttributeValueType.Diagram
        );
      };

      // Filters out the custom attributes from the list of attributes
      const filteredCustomAttributes = productDetail.attributes.filter(attribute => attribute.isCustomAttribute);
      setCustomAttributes(filteredCustomAttributes);

      // Attributes, excluding the diagram attribute since we need to pull this out and display separately.
      const unsortedProductAttributes = productDetail.attributes.filter(
        attribute =>
          !isDiagramAttribute(attribute) &&
          attribute.name !== AttributeName.MissingTooth &&
          attribute.isVisible &&
          !attribute.isCustomAttribute
      );
      const sortedProductAttributes = sortByRank(unsortedProductAttributes);
      setAttributes(sortedProductAttributes);

      // Building attributes that will eventually be set into order context model
      sortedProductAttributes.forEach(attribute => {
        const attributeInput = getDefaultOrderProductAttributeInput();
        // Existing attribute can be there if order from data capture was loaded.
        const existingAttributeWithName = newOrderItem.attributes.find(att => att.name === attribute.name);
        attributeInput.name = attribute.name;
        attributeInput.type = attribute.type;
        attributeInput.value = existingAttributeWithName?.value || attribute.defaultValue || '';
        attributeInput.quantity =
          existingAttributeWithName?.quantity ??
          getAttributeQuantity({
            ...attributeInput,
            attributeValueType: attribute.attributeValueType,
          });
        orderItemAttributes.push(attributeInput);
      });

      // Checking to see if there is a diagram (Each product can only have up to 1 diagram)
      const productDiagram = productDetail.attributes.find(isDiagramAttribute);
      // If there is not diagram or if it is different from the existing, then setting state
      if (productDiagram) {
        // Existing attribute can be there if order from data capture was loaded.
        const existingAttributeWithName = newOrderItem.attributes.find(att => att.name === productDiagram.name);
        const diagramAttribute: OrderProductAttributeInput = {
          ...getDefaultOrderProductAttributeInput(),
          name: productDiagram.name || '',
          value: existingAttributeWithName?.value || productDiagram.defaultValue || '',
          type: productDiagram.type,
        };
        if (existingAttributeWithName?.quantity) {
          diagramAttribute.quantity = existingAttributeWithName.quantity;
        }
        setDiagramAttribute({
          ...diagramAttribute,
          isRequired: productDiagram.isRequired ?? false,
        });
        // Continuing to build attributes to set into order context model
        orderItemAttributes.push(diagramAttribute);
      }

      // Checking to see if there are pontic attributes. NOTE: Won't always exist, could return empty array.
      const unsortedPonticAttributes = productDetail.attributes.filter(
        attribute => attribute.type === AttributeType.PonticDesign
      );

      if (unsortedPonticAttributes.length) {
        const sortedPonticAttributes = sortByRank(unsortedPonticAttributes);
        setPonticAttributes(sortedPonticAttributes);

        // specially map the existing order pontics, they're a little different than above line 153
        const orderPontics = newOrderItem.attributes.filter(attribute => attribute.type === AttributeType.PonticDesign);
        if (orderPontics.length > 0) {
          orderPontics.forEach(item => {
            orderItemAttributes.push({
              name: item.name,
              type: item.type,
              value: item.value,
              quantity: 1,
              id: uuidv4(),
            });
          });
        }
      }
      //alloy attribute check and add if alloy attribute present in productDetails/ in orderItem
      const alloyAttribute = productDetail.attributes.filter(attribute => attribute.type === AttributeType.Alloy);
      if (alloyAttribute.length > 0) {
        const orderAlloy = newOrderItem.attributes.filter(attribute => attribute.type === AttributeType.Alloy);
        if (orderAlloy.length > 0) {
          orderAlloy.forEach(item => {
            orderItemAttributes.push({
              name: item.name,
              type: item.type,
              value: +item.value ? item.value : '',
              quantity: 1,
              id: uuidv4(),
            });
          });
        } else {
          orderItemAttributes.push({
            name: '',
            type: AttributeType.Alloy,
            value: '',
            quantity: 0,
            id: uuidv4(),
          });
        }
      }

      // set alloys
      const unsortedAlloys = productDetail.attributes.filter(attribute => attribute.type === AttributeType.Alloy);
      const sortedAlloys = sortByRank(unsortedAlloys);
      setAlloys(sortedAlloys);

      // set layered attribute for emax products
      setShowLayered(
        productDetail.attributes.some(attribute => attribute.name === 'Anterior Layered') &&
          productDetail.attributes.some(attribute => attribute.name === 'Posterior Layered')
      );

      // Getting department attribute and setting state
      const productDepartmentsAttribute = productDetail.attributes.find(
        a => a.type === AttributeType.ReportGrouping && a.name === AttributeName.Department
      );

      // Set state for departments
      if (productDepartmentsAttribute) {
        newOrderItem.department = newOrderItem.department.length
          ? newOrderItem.department
          : productDepartmentsAttribute.defaultValue
          ? productDepartmentsAttribute.defaultValue
          : '';
        setDepartmentAttribute(productDepartmentsAttribute);
      }

      const currentOrder = currentOrderRef.current;
      const foundOrderItemIndex = currentOrder.orderItems.findIndex(item => item.id === newOrderItem.id);

      /**
       * filterRequiredAttributes is used to filter out attributes that are required and should be included in order item.
       * and only include the attributes that are not custom attributes, and are visible or are of the type implantAttributeTypes.
       */
      const filterRequiredAttributes = productDetail.attributes.filter(attribute => {
        return attribute.isRequired && !attribute.isCustomAttribute && attribute.isVisible;
      });

      /**
       * add the required attributes to the orderItemAttributes, if they missed to add from the above logic.
       * Below code is to make sure that the required attributes are always present in the orderItemAttributes.
       * So that we can properly validate the required attributes, when the user tries to submit the order.
       */
      filterRequiredAttributes.forEach(filterAttribute => {
        const isExistingAttribute = orderItemAttributes.find(att => att.name === filterAttribute.name);
        if (isExistingAttribute) return; // if attribute already exists in orderItemAttributes array, then don't add it again
        const attributeInput = getDefaultOrderProductAttributeInput();
        const existingAttributeWithName = newOrderItem.attributes.find(att => att.name === filterAttribute.name);
        attributeInput.name = filterAttribute.name;
        attributeInput.type = filterAttribute.type;
        attributeInput.value = existingAttributeWithName?.value || filterAttribute.defaultValue || '';
        attributeInput.quantity =
          existingAttributeWithName?.quantity ??
          getAttributeQuantity({
            ...attributeInput,
            attributeValueType: filterAttribute.attributeValueType,
          });
        orderItemAttributes.push(attributeInput);
      });

      // Parses through each add-on and service to see if its children need to be rendered but are not in the order.
      // Without this code, the optional children of add-ons that currently have no value will not automatically render.
      sortedAddonsAndServices.forEach(targetProductAddOn => {
        // Cycles through the target add-on's attributeRules to ensure that any children are added to the order.
        targetProductAddOn.attributeRules?.forEach(rule => {
          rule.displayAttributes.forEach(displayAttribute => {
            const childName = displayAttribute.name;
            const isChildInOrder = newOrderItem.addOns.find(addon => addon.name === childName);
            if (!isChildInOrder) {
              newOrderItem.addOns.push({
                name: childName,
                value: '',
                type: AttributeType.AddOn,
                quantity: 1,
              });
            }
          });
        });
      });

      if (foundOrderItemIndex > -1) {
        newOrderItem.attributes = orderItemAttributes;
        currentOrder.orderItems[foundOrderItemIndex] = { ...newOrderItem, isLoaded: true };
        updateInitialOrderData(orderId, currentOrder);
        patchOrder(
          {
            orderItems: currentOrder.orderItems,
          },
          true
        );
      }
      setProductState(ProductState.AttributesRendered);
    },
    [orderId, patchOrder, updateInitialOrderData]
  );

  /**
   * Main callback function that runs whenever a product material and restoration is selected.
   */
  const materialAndRestorationSelectHandler = useCallback(
    async (productCode: string | null) => {
      if (!productCode) return;

      const classificationProduct = getMaterialAndResorationType(productCode);

      if (!classificationProduct) return;

      const { materialName, restorationName } = classificationProduct;

      const newOrderItem = _.cloneDeep(orderItem);

      newOrderItem.productCode = productCode;
      newOrderItem.productName = getProductName(materialName, restorationName);

      // add seperate material/type information so the backend can map easier
      newOrderItem.material = materialName;
      newOrderItem.restorationType = restorationName;
      setProductState(ProductState.AttributesLoading);

      // Get Product Detail
      try {
        const { data: productDetail, error } = await getProductFullFetcher(productCode);
        if (error) throw error;

        if (!productDetail) {
          throw new Error(`No data found for '${materialName} ${restorationName}'`);
        }

        // Sets the product brands.
        const productBrands: Brand[] = await getProductBrandFetcher(productCode);
        setProductBrands(productBrands);

        // Sets the required attributes for this product.
        setRequiredAttributes({
          ...requiredAttributes,
          [productCode]: productDetail.attributes
            .filter(attribute => attribute.isRequired)
            .map(attribute => attribute.name),
        });

        setTierPricingAttributes({
          ...tierPricingAttributes,
          [productCode]: productDetail.attributes
            .filter(attribute => isTierPricingAttribute(attribute) && attribute.isVisible)
            .map(attribute => attribute.name),
        });

        loadAttributeData(productDetail, newOrderItem);
      } catch (e) {
        console.error(e);
        setProductState(ProductState.AttributesError);
        alert.show(
          'Unable to Load Data',
          'There was an issue loading the product information. Please try again later.'
        );
      }
    },
    [
      alert,
      getMaterialAndResorationType,
      getProductFullFetcher,
      loadAttributeData,
      orderItem,
      requiredAttributes,
      tierPricingAttributes,
      setTierPricingAttributes,
      setRequiredAttributes,
      getProductBrandFetcher,
    ]
  );

  const updateOrderItemsWithPreferences = useCallback(() => {
    applyTechnicalPreferencesToTargetOrderItem({
      technicalPreferences: productTechnicalPreferences,
      addOnsAndServices,
      targetOrderItemId: orderItem.id,
      isNewOrPendingOrder,
      productAttributes: attributes,
    });
  }, [
    addOnsAndServices,
    isNewOrPendingOrder,
    orderItem.id,
    productTechnicalPreferences,
    applyTechnicalPreferencesToTargetOrderItem,
    attributes,
  ]);

  useEffect(() => {
    if (productTechnicalPreferences && productState === ProductState.AttributesRendered) {
      updateOrderItemsWithPreferences();
    }
  }, [updateOrderItemsWithPreferences, productState, productTechnicalPreferences]);

  // Handles initial order load in case edit mode.
  /**
   * During exchange order or similar scenarios, the materialAndRestorationSelectHandler is invoked before the products are loaded (race condition).
   * In such situations, it's important to check the isLoadingProductInformation as well.
   * This check ensures that the products have been loaded,
   * preventing issues where productName might return as empty and potentially cause a failure in the case submission process.
   */
  useEffect(() => {
    if (
      orderItem.productCode &&
      !orderItem.isLoaded &&
      productState === ProductState.ProductSelection &&
      !isLoadingProductInformation
    ) {
      materialAndRestorationSelectHandler(orderItem.productCode);
    }
  }, [
    materialAndRestorationSelectHandler,
    isLoadingProductInformation,
    isOrderUpdate,
    orderItem.isLoaded,
    orderItem.productCode,
    productState,
  ]);

  const [attributesWithoutQuantity, quantityAttribute] = useMemo(() => {
    return [attributes.filter(item => !isQuantityAttribute(item)), attributes.find(isQuantityAttribute)];
  }, [attributes]);

  // If attribute already exists in order then updates, otherwise creates attribute in order
  const attributeChangeHandler = (attribute: OrderProductAttributeInput) => {
    const newOrder = _.cloneDeep(order);
    const foundProductAttribute = productInfo?.attributes.find(
      att => att.name === attribute.name && att.type === attribute.type
    );
    const foundOrderItemIndex = newOrder.orderItems.findIndex(item => item.id === orderItem.id);

    if (foundOrderItemIndex === -1) return;

    let orderItemOverrides = newOrder.orderItems[foundOrderItemIndex]?.overrides || [];
    const findAtrribute = newOrder.orderItems[foundOrderItemIndex]?.attributes.filter(
      attr => attr.name === attribute.name
    )[0];
    const toothAttributeHasValue = order.orderItems[foundOrderItemIndex]?.attributes?.find(
      att => att.name === AttributeType.ToothString
    )?.value.length;

    const attributeQuantity = getAttributeQuantity({
      ...attribute,
      attributeValueType: foundProductAttribute?.attributeValueType,
    });

    if (findAtrribute) {
      findAtrribute.value = attribute.value;
      findAtrribute.type = attribute.type;
      findAtrribute.quantity = attributeQuantity;
      if (attribute.name === AttributeName.NoTooth && attribute.value === 'Yes' && toothAttributeHasValue) {
        const newAttribute = newOrder.orderItems[foundOrderItemIndex].attributes.filter(
          att => att.name === AttributeType.ToothString
        );
        newAttribute.forEach(att => (att.value = ''));
      }

      // Filters the overwritten field to avoid duplicates. It will be re-evaluated in the next if statement.
      orderItemOverrides = orderItemOverrides.filter(override => override.field !== attribute.name);
      // If the preference value for the attribute has changed, adds it to the list of overwritten attributes.
      if (findAtrribute.defaultValue && attribute.value !== findAtrribute.defaultValue) {
        orderItemOverrides.push({
          new: attribute.value,
          old: findAtrribute.defaultValue,
          type: OverrideFieldType.TechnicalPreference,
          field: attribute.name,
        });
      }
      // Sets the new overrides values after the current attribute selection.
      newOrder.orderItems[foundOrderItemIndex].overrides = orderItemOverrides.length ? orderItemOverrides : null;
    } else {
      newOrder.orderItems[foundOrderItemIndex].attributes.push({
        name: attribute.name,
        type: attribute.type,
        value: attribute.value,
        quantity: attributeQuantity,
      });
    }
    if (isQuantityAttribute(attribute)) {
      newOrder.orderItems[foundOrderItemIndex].quantity = attributeQuantity;
      // If the attribute is a quantity attribute, then set quantity value to the toothUnits.
      newOrder.orderItems[foundOrderItemIndex].toothUnits = attributeQuantity;
    }

    patchOrder({
      orderItems: newOrder.orderItems,
    });
  };

  const alloyChangeHandler = (attribute: OrderProductAttributeInput) => {
    const newOrder = _.cloneDeep(order);
    const foundOrderItemIndex = newOrder.orderItems.findIndex(item => item.id === orderItem.id);
    const findAtrribute = newOrder.orderItems[foundOrderItemIndex]?.attributes.filter(
      attr => attr.type === AttributeType.Alloy
    )[0];

    if (findAtrribute) {
      findAtrribute.value = attribute.value;
      findAtrribute.name = attribute.name;
      findAtrribute.quantity = 1;
    } else {
      newOrder.orderItems[foundOrderItemIndex].attributes.push({
        name: attribute.name,
        type: attribute.type,
        value: attribute.value,
        quantity: attribute.quantity ? attribute.quantity : 1,
      });
    }
    patchOrder({
      orderItems: newOrder.orderItems,
    });
  };

  // Adds a pontic to order which creates a new pontic on ui
  const addPonticHandler = () => {
    const newOrder = _.cloneDeep(order);
    const foundOrderItemIndex = newOrder.orderItems.findIndex(item => item.id === orderItem.id);
    if (foundOrderItemIndex > -1) {
      newOrder.orderItems[foundOrderItemIndex].attributes.push({
        name: '',
        type: AttributeType.PonticDesign,
        value: '',
        quantity: 0,
        id: uuidv4(),
      });
      patchOrder({
        orderItems: newOrder.orderItems,
      });
    }
  };

  // When selecting a pontic or pontic teeth, updates order which updates ui
  const updatePonticHandler = (pontic: string, ponticId: string, ponticValue?: string) => {
    const newOrder = _.cloneDeep(order);
    const foundOrderItemIndex = newOrder.orderItems.findIndex(item => item.id === orderItem.id);
    if (foundOrderItemIndex > -1) {
      const foundPonticItem = newOrder.orderItems[foundOrderItemIndex].attributes.find(att => att.id === ponticId);

      if (foundPonticItem) {
        foundPonticItem.name = pontic;
        foundPonticItem.value = ponticValue
          ? ponticValue
              .split(',')
              .map(value => `#${value.replace('#', '')}`)
              .join(',')
          : '';
        foundPonticItem.quantity = 1;
      }

      patchOrder({
        orderItems: newOrder.orderItems,
      });
    }
  };

  // Removes pontic from order which removes from ui
  const removePonticHandler = (ponticId: string) => {
    const newOrder = _.cloneDeep(order);
    const foundOrderItemIndex = newOrder.orderItems.findIndex(item => item.id === orderItem.id);
    const foundPonticIndex = newOrder.orderItems[foundOrderItemIndex]?.attributes.findIndex(
      att => (att?.id ?? 0) === ponticId
    );
    newOrder.orderItems[foundOrderItemIndex].attributes.splice(foundPonticIndex, 1);

    patchOrder({
      orderItems: newOrder.orderItems,
    });
  };

  /**
   * Filter the attribute's value to only include values available in the tooth string.
   * For Missing Tooth, use the unfiltered tooth string list.
   * For all other attributes, use the tooth string list that has missing teeth filtered out since they are not
   * selectable.
   * @param value - the tooth string value to filter.
   * @returns returns tooth string value filtered.
   */
  const filterAttributeOnSelectedTooth = useCallback(
    (attributeValue: string, attributeName: string) => {
      let teethToUse = itemTeeth;
      if (attributeName === AttributeName.MissingTooth) {
        teethToUse = itemTeethUnfiltered;
      }
      return attributeValue
        .replace(/#/g, '')
        .split(',')
        .filter(tooth => teethToUse.some((t: string) => t === tooth))
        .map(tooth => `#${tooth}`)
        .join(',');
    },
    [itemTeeth, itemTeethUnfiltered]
  );

  /**
   * Reset the attribute to only include values from tooth string.
   * This is used when the tooth string changes.
   * @param toothSelectionAttributeNames - tooth selection attributes too look for.
   * @param orderAttribute - the attribute being changed.
   */
  const resetToothSelectionAttributeBasedOnToothString = useCallback(
    (toothSelectionAttributeNames: string[], orderAttribute: LocalOrderProductAttributeInput) => {
      if (toothSelectionAttributeNames.includes(orderAttribute.name)) {
        const newValue = filterAttributeOnSelectedTooth(orderAttribute.value, orderAttribute.name);
        return {
          ...orderAttribute,
          value: newValue,
        };
      } else {
        return orderAttribute;
      }
    },
    [filterAttributeOnSelectedTooth]
  );

  /**
   * Reset tooth selection attributes, add-ons, and services whenever tooth selection changes.
   * Filter out any implant attributes if it is restorative or abutment implant type.
   */
  useEffect(() => {
    if (prevSelectedTeeth && itemTeeth.length !== prevSelectedTeeth.length) {
      const newOrderItem = _.cloneDeep(orderItem);
      let filteredAttributes = newOrderItem.attributes;

      // We have to remove the tooth based Restorative Attribute attributes that are not selected in the tooth string.
      filteredAttributes = filteredAttributes.filter(attribute => {
        const customAttribute = customAttributes.find(customAttribute => customAttribute.type === attribute.type);
        // Check If attribute type is present in custom attributes and it's display type is ToothDown
        if (customAttribute && customAttribute.displayType === AttributeDisplayType.ToothDown) {
          return itemTeeth.includes(attribute.value.replace(/#/g, '')); // Filter out custom attributes based on tooth selection
        }

        /**
         * Check if attribute type value contains "Implant System" word. If it contains, then filter out the attribute value
         * Currently, We have two attribute types that contain "Implant System" word. one is "Inclusive Implant System" and the other is "Non-inclusive Implant System".
         * Bug ticket: https://glidewell.atlassian.net/browse/LMS1-7389
         */
        if (attribute.type.includes(AttributeType.ImplantSystem)) {
          return itemTeeth.includes(attribute.value.replace(/#/g, '')); // Filter out implant attributes based on tooth selection
        }

        return true;
      });

      // Loop through raw attribute data and get all names for tooth selection attributes.
      const toothSelectionAttributeNames = attributes
        .filter(attributeData => {
          return attributeData.attributeValueType === ProductAttributeValueType.ToothSelection;
        })
        .map(attribute => attribute.name);

      // Loop through order attributes and set value to previous value but filtered by teeth available in the tooth string.
      const newAttributes = filteredAttributes.map(orderAttribute => {
        return resetToothSelectionAttributeBasedOnToothString(toothSelectionAttributeNames, orderAttribute);
      });

      // Reset add-on/service tooth selections.
      const toothSelectionAddOnNames = addOnsAndServices
        .filter(addOn => addOn.attributeValueType === ProductAttributeValueType.ToothSelection)
        .map(addOn => addOn.name);

      // Selects all addonsAndServices in order that match names of addon with tooth selection type
      // Changes all values of found addonsAndServices to previous value but filtered by teeth available in the tooth string.
      const newAddons = newOrderItem.addOns.map(addOn => {
        return resetToothSelectionAttributeBasedOnToothString(toothSelectionAddOnNames, addOn);
      });

      updateOrderItem(newOrderItem.id, { attributes: newAttributes, addOns: newAddons });
    }
  }, [
    addOnsAndServices,
    attributes,
    itemTeeth.length,
    itemTeeth,
    orderItem,
    prevSelectedTeeth,
    requiredImplantItems,
    customAttributes,
    resetToothSelectionAttributeBasedOnToothString,
    setRequiredImplantsItems,
    updateOrderItem,
  ]);

  const canAddNewPontic = () => {
    return ponticAttributes?.length !== ponticsInOrder?.length;
  };
  const isProductVisible = orderItem.id === selectedProductId;

  const renderRMAType = useCallback(() => {
    if (isRmaLocalOrder(order) && isProductVisible) {
      // Show RMA selection for new RMA orders, for order items without a return type (i.e. user removed the previous selection and wants to select a new one),
      // or for order items with a return type of Adjust or Remake, as per business requirements laid out in LMS1-4592.
      if (
        !isOrderUpdate ||
        !orderItem.returnType ||
        orderItem.returnType === ReturnType.Adjust ||
        orderItem.returnType === ReturnType.Remake
      ) {
        return (
          <RMAType
            orderItem={orderItem}
            isReturnReasonsLoading={isReturnReasonsLoading}
            returnReasons={returnReasons}
            returnReasonsError={returnReasonsError}
            isOrderUpdate={!!isOrderUpdate}
            productState={productState}
            isLegacyOrder={isLegacyCase}
          />
        );
      }
    } else {
      return null;
    }
  }, [
    isLegacyCase,
    isOrderUpdate,
    isProductVisible,
    isReturnReasonsLoading,
    order,
    orderItem,
    productState,
    returnReasons,
    returnReasonsError,
  ]);

  const productName = useMemo(
    () => getProductName(orderItem.material, orderItem.restorationType),
    [orderItem.material, orderItem.restorationType]
  );

  const isExchange = useMemo(
    () => isOrderItemExchangeReturnType(orderItem.id),
    [orderItem.id, isOrderItemExchangeReturnType]
  );

  return isLoadingProductInformation ? (
    <div className="flex align-middle justify-center">
      <Loader show={true} className="text-white h-10 w-10 mt-20" spin />
    </div>
  ) : (
    <>
      {renderRMAType()}
      <div className={classNames('flex flex-col gap-4 px-6 py-4 relative', isProductVisible ? '' : 'hidden')}>
        {productState !== ProductState.AttributesRendered && (
          <>
            <ProductSearch
              onMaterialAndRestorationSelect={materialAndRestorationSelectHandler}
              disabled={productState === ProductState.AttributesLoading}
              billingAccountId={billingAccountId}
              productState={productState}
            />
          </>
        )}
        {productState === ProductState.AttributesRendered && (
          <>
            {isExchange && !isLegacyCase && (
              <div className={classNames('absolute left-0 top-0 bottom-0 right-0', styles['disable-product'])}>
                <div
                  className={classNames(
                    'absolute top-2/4 left-2/4 transform -translate-x-1/2 -translate-y-1/2',
                    styles['disable-product-message']
                  )}
                >
                  Editing disabled for exchange
                </div>
              </div>
            )}
            <div data-testid="attributesRendered" className={classNames('flex flex-col gap-4 relative')}>
              {/* Product title and remove icon */}
              <div className="flex justify-between">
                <div className="flex">
                  <label data-testid="productHeader" className="text-base font-medium text-gray-900 mb-2 mr-4">
                    {productName}&nbsp;
                    {isNewOrder && !orderItem.returnType && <Badge text="New" />}
                  </label>
                  {orderItem.returnType && (
                    <div className="mt-neg-2">
                      <ReturnTypeBadge type={orderItem.returnType} />
                    </div>
                  )}
                  {orderItem.isProcessSwap && (
                    <div className="mt-neg-2">
                      <ProcessSwapBadge />
                    </div>
                  )}
                </div>
                <DropdownMenuButton
                  menuItems={[{ id: 'delete', name: 'Delete' }]}
                  onMenuClick={() => {
                    setProductState(ProductState.ProductSelection);
                    onProductRemove();
                  }}
                  rightIcon={<EllipsisVerticalIcon className="h-5 w-5" aria-label="chevron-down" />}
                />
              </div>
              {/* Render Single tooth or tooth range selection for Restoration type Single or Bridge */}
              {diagramAttribute && (
                <section>
                  {isRestorationTypeBridge(orderItem.restorationType) ? (
                    <ToothRange
                      attribute={diagramAttribute}
                      itemId={orderItem.id}
                      onAttributeChange={attributeChangeHandler}
                    />
                  ) : (
                    <ToothSingle
                      itemId={orderItem.id}
                      attribute={diagramAttribute}
                      onAttributeChange={attributeChangeHandler}
                      disabled={disableToothInput}
                    />
                  )}
                </section>
              )}

              {/* Display Pontic Teeth if they exist. */}
              {ponticAttributes && !!ponticAttributes.length && (
                <section>
                  <div className="grid grid-cols-4 gap-x-6 gap-y-4">
                    <Label data-testid="pontic-tooth-label">Pontic Tooth #</Label>
                    {ponticsInOrder.length > 0 && <Label data-testid="pontic-design-label">Pontic Design</Label>}
                  </div>

                  {ponticsInOrder.map((pontic, i) => (
                    <div key={i} className={i < ponticsInOrder.length - 1 ? 'mb-3' : ''}>
                      <Pontic
                        attributes={ponticAttributes}
                        key={i}
                        pontic={pontic}
                        itemId={orderItem.id}
                        removePontic={removePonticHandler}
                        updateSelectedPontic={updatePonticHandler}
                        ponticsInOrder={ponticsInOrder}
                      />
                    </div>
                  ))}
                  <AddButton
                    text={`Add ${ponticsInOrder.length > 0 ? 'Another' : 'a'} Pontic Design`}
                    onClick={addPonticHandler}
                    id={'addPonticButton'}
                    disabled={!canAddNewPontic()}
                  />
                </section>
              )}

              {/* Display Layered Attribute */}
              {showLayered && <Layered itemId={orderItem.id} />}
              {/* Render common attributes */}
              {attributes && !!attributes.length && (
                <section>
                  <div className="grid grid-cols-4 gap-x-6 gap-y-4">
                    {quantityAttribute && (
                      <Quantity
                        attribute={quantityAttribute}
                        orderItem={orderItem}
                        onAttributeChange={attributeChangeHandler}
                      />
                    )}
                    {attributesWithoutQuantity.map((attribute, index) => {
                      const technicalPreference = productTechnicalPreferences?.find(
                        preference => preference.attributeName === attribute.name
                      );
                      return (
                        <Fragment key={`${attribute.name}-${index}`}>
                          <Attribute
                            attribute={attribute}
                            itemId={orderItem.id}
                            onAttributeChange={attributeChangeHandler}
                            technicalPreference={technicalPreference}
                            label={attribute.name}
                          />
                        </Fragment>
                      );
                    })}
                  </div>
                </section>
              )}

              {/* Display Alloy */}
              {alloys.length > 0 && (
                <Alloy
                  attributes={alloys}
                  isRequired={false}
                  onAttributeChange={alloyChangeHandler}
                  productId={parseInt(orderItem.id)}
                  disabled={!editAlloyPermission.allowUpdate}
                  alloyInOrder={alloyInOrder[0]}
                />
              )}
              {/* Custom attributes */}
              {orderItem.isLoaded && (
                <AttributeCustomized
                  attributes={customAttributes}
                  selectedTeeth={itemTeeth}
                  orderItemId={orderItem.id}
                />
              )}
              {/* Add-On and Services section */}
              <section className="mb-3">
                <AddOn
                  orderItemId={orderItem.id}
                  addonsAndServices={addOnsAndServices}
                  technicalPreferences={productTechnicalPreferences}
                />
              </section>
              <section>
                <ProductionFacility
                  productBrands={productBrands}
                  orderItem={orderItem}
                  productDepartmentAttribute={departmentAttribute}
                  isOrderUpdate={isOrderUpdate}
                  salesCategoryAndBackLogGroupAttributeRecord={salesCategoryAndBackLogGroupAttributeRecord}
                />
              </section>
              <div ref={footerElementRef}>
                <FooterLegend />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Product;
