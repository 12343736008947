import {
  AccountClosedReason,
  AccountStanding,
  AccountStatus,
  AddressInput,
  AddressType,
  Attribute,
  AttributeDisplayType,
  Classifications,
  CreateDeductionInput,
  CreateOrderInput,
  CreateRmaOrderInput,
  Deduction,
  Discount,
  Order,
  OrderAttachmentInput,
  OrderItem,
  OrderItemInput,
  OrderLinkType,
  OrderProductAttributeInput,
  OrderStatus,
  OrderType,
  ProductAttributeValueType,
  ProductFullAttribute,
  ReturnType as ReturnTypeEnum,
  RmaOrder,
  RmaOrderItem,
  RmaOrderItemInput,
  TrackingNumberItemInput,
  UpdateOrderInput,
} from 'API';
import { CarrierExceptions, CarrierRegexListConfiguration, Carriers } from 'configurations/OrderEntryConfigurations';
import _, { omit } from 'lodash';
import moment from 'moment-timezone';
import { OverlayLoaderState } from 'providers/OverlayLoaderProvider';
import { ToastState } from 'providers/ToastProvider';
import { createOrder, updateOrder } from 'shared/api/order.api';
import { createRmaOrder, nextRmaOrder } from 'shared/api/rma-order.api';
import { NEW_CASE } from 'shared/constants/constants';
import { DISCOUNT_TYPE_LABELS } from 'shared/constants/invoice-data.constants';
import { RestorationType } from 'shared/constants/restoration-type.constant';
import {
  AttributeType,
  CommonAlerts,
  ErrorMessage,
  FileUploadState,
  OrderTypeName,
  ToastNotificationType,
} from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { Material } from 'shared/enums/product';
import {
  CreatedOrder,
  LocalOrderEnclosedItem,
  LocalOrderFileAttachments,
  LocalOrderInput,
  LocalOrderItemInput,
  LocalOrderProductAttributeInput,
  LocalOrderType,
} from 'shared/models';
import { ClassificationProduct } from 'shared/models/classification-product';
import { AnalyticsService } from 'shared/services/analytics.service';
import {
  fetchOrderItemWithReturnRequirement,
  getSpiltBundleCaseInfo,
  isNumeric,
  isRmaOrderTypename,
} from 'shared/utils';
import { BundledSplitCase } from 'stores/useBundleSplitCaseStore';
import { OrderItemDropdownModel } from 'types/dropdown-menu';
import { JsonType } from 'types/json-type';
import { HandleSubmitOrderAlertData } from 'types/order-entry';
import { v4 as uuidv4 } from 'uuid';
import { getCaseAndReturnType } from '../analytics.helper';
import { getOriginFacility, isRmaLocalOrder } from '../util.helper';

/**
 * Return default order.
 * @returns CreateOrderInput
 */
export const getDefaultOrderInput = (): LocalOrderInput => {
  return {
    orderNumber: '',
    originalOrderNumber: null,
    billingAccountId: '',
    providerId: '',
    providerName: '',
    patientFirstName: '',
    patientLastName: '',
    patientId: '',
    enclosedItems: [],
    fileAttachments: [],
    orderItems: [],
    inboundTrackingNumbers: [],
    shippingAddress: getDefaultAddress(),
    coupons: [],
    notes: '',
    utcConversionTimeZone: moment.tz.guess(),
    createdBy: '',
    originFacility: '',
    originFacilityId: -1,
    updatedBy: '',
    localMetadata: {
      localOrderType: LocalOrderType.Standard,
    },
    estimatedShipDate: new Date().toISOString(),
  };
};

/**
 * Return default product attribute input
 * @returns OrderProductAttributeInput
 */
export const getDefaultOrderProductAttributeInput = (): OrderProductAttributeInput => {
  return {
    name: '',
    type: '',
    value: '',
    quantity: 0,
  };
};

/**
 * Return default classification for product
 * @returns ClassificationProduct
 */
export const getDefaultClassifications = (): ClassificationProduct[] => {
  return [
    {
      productCode: '',
      materialName: '',
      restorationName: '',
    },
  ];
};

/**
 * Return default address.
 * @returns AddressInput
 */
export const getDefaultAddress = (): AddressInput => {
  return {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    type: AddressType.Shipping,
  };
};

/**
 * Return a tracking number item.
 * @param number - (optional) the tracking number
 * @returns TrackingNumberItemInput
 */
export const getDefaultTrackingNumberItem = (number?: string): TrackingNumberItemInput => {
  return {
    trackingNumber: number || '',
    insertionDate: new Date().toISOString(),
    insertedBy: '',
  };
};

/**
 * Return account standing.
 * @param standing - The standing of the account
 * @returns Text friendly version of AccountStanding enum
 */
export const getAccountStandingText = (standing: AccountStanding): string => {
  switch (standing) {
    case AccountStanding.OnCod:
      return 'COD';
    case AccountStanding.AlwaysOnCod:
      return 'ALWAYS ON COD';
    case AccountStanding.AlwaysOnCreditHold:
    case AccountStanding.OnCreditHold:
      return 'Credit Hold';
    case AccountStanding.Good:
      return 'Good Standing';
    default:
      return standing;
  }
};

/**
 * Returns a user-friendly version of AccountClosedReason enum
 * @param reason - the enum representing account closing reason
 * @returns user-friendly message for closing account
 */
export const getAccountClosedReasonText = (reason: AccountClosedReason | null | undefined): string => {
  switch (reason) {
    case AccountClosedReason.Bankruptcy:
      return 'Bankruptcy';
    case AccountClosedReason.Collection:
      return 'Collection';
    case AccountClosedReason.CustomerRequest:
      return 'Customer Request';
    case AccountClosedReason.DuplicateAccount:
      return 'Duplicate Account';
    case AccountClosedReason.Inactive:
      return 'Inactive';
    case AccountClosedReason.OtherAccount:
      return 'Other Account';
    case AccountClosedReason.ReferToManagement:
      return 'Refer to Management';
    case AccountClosedReason.Sold:
      return 'Sold';
    default:
      return reason ?? 'None provided';
  }
};

export const accountStatusClosed = (status: AccountStatus) => {
  return status === AccountStatus.Closed || status === AccountStatus.Deleted;
};

/**
 * Determines order type. Only digital if externalOrderId and sourceSystem are truthy.
 * @param externalOrderId - the source system order id
 * @param sourceSystem - the source system
 * @returns order type.
 */
export const getOrderType = (externalOrderNumber?: string | null, sourceSystem?: string | null): OrderType => {
  if (!!externalOrderNumber && !!sourceSystem) {
    return OrderType.Digital;
  }

  return OrderType.Physical;
};

/**
 * Determine whether an order is a digital order based on the order type.
 * @param orderType - the order type to check.
 * @returns whether order is digital.
 */
export const isDigitalOrder = (orderType: OrderType | undefined) => {
  return orderType === OrderType.Digital;
};

/**
 * Returns the default order item input.
 * If `itemId` is provided, it will be used as the ID of the order item.
 * Otherwise, a new UUID will be generated as the ID.
 * @param itemId - The ID of the order item (optional). itemId is always available in case edit mode.
 * @returns The default order item input.
 */
export const getDefaultOrderItemInput = (itemId?: string): LocalOrderItemInput => {
  return {
    id: itemId || uuidv4(),
    quantity: 1,
    productCode: '',
    productName: '',
    coupons: [],
    services: [],
    addOns: [],
    attributes: [],
    daysInLab: 0,
    manufacturingLocation: '',
    manufacturingLocationId: 0,
    material: '',
    restorationType: '',
    department: '',
  };
};

export const getDefaultOrder = (): Order => {
  return {
    __typename: 'Order',
    addresses: [],
    coupons: [],
    createdDate: '',
    enclosedItems: [],
    fileAttachments: [],
    orderItems: [],
    orderNumber: '',
    originFacility: '',
    originFacilityId: 0,
    providerId: '',
    providerName: '',
    status: OrderStatus.InLab,
    utcConversionTimeZoneCode: moment.tz.guess(),
  };
};

export const getDefaultRmaOrder = (): RmaOrder => {
  return {
    __typename: 'RmaOrder',
    addresses: [],
    coupons: [],
    createdDate: '',
    enclosedItems: [],
    fileAttachments: [],
    orderItems: [],
    orderNumber: '',
    originFacility: '',
    originFacilityId: 0,
    providerId: '',
    providerName: '',
    originalOrderNumber: '',
    status: OrderStatus.InLab,
    utcConversionTimeZoneCode: moment.tz.guess(),
  };
};

export const getDefaultProductClassifications = (): Classifications[] => [
  {
    __typename: 'Classifications',
    materialName: '',
    restorations: [
      {
        __typename: 'ClassificationRestoration',
        restorationName: '',
        productCode: '',
      },
    ],
  },
];

export const getProductAttribute = (): ProductFullAttribute => {
  return {
    __typename: 'ProductFullAttribute',
    attributeOptions: [],
    attributeValueType: ProductAttributeValueType.Number,
    name: '',
    type: '',
    displayName: '',
    isCustomAttribute: false,
    isPricing: false,
    isRequired: false,
    isVisible: false,
  };
};

export const getAlloyProductAttribute: ProductFullAttribute[] = [
  {
    ...getProductAttribute(),
    attributeValueType: ProductAttributeValueType.String,
  },
];

export const getAttributeDetail: Attribute[] = [
  {
    __typename: 'Attribute',
    attributeOptions: [],
    attributeValueType: ProductAttributeValueType.String,
    name: '',
    type: '',
  },
];

/**
 * Returns the target order source, if there is a regex match for one.
 * Please note: Shape order numbers are variable and therefore cannot be set based on the source system. By default, the source system value is undefined.
 * @param currentSearchValue - The current search value in the external reference ID field.
 * @param sourceSystemRegex -
 * @returns the target order source.
 */
export const determineSourceSystemByExternalReferenceID = (
  currentSearchValue: string,
  sourceSystemRegex: JsonType
): string => {
  return (
    Object.keys(sourceSystemRegex).find((regexKey: string) => {
      const regexValue = sourceSystemRegex[regexKey];
      const sourceSystemRegExp = new RegExp('^' + regexValue + '$');
      return sourceSystemRegExp.test(currentSearchValue);
    }) || ''
  );
};

export const getToothNumbers = (toothRange?: string, missingTooth?: string, restorationType?: string): number[] => {
  if (restorationType === RestorationType.FullUpperArch) {
    return [...Array.from({ length: 16 }, (_, i) => i + 1)];
  } else if (restorationType === RestorationType.FullLowerArch) {
    return [...Array.from({ length: 16 }, (_, i) => 17 + i)];
  } else if (restorationType === RestorationType.Single) {
    return toothRange?.includes(',') ? toothRange.split(',').map(t => +t) : [+(toothRange ?? '')];
  }

  if (!toothRange) {
    return [];
  }

  if (isNumeric(toothRange)) {
    return [+toothRange];
  }

  const splits = toothRange.split('-');
  if (splits.length < 2 || !isNumeric(splits[0]) || !isNumeric(splits[1])) {
    return [];
  }

  const first = +splits[0] > +splits[1] ? +splits[1] : +splits[0];
  const last = +splits[0] > +splits[1] ? +splits[0] : +splits[1];

  let missing: number[] = [];
  if (missingTooth?.trim().length) {
    missing = missingTooth.split(',').map(m => +m);
  }

  const numbers = [];
  for (let i: number = first; i <= last; i++) {
    if (!missing.includes(i)) {
      numbers.push(i);
    }
  }
  return numbers;
};

export const getToothQuantity = (toothRange: string, missingTooth: string): number => {
  if (!toothRange) {
    return 0;
  }

  if (isNumeric(toothRange)) {
    return 1;
  }

  const splits = toothRange.split('-');
  if (splits.length < 2 || !isNumeric(splits[0]) || !isNumeric(splits[1])) {
    return 0;
  }

  const first = +splits[0] > +splits[1] ? +splits[1] : +splits[0];
  const last = +splits[0] > +splits[1] ? +splits[0] : +splits[1];

  let missing: number[] = [];
  if (missingTooth?.trim().length) {
    missing = missingTooth.split(',').map(m => +m);
  }

  let quantity = 0;
  for (let i: number = first; i <= last; i++) {
    if (!missing.includes(i)) {
      quantity++;
    }
  }

  return quantity;
};

/**
 * given track number is matched with available regex patterns of various supported
 * carriers, and matched carrier name is returned
 *
 * @param trackingNumber - number issued/assigned by Carrier to identify the tracking details of a product
 *
 * @returns the matched carrier name
 */
export const getCarrier = (trackingNumber: string) => {
  for (const regexItem of CarrierRegexListConfiguration) {
    const regex = new RegExp(regexItem.regex, 'i');
    if (regex.test(trackingNumber)) {
      return regexItem.carrier;
    }
  }
  return '';
};

/**
 * Gets the carrier name based on tracking number if the carrier is not passed as a param.
 * There are a few exceptions for names so we check that before returning the name.
 *
 * @param trackingNumber - number issued/assigned by Carrier to identify the tracking details of a product
 * @param carrier - carrier name
 *
 * @returns carrier's name associated with tracking number
 */
export const getCarrierName = (trackingNumber: string, carrier?: string) => {
  let carrierName = carrier || getCarrier(trackingNumber);

  if (!carrierName) {
    const map = CarrierExceptions.filter(c => c.trackingNumber.toLowerCase() === trackingNumber.toLowerCase())[0];
    if (map) {
      carrierName = map.carrier;
    }
  }

  return carrierName;
};

export const getTrackingUrl = (trackingNumber: string) => {
  let carrierName = getCarrier(trackingNumber);

  if (!carrierName) {
    const found = CarrierExceptions.find(item => item.trackingNumber.toLowerCase() === trackingNumber.toLowerCase());
    if (found) {
      carrierName = found.carrier;
    }
  }

  const carrier = Carriers.find(carrier => carrier.name === carrierName);

  const result = {
    url: '',
    search: '',
  };

  if (carrier?.url) {
    const parsedUrl = new URL(carrier.url + trackingNumber, window.location.origin);
    result.url = parsedUrl.origin + parsedUrl.pathname;
    result.search = parsedUrl.search;
  }
  return result;
};

export const patchKeysList = (patchKeysList: string[], key: string) => {
  if (key === 'noPatientInfo') {
    return patchKeysList.includes('noPatientInfo')
      ? [...patchKeysList]
      : [...patchKeysList, 'patientFirstName', 'patientLastName', 'patientId'];
  } else if (key === 'externalOrderNumber') {
    return patchKeysList.includes('externalOrderNumber')
      ? [...patchKeysList]
      : [...patchKeysList, 'externalOrderNumber', 'orderSource', 'type'];
  } else if (key === 'shippingAddressStreet') {
    return patchKeysList.includes('shippingAddressStreet')
      ? [...patchKeysList]
      : [
          ...patchKeysList,
          'shippingAddressStreet',
          'shippingAddressStreet2',
          'shippingAddressCity',
          'shippingAddressState',
          'shippingAddressZip',
          'shippingAddressCountry',
        ];
  } else if (key === 'billingAccountNumber') {
    return patchKeysList.includes('billingAccountNumber')
      ? [...patchKeysList]
      : patchKeysList.includes('shippingAddressStreet')
      ? [...patchKeysList, 'billingAccountNumber', 'originFacilityId', 'providerId']
      : [
          ...patchKeysList,
          'billingAccountNumber',
          'originFacilityId',
          'providerId',
          'shippingAddressStreet',
          'shippingAddressStreet2',
          'shippingAddressCity',
          'shippingAddressState',
          'shippingAddressZip',
          'shippingAddressCountry',
        ];
  } else {
    return patchKeysList?.includes(key) ? [...patchKeysList] : [...patchKeysList, key];
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onOrderUpdate = (order: any) => {
  const orderUpdate = order.patchKeysList.map((path: string) => ({
    op: 'replace',
    path: path,
    value: order[path],
  }));

  orderUpdate.push({
    op: 'replace',
    path: '/updatedDate',
    value: new Date(),
  });

  return orderUpdate;
};

export const isValidToothRange = (value: boolean) => {
  console.log('Need to implement isValidToothRange', value);
  // TODO: Implement again.
  // isValidToothRangeForOrder = value;
};

export const sortToothValue = (data: string[]): string[] => {
  return data.filter(Boolean).sort((a, b) => +a - +b);
};

export const getAttributeToothValue = (data: string[]): string => {
  return sortToothValue(data)
    .map(value => `#${value}`)
    .join(',');
};

const localOrderItemToOrderItemInput = (
  item: LocalOrderItemInput,
  isRmaOrder: boolean
): OrderItemInput | RmaOrderItemInput => {
  const newItem: OrderItemInput = {
    addOns: item.addOns,
    attributes: item.attributes,
    coupons: item.coupons,
    daysInLab: item.daysInLab,
    department: item.department,
    manufacturingLocation: item.manufacturingLocation,
    manufacturingLocationId: item.manufacturingLocationId,
    productCode: !isRmaOrder && item.newProduct?.productCode ? item.newProduct?.productCode : item.productCode,
    productName: !isRmaOrder && item.newProduct?.productName ? item.newProduct?.productName : item.productName,
    material: item.material,
    restorationType: item.restorationType,
    quantity: item.quantity,
    toothUnits: item.toothUnits || 0,
    services: item.services,
    overrides: item.overrides,
    // Use the existing itemId if available; otherwise, generate a new unique ID
    itemId: item.itemId || uuidv4(),
  };

  if (isRmaOrder) {
    const { newProduct, returnReasons = [], restorationType, isOldProductReturned = false, returnType } = item;
    return {
      ...newItem,
      newProduct,
      returnReasons,
      restorationType,
      isOldProductReturned,
      returnType,
    };
  } else {
    return newItem;
  }
};

const localOrderAttachmentToOrderAttachmentInput = (item: LocalOrderFileAttachments): OrderAttachmentInput => {
  return {
    createdBy: item.createdBy,
    createdDate: item.createdDate,
    extension: item.extension,
    fileUrl: item.fileUrl,
    name: item.name,
    size: item.size,
    uploadProperties: item.uploadProperties,
  };
};

/**
 * Filters and maps attributes.
 * @param attributes - The local order product attribute inputs to filter and map.
 * @param typeToMatch - The attribute type to match, if any.
 * @returns filtered and mapped attributes
 */
export const filterAndMapAttributes = (
  attributes: LocalOrderProductAttributeInput[],
  typeToMatch?: AttributeType
): LocalOrderProductAttributeInput[] =>
  attributes
    .filter(
      ({ name, type: attributeType, value }) =>
        !!name && !!attributeType && !!value && (!typeToMatch || attributeType === typeToMatch)
    )
    .map(({ name, type: attributeType, value, quantity, id }) => ({
      name,
      type: attributeType,
      value,
      quantity,
      // Use existing id if available; otherwise, generate a new one for addOns or Services.
      ...(typeToMatch === AttributeType.AddOn || typeToMatch === AttributeType.Service ? { id: id || uuidv4() } : {}),
    }));

export const ALLOY_NO_VALUE = 'No Value';

/**
 * Map the local order input we use in the UI to the order input that the API is expecting.
 * @param orderInput - the input we are going to map.
 * @returns CreateOrderInput
 */
export const localOrderInputToOrderInput = (
  orderInput: LocalOrderInput,
  hasAppliedAlerts: boolean
): CreateOrderInput | CreateRmaOrderInput => {
  const clonedOrder = _.cloneDeep(orderInput);
  const { localMetadata, ...newOrderInput } = clonedOrder;
  const isRmaOrder = isRmaLocalOrder(clonedOrder);
  // Removes product preferences (attached to order for ease of use) from the data being sent to AppSync.
  const orderItems: OrderItemInput[] = newOrderInput.orderItems.map(item => {
    const addOns = _.cloneDeep(item.addOns);
    const isRmaAdjustOrder = item.returnType === ReturnTypeEnum.Adjust;
    /**
     * Remove attributes that don't have type and value set. We maintain all until we submit but API will throw error.
     * Alloys are an exception because we need the attributes at time of invoicing.
     */
    item.attributes = item.attributes
      .filter(({ type: attributeType, value: attributeValue }) => {
        const isAttributeAlloy = attributeType === AttributeType.Alloy;
        const isRmaAdjustAlloy = isAttributeAlloy && isRmaAdjustOrder;
        /**
         * Per request from LMS1-7855, we will remove Alloy attribute with Adjust returnType for
         * RMA order so it will no longer display "No Value" and "Missing alloy type and/or weight"
         * https://glidewell.atlassian.net/browse/LMS1-7855
         */
        if (isRmaAdjustAlloy && (!attributeValue || attributeValue === '0')) return false;
        const hasValidAttributeTypeAndValue = !!attributeType && !!attributeValue;
        return hasValidAttributeTypeAndValue || isAttributeAlloy;
      })
      .map(att => {
        return {
          name: att.type === AttributeType.Alloy && !att.name ? ALLOY_NO_VALUE : att.name,
          type: att.type,
          value: att.type === AttributeType.Alloy && !att.value ? '0' : att.value,
          quantity: att.quantity,
        };
      });

    // Remove attributes that don't have type and value set. We maintain all until we submit but API will throw error.
    item.attributes = filterAndMapAttributes(item.attributes);
    item.addOns = filterAndMapAttributes(addOns, AttributeType.AddOn);
    item.services = filterAndMapAttributes(addOns, AttributeType.Service);
    return localOrderItemToOrderItemInput(item, isRmaOrder);
  });

  const fileAttachments: OrderAttachmentInput[] = newOrderInput.fileAttachments.map(
    localOrderAttachmentToOrderAttachmentInput
  );

  let customerDueDate: string | undefined = undefined;

  if (newOrderInput.customerDueDate) {
    customerDueDate = moment(newOrderInput.customerDueDate).toISOString();
  }

  const enclosedItems = newOrderInput.enclosedItems.map((item: LocalOrderEnclosedItem) => {
    delete item.errors;
    if ('id' in item) {
      delete item.id;
    }
    if ('__typename' in item) {
      delete item.__typename;
    }
    return item;
  });

  const newCoupons = newOrderInput.coupons.map(coupon => coupon.code);
  if (!newOrderInput.orderSource?.length) {
    delete newOrderInput.orderSource;
  }

  if ('__typename' in newOrderInput.shippingAddress) {
    delete newOrderInput.shippingAddress.__typename;
  }

  /**
   * Apply the correct originFacility to bundle split case scenario
   * Origin facility mapping (please see LMS1-7520, LMS1-7679).
   * If the origin facility matches a mapped value in our configuration, we will use the mapped value.
   * For example, "30-RDC" will be mapped to "RIVERSIDE DENTAL CERAMICS".
   * */
  newOrderInput.originFacility = getOriginFacility(newOrderInput.originFacility);

  return {
    ...newOrderInput,
    orderNumber:
      newOrderInput.orderNumber && newOrderInput.orderNumber !== NEW_CASE ? newOrderInput.orderNumber : undefined,
    orderItems,
    fileAttachments,
    customerDueDate,
    enclosedItems,
    coupons: newCoupons,
    // We need to send updatedBy only for Rma Orders, for new orders we don't need to send it. as per the order input api schema.
    updatedBy: isRmaOrder ? newOrderInput.updatedBy : undefined,
    hasAppliedAlerts,
  };
};

export const createOrderInputToUpdateOrderInput = (orderInput: CreateOrderInput): UpdateOrderInput => {
  const { createdBy, linkedOrder, originalOrderNumber, ...modifiedInput } = orderInput; // must remove some things the update doesn't want
  return {
    ...modifiedInput,
    orderNumber: modifiedInput.orderNumber || '',
    updatedBy: '',
    updatedDate: '',
  };
};

export const getProductName = (material: string, restoration: string): string => {
  return `${material} ${restoration}`;
};

export const sortProductMaterialNames = (materialNames: string[]) => {
  return materialNames.sort(function (current, next) {
    if (current === next) return 0;
    if (current === Material.BruxZirSolidZirconia) return -1;
    if (next === Material.BruxZirSolidZirconia) return 1;
    if (current === Material.BruxZirEsthetic) return -2;
    if (next === Material.BruxZirEsthetic) return 2;

    if (current < next) return -1;
    if (current > next) return 1;
    return 0;
  });
};

/**
 * Converts a LocalOrderInput object into CreateOrderInput or CreateRmaOrderInput based on the order type.
 * @param order - The LocalOrderInput object to be converted.
 * @returns An object containing the converted order and rmaOrder inputs.
 */
export const getCreateOrderAndRmaOrderInput = (order: LocalOrderInput) => {
  const isRmaOrder = isRmaLocalOrder(order);
  // TODO: If needed, add hasAppliedAlerts to bundle endpoint.
  const orderInputData = localOrderInputToOrderInput(order, false);

  const result: { order: CreateOrderInput | undefined; rmaOrder: CreateRmaOrderInput | undefined } = {
    order: undefined,
    rmaOrder: undefined,
  };

  // If isRmaOrder is true, then we should set the rmaOrder property, otherwise we should set the order property.
  if (isRmaOrder) {
    result.rmaOrder = orderInputData as CreateRmaOrderInput;
  } else {
    result.order = orderInputData;
  }

  return result;
};

/**
 * Checks if any order item in the given array matches the specified return type and does not have an old product returned.
 *
 * @param orderItems - The array of order items to check.
 * @param returnType - The return type to match against.
 * @param isEvery - If true, checks if every order item matches the return type and does not have an old product returned. If false, checks if at least one order item matches the return type and does not have an old product returned.
 * @returns True if there is at least one order item that matches the return type and does not have an old product returned, false otherwise.
 */
const checkReturnTypeOrderItemWithNotProductReturned = (
  orderItems: Array<RmaOrderItemInput>,
  returnType: ReturnTypeEnum,
  isEvery = false
) => {
  const condition = isEvery ? 'every' : 'some';
  return orderItems[condition](item => item.returnType === returnType && !item.isOldProductReturned);
};

/**
 * Filters the order items based on the given return type and whether the old product is returned.
 *
 * @param orderItems - The array of order items to filter.
 * @param returnType - The return type to filter by.
 * @param isOldProductReturned - Whether the old product is returned.
 * @returns The filtered array of order items.
 */
export const filterRmaOrderItemsByReturnType = <TReturnType extends ReturnTypeEnum>(
  orderItems: Array<RmaOrderItemInput | LocalOrderItemInput>,
  returnType: TReturnType,
  isOldProductReturned?: boolean
) => {
  return orderItems.filter((item): item is RmaOrderItemInput & { returnType: TReturnType } => {
    if (isOldProductReturned === undefined) {
      return item.returnType === returnType;
    }
    return item.returnType === returnType && item.isOldProductReturned === isOldProductReturned;
  });
};

const handleSubmitOrderAlertModal = (
  toast: ToastState,
  setCreateRmaErrorModalData?: (data: HandleSubmitOrderAlertData) => void
) => {
  return (data: HandleSubmitOrderAlertData) => {
    const { title, description, isExchange = false } = data;
    if (setCreateRmaErrorModalData) {
      setCreateRmaErrorModalData({
        title: title,
        description: description,
        isExchange: isExchange,
      });
    } else {
      toast.notify(title, ToastNotificationType.Error);
      toast.notify(description, ToastNotificationType.Error);
    }
  };
};

/**
 * Opens an alert modal with a specific message if an alloy is in the order and the return reason is credit.
 * Otherwise, does not open the modal.
 * @param creditRmaOrderItems - The local credit rma order items input.
 * @param openAlertModal - The function to open the alert modal.
 * @returns A boolean value indicating whether the modal is opened or not.
 */
export const creditRmaOrderItemsValidation = (
  creditRmaOrderItems: Array<RmaOrderItemInput & { returnType: ReturnTypeEnum.ReturnForCredit }>,
  openAlertModal: (data: HandleSubmitOrderAlertData) => void
) => {
  /**
   * Business Logic:
   * If an alloy is in the order and the return reason is credit, always displays the "Cannot process the RMA" error message,
   * regardless of whether the old product was returned.
   */
  const isAlloyInOrderWithReturnForCredit = creditRmaOrderItems.some(item => {
    const existAlloyAttribute = item.attributes.find(att => att.type === AttributeType.Alloy);
    return existAlloyAttribute;
  });

  // Shows an error message for any order involving an alloy
  if (isAlloyInOrderWithReturnForCredit) {
    openAlertModal({
      title: CommonAlerts.CANNOT_PROCESS_RMA,
      description: CommonAlerts.SEND_BACK_TO_SHIPPING_SPECIALIST,
    });
    return true; // It represents that the alert modal is opened.
  }
  return false; // It represents that the alert modal is not opened.
};

/**
 * Opens an alert modal if there is at least one non-returned product for which an exchange request is being made.
 *
 * @param exchangeRmaOrderItems - The local exchange rma order items input.
 * @param openAlertModal - The function to open the alert modal.
 * @returns A boolean value indicating whether the modal is opened or not.
 */
export const exchangeRmaOrderItemsValidation = (
  exchangeRmaOrderItems: Array<RmaOrderItemInput & { returnType: ReturnTypeEnum.Exchange }>,
  openAlertModal: (data: HandleSubmitOrderAlertData) => void
) => {
  // If all of the products has the exchange return type and the old product was not returned, then we should not proceed to create the RMA order. Instead, we should open an alert modal to create New Case.
  const isExchangeOrderWithNonReturnedProduct = checkReturnTypeOrderItemWithNotProductReturned(
    exchangeRmaOrderItems,
    ReturnTypeEnum.Exchange,
    true
  );

  // Only alert modal should be opened if all the exchange products are not returned.
  if (isExchangeOrderWithNonReturnedProduct) {
    openAlertModal({
      title: CommonAlerts.CANNOT_PROCESS_RMA_EXCHANGE_TITLE,
      description: CommonAlerts.CANNOT_PROCESS_RMA_EXCHANGE_DESCRIPTION,
      isExchange: true,
    });
    return true; // It represents that the alert modal is opened.
  }

  return false; // It represents that the alert modal is not opened.
};

const createRmaOrderAfterValidation = async (params: {
  parsedOrder: CreateRmaOrderInput;
  toast: ToastState;
  openSubmitAlertModal: (data: HandleSubmitOrderAlertData) => void;
}): Promise<RmaOrder | undefined> => {
  const { parsedOrder, toast, openSubmitAlertModal } = params;

  /** Adjust Items validation start here  */
  const isAdjustItemWithOldProductNotReturned = checkReturnTypeOrderItemWithNotProductReturned(
    parsedOrder.orderItems,
    ReturnTypeEnum.Adjust
  );

  // We should always deny the entire RMA order if there is an Adjust item with the old product not returned.
  if (isAdjustItemWithOldProductNotReturned) {
    toast.notify(ErrorMessage.CANNOT_PROCESS_ADJUST, ToastNotificationType.Error);
    return;
  }
  /** Adjust Items validation end here  */

  /** Credit Items validation start here  */
  const creditOrderItems = filterRmaOrderItemsByReturnType(parsedOrder.orderItems, ReturnTypeEnum.ReturnForCredit);
  const creditReturnRequirement = await fetchOrderItemWithReturnRequirement(creditOrderItems);

  // Validates all the rma case credit order items
  const isModalOpened = creditRmaOrderItemsValidation(creditOrderItems, openSubmitAlertModal);
  if (isModalOpened) return; // If the modal is opened, then we should not proceed to create the RMA order.

  if (creditReturnRequirement.hasProductIsReturnRequired) {
    const isAllCreditOrderWithNonReturnedProduct = checkReturnTypeOrderItemWithNotProductReturned(
      creditOrderItems,
      ReturnTypeEnum.ReturnForCredit,
      true
    );

    if (isAllCreditOrderWithNonReturnedProduct) {
      openSubmitAlertModal({
        title: CommonAlerts.CANNOT_PROCESS_RMA,
        description: CommonAlerts.SEND_BACK_TO_SHIPPING_SPECIALIST,
      });
      return;
    }

    // If creditOrderItems length is equal to the parsedOrder.orderItems length, then all the items are credit items with old product not returned.
    const isAllCreditItemWithOldProductNotReturned = creditOrderItems.length === parsedOrder.orderItems.length;

    /**
     * If the orderItem has a return type of ReturnForCredit and the old product was not returned(isOldProductReturned is false),
     * displays the "Cannot process credit without returned product" error message.
     *
     * We should not allow the user to create a order with a ReturnForCredit item without the old product being returned.
     */
    if (isAllCreditItemWithOldProductNotReturned) {
      toast.notify(ErrorMessage.CANNOT_PROCESS_CREDIT, ToastNotificationType.Error);
      return;
    }
  }
  /** Credit Items validation end here  */

  /** Exchange Items validation start here  */
  const exchangeOrderItems = filterRmaOrderItemsByReturnType(parsedOrder.orderItems, ReturnTypeEnum.Exchange);
  const exchangeReturnRequirement = await fetchOrderItemWithReturnRequirement(exchangeOrderItems);

  if (exchangeReturnRequirement.hasProductIsReturnRequired) {
    // Validates all the rma case exchange order items
    const isModalOpened = exchangeRmaOrderItemsValidation(exchangeOrderItems, openSubmitAlertModal);
    if (isModalOpened) return; // If the modal is opened, then we should not proceed to create the RMA order.
  }
  /** Exchange Items validation end here  */

  /**
   * Origin facility mapping (please see LMS1-7520, LMS1-7679).
   * If the origin facility matches a mapped value in our configuration, we will use the mapped value.
   * For example, "30-RDC" will be mapped to "RIVERSIDE DENTAL CERAMICS".
   * As per a conversation with Dizhen, this logic will remain on the UI-side to limit the scope of these changes.
   * */
  parsedOrder.originFacility = getOriginFacility(parsedOrder.originFacility);

  const newRmaOrder = await createRmaOrder(parsedOrder);

  // For the purpose of displaying the success notification and for submitting the case alerts,
  // transforms the RMA order into a standard order.
  const { rmaOriginalOrderNumber, ...baseOrder } = newRmaOrder;
  return {
    ...baseOrder,
    originalOrderNumber: rmaOriginalOrderNumber,
  };
};

const logAnalyticsEvent = (order: CreatedOrder, eventName = AnalyticsEventName.CaseCreated) => {
  const { __typename, ...restOfOrder } = order;

  const { caseType, returnType } = getCaseAndReturnType(order);
  const { isBundle, bundledOrders, bundledOrderNumbers } = getSpiltBundleCaseInfo(order);

  if (isBundle && !!bundledOrders.length) {
    bundledOrders.forEach(bundledOrder => {
      // remove __typename in final payload
      // remove orderNumber here, as it will be sent as caseNumber
      const { __typename, orderNumber, ...restOfBundle } = bundledOrder;
      const returnType = bundledOrder.orderItems.map(o => o.returnType).filter(Boolean);
      AnalyticsService.track(eventName, {
        ...restOfBundle,
        caseType,
        caseNumber: orderNumber,
        ordersInBundle: bundledOrderNumbers,
        ...(!!returnType.length && { returnType }),
      });
    });
    return;
  }

  AnalyticsService.track(eventName, {
    ...restOfOrder,
    caseType,
    caseNumber: order.orderNumber,
    ...(!!returnType?.length && { returnType }),
  });
};

export const handleSubmitOrder = async (params: {
  currentUserName: string;
  order: LocalOrderInput;
  overlayLoader: OverlayLoaderState;
  toast: ToastState;
  hasAppliedAlerts: boolean;
  orderUpdateId?: string;
  setCreateRmaErrorModalData?: (data: HandleSubmitOrderAlertData) => void;
}): Promise<Order | undefined> => {
  const { order, orderUpdateId, overlayLoader, hasAppliedAlerts, toast, currentUserName, setCreateRmaErrorModalData } =
    params;
  const isRmaOrder = order.localMetadata.localOrderType === LocalOrderType.RMA;
  const parsedOrder = localOrderInputToOrderInput(order, hasAppliedAlerts) as CreateRmaOrderInput;
  const openSubmitOrderAlertModal = handleSubmitOrderAlertModal(toast, setCreateRmaErrorModalData);
  try {
    // Ensures that these 2 fields are not submitted for every order, unless required.
    const { updatedBy, orderNumber, ...baseOrderRequest } = parsedOrder;

    if (isRmaOrder) {
      parsedOrder.orderNumber = orderNumber;
      const rmaOrder = await createRmaOrderAfterValidation({
        parsedOrder,
        toast,
        openSubmitAlertModal: openSubmitOrderAlertModal,
      });

      if (rmaOrder) {
        logAnalyticsEvent(rmaOrder);
      }

      // For the purpose of displaying the success notification and for submitting the case alerts,
      // transforms the RMA order into a standard order.
      const { ...baseOrder } = rmaOrder;

      return {
        ...baseOrder,
        __typename: 'Order',
        orderItems: [],
      };
    } else if (orderUpdateId) {
      const updateOrderInput = createOrderInputToUpdateOrderInput(baseOrderRequest); // above parser is handy but missing update fields
      updateOrderInput.orderNumber = orderNumber;
      updateOrderInput.updatedBy = currentUserName;
      updateOrderInput.updatedDate = new Date().toISOString();
      const updatedOrder = await updateOrder({ input: updateOrderInput });

      logAnalyticsEvent(updatedOrder, AnalyticsEventName.CaseUpdated);

      return updatedOrder;
    } else {
      const input = {
        ...baseOrderRequest,
        orderNumber,
      };
      const createdOrder = await createOrder({ input });

      logAnalyticsEvent(createdOrder);

      return createdOrder;
    }
  } catch (error) {
    console.error(error);
    if (isRmaOrder) {
      if (error instanceof Error && error.name === 'BadRequestError') {
        toast.notify('Cannot process RMA without returned product.', ToastNotificationType.Error);
      } else {
        toast.notify(
          'Failed to create the RMA order. Please ensure that the original order or previous RMA order is currently invoiced.',
          ToastNotificationType.Error
        );
      }
    } else if (orderUpdateId) {
      toast.notify('Failed to save the order, please try again', ToastNotificationType.Error);
    } else {
      toast.notify('Failed to create the order, please try again', ToastNotificationType.Error);
    }
  } finally {
    overlayLoader.hide();
  }
};

export const generateNewRmaOrder = async (params: {
  targetOrder: CreatedOrder;
  selectedProducts: string[];
  products: ClassificationProduct[];
  order: LocalOrderInput;
  currentUserName: string;
  originalOrderIsLegacy?: boolean;
}) => {
  const { targetOrder, selectedProducts, products, order, currentUserName, originalOrderIsLegacy } = params;

  const { orderNumber: targetCaseOrderNumber, originalOrderNumber: targetCaseOriginalOrderNumber } = targetOrder;
  // Gets the next RMA order number.
  const result = await nextRmaOrder(targetCaseOrderNumber);
  // Only adds order items for the selected products.
  let newOrderItems: OrderItem[] = [];
  if (targetOrder.__typename === OrderTypeName.RmaOrder) {
    newOrderItems = targetOrder.orderItems.map((item: RmaOrderItem) => {
      return {
        ...item,
        __typename: 'OrderItem',
      };
    });
  } else {
    newOrderItems = targetOrder.orderItems;
  }

  const selectedOrderItems: LocalOrderItemInput[] = newOrderItems
    .filter(item => selectedProducts.includes(item.itemId))
    .map(item => {
      // Maps the selected product code to its material and restoration names.
      const targetProduct = products.find(product => product.productCode === item.productCode);
      const { materialName = '', restorationName = '' } = targetProduct || {};
      // Returns the mapped order item.
      if (originalOrderIsLegacy) {
        //For legacy cases avoid auto-populating the existing orderItems.
        return getDefaultOrderItemInput();
      }

      // filtering out Alloy Type/Weight attribute, so it wont auto populate during a new RMA order creation
      // refer https://glidewell.atlassian.net/browse/LMS1-7691 for further details
      const attributes = item.attributes.filter(attr => attr.type !== AttributeType.Alloy);

      return {
        id: uuidv4(),
        attributes,
        itemId: item.itemId,
        addOns: [...item.addOns, ...item.services],
        coupons: item.coupons,
        daysInLab: item.daysInLab,
        department: item.department,
        manufacturingLocation: item.manufacturingLocation,
        manufacturingLocationId: item.manufacturingLocationId || 0,
        productCode: item.productCode,
        productName: item.productName,
        quantity: item.quantity,
        toothUnits: item.toothUnits || 0,
        services: item.services,
        material: materialName,
        restorationType: restorationName,
      };
    });

  // Omit the __typename here because the Mutation will fail if it is included in the input (LMS1-6199).
  // As per LMS1-7584, the shipping address used for legacy cases should not be taken from the target order.
  // Rather, use the user's current address selection in the order object.
  const shippingAddress: AddressInput = originalOrderIsLegacy
    ? order.shippingAddress
    : omit(targetOrder.shipping?.address, ['__typename']);

  /**
   * In LMS1-6987, we established that non-RMA orders with an original order number
   * are exchanges cases and should have their order number used as the RMA's original order number.
   * This is to ensure that credits are proceeded correctly for that case.
   */
  const isRmaOrder = isRmaOrderTypename(targetOrder);
  const isExchangeCase = !isRmaOrder && !!targetCaseOriginalOrderNumber;
  const originalOrderNumberForRma = isExchangeCase
    ? targetCaseOrderNumber
    : targetCaseOriginalOrderNumber || targetCaseOrderNumber;

  // Builds a combined order to be submitted as an RMA order, with elements from both the order capture and from the original case.
  // Only taking tracking numbers, enclosed items, and file attachments from data capture order, as per Quenton. The rest comes from the original order.
  const combinedOrder: LocalOrderInput = {
    orderNumber: result.orderNumber,
    originalOrderNumber: originalOrderNumberForRma,
    linkedOrder: {
      orderNumber: targetOrder.orderNumber,
      linkType: OrderLinkType.Rma,
    },
    orderItems: selectedOrderItems,
    enclosedItems: order.enclosedItems,
    fileAttachments: order.fileAttachments.map(attachment => {
      return {
        ...attachment,
        uploadStatus: FileUploadState.Uploaded, // Mark all as uploaded because at this point they will all be uploaded.
      };
    }),
    inboundTrackingNumbers: order.inboundTrackingNumbers,
    utcConversionTimeZone: targetOrder.utcConversionTimeZoneCode || moment.tz.guess(),
    shippingAddress,
    billingAccountId: targetOrder.billingAccountId || order.billingAccountId,
    createdBy: currentUserName,
    originFacility: targetOrder.originFacility || order.originFacility,
    originFacilityId: targetOrder.originFacilityId || order.originFacilityId,
    updatedBy: currentUserName,
    providerId: targetOrder.providerId,
    providerName: targetOrder.providerName,
    coupons: [],
    customerDueDate: order.customerDueDate,
    externalOrderNumber: order.externalOrderNumber,
    notes: undefined,
    orderSource: order.orderSource,
    patientFirstName: targetOrder.patientFirstName,
    patientLastName: targetOrder.patientLastName,
    patientId: targetOrder.patientId,
    localMetadata: {
      previousOrderType: getOrderType(targetOrder.externalOrderNumber, targetOrder.orderSource),
      localOrderType: LocalOrderType.RMA,
    },
    estimatedShipDate: order.estimatedShipDate,
  };

  return combinedOrder;
};

export const concatOrderNumber = (orders: Array<{ orderNumber: string }>) => {
  return orders.reduce((result, order, index) => {
    if (index === 0) {
      return `${order.orderNumber}`;
    } else if (index === orders.length - 1) {
      return `${result} and ${order.orderNumber}`;
    }
    return `${result}, ${order.orderNumber}`;
  }, '');
};

/**
 * Checks if any or every order item in the given array matches the specified return type.
 *
 * @param orderItems - The array of order items to check.
 * @param returnType - The return type to match against.
 * @param isEvery - Optional. If true, checks if every order item matches the return type. If false, checks if any order item matches the return type. Default is false.
 * @returns True if any or every order item matches the return type, false otherwise.
 */
export const checkOrderItemByReturnType = (
  orderItems: Array<LocalOrderItemInput>,
  returnType: ReturnTypeEnum,
  isEvery = false
) => {
  const condition = isEvery ? 'every' : 'some';
  return orderItems[condition](item => item.returnType === returnType);
};

/**
 * Returns the success toast message for a bundle of split cases.
 * @param bundledSplitCases - An array of bundled split cases.
 * @returns The success toast message.
 */
export const getBundleSuccessToastMessage = (bundledSplitCases: BundledSplitCase[]) => {
  return `Bundle with cases # ${concatOrderNumber(bundledSplitCases)} have been submitted successfully`;
};

/**
 * Checks if a product attribute exists in the given array of attributes
 * based on the attribute type and name.
 *
 * @param attributes - The array of product attributes to search in.
 * @param type - The type of the attribute to check.
 * @param name - The name of the attribute to check.
 * @returns A boolean indicating whether the attribute exists or not.
 */
export const checkAttributeByTypeAndName = (attributes: ProductFullAttribute[], type: string, name: string) => {
  return attributes.some(attribute => attribute.type === type && attribute.name === name);
};

/**
 * Get attribute by name and type and return.
 * @param attributes - the attributes too look at.
 * @param name - the attribute name we are looking for.
 * @param type - the attribute type we are looking for.
 * @returns The attribute matching the provided name and type, if any.
 */
export const getProductAttributeByNameAndType = (attributes: ProductFullAttribute[], name: string, type: string) => {
  return attributes.find(att => att.name === name && att.type === type);
};

/**
 * Filters the order item attributes by type and name.
 *
 * @param orderItem - The local order item input.
 * @param attributes - The list of product attributes.
 * @param key - The key indicating the type of attributes to filter ('addOns', 'services', or 'attributes').
 * @returns The filtered list of order item attributes.
 */
export const filterOrderItemAttributesByTypeAndName = (
  orderItem: LocalOrderItemInput,
  attributes: ProductFullAttribute[],
  key: 'addOns' | 'services' | 'attributes'
) => {
  const orderItemAttributes = orderItem[key] || [];
  return orderItemAttributes.filter(attribute => {
    return checkAttributeByTypeAndName(attributes, attribute.type, attribute.name);
  });
};

/**
 * Filters the given attributes by display type.
 *
 * @param attributes - The list of attributes to filter.
 * @param displayType - The display type to filter by.
 * @returns The filtered attributes.
 */
export const filterAttributesByDisplayType = <TDisplayType extends AttributeDisplayType>(
  attributes: ProductFullAttribute[],
  displayType: TDisplayType
): Array<ProductFullAttribute & { displayType: TDisplayType }> => {
  return attributes.filter(attribute => attribute.isVisible && attribute.displayType === displayType) as Array<
    ProductFullAttribute & { displayType: TDisplayType }
  >;
};

/**
 * returns a sorted list of strings representing restorations
 * mapped from classifications list
 *
 * @param classifications - list representing a list of classifications
 * @param selectedMaterialName - string indicating a selected material name
 */
export const extractRestorationFromClassifications = (
  classifications: Classifications[],
  selectedMaterialName: string
) => {
  return classifications
    .filter(p => p.materialName === selectedMaterialName)[0]
    .restorations.sort((current, next) => {
      if (current.restorationName === next.restorationName) return 0;
      if (current.restorationName === 'Single') return -1;
      if (next.restorationName === 'Single') return 1;
      if (current.restorationName < next.restorationName) {
        return -1;
      }
      if (next.restorationName < current.restorationName) {
        return 1;
      }
      return 0;
    })
    .map(r => r.restorationName);
};

/**
 * As per LMS1-7205, only the product itself and not any of its attributes or add-ons should be transferred over to a new exchange case.
 * @param orderItem - The existing order item input.
 * @returns a local order item object containing only information about the selected product and nothing else (i.e. attributes).
 */
export const getBaseProductOrderItem = ({
  isLoaded,
  productCode,
  productName,
  material,
  restorationType,
  id,
}: LocalOrderItemInput): LocalOrderItemInput => {
  return {
    ...getDefaultOrderItemInput(id),
    isLoaded,
    productCode,
    productName,
    material,
    restorationType,
  };
};

/**
 * The business has requested that exchange cases for which the original product was required to be returned
 * but wasn't must include this notice in the order notes section.
 * @param originalOrderNumber - The original order number.
 * @returns a message to be added to the notes section of the product.
 */
export const getExchangeCaseNotes = (originalOrderNumber: string) => {
  return `Product swap not returned original case # ${originalOrderNumber}.`;
};

/**
 * Converts a CreateDeductionInput object to a Deduction object to save and render applied special discounts in order entry.
 *
 * - Copies all properties from the input object.
 * - Casts the `deductions` field to `Discount[]` for type consistency.
 * - Initializes `discountId` as an empty string (to be assigned later if needed).
 *
 * @param input - The CreateDeductionInput object to be converted.
 * @returns Deduction object.
 */
export const convertDiscountInputToDeduction = (input: CreateDeductionInput): Deduction => {
  return {
    ...input,
    deductions: input.deductions as Discount[],
    __typename: 'Deduction',
    discountId: '',
  };
};

/**
 * Returns a discount option.
 * @param label - the label to use for this option.
 * @returns a discount option.
 */
const createDiscountOption = (label: string): OrderItemDropdownModel => ({
  availableAmount: 0,
  description: label,
  orderItemId: label,
  primaryLabel: label,
  productCode: label,
  value: label,
});

/**
 * Generates a list of discount options based on the provided order data for part of case option in discount modal during order entry.
 *
 * - Includes predefined discount types such as "Entire Case," "Inbound Shipping," and "Outbound Shipping."
 * - Extracts attributes from `orderItems`, specifically the `ToothString` value, to generate product discount option.
 * - Processes `addOns` and `services` to create additional discount options.
 * - Ensures all generated options conform to the `OrderItemDropdownModel` type.
 *
 * @param currentOrder - The order data used to generate discount options.
 * @returns An array of `OrderItemDropdownModel` objects representing discount options.
 */
export const getNewCaseDiscountOptions = (
  currentOrder: LocalOrderInput | null | undefined
): OrderItemDropdownModel[] => {
  if (!currentOrder) return [];
  const outboundShippingOption = createDiscountOption(DISCOUNT_TYPE_LABELS.OUTBOUND_SHIPPING);
  const inboundShippingOption = createDiscountOption(DISCOUNT_TYPE_LABELS.INBOUND_SHIPPING);

  const attributes = currentOrder.orderItems.flatMap(item => {
    const toothString = item.attributes.find(attr => attr.type === AttributeType.ToothString)?.value;
    if (!toothString?.length) return [];

    const description = `${item.productName} (${toothString})`;
    return [
      {
        description,
        orderItemId: item?.id,
        primaryLabel: description,
        value: description,
        productCode: item.productCode,
        availableAmount: 0,
      },
    ];
  });

  const addOnsAndServices = currentOrder.orderItems.flatMap(({ addOns, services }) => {
    return [...(addOns ?? []), ...(services ?? [])].map(
      ({ name: serviceItemName, type: serviceItemType, value: serviceItemValue, id: serviceItemId }) => {
        const value = `${serviceItemName} (${serviceItemType}) (${serviceItemValue})`;
        const descriptionService = `${serviceItemName} (${serviceItemType})`;
        const description = serviceItemType === AttributeType.AddOn ? value : descriptionService;
        return {
          description,
          orderItemId: serviceItemId || undefined,
          primaryLabel: description,
          value,
          productCode: serviceItemType,
          availableAmount: 0,
        };
      }
    );
  });

  return [...attributes, ...addOnsAndServices, outboundShippingOption, inboundShippingOption];
};
