import { OrderStatus } from 'API';

export const BaseConstants = {
  Home: 'Home',
  All_Cases: 'All Cases',
  Fetching_Data: 'Fetching Data',
  No_Tracking: 'No Tracking',
  Unknown: 'Unknown',
};

export const CaseStatus: Record<OrderStatus, { displayName: string; textColor: string; bgColor: string }> = {
  Deleted: {
    displayName: 'Deleted',
    textColor: 'text-gray-800',
    bgColor: 'bg-gray-100',
  },
  Cancelled: {
    displayName: 'Cancelled',
    textColor: 'text-gray-800',
    bgColor: 'bg-gray-100',
  },
  OnHold: {
    displayName: 'On Hold',
    textColor: 'text-red-800',
    bgColor: 'bg-red-100',
  },
  CallCenter: {
    displayName: 'Call Center',
    textColor: 'text-red-800',
    bgColor: 'bg-red-100',
  },
  CreditPending: {
    displayName: 'Credit Pending',
    textColor: 'text-yellow-800',
    bgColor: 'bg-yellow-100',
  },
  Pending: {
    displayName: 'Pending',
    textColor: 'text-yellow-800',
    bgColor: 'bg-yellow-100',
  },
  Manufacturing: {
    displayName: 'Manufacturing',
    textColor: 'text-blue-800',
    bgColor: 'bg-blue-100',
  },
  Invoiced: {
    displayName: 'Invoiced',
    textColor: 'text-blue-800',
    bgColor: 'bg-blue-100',
  },
  Credited: {
    displayName: 'Credited',
    textColor: 'text-teal-800',
    bgColor: 'bg-teal-100',
  },
  InvoicePending: {
    displayName: 'Invoiced',
    textColor: 'text-blue-800',
    bgColor: 'bg-blue-100',
  },
  Shipped: {
    displayName: 'Shipped',
    textColor: 'text-teal-800',
    bgColor: 'bg-teal-100',
  },
  InLab: {
    displayName: 'InLab',
    textColor: 'text-blue-800',
    bgColor: 'bg-blue-100',
  },
  Forwarded: {
    displayName: 'Forwarded',
    textColor: 'text-blue-800',
    bgColor: 'bg-blue-100',
  },
  Processing: {
    displayName: 'Processing',
    textColor: 'text-yellow-800',
    bgColor: 'bg-yellow-100',
  },
  UnInvoiced: {
    displayName: 'UnInvoiced',
    textColor: 'text-blue-800',
    bgColor: 'bg-blue-100',
  },
};

export const COUPON_EXPIRED_LABEL = `(expired)`;

export const ACCOUNT_NUMBER_REGEX = /^\d+-?\w+$/;

export const NEW_CASE = 'New Case';

export const DOWNLOADABLE_FILE_TYPES = ['stl', 'ply', 'xyz'];

// .* at the end matches ISO strings with or without milliseconds attached at the end (see LMS1-5984).
export const UTC_DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2,}.*$/;

export const DISABLED_LINK_CLASSNAME = 'opacity-40 cursor-not-allowed pointer-events-none';

export const AZURE_AUTHENTICATION_ERROR = 'AzureAuthenticationError';

export const LAB_CUTOFF_TIME_FORMAT = 'h:mm A';

export const LAB_HOLIDAY_DATES = [
  '2025-05-26', // Memorial Day
  '2025-07-04', // Independence Day
  '2025-09-01', // Labor Day
  '2025-11-27', // Thanksgiving
  '2025-12-25', // Christmas
  '2026-01-01', // New Year
];
export const LAB_HOLIDAY_DATE_FORMAT = 'YYYY-MM-DD';
