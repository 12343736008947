import { MinusCircleIcon } from '@heroicons/react/24/solid';
import { CaseDiscountType, DiscountType } from 'API';
import Dropdown from 'components/common/Dropdown/Dropdown';
import NumberInput from 'components/common/NumberInput/NumberInput';
import Toggle, { ToggleOption } from 'components/common/Toggle';
import { usePreviousRef } from 'hooks/use-previous-ref';
import { FC, useCallback, useMemo } from 'react';
import { PART_OF_CASE_DISCOUNT_TYPE } from 'shared/constants/invoice-data.constants';
import { DiscountCategory } from 'shared/enums';
import { ToggleState } from 'shared/enums/toggle-state';
import { uniqueDiscountItem } from 'shared/helpers/order-detail/order-detail.helper';
import { convertToCurrency, isPositiveNumberWithDecimal } from 'shared/utils';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { DiscountState } from 'stores/useAddDiscountModalStore/createSpecialDiscountSlice';
import { OrderItemDropdownModel } from 'types/dropdown-menu';
import { DiscountModalType } from '../../types';

/**
 * Props for the DiscountCaseItem component.
 */
interface DiscountCaseItemProps extends DiscountState {
  /**
   * Callback function to delete a discount.
   */
  deleteDiscount: (key: string) => void;
  /**
   * Callback function to update a discount.
   */
  updateDiscount: (discount: Partial<DiscountState>, key: string) => void;
  /**
   * Callback function to set discount errors.
   */
  setDiscountErrors: (errors: Partial<DiscountState['errors']>) => void;
  /**
   * List of order item options.
   */
  orderItemOptions: OrderItemDropdownModel[];
  /**
   * Boolean which indicate if its a bundle
   */
  isBundle: boolean;

  hasNoMaxPrice: boolean;
}

/**
 * Component for displaying a discount item in the discount modal.
 *
 * @param isPercentage - Indicates if the discount is a percentage.
 * @param percentageValue - The value of the percentage discount.
 * @param dollarAmount - The dollar amount of the discount.
 * @param deleteDiscount - Callback function to delete a discount.
 * @param updateDiscount - Callback function to update a discount.
 * @param errors - Errors related to the discount.
 * @param setDiscountErrors - Callback function to set discount errors.
 * @param orderItemOptions - List of order item options.
 * @returns a discount case item component.
 */
export const DiscountCaseItem: FC<DiscountCaseItemProps> = ({
  isPercentage,
  percentageValue,
  dollarAmount,
  deleteDiscount,
  updateDiscount,
  errors,
  setDiscountErrors,
  orderItemOptions,
  isBundle,
  hasNoMaxPrice,
  ...discount
}) => {
  const previousPercentageRef = usePreviousRef(percentageValue);
  const previousDollarRef = usePreviousRef(dollarAmount);
  const selectedDiscountType = useAddDiscountModalStore(state => state.specialDiscount.selectedDiscountType);
  const selectedDiscounts = useAddDiscountModalStore(state => state.specialDiscount.discounts);
  const maxDiscountValue = useAddDiscountModalStore(state => state.maxDiscountValue);
  const discountModalType = useAddDiscountModalStore(state => state.discountModalType);
  const getMaxTotalDollarAmount = useAddDiscountModalStore(state => state.getMaxTotalDollarAmount);
  const selectedItem = useMemo(() => {
    const matchingItem = orderItemOptions.find(
      item => uniqueDiscountItem(item.primaryLabel, item.orderItemId) === discount.itemId
    );
    const matchingItemUniqueValue = matchingItem
      ? uniqueDiscountItem(matchingItem.primaryLabel, matchingItem.orderItemId)
      : '';

    return {
      ...matchingItem,
      primaryLabel: matchingItemUniqueValue,
      value: matchingItemUniqueValue,
    };
  }, [discount.itemId, orderItemOptions]);

  const isDeductionModalOpened = useMemo(
    () => discountModalType === DiscountModalType.SpecialDiscount,
    [discountModalType]
  );
  // this condition is for outbound shipping for deductions, not for outbound shipping for credits
  const isOutboundShippingForDeduction =
    isDeductionModalOpened && discount.itemId === PART_OF_CASE_DISCOUNT_TYPE.OUTBOUND_SHIPPING;

  const { maxDollar, maxPercentage } = maxDiscountValue[discount.itemId] || {};

  /**
   * determines if dollar field should be disabled if Part of Case + Outbound shipping are selected
   *
   * for further details, refer https://glidewell.atlassian.net/browse/LMS1-6031
   */
  const shouldDisableDollar = useCallback(
    (productCode?: string) => {
      return (
        selectedDiscountType === CaseDiscountType.PartOfCase &&
        productCode === DiscountCategory.OutboundShipping &&
        isDeductionModalOpened
      );
    },
    [selectedDiscountType, isDeductionModalOpened]
  );

  const disableDollar = useMemo(
    () => shouldDisableDollar(selectedItem.productCode),
    [shouldDisableDollar, selectedItem.productCode]
  );

  const toggleOptions = useMemo<ToggleOption[]>(() => {
    return [
      { value: ToggleState.Percentage },
      {
        value: ToggleState.Dollar,
        disabled: disableDollar,
        toolTipText: disableDollar
          ? 'Outbound shipping discounts may only be applied using a percentage amount'
          : undefined,
      },
    ];
  }, [disableDollar]);

  const dropdownOptions = useMemo(() => {
    const selectDiscountValues = selectedDiscounts.map(selectedDiscount => selectedDiscount.itemId);

    /**
     * Filter out currently selected discount types unless it is the current type we are on.
     * This way the user cannot select the same option more than once.
     */
    return orderItemOptions
      .map(option => {
        const uniqueLabel = uniqueDiscountItem(option.primaryLabel, option.orderItemId);
        return { ...option, primaryLabel: uniqueLabel, value: uniqueLabel };
      })
      .filter(option => {
        /**
         * Remove special parts price and notes from dropdownOptions
         */
        return !selectDiscountValues.includes(option.value) || option.value === selectedItem.value;
      });
  }, [orderItemOptions, selectedDiscounts, selectedItem.value]);

  const availableAmount = selectedItem.availableAmount || 0;
  const currentMaxDiscountAmount = getMaxTotalDollarAmount(selectedItem.value, availableAmount);

  const toggleDiscountAmount = (value: string) => {
    const isPercentage = value.endsWith(ToggleState.Percentage);
    const data = { isPercentage, dollarAmount: 0, percentageValue: 0 };
    updateDiscount(data, selectedItem.value);
    setDiscountErrors({ dollarAmount: false, percentageValue: false });
  };

  /**
   * Handles the selection of a discount item.
   * @param selectedItem - The selected discount item.
   */
  const onDiscountItemIdChange = (selectedItem: OrderItemDropdownModel) => {
    const dataToUpdate: Partial<DiscountState> = {
      itemId: selectedItem.value,
      productCode: selectedItem.productCode,
      description: selectedItem.description,
      dollarAmount: 0,
      percentageValue: 0,
      orderItemId: selectedItem.orderItemId,
    };

    if (shouldDisableDollar(selectedItem.productCode)) {
      dataToUpdate.isPercentage = true;
    }

    updateDiscount(dataToUpdate, selectedItem.value);
    setDiscountErrors({ itemId: !selectedItem.value });
  };

  /**
   * Returns true if the discount amount is over the maximum.
   * @param discountValue - The amount to discount by.
   * @returns true if the discount amount is over the maximum.
   */
  const isPercentageValueOverMaximum = (discountValue: number) => {
    const previousPercentage = previousPercentageRef.current || 0;
    const currentMaxPercentage = maxPercentage - previousPercentage + discountValue;
    const currentMaxDiscountAmountValue = +(availableAmount * (currentMaxPercentage / 100) + maxDollar).toFixed(2);
    return currentMaxDiscountAmountValue > availableAmount;
  };

  /**
   * Returns true if the discount amount is over the maximum.
   * @param discountValue - The amount to discount by.
   * @returns true if the discount amount is over the maximum.
   */
  const isAmountValueOverMaximum = (discountValue: number) => {
    const previousDollar = previousDollarRef.current || 0;
    const currentMaxDollar = maxDollar - previousDollar + discountValue;
    const currentDollarPercentage = +((currentMaxDollar / availableAmount) * 100).toFixed(2);
    const currentMaxPercentage = maxPercentage + currentDollarPercentage;
    return currentMaxPercentage > 100;
  };

  /**
   * Handles a user request to update the discount dollar or percentage amount.
   * Please note that there is no maximum discount at case creation, or for outbound shipping options.
   * The amount will not be updated if there is an order maximum and the user input is not valid.
   * @param value - the value entered by the user.
   * @param discountType - the type of discount being applied (i.e. Amount, Percentage).
   */
  const handleDiscountValueChange = (value: string, discountType: DiscountType) => {
    const hasNoMaxValue = hasNoMaxPrice || isOutboundShippingForDeduction;
    const hasNoAmountAvailableToDiscount = !hasNoMaxValue && !availableAmount;
    if (!isPositiveNumberWithDecimal(value) || hasNoAmountAvailableToDiscount) return;

    const discountValue = +value;
    const isPercentage = discountType === DiscountType.Percentage;

    if (!hasNoMaxValue) {
      if (isPercentage) {
        if (isPercentageValueOverMaximum(discountValue)) {
          return;
        }
        // If percentage value not over percentage maximum, it should be allowed. please see LMS1-8649.
        // In that case, no need to check amount value below.
      } else if (isAmountValueOverMaximum(discountValue)) {
        return;
      }
    }

    const targetField = isPercentage ? 'percentageValue' : 'dollarAmount';

    updateDiscount({ [targetField]: discountValue }, selectedItem.value);
    setDiscountErrors({ [targetField]: false });
  };

  return (
    <div className="mb-2">
      <div className="flex gap-2 justify-start items-center flex-1">
        <div className="flex-grow max-w-xs">
          <Dropdown
            data={dropdownOptions}
            selected={selectedItem}
            setSelected={onDiscountItemIdChange}
            placeholder="Select"
            errorMessage={errors.itemId ? `${discountModalType} value is required` : ''}
            emptyText={isBundle ? 'Please select a case first.' : undefined}
          />
        </div>

        <Toggle
          options={toggleOptions}
          selectedOption={toggleOptions[isPercentage ? 0 : 1]}
          onToggle={toggleState => toggleDiscountAmount(toggleState.value)}
          className="w-24 flex-none"
          dataQa="discountAmountToggle"
          selectedClassName="bg-indigo-500 border-indigo-600 text-white"
        />
        <NumberInput
          id="percentage"
          value={percentageValue || 0}
          onChange={value => handleDiscountValueChange(value, DiscountType.Percentage)}
          placeholder="0"
          min={0}
          max={100}
          isDisabled={!isPercentage}
          inputRightPadding="pr-1"
          errorMessage={errors.percentageValue ? 'Percentage is required' : ''}
        />
        <NumberInput
          id="dollar"
          value={dollarAmount || 0}
          onChange={value => handleDiscountValueChange(value, DiscountType.Amount)}
          placeholder="$0"
          isDisabled={isPercentage}
          inputRightPadding="pr-1"
          errorMessage={errors.dollarAmount ? 'Amount is required' : ''}
        />

        <div className="flex items-center mt-2">
          <button
            onClick={() => {
              deleteDiscount(selectedItem.value);
            }}
            disabled={selectedDiscounts.length === 1}
          >
            <MinusCircleIcon className="text-gray-400 h-5 w-5 cursor-pointer" />
          </button>
        </div>
      </div>
      {!isOutboundShippingForDeduction && !hasNoMaxPrice && (
        <div className="text-sm text-gray-800 mt-1 text-right">
          Max. {currentMaxDiscountAmount ? convertToCurrency(currentMaxDiscountAmount) : '$0'}
        </div>
      )}
    </div>
  );
};
