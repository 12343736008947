import { ProductReturnRequirement } from 'API';
import { getDefaultOrder } from 'mocks/order-entry.mock';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useCallback, useMemo, useState } from 'react';
import { convertToCurrency, getOutboundApplicableIndex } from 'shared/utils';
import InvoiceSummary from './InvoiceSummary';

/**
 * Component responsible for rendering the skeleton loading state for the invoice summary.
 *
 * @returns JSX.Element The skeleton loading state for the invoice summary.
 */
const SkeletonLoading = () => {
  return <InvoiceSummary isLoading={true} invoiceCase={getDefaultOrder()} />;
};

interface TotalInvoiceCase {
  orderNumber: string;
  totalInvoiceCodAmount: number;
}

interface InvoiceSummaryListsProps {
  productReturnRequirements: ProductReturnRequirement[];
}

/**
 * Component responsible for rendering the list of invoice summaries.
 *
 * @param productReturnRequirements - The product return requirements for each invoice case.
 */
const InvoiceSummaryLists = ({ productReturnRequirements }: InvoiceSummaryListsProps) => {
  const { invoiceCases } = useInvoicingDetail();

  const [totalInvoiceCases, setTotalInvoiceCases] = useState<Array<TotalInvoiceCase>>([]);

  // Memoized calculation to determine the index of the next case where shipping charges are applicable
  const shippingApplicableNextCaseIndex = useMemo(() => {
    return getOutboundApplicableIndex(invoiceCases, productReturnRequirements);
  }, [invoiceCases, productReturnRequirements]);

  const onAddTotalInvoiceCase = useCallback((orderNumber: string, totalInvoiceCodAmount: number) => {
    setTotalInvoiceCases(prev => {
      const index = prev.findIndex(item => item.orderNumber === orderNumber);
      if (index === -1) {
        return [...prev, { orderNumber, totalInvoiceCodAmount }];
      }
      return prev.map(item => (item.orderNumber === orderNumber ? { ...item, totalInvoiceCodAmount } : item));
    });
  }, []);

  const totalInvoiceCodAmount = totalInvoiceCases.reduce((acc, item) => {
    return acc + item.totalInvoiceCodAmount;
  }, 0);

  return (
    <div className="flex flex-col gap-6">
      {invoiceCases.map((invoiceCase, index) => (
        <InvoiceSummary
          key={invoiceCase.orderNumber}
          invoiceCase={invoiceCase}
          isChargeApplicableCase={index === shippingApplicableNextCaseIndex}
          onAddTotalInvoiceCase={onAddTotalInvoiceCase}
        />
      ))}
      {!!totalInvoiceCodAmount && (
        <div>
          <div className="font-medium text-sm text-gray-500 pb-2 border-b border-gray-200 mb-2.5" />
          <div className="flex justify-end items-center">
            <div className="flex-grow text-sm font-bold text-right text-gray-700 mt-0.5">COD Total</div>
            <div className="w-24 font-bold text-gray-700 text-right" data-qa="codTotalLabel">
              {convertToCurrency(totalInvoiceCodAmount)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface RightPanelProps {
  isLoading: boolean;
  productReturnRequirements: ProductReturnRequirement[];
}
/**
 * Component responsible for rendering the right panel containing the invoice summary.
 *
 * @param isLoading - Flag indicating whether the component is in a loading state.
 * @param productReturnRequirements - The product return requirements for each invoice case.
 */
const RightPanel: React.FC<RightPanelProps> = ({ isLoading, ...props }) => {
  return (
    <div className="flex-none w-88 3xl:w-80 bg-white border-l p-6">
      <div className="font-medium text-gray-900 mb-3">Invoice Summary</div>
      {isLoading ? <SkeletonLoading /> : <InvoiceSummaryLists {...props} />}
    </div>
  );
};

export default RightPanel;
