import { SkeletonBorder } from 'components/common/Skeleton';
import { AddedSpecialDiscountItems } from 'components/SpecialDiscount/SpecialDiscount/AddedSpecialDiscountItems/AddedSpecialDiscountItems';
import { FC } from 'react';
import { AddedSpecialDiscountItemType } from 'types/common';

/**
 * Props for the DisplayDiscountItem component.
 */
interface AddSpecialDiscountInputProps {
  /**
   * Function to handle deletion of the discount.
   */
  onDeleteItem: (discountId: string, description: string, index: number) => void;

  /**
   * Boolean flag for loading.
   */
  isLoading: boolean;
  /**
   * List of applied special discount items
   */
  addedSpecialDiscountsItems: AddedSpecialDiscountItemType[];
  /**
   * Boolean flag for indicating where the button should be disabled
   */
  disabled?: boolean;

  /**
   * Callback function on Add special discount click
   */
  onAddButtonClick: () => void;
  hideTitle?: boolean;
  showCaseId?: boolean;
}
/**
 * Renders the add special discount section.
 *
 * @returns JSX.Element | null - The add special discount section.
 */
const AddSpecialDiscountInput: FC<AddSpecialDiscountInputProps> = ({
  isLoading,
  addedSpecialDiscountsItems,
  onDeleteItem,
  disabled,
  onAddButtonClick,
  hideTitle,
  showCaseId,
}) => {
  return (
    <>
      {isLoading ? (
        <SkeletonBorder className="mb-2" />
      ) : (
        <>
          {!hideTitle && <div className="font-medium flex mt-2">Special Discount</div>}
          <AddedSpecialDiscountItems
            items={addedSpecialDiscountsItems}
            onDelete={disabled ? undefined : onDeleteItem}
            showCaseId={showCaseId}
          />
          <button
            disabled={disabled}
            onClick={onAddButtonClick}
            className="font-medium inline-flex text-indigo-600 leading-5 text-sm "
          >
            Add A Special Discount
          </button>
        </>
      )}
    </>
  );
};
export default AddSpecialDiscountInput;
