import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import segmentPlugin from '@analytics/segment';
import { AnalyticsInstance, init } from 'analytics';
import { ProjectInfo } from 'shared/models/projectInfo';
import { UserInfo } from 'types/common';

class AnalyticsService {
  #instance: AnalyticsInstance = init({
    app: 'LMS',
  });
  #defaultTrackData: {
    deployedEnvironment: string;
    deployedVersion: string;
    deployedApplication: string;
    hostname: string;
  } = {
    deployedEnvironment: '',
    deployedVersion: '',
    deployedApplication: '',
    hostname: window.location.hostname,
  };

  init(projectInfo: ProjectInfo | null, AMPLITUDE_PUBLIC_WRITE_KEY?: string, SEGMENT_PUBLIC_WRITE_KEY?: string) {
    if (!projectInfo?.deployedEnvironment) throw new Error('No deployed environment found.');

    if (SEGMENT_PUBLIC_WRITE_KEY) {
      this.#instance = init({
        app: 'LMS',
        version: projectInfo.deployedVersion,
        plugins: [
          segmentPlugin({
            writeKey: SEGMENT_PUBLIC_WRITE_KEY,
          }),
        ],
      });
      this.#defaultTrackData = {
        deployedEnvironment: projectInfo.deployedEnvironment,
        deployedVersion: projectInfo.deployedVersion,
        deployedApplication: projectInfo.deployedApplication,
        hostname: window.location.hostname,
      };
    } else {
      console.warn('No Segment write key provided.');
    }

    const userId = this.#instance.user().userId;

    if (AMPLITUDE_PUBLIC_WRITE_KEY) {
      const sessionReplayTracking = sessionReplayPlugin();
      amplitude.add(sessionReplayTracking);
      amplitude.init(AMPLITUDE_PUBLIC_WRITE_KEY, {
        defaultTracking: true,
        userId,
      });
    } else {
      console.warn('No Amplitude write key provided.');
    }
  }

  /**
   * Identify a user so session details can be sent in each event the user performs.
   * @param user - the user to identify.
   */
  identify(user: UserInfo) {
    const { employeeId, avatar, roles, ...rest } = user;
    this.#instance.identify(rest.email, {
      ...rest,
    });
  }

  /**
   * Track when a page loads.
   * @param data - page data.
   */
  page(data: { path: string; referrer?: string }) {
    this.#instance.page({
      ...data,
      ...this.#defaultTrackData,
    });
  }

  /**
   * Track custom events.
   * @param eventName - event name to track.
   * @param properties - event properties.
   */
  track(eventName: string, properties?: Record<string, string | number | boolean | object | null>) {
    /**
     * This will send user events to Amplitude.
     * Previously, events were being forwarded from Segment to Amplitude, but this did not support session replays,
     * which require paying for a more expensive version of Segment.
     * Likewise, by sending events separately, we are able to use different event names.
     */
    amplitude.track(eventName, {
      ...properties,
      ...this.#defaultTrackData,
    });
    // Sends events to Segment.
    this.#instance.track(eventName, {
      ...properties,
      ...this.#defaultTrackData,
    });
  }

  /**
   * Reset analytics state (e.g. clear user info).
   */
  reset = () => {
    this.#instance.reset();
  };
}

const Analytics = new AnalyticsService();
export { Analytics as AnalyticsService };
