import { TrashIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { CouponStatus } from 'API';
import Images from 'assets/images';
import classNames from 'classnames';
import { FC } from 'react';
import { COUPON_EXPIRED_LABEL } from 'shared/constants/constants';
import { CouponStatusText } from 'shared/enums/coupon-status-text';
import { DisplayDiscountItemType } from 'types/common';

/**
 * Props for the DisplayDiscountItem component.
 */
interface DisplayDiscountItemElementProps extends DisplayDiscountItemType {
  /**
   * Function to handle deletion of the discount.
   */
  onDelete?: () => void;

  /**
   * Boolean flag indicating whether to show the case ID.
   */
  showCaseId?: boolean;
  /**
   * Boolean flag indicating whether to show left side loading indicator.
   */
  showLoading?: boolean;
}

/**
 * Component for displaying discount items.
 *
 * @param caseId - The case ID.
 * @param showCaseId - Boolean flag indicating whether to show the case ID.
 * @param discountText - The text describing the discount.
 * @param status - The status of the discount.
 * @param tooltipComponent - The tooltip component.
 * @param onDelete - Function to handle deletion of the discount.
 * @returns JSX.Element A component to display discount items.
 */
export const DisplayDiscountItem: FC<DisplayDiscountItemElementProps> = ({
  caseId,
  showCaseId,
  discountText,
  status,
  tooltipComponent,
  onDelete,
  showLoading,
}) => {
  // Determines whether the coupon is applied or not applied. Special icons and styling exist for these statuses.
  const isCouponApplied = status === CouponStatus.Applied;
  const isCouponNotApplied = status === CouponStatus.NotApplied;
  const hasCouponIcon = isCouponApplied || isCouponNotApplied;

  return (
    <div className="group flex flex-row relative items-center bg-gray-100 border border-gray-200 p-3 rounded-md">
      {hasCouponIcon && (
        <div className="flex self-start">
          {isCouponApplied && <CheckCircleIcon className="h-5 w-5 text-green-600 self-start" />}
          {isCouponNotApplied && <ExclamationCircleIcon className="h-5 w-5 text-red-600 self-start" />}
        </div>
      )}
      <div className="flex flex-col flex-grow gap-1 px-1">
        <div className="flex row-auto align-middle">
          {showLoading && !status && (
            <img alt="spinner" src={Images.SpinnerGif} width={20} height={20} className="self-start" />
          )}
          <div
            className={classNames('text-sm line-clamp-2', {
              'text-orange-600': !status,
              'text-green-600': isCouponApplied,
              'text-red-600': isCouponNotApplied,
            })}
          >
            {discountText.replace(COUPON_EXPIRED_LABEL, '')}
            {discountText.includes(COUPON_EXPIRED_LABEL) && <span>{COUPON_EXPIRED_LABEL}</span>}
          </div>
        </div>
        <div className={classNames('text-sm text-black', { 'ml-5': showLoading })}>
          {status ? CouponStatusText[status] : 'Pending'}
        </div>
        {showCaseId && caseId && (
          <div className={classNames('text-xs font-medium text-gray-600', { 'ml-5': showLoading })}>CASE #{caseId}</div>
        )}
      </div>
      {tooltipComponent}
      {onDelete && (
        <div className="flex p-1">
          <TrashIcon className="text-gray-500 h-5 w-5 cursor-pointer" onClick={onDelete} />
        </div>
      )}
    </div>
  );
};
