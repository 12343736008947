import { Brand, OverrideFieldType, ProductFullAttribute } from 'API';
import Dropdown from 'components/common/Dropdown/Dropdown';
import Input from 'components/common/Input/Input';
import Label from 'components/common/Label';
import NumberInput from 'components/common/NumberInput/NumberInput';
import { getDefaultLabOriginId } from 'configurations/DefaultLabOrigin';
import { useDaysInLab } from 'hooks/use-days-in-lab';
import { usePrevious } from 'hooks/use-previous';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { DropdownModel, LocalOrderItemInput, LocalOrderProductAttributeInput } from 'shared/models';

enum OverrideField {
  ProductionFacility = 'productionFacility',
  DaysInLab = 'daysInLab',
}

interface IProps {
  productDepartmentAttribute: ProductFullAttribute;
  orderItem: LocalOrderItemInput;
  productBrands: Brand[];
  isOrderUpdate?: boolean;
  salesCategoryAndBackLogGroupAttributeRecord: Record<'sameAttributeValues' | 'differentAttributeValues', string[]>;
}

/**
 * Production Facility:
 * Defaults to the production facility associated whth the lab that the billing account id belongs to.
 * On edit, should load the production facility that was set during case creation.
 * Department:
 * Defaults to the department attribute default value coming from Product Admin (if available).
 * On edit, should load the department set during case creation.
 * Days In Lab:
 * Is set to the days in lab assigned to the product for the specific manufacturing location (production facility).
 * Days in lab is updated when Add-Ons and/or Services are added that have days in lab set in Product Admin.
 * On edit, should load the values that were set during case creation.
 * @param productBrands - The list of product brands.
 * @param productDepartmentAttribute - The product department attribute.
 * @param orderItem - The order item.
 * @param isOrderUpdate - Indicates whether the component is used for updating an existing order. (optional)
 * @returns JSX element representing the ProductionFacility component.
 */
const ProductionFacility: React.FC<IProps> = ({
  productBrands,
  productDepartmentAttribute,
  orderItem,
  isOrderUpdate,
  salesCategoryAndBackLogGroupAttributeRecord,
}) => {
  const { updateOrderItem } = useContext(OrderModuleActionsContext);
  const { order, onSubmitAlertCount, isDigitalOrder } = useContext(OrderModuleContext);
  const manufacturingLocation = orderItem.manufacturingLocation;
  const selectedDaysInLab = orderItem.daysInLab;
  const orderItemId = orderItem.id;
  const addOnString = JSON.stringify(orderItem.addOns);
  const attributesString = JSON.stringify(orderItem.attributes);
  const initialLoadRef = useRef(true);

  const productBrandsResult = useMemo(() => {
    return {
      [orderItem.productCode]: productBrands,
    };
  }, [orderItem.productCode, productBrands]);

  const { getDaysInLabForProductionFacility, calculateTotalDaysInLab, calculateMaxDaysInLab } =
    useDaysInLab(productBrandsResult);

  const selectedProductionFacility = useMemo(() => {
    return {
      primaryLabel: manufacturingLocation,
      value: manufacturingLocation,
    };
  }, [manufacturingLocation]);

  //setting attribute defaultValue value to department field(readonly)
  const selectedDepartmentValue = useMemo<string>(() => {
    if (productDepartmentAttribute.defaultValue) {
      return productDepartmentAttribute.defaultValue;
    } else {
      return '';
    }
  }, [productDepartmentAttribute.defaultValue]);

  const getDaysInLabForAddedAttributes = useCallback(
    (productionFacility: string) => {
      const daysInLabProductionFacility = getDaysInLabForProductionFacility({
        productionFacility,
        isDigitalOrder,
        productCode: orderItem.productCode,
      });
      /**
       * NOTE: using json stringify and parse so that we know when the array is updated.
       * Otherwise, if a property is updated in one of the objects in the array, this function won't re-render.
       */
      const addOns = JSON.parse(addOnString) as LocalOrderProductAttributeInput[];
      const attributes = JSON.parse(attributesString) as LocalOrderProductAttributeInput[];

      // calculate total days in lab for add-ons/services
      const totalAddonDaysInLab = calculateTotalDaysInLab({ items: addOns, productionFacility, isDigitalOrder });

      // concat multiple sales category and backlog group attributes into a single array
      const salesCategoryAndBackLogGroupAttributeNames =
        salesCategoryAndBackLogGroupAttributeRecord.sameAttributeValues.concat(
          salesCategoryAndBackLogGroupAttributeRecord.differentAttributeValues
        );

      // check if sales category and backlog group attributes exist
      const checkIfSalesCategoryAndBackLogExist = attributes.some(attribute => {
        return salesCategoryAndBackLogGroupAttributeNames.includes(attribute.name);
      });

      // if sales category and backlog group attributes do not exist, return product level days in lab + add-on days in lab
      if (!checkIfSalesCategoryAndBackLogExist) {
        return daysInLabProductionFacility + totalAddonDaysInLab;
      }

      /**
       * As per the Quenton's comment in https://glidewell.atlassian.net/browse/LMS1-5933?focusedCommentId=75980,
       * We need to remove the duplicate attributes from the list to avoid double counting days in lab.
       */
      const nonDuplicateAttributes = attributes.filter((attribute, index) => {
        // If the attribute is empty, we don't want to count it in the days in lab.
        if (!attribute.value) return false;
        const currentIndex = attributes.findIndex(a => a.name === attribute.name && a.type === attribute.type);
        return currentIndex === index;
      });

      const salesCategoryAndBackLogGroupAttributes: LocalOrderProductAttributeInput[] = [];
      const nonSalesCategoryAndBackLogGroupAttributes: LocalOrderProductAttributeInput[] = [];

      // separate the attributes into two arrays based on whether they are sales category and backlog group attributes
      nonDuplicateAttributes.forEach(attribute => {
        if (salesCategoryAndBackLogGroupAttributeRecord.sameAttributeValues.includes(attribute.name)) {
          salesCategoryAndBackLogGroupAttributes.push(attribute);
        } else {
          nonSalesCategoryAndBackLogGroupAttributes.push(attribute);
        }
      });

      // calculate the max days in lab for sales category and backlog group attributes
      const totalMaxDaysInLab = calculateMaxDaysInLab({
        items: salesCategoryAndBackLogGroupAttributes,
        productionFacility,
        isDigitalOrder,
      });
      // calculate the total days in lab for non-sales category and backlog group attributes
      const totalAttributesDaysInLab = calculateTotalDaysInLab({
        items: nonSalesCategoryAndBackLogGroupAttributes,
        productionFacility,
        isDigitalOrder,
      });
      // sum of max days in lab for sales category and backlog group attributes, total days in lab for non-sales category and backlog group attributes, and add-on days in lab
      return totalMaxDaysInLab + totalAttributesDaysInLab + totalAddonDaysInLab;
    },
    [
      getDaysInLabForProductionFacility,
      addOnString,
      attributesString,
      calculateTotalDaysInLab,
      calculateMaxDaysInLab,
      salesCategoryAndBackLogGroupAttributeRecord,
      isDigitalOrder,
      orderItem.productCode,
    ]
  );

  const calculatedDaysInLab = useMemo(
    () => getDaysInLabForAddedAttributes(selectedProductionFacility.value),
    [selectedProductionFacility.value, getDaysInLabForAddedAttributes]
  );

  const prevCalculatedDaysInLab = usePrevious(calculatedDaysInLab);

  // The default days in lab for the currently selected production facility.
  const defaultDaysInLab = useMemo(
    () => getDaysInLabForAddedAttributes(selectedProductionFacility.value),
    [getDaysInLabForAddedAttributes, selectedProductionFacility.value]
  );

  const updateProductionFacility = useCallback(
    (productionFacility: string) => {
      const prodFacilityLowerCase = productionFacility.toLowerCase();
      const locationData = productBrands.find(data => data.brandName.toLowerCase() === prodFacilityLowerCase);
      const calculatedDaysInLab = getDaysInLabForAddedAttributes(productionFacility);
      updateOrderItem(orderItemId, {
        manufacturingLocation: productionFacility,
        manufacturingLocationId: locationData?.siteId ? +locationData.siteId : 0,
        daysInLab: calculatedDaysInLab,
      });
    },
    [productBrands, getDaysInLabForAddedAttributes, updateOrderItem, orderItemId]
  );

  /**
   * Returns the default site id.
   * Defaults to the brand associated with the default lab origin id if there is no originFacilityId set on the order yet.
   */
  const getDefaultProductBrand = useCallback(() => {
    const defaultBrand = productBrands.find(p => p.siteId === getDefaultLabOriginId()) || productBrands[0];

    // originFacilityId gets set on the order when a billing account id is searched.
    if (!order.originFacilityId) {
      return defaultBrand;
    }

    return productBrands.find(p => p.siteId === order.originFacilityId.toString()) || defaultBrand;
  }, [order.originFacilityId, productBrands]);

  useEffect(() => {
    if (order.originFacilityId && !selectedProductionFacility.value) {
      if (initialLoadRef.current && isOrderUpdate && orderItem?.manufacturingLocation) return; // if it order update and manufacturingLocation is already present ie, it is not a newly added orderItem, Then don't set default production facility

      // Update order with production facility
      const defaultProductionFacility = getDefaultProductBrand()?.brandName || '';
      updateProductionFacility(defaultProductionFacility);
    }
  }, [
    getDefaultProductBrand,
    isOrderUpdate,
    order.originFacilityId,
    orderItem?.manufacturingLocation,
    selectedProductionFacility.value,
    updateProductionFacility,
  ]);

  /**
   * Checks if the new selected value for days in lab overrides the default value.
   * If it does, adds it to the overrides array for this order.
   * @param newDaysInLab - The new value selected for days in lab.
   */
  const handleOverrideCheck = useCallback(
    (fieldName: string, defaultValue: string, newValue: string) => {
      // Filters the overwritten field to avoid duplicates. It will be re-evaluated in the next if statement.
      let orderOverrides =
        orderItem.overrides?.filter(override => {
          // If the field is production facility, also clears days in lab, since the UI will reset its value.
          const isProductionFacility = fieldName === OverrideField.ProductionFacility;
          return isProductionFacility
            ? override.field !== OverrideField.ProductionFacility && override.field !== OverrideField.DaysInLab
            : override.field !== fieldName;
        }) || [];
      // When changing the production facility, resets the days in lab override for this product, since that value would have reset.
      if (fieldName === OverrideField.ProductionFacility) {
        orderOverrides = orderOverrides.filter(orderOverride => orderOverride.field !== OverrideField.DaysInLab);
      }
      // If the value for days in lab does not match the default, adds it to the list of overwritten attributes.
      if (defaultValue && newValue !== defaultValue) {
        orderOverrides.push({
          new: newValue,
          old: defaultValue,
          type: OverrideFieldType.Default,
          field: fieldName,
        });
      }

      // Adds the updates overrides to the target order item.
      updateOrderItem(orderItemId, { overrides: orderOverrides });
    },
    [orderItem.overrides, orderItemId, updateOrderItem]
  );

  /**
   * Update the days in lab for the given product and production facility whenever order data changes that could affect the days in lab
   * (e.g. addons, services, providerId which reloads technical preferences, etc).
   * Should not run on initial load of case update page (why we have initialLoadRef) so that the set value on the order is used.
   * If 'isOrderUpdate' is true and 'initialLoadRef' is still true, it skips the update to avoid unintended re-execution.
   * If 'isOrderUpdate' is false, it directly updates the 'daysInLab'.
   */
  useEffect(() => {
    if (initialLoadRef.current && isOrderUpdate) {
      initialLoadRef.current = false;
      return;
    }

    if (calculatedDaysInLab !== prevCalculatedDaysInLab) {
      handleOverrideCheck(OverrideField.DaysInLab, String(defaultDaysInLab), String(calculatedDaysInLab));
      updateOrderItem(orderItemId, { daysInLab: calculatedDaysInLab });
    }
  }, [
    orderItemId,
    calculatedDaysInLab,
    isOrderUpdate,
    updateOrderItem,
    handleOverrideCheck,
    defaultDaysInLab,
    prevCalculatedDaysInLab,
  ]);

  /**
   * Handles production facility select.
   * @param e - The new production facility value.
   */
  const handleProductionFacilitySelect = (e: DropdownModel) => {
    updateProductionFacility(e.value);
    const defaultProductBrand = getDefaultProductBrand();
    // Update order with production facility
    const defaultProductionFacility = defaultProductBrand?.brandName || '';
    handleOverrideCheck(OverrideField.ProductionFacility, defaultProductionFacility, e.value);
  };

  /**
   * Handles days in lab select.
   * @param newDaysInLab - The new days in lab value.
   */
  const handleDaysInLabSelect = (newDaysInLab: string) => {
    if (initialLoadRef.current && isOrderUpdate) {
      return;
    }
    updateOrderItem(orderItemId, { daysInLab: +newDaysInLab });
    handleOverrideCheck(OverrideField.DaysInLab, String(defaultDaysInLab), newDaysInLab);
  };

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-3">
        <Dropdown
          label="Production Facility"
          isRequired
          selected={selectedProductionFacility}
          data={productBrands.map(p => ({ primaryLabel: p.brandName, value: p.brandName }))}
          setSelected={handleProductionFacilitySelect}
          onSubmitAlert={!!onSubmitAlertCount}
        />
      </div>
      <div className="col-span-2">
        <Input
          id="department"
          label="Department"
          name="department"
          type="text"
          value={selectedDepartmentValue}
          isRequired
          readOnly
        />
      </div>
      <div className="col-span-1">
        <Label required>Days in Lab</Label>
        <NumberInput
          id={`daysInLabInput`}
          value={selectedDaysInLab}
          min={1}
          onChange={handleDaysInLabSelect}
          onSubmitAlert={!!onSubmitAlertCount}
          isRequired
        />
      </div>
    </div>
  );
};

export default ProductionFacility;
