import Dropdown from 'components/common/Dropdown/Dropdown';
import { FC, useMemo } from 'react';
import { CreatedOrder, DropdownModel } from 'shared/models';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';

/**
 * Props for the SelectCaseFromBundle component.
 */
interface SelectCaseFromBundleProps {
  // orders inside the bundle: CreatedOder array
  bundledCases: CreatedOrder[];
}

/**
 * Component for selecting case for discount in a bundled order.
 * @param bundledCases - orders inside the bundle: CreatedOder array
 * @returns JSX.Element
 */
export const SelectCaseFromBundle: FC<SelectCaseFromBundleProps> = ({ bundledCases }) => {
  const currentOrder = useAddDiscountModalStore(state => state.currentOrder);
  const setCurrentOrder = useAddDiscountModalStore(state => state.setCurrentOrder);
  const currentOrderNumber = currentOrder?.orderNumber || '';
  const selectedOption: DropdownModel = useMemo(() => {
    return {
      value: currentOrderNumber,
      primaryLabel: currentOrderNumber,
    };
  }, [currentOrderNumber]);
  const cases = bundledCases.map(item => {
    return {
      value: item.orderNumber,
      primaryLabel: item.orderNumber,
    };
  });
  const setSelectedCase = (caseId: string) => {
    const order = bundledCases.find(item => item.orderNumber === caseId);
    setCurrentOrder(order);
  };

  return (
    <div>
      <Dropdown
        isRequired
        label="Select a case"
        selected={selectedOption}
        setSelected={option => {
          setSelectedCase(option.value);
        }}
        data={cases}
      />
    </div>
  );
};
