import AddButton from 'components/common/AddButton';
import RemoveButton from 'components/common/RemoveButton';
import SelectDropdown from 'components/common/SelectDropdown/SelectDropdown';
import { SkeletonBorder } from 'components/common/Skeleton';
import { getSourceSystemMapping } from 'configurations/SourceSystemConfiguration';
import { getSourceSystemRegexMapping } from 'configurations/SourceSystemRegexConfiguration';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { determineSourceSystemByExternalReferenceID } from 'shared/helpers/order-entry/order-entry.helper';

/**
 * Component responsible for managing the external case ID input and scanner system selection.
 * It allows users to add or remove external case information.
 * @returns JSX Element
 */
const ExternalCase: React.FC = () => {
  const [onSubmitAlert, onSubmitAlertChange] = useState<boolean>();
  const [addExternalOrder, setAddExternalOrder] = useState(false);
  const externalCaseRef = useRef<HTMLInputElement>(null);
  const { orderLoading, order, onSubmitAlertCount } = useContext(OrderModuleContext);
  const { patchOrder } = useContext(OrderModuleActionsContext);
  const sourceSystemOptions = getSourceSystemMapping();
  const sourceSystemRegex = getSourceSystemRegexMapping();

  useEffect(() => {
    if (order.externalOrderNumber) {
      setAddExternalOrder(true);
    }
  }, [order.externalOrderNumber]);

  useEffect(() => {
    // focus the new externalCase field, when user clicks add
    if (addExternalOrder && !order.externalOrderNumber) {
      externalCaseRef.current?.focus();
    }
  }, [addExternalOrder, order.externalOrderNumber]);

  useEffect(() => {
    onSubmitAlertChange(onSubmitAlertCount ? true : false);
  }, [onSubmitAlertCount]);

  /**
   * Handles the change event for the external order number input field.
   * @param e - Change event
   */
  const onExternalOrderNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const orderSource = determineSourceSystemByExternalReferenceID(e.target.value, sourceSystemRegex);
    patchOrder({
      orderSource,
      externalOrderNumber: e.target.value,
    });
  };

  /**
   * Handles the change event for the scanner system selection dropdown.
   * @param value - Selected value
   */
  const onSelectDropdownChange = (value: string) => {
    patchOrder({
      orderSource: value,
    });
  };

  /**
   * Handles the removal of the external case information.
   */
  const onRemoveHandler = () => {
    onSubmitAlertChange(false);
    setAddExternalOrder(false);
    patchOrder({
      orderSource: undefined,
      externalOrderNumber: undefined,
    });
  };

  if (orderLoading) return <SkeletonBorder />;
  return (
    <>
      {addExternalOrder && (
        <div className="pb-4">
          <div className="flex -mt-1 justify-center">
            {orderLoading ? (
              <SkeletonBorder />
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Enter ID"
                  name="externalOrderNumber"
                  data-qa="externalOrderNumber"
                  data-testid="externalOrderNumber"
                  className={`${
                    onSubmitAlert && (!order.externalOrderNumber || (!order.externalOrderNumber && order.orderSource))
                      ? 'focus:ring-red-500 focus:border-red-500 border-red-500'
                      : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                  } flex-grow mr-2 my-px shadow-sm text-sm rounded-md w-1/2`}
                  value={order.externalOrderNumber || ''}
                  onChange={onExternalOrderNumberChange}
                  onBlur={() => {
                    onSubmitAlertChange(!order.externalOrderNumber && order.orderSource ? true : false);
                  }}
                  ref={addExternalOrder ? externalCaseRef : undefined}
                />
                <div className="w-1/2">
                  <SelectDropdown
                    placeholder="Select"
                    id="scannerSystem"
                    value={order.orderSource || ''}
                    onValueChange={(value: string) => onSelectDropdownChange(value)}
                    onBlurHandler={() => {
                      onSubmitAlertChange(
                        (order.externalOrderNumber && !order.orderSource) ||
                          (!order.externalOrderNumber && order.orderSource)
                          ? true
                          : false
                      );
                    }}
                    hasError={
                      onSubmitAlert &&
                      (!order.orderSource || (!order.orderSource && order.externalOrderNumber ? true : false))
                    }
                    options={Object.keys(sourceSystemOptions).map(source => ({
                      label: sourceSystemOptions[source],
                      value: source,
                    }))}
                  />
                </div>
              </>
            )}
            <RemoveButton id="removeExternalCaseButton" onRemove={() => onRemoveHandler()} />
          </div>
          {onSubmitAlert && (!order.externalOrderNumber || !order.orderSource) && (
            <p data-testid="externalCaseErrorMessage" className="mb-1 ml-px mt-px text-xs text-red-500">
              {!order.externalOrderNumber
                ? 'Add an external case id'
                : !order.orderSource
                ? 'Add a scanner system'
                : ''}
            </p>
          )}
        </div>
      )}
      {!addExternalOrder && (
        <AddButton
          id="addExternalCaseButton"
          data-testid="addExternalCaseButton"
          text="Add External Case"
          onClick={() => {
            setAddExternalOrder(true);
          }}
        />
      )}
    </>
  );
};

export default ExternalCase;
