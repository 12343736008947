import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { InitialLoader } from 'components/common/InitialLoader/InitialLoader';
import { datadogRumInit } from 'shared/lib/datadog-rum';
import { ProjectInfo } from 'shared/models/projectInfo';
import { ServiceConfig } from 'shared/models/service-configs';
import { AnalyticsService } from 'shared/services/analytics.service';
import ConfigService from 'shared/services/config.service';
import { NonNullableRecord } from 'types/generic';
import { v4 as uuidv4 } from 'uuid';

/**
 * Interface representing the initial context for the app.
 */
interface IAppContext {
  config: ServiceConfig | null;
  projectInfo: ProjectInfo | null;
  sessionId: string;
}

/**
 * Default initial context values.
 */
const AppInitialContext: IAppContext = {
  config: null,
  projectInfo: null,
  sessionId: uuidv4(),
};

/**
 * Context for managing the app configuration.
 */
const AppContext = createContext<IAppContext | undefined>(undefined);

/**
 * Provider component for managing the app configuration.
 */
export const AppConfigProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [sessionId] = useState(AppInitialContext.sessionId);
  const msalInstance = useRef<IPublicClientApplication>();
  const appConfigRef = useRef<IAppContext>(AppInitialContext);

  useEffect(() => {
    /**
     * Fetches service configuration.
     */
    const fetchServiceConfig = async () => {
      try {
        await ConfigService.load(sessionId);
        appConfigRef.current.projectInfo = ConfigService.projectInfo; // This is the project info that will be used by the entire app
        appConfigRef.current.config = ConfigService.serviceConfigs; // This is the service config that will be used by the entire app
        msalInstance.current = ConfigService.getMSALInstance(); // This is the MSAL instance that will be used by the entire app

        const env = ConfigService.getEnv();
        const datadogValue = ConfigService.getConfigValue('settings', 'DatadogRum');
        datadogRumInit(env, datadogValue); // Initialize datadogRum to monitoring the application

        // Public analytics keys can be pulled from either our local environment files, or from the process environment.
        const AMPLITUDE_PUBLIC_WRITE_KEY =
          ConfigService.projectInfo?.amplitudePublicWriteKey || process.env.AMPLITUDE_PUBLIC_WRITE_KEY;
        const SEGMENT_PUBLIC_WRITE_KEY =
          ConfigService.projectInfo?.segmentPublicWriteKey || process.env.SEGMENT_PUBLIC_WRITE_KEY;

        AnalyticsService.init(ConfigService.projectInfo, AMPLITUDE_PUBLIC_WRITE_KEY, SEGMENT_PUBLIC_WRITE_KEY);
      } catch (e) {
        console.error(e);
        setLoadingError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchServiceConfig();
  }, [sessionId]);

  if (isLoading) return <InitialLoader />;
  if (loadingError || !msalInstance.current) return <div className="ml-4 mt-4">Error loading application</div>;

  return (
    <AppContext.Provider value={{ ...appConfigRef.current, sessionId }}>
      <MsalProvider instance={msalInstance.current}>{children}</MsalProvider>
    </AppContext.Provider>
  );
};

/**
 * Hook for accessing the app configuration context.
 * @returns The app configuration context.
 * @throws Throws an error if used outside of AppConfigProvider.
 */
export const useAppConfig = () => {
  const appConfig = useContext(AppContext);
  if (!appConfig) throw new Error('useAppConfig must be used within AppConfigProvider');
  return appConfig as NonNullableRecord<IAppContext>;
};
