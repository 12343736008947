import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { CaseDiscountType, Coupon, Deduction } from 'API';
import { BundleCouponInput } from 'components/common/BundleCouponInput/BundleCouponInput';
import Button from 'components/common/Button/Button';
import { CouponInput } from 'components/common/CouponInput/CouponInput';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import { DisplayDiscountItems } from 'components/SpecialDiscount/DisplayDiscountItems/DisplayDiscountItems';
import AddSpecialDiscountInput from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscount/AddSpecialDiscount';
import { AddSpecialDiscountModal } from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscountModal/AddSpecialDiscountModal';
import { DiscountModalType } from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscountModal/types';
import { useAuth } from 'providers/AuthProvider';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { ToastContext } from 'providers/ToastProvider';
import { FC, Fragment, useContext, useMemo, useState } from 'react';
import { createDiscount, removeDiscount, updateDiscount } from 'shared/api/discount.api';
import { ToastNotificationType } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import {
  getApplyDiscountAnalytics,
  getNewDiscountInput,
  getUpdateDiscountDeductions,
  getUpdateDiscountPayload,
} from 'shared/helpers/discount.helper';
import { getAppliedSpecialDiscountItem, getDiscountCouponItem } from 'shared/helpers/invoice/invoice.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { AnalyticsService } from 'shared/services/analytics.service';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { AddedSpecialDiscountItemType, DiscountForRemove, DisplayDiscountItemType } from 'types/common';

/**
 * Props interface for the InvoicedDiscountSection component.
 * It includes a boolean flag indicating whether the component is in a loading state.
 */
interface InvoicedDiscountSectionProps {
  isLoading: boolean;
  isDiscountDisabled: boolean;
  onRefresh?: (caseId?: string) => void;
}

/**
 * Component responsible for displaying invoiced discount details.
 * It renders a list of discount items, including coupons and other discounts, for invoiced cases.
 * @param isLoading - Indicates whether the component is in a loading state or ready to render.
 * @returns A JSX element representing the InvoicedDiscountSection component.
 */
export const InvoicedDiscountSection: FC<InvoicedDiscountSectionProps> = ({
  isLoading,
  onRefresh,
  isDiscountDisabled,
}) => {
  const { invoiceCases, updateCase } = useInvoicingDetail();

  const toast = useContext(ToastContext);
  const { user } = useAuth();
  const isBundle = useMemo(() => invoiceCases.length > 1, [invoiceCases]);
  const AllCoupons = useMemo(() => invoiceCases?.flatMap(order => order.coupons), [invoiceCases]);
  const setCurrentOrder = useAddDiscountModalStore(state => state.setCurrentOrder);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isDeletingSpecialDiscount, setIsDeletingSpecialDiscount] = useState(false);
  const [discountForRemove, setDiscountForRemove] = useState<DiscountForRemove>();
  const { fetcher: removeDiscountFetcher } = useLazyQueryFetcher(removeDiscount);
  const { fetcher: updateDiscountFetcher } = useLazyQueryFetcher(updateDiscount);
  const currentOrderInStore = useAddDiscountModalStore(state => state.currentOrder);

  const {
    discounts: selectedDiscounts,
    note,
    reason: selectedReason,
    selectedDiscountType,
  } = useAddDiscountModalStore(state => state.specialDiscount);

  const { fetcher: createDiscountFetcher } = useLazyQueryFetcher(createDiscount);

  /**
   * Opens the add special discount modal.
   */
  const onOpenModal = () => {
    const currentOrder = !isBundle ? invoiceCases[0] : null;
    setCurrentOrder(currentOrder);
    setShowModal(true);
  };

  /**
   * Closes the add special discount modal.
   */
  const onCloseModal = () => {
    setCurrentOrder(null);
    setShowModal(false);
  };

  // List of applied special discount items
  const addedSpecialDiscountsItems: AddedSpecialDiscountItemType[] = invoiceCases.flatMap(
    ({ appliedDiscounts, orderNumber }) => {
      return appliedDiscounts ? getAppliedSpecialDiscountItem(appliedDiscounts, orderNumber) : [];
    }
  );

  // List of applied special discount items
  const appliedDiscounts: Deduction[] = invoiceCases.flatMap(({ appliedDiscounts }) => {
    return appliedDiscounts || [];
  });

  // List of pending coupon items used for UI rendering
  const pendingCouponItems: DisplayDiscountItemType[] = invoiceCases.flatMap(({ appliedCoupons, orderNumber }) => {
    return appliedCoupons?.map(coupon => getDiscountCouponItem(coupon, orderNumber)) || [];
  });

  // (TODO: Implement bundle support)
  /**
   * Adds a coupon to the invoice case.
   *
   * - Updates appliedCoupons to reflect changes in the UI.
   * - Updates coupons to modify the list of coupons submitted with the invoice.
   * - Calls updateCase to update the corresponding order within invoiceCases.
   *
   * @param coupon - Coupon.
   * @param caseId - orderNumber will be passed for bundled cases
   */
  const updateInvoiceOrderCoupons = (coupon: Coupon | null, caseId?: string) => {
    if (!coupon) return;

    const applyingCase = caseId
      ? invoiceCases.find(({ orderNumber }) => orderNumber === caseId) || invoiceCases[0]
      : invoiceCases[0];

    if (!applyingCase?.orderNumber) return;

    const { orderNumber, appliedCoupons = [], coupons = [] } = applyingCase;

    // Updates `coupons` for invoice submission and `appliedCoupons` for UI rendering
    updateCase({
      orderNumber,
      appliedCoupons: [...(appliedCoupons || []), coupon],
      coupons: [...coupons, coupon.code],
    });
  };

  /**
   * Deletes a coupon from the case at the specified index.
   *
   * - Updates appliedCoupons to reflect changes in the UI.
   * - Updates coupons to modify the list of coupons submitted with the invoice.
   * - Calls updateCase to update the corresponding order within invoiceCases.
   *
   * @param index - The index of the coupon to be removed.
   */

  const deleteCoupon = (caseId: string, discountCode: string) => {
    const deletingCase = invoiceCases?.find(item => item.orderNumber === caseId);
    if (!deletingCase?.orderNumber) return;
    const { orderNumber, appliedCoupons = [], coupons = [] } = deletingCase || {};

    updateCase({
      orderNumber,
      appliedCoupons: (appliedCoupons || []).filter(item => item.code !== discountCode),
      coupons: coupons.filter(item => item !== discountCode),
    });
  };

  /**
   * Applies a discount to the case.
   * Creates a new discount based on the user's input and updates the case details accordingly.
   */
  const onDiscountApply = async () => {
    if (currentOrderInStore) {
      const { orderNumber } = currentOrderInStore;
      const newDiscountInput = getNewDiscountInput(
        user?.displayName,
        selectedDiscountType,
        selectedDiscounts,
        note,
        orderNumber,
        selectedReason
      );

      try {
        await createDiscountFetcher(newDiscountInput);
        const deductionAnalytics = getApplyDiscountAnalytics(
          user?.displayName,
          selectedDiscountType,
          selectedDiscounts,
          note,
          orderNumber,
          selectedReason
        );
        AnalyticsService.track(AnalyticsEventName.CaseDeductionAdded, deductionAnalytics);
        // Passing the orderNumber to onRefresh retrieves the latest data for the applied discount order.
        // Based on order.metadata, further updates and banners will be handled using the OnRefresh function in invoicingPage.tsx.
        onRefresh?.(orderNumber);
        onCloseModal();
      } catch (err) {
        toast.notify(
          'Failed to add special discount. Please make sure you entered valid values.',
          ToastNotificationType.Error
        );
      }
    }
  };

  /**
   * Renders the coupon input section for invoices with a single case(TODO: Implement bundle support).
   * - If multiple cases exist, this component returns `null` (intended for single-case invoices only for now).
   *
   * @returns JSX.Element | null - The coupon input section if applicable, otherwise `null`.
   */
  const RenderCouponInput = () => {
    return (
      <div className="bg-white border p-4 sm:rounded-lg shadow-sm">
        <div className="font-medium text-sm text-gray-700 mb-2">Coupon</div>
        <Fragment>
          {!isBundle && !isDiscountDisabled && (
            <CouponInput
              isLoading={isLoading}
              billingAccountId={invoiceCases[0]?.billingAccountId || ''}
              providerId={invoiceCases[0]?.providerId}
              onUpdateCoupon={coupon => updateInvoiceOrderCoupons(coupon)}
              coupons={AllCoupons || []}
            />
          )}
          <DisplayDiscountItems
            items={pendingCouponItems}
            onDelete={(_, caseId, discountCode) => {
              deleteCoupon(caseId, discountCode);
            }}
            className="mb-2"
            showCaseId={isBundle}
            showLoading={true}
          />
          {isBundle && (
            <BundleCouponInput
              billingAccountId={invoiceCases[0]?.billingAccountId || ''}
              providerId={invoiceCases[0]?.providerId}
              onUpdateCoupon={(coupon, caseId) => updateInvoiceOrderCoupons(coupon, caseId)}
              coupons={pendingCouponItems || []}
              bundledCases={invoiceCases}
            />
          )}
          <Button
            // onClick={onApply}
            variant={'bordered'}
            className="mt-5 text-sm text-gray-700"
            disabled={isDiscountDisabled}
          >
            Apply Coupon
          </Button>
        </Fragment>
      </div>
    );
  };

  /**
   * Handles removing a special discount from the case.
   */
  const removeSpecialDiscount = async () => {
    const discount = appliedDiscounts.find(item => item.discountId === discountForRemove?.discountId);
    const discounts = [...appliedDiscounts];
    const orderNumber = discount?.orderNumber || '';
    if (!discount) return; // if we can't find it, just return

    const isEntireCase = discount.type === CaseDiscountType.EntireCase;
    setIsDeletingSpecialDiscount(true);

    try {
      /**
       * If there is only one deduction, then we delete the item by discountId and if there are more than one, then we update the discount
       * Because we don't have a way to delete a single deduction from the database
       *
       * That's why we need to check the length of the deductions array here
       *
       * If length is greater than 1, we can just update the discount with the remaining deductions
       */
      if (discount.deductions.length <= 1 || isEntireCase) {
        await removeDiscountFetcher({ discountId: discount.discountId });
        updateCase({
          orderNumber,
          appliedDiscounts: discounts.splice(0, 1),
        });
      } else {
        const updatePayload = getUpdateDiscountPayload(discount, discountForRemove, selectedDiscountType);
        await updateDiscountFetcher(updatePayload);
        const updatedDiscounts = discounts.map(item => {
          if (item.discountId === discountForRemove?.discountId) {
            item.deductions = getUpdateDiscountDeductions(discount, discountForRemove);
          }
          return item;
        });
        updateCase({
          orderNumber,
          appliedDiscounts: updatedDiscounts,
        });
      }

      onRefresh?.(orderNumber);
      AnalyticsService.track(AnalyticsEventName.CaseDeductionRemoved, {
        caseNumber: discount?.orderNumber || '',
      });
    } catch (err) {
      toast.notify('Failed to remove special discount.', ToastNotificationType.Error);
    } finally {
      setIsDeletingSpecialDiscount(false);
      setShowConfirmModal(false);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="font-medium flex">
        3. Add discount<div className="font-medium text-gray-500 ml-1">(optional)</div>
      </div>
      <div className="w-full">
        <RenderCouponInput />
      </div>
      <AddSpecialDiscountInput
        onAddButtonClick={onOpenModal}
        isLoading={isLoading}
        addedSpecialDiscountsItems={addedSpecialDiscountsItems}
        disabled={isDiscountDisabled}
        onDeleteItem={(discountId: string, description: string) => {
          setShowConfirmModal(true);
          setDiscountForRemove({ discountId, description });
        }}
        showCaseId={isBundle}
      />
      <AddSpecialDiscountModal
        showModal={showModal}
        onClose={onCloseModal}
        onApply={onDiscountApply}
        type={DiscountModalType.SpecialDiscount}
        bundledCases={invoiceCases}
      />
      {showConfirmModal && (
        <DialogBox
          title="Are you sure you want to remove this discount?"
          onCancel={() => setShowConfirmModal(false)}
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" />}
          confirmText="Remove"
          onConfirm={() => removeSpecialDiscount()}
          confirmButtonDisabled={isDeletingSpecialDiscount}
        >
          <div className="text-base text-gray-500"></div>
        </DialogBox>
      )}
    </div>
  );
};
