import { TrashIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Images from 'assets/images';
import { FC } from 'react';
import { cn } from 'shared/utils';
import { AddedSpecialDiscountItemType } from 'types/common';

/**
 * Props for the AddedSpecialDiscountItem component.
 */
interface AddedSpecialDiscountItemProps extends AddedSpecialDiscountItemType {
  /**
   * Function to handle deletion of the discount.
   */
  onDelete?: () => void;

  /**
   * Boolean flag indicating whether to show the case ID.
   */
  showCaseId?: boolean;
}

/**
 * Component for displaying added discount items.
 *
 * @param caseId - The case ID.
 * @param showCaseId - Boolean flag indicating whether to show the case ID.
 * @param amount - The discount amount.
 * @param tooltipComponent - The tooltip component.
 * @param onDelete - Function to handle deletion of the discount.
 * @returns JSX.Element A component to display discount items.
 */
export const AddedSpecialDiscountItem: FC<AddedSpecialDiscountItemProps> = ({
  caseId,
  showCaseId,
  amount,
  tooltipComponent,
  onDelete,
  description,
  reason,
}) => {
  const isPendingAmount = !amount || amount === '$0.00';
  const amountTextToShow = isPendingAmount ? 'Pending' : `${amount} off`;
  return (
    <div className="group flex flex-row relative items-center bg-gray-100 border border-gray-200 p-3 rounded-md">
      <div className="flex flex-col flex-grow gap-1 px-1">
        <div className="flex row-auto align-middle">
          {isPendingAmount ? (
            <img alt="spinner" src={Images.SpinnerGif} width={20} height={20} className="self-start" />
          ) : (
            <CheckCircleIcon className="h-5 w-5 self-start text-green-600" />
          )}
          <div className="ml-1 w-full">
            <div
              className={cn('text-sm text-green-700', {
                'text-orange-600': isPendingAmount,
                'text-green-600': !isPendingAmount,
              })}
            >
              {description}: {amountTextToShow}
              <span className="text-gray-500 ml-1">{`(${reason})`}</span>
            </div>
            {showCaseId && caseId && <div className="text-xs font-medium text-gray-500">CASE #{caseId}</div>}
          </div>
        </div>
      </div>
      {tooltipComponent}
      {onDelete && (
        <div className="flex p-1">
          <TrashIcon className="text-gray-500 h-5 w-5 cursor-pointer" onClick={onDelete} />
        </div>
      )}
    </div>
  );
};
