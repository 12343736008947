import { CaseDiscountType } from 'API';
import { AlertMessage } from 'components/common/Alert/AlertMessage';
import Label from 'components/common/Label';
import { OrderModuleContext } from 'providers/OrderModuleProvider';
import { FC, useContext, useMemo } from 'react';
import { availableCredits, availableDiscounts } from 'shared/api/discount.api';
import { NEW_CASE } from 'shared/constants/constants';
import { PART_OF_CASE_DISCOUNT_TYPE } from 'shared/constants/invoice-data.constants';
import { ToastNotificationType } from 'shared/enums';
import { getNewCaseDiscountOptions } from 'shared/helpers/order-entry/order-entry.helper';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { OrderItemDropdownModel } from 'types/dropdown-menu';
import { DiscountModalType, DiscountModalTypeLabelMapping } from '../../types';
import { DiscountCaseItems } from './DiscountCaseItems';
import { DiscountCaseType } from './DiscountCaseType';

/**
 * Props for the DiscountFor component.
 */
interface DiscountForProps {
  // Boolean which indicate if its a bundle
  isBundle: boolean;
}

/**
 * Component for selecting the discount type and items for discount.
 * @returns DiscountFor component.
 */
export const DiscountFor: FC<DiscountForProps> = ({ isBundle }) => {
  const { order } = useContext(OrderModuleContext);
  const selectedDiscountType = useAddDiscountModalStore(state => state.specialDiscount.selectedDiscountType);
  const isEntireCase = selectedDiscountType === CaseDiscountType.EntireCase;
  const discountAppliedFor = useAddDiscountModalStore(state => state.specialDiscount.discountAppliedFor);
  const discountModalType = useAddDiscountModalStore(state => state.discountModalType);
  const currentOrder = useAddDiscountModalStore(state => state.currentOrder);
  const orderNumber = currentOrder?.orderNumber || '';

  const label = useMemo(() => {
    const labelType = DiscountModalTypeLabelMapping[discountModalType];
    return `${labelType} for`;
  }, [discountModalType]);

  // Check if the order corresponds to a new case by comparing the order number with the predefined NEW_CASE constant.
  const isNewCase = orderNumber === NEW_CASE;

  // if orderNumber is not available, then we don't need to make any api calls
  const skipBothApiCalls = !orderNumber || isNewCase;

  const { data: discountItems } = useQueryFetcher(availableDiscounts, {
    orderNumber,
    // if skipBothApiCalls is true or discountModalType is Credit, then we don't need to make available discounts api call
    skip: skipBothApiCalls || discountModalType === DiscountModalType.Credit,
  });

  const { data: creditItems } = useQueryFetcher(availableCredits, {
    orderNumber,
    // if skipBothApiCalls is true or discountModalType is SpecialDiscount, then we don't need to make available credits api call
    skip: skipBothApiCalls || discountModalType === DiscountModalType.SpecialDiscount,
  });

  const discountOptions = useMemo<OrderItemDropdownModel[]>(() => {
    if (isNewCase) {
      // Generate discount options based on the provided order data.
      // This function returns applicable discount options for the new case entry, including added product, add-ons, services and shipping options.
      return getNewCaseDiscountOptions(order);
    }

    const isSpecialDiscountModal = discountModalType === DiscountModalType.SpecialDiscount;
    const items = (isSpecialDiscountModal ? discountItems : creditItems) || [];

    /**
     * Filter items with no available amount unless it's a special discount for outbound shipping.
     * Map to dropdown model.
     */
    return items
      .filter(item => {
        return (
          item.availableAmount > 0 ||
          (isSpecialDiscountModal && item.productCode === PART_OF_CASE_DISCOUNT_TYPE.OUTBOUND_SHIPPING)
        );
      })
      .map(item => {
        const { description, orderItemId, productCode, availableAmount } = item;
        return {
          productCode,
          description,
          orderItemId,
          availableAmount,
          primaryLabel: description,
          value: description,
        };
      });
  }, [creditItems, discountItems, discountModalType, isNewCase, order]);

  return (
    <div className="flex flex-col gap-1">
      <Label required={true}>{label}</Label>
      {discountAppliedFor === CaseDiscountType.EntireCase && (
        <AlertMessage
          type={ToastNotificationType.Info}
          className="w-full"
          message={`${discountModalType} was already applied to the entire case. If you want to apply a ${discountModalType.toLowerCase()} to a specific item, please remove the existing ${discountModalType.toLowerCase()} and create a new "Part of Case" deduction.`}
        />
      )}

      {/* If the user has previously selected a discount, no need to show a discount selection.
      The user cannot enter an additional discount, if an entire case discount is already selected.
      The user also cannot enter an entire case discount, if a partial case discount is already selected.
      Please see LMS1-8662. */}
      {!discountAppliedFor && <DiscountCaseType />}

      {!isEntireCase && <DiscountCaseItems options={discountOptions} isBundle={isBundle} hasNoMaxPrice={isNewCase} />}
    </div>
  );
};
