import { RmaOrderItem } from 'API';
import { CreatedOrder, LocalOrderType } from 'shared/models';

export const getCaseAndReturnType = (caseDetails?: CreatedOrder) => {
  const { __typename, type: orderType, orderItems = [] } = caseDetails ?? {};
  const isRmaOrder = __typename === 'RmaOrder';

  return {
    caseType: [...(orderType ? [orderType] : []), isRmaOrder ? LocalOrderType.RMA : LocalOrderType.Standard],

    // add returnType property only if order is of RMA type
    returnType:
      orderItems && isRmaOrder ? orderItems.map(o => (o as RmaOrderItem).returnType).filter(Boolean) : undefined,
  };
};
