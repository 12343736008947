import {
  Address,
  AddressType,
  BaseOrderItem,
  CaseDiscountType,
  Coupon,
  Credit,
  CreditItem,
  CreditType,
  Deduction,
  Discount,
  DiscountType,
  DoctorPickupLocation,
  LegacyAddress,
  LmsOrder,
  Order,
  OrderLinkType,
  OrderOriginatingSystem,
  OrderProductAttribute,
  OrderQuery,
  OrderStatus,
  PackageType,
  PendingOrder,
  RelatedCasesQuery,
  RmaOrder,
  SearchedOrderItem,
  ShippingCharge,
  ShippingChargeType,
} from 'API';
import { getDefaultLabOriginId } from 'configurations/DefaultLabOrigin';
import { cloneDeep, startCase, sumBy } from 'lodash';
import {
  CaseCreditItem,
  CaseDeductionItem,
  CaseHistoryAdditionalDetailsObject,
  LocalCaseHistoryItem,
} from 'shared/constants/case-detail.constants';
import { DISCOUNT_TYPE_LABELS, PART_OF_CASE_DISCOUNT_TYPE } from 'shared/constants/invoice-data.constants';
import { ORDER_INPUT_VALIDATION_KEYS } from 'shared/constants/order.constants';
import { AttributeName, AttributeType, FileUploadState } from 'shared/enums';
import { SpecialOrderParts } from 'shared/enums/special-order-parts';
import {
  ALLOY_NO_VALUE,
  getDefaultOrderItemInput,
  getDefaultRmaOrder,
} from 'shared/helpers/order-entry/order-entry.helper';
import {
  CreatedOrder,
  CreatedOrderItem,
  LocalOrderInput,
  LocalOrderType,
  RelatedLegacyOrder,
  RelatedOrderData,
  RelatedOrderItem,
  RelatedRmaOrder,
  RelatedRmaOrderItem,
} from 'shared/models';
import {
  getDateInShortFormat,
  isCreatedOrder,
  isLegacyOrderData,
  isOrderData,
  isPendingOrderData,
  isRmaOrderData,
  isRmaOrderTypename,
} from 'shared/utils';
import { DiscountState } from 'stores/useAddDiscountModalStore/createSpecialDiscountSlice';
import { AdditionalDetailOldAndNewValueItem } from 'types/case-details';
import { GetDataWithTypename } from 'types/generic';
import { v4 as uuidv4 } from 'uuid';
import { OrderItemsToFilter } from '../../enums/order-item-filter';
import { fetchCouponsByCode, formatDeductionAmount } from '../coupon.helper';
import {
  getCaseAddOnOrServiceByProperty,
  getCaseItemAttributeByAttributeName,
  getDefaultOrderItem,
} from '../invoice/invoice.helper';
import { getDefaultOrderInput } from './../order-entry/order-entry.helper';
import { removeIdFromItems, roundDecimal } from './../util.helper';

type OrderQueryToOrderType = ReturnType<typeof convertOrderQueryToOrderType>;

export const getDefaultOrder = (): Order => {
  return {
    __typename: 'Order',
    addresses: [],
    authorizationAmount: null,
    authorizationId: '',
    billingAccountId: '',
    coupons: [],
    createdBy: '',
    createdDate: '',
    creditRequestedDate: '',
    currencyCode: null,
    customerDueDate: '',
    departmentId: null,
    enclosedItems: [],
    externalOrderNumber: '',
    fileAttachments: [
      {
        __typename: 'OrderAttachment',
        createdBy: '',
        createdDate: '',
        extension: '',
        fileUrl: '',
        id: '',
        name: '',
        size: null,
        uploadProperties: {
          __typename: 'AttachmentUploadProperties',
          fileName: '',
          filePath: '',
          fileType: '',
        },
      },
    ],
    invoiceBy: '',
    invoiceDate: '',
    labReceivedDate: '',
    notes: '',
    orderItems: [],
    orderNumber: '',
    orderSource: null,
    originFacility: '',
    originFacilityId: 0,
    originatingSystem: null,
    pONumber: '',
    patientFirstName: '',
    patientId: '',
    patientLastName: '',
    paymentTerms: null,
    providerId: '',
    providerName: '',
    sCINumber: '',
    shipping: {
      __typename: 'Shipping',
      address: {
        __typename: 'Address',
        street1: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        type: AddressType.Shipping,
      },
      packageType: PackageType.Box,
      inboundTrackingNumbers: [],
      outboundTrackingNumbers: [],
      codAmount: 0,
    },
    status: OrderStatus.InLab,
    subtotalAmount: null,
    totalAmount: null,
    totalDiscountAmount: null,
    totalFreightCharges: null,
    totalTaxAmount: null,
    transactionId: '',
    type: null,
    updatedBy: '',
    updatedDate: '',
    utcConversionTimeZoneCode: '',
  };
};

export const orderLocked = (status?: OrderStatus) => {
  if (!status) {
    return true;
  }

  const lockedStatus = [
    OrderStatus.Invoiced,
    OrderStatus.InvoicePending,
    OrderStatus.Shipped,
    OrderStatus.Deleted,
    OrderStatus.Cancelled,
  ];

  return lockedStatus.includes(status);
};

export const orderDisabled = (status?: OrderStatus) => {
  if (!status) {
    return true;
  }

  const lockedStatus = [OrderStatus.Deleted, OrderStatus.Cancelled];

  return lockedStatus.includes(status);
};

/**
 * Calculates the shipping charge amount and discount amount based on the given shipping charges and charge type.
 * @param shippingCharges - The array of shipping charges.
 * @param shippingChargeType - The type of shipping charge to filter.
 * @returns An object containing the calculated amount and discount amount.
 */
export const getShippingCharge = (shippingCharges: ShippingCharge[], shippingChargeType: ShippingChargeType) => {
  const filteredShippingCharges = shippingCharges.filter(charge => charge.type === shippingChargeType);
  const amount = sumBy(filteredShippingCharges, 'amount');
  const discountAmount = sumBy(filteredShippingCharges, 'discountAmount');

  return {
    amount: roundDecimal(amount),
    discountAmount: roundDecimal(discountAmount),
  };
};

/**
 * Formats a tooth string value.
 *
 * @param toothStringValue - The tooth string value to format.
 * @returns The formatted tooth value.
 */
export const formatToothString = (toothStringValue: string) => {
  if (!toothStringValue) return '';

  let toothValue = '';
  const toothStringArray = toothStringValue.split(',');
  const toothLength = toothStringArray.length;
  const firstToothNumber = toothStringArray[0].replace('#', '');
  if (toothLength > 1) {
    const lastToothNumber = toothStringArray[toothLength - 1].replace('#', '');
    toothValue += `#${firstToothNumber}-${lastToothNumber}`;
  } else {
    toothValue += `#${firstToothNumber}`;
  }
  return toothValue;
};

/**
 * Retrieves the tooth value from the given order item.
 * @param orderItem - The order item object.
 * @returns The sanitized tooth value, or an empty string if the tooth attribute is not found.
 */
export const getToothValue = (
  orderItem: BaseOrderItem | CreatedOrderItem | SearchedOrderItem | RelatedOrderItem | RelatedRmaOrderItem
) => {
  const toothAttribute = getCaseItemAttributeByAttributeName(orderItem, AttributeName.ToothString);
  if (!toothAttribute) return '';
  return formatToothString(toothAttribute.value);
};

/**
 * Returns the string value representing the shade and tooth of an order item.
 * If both tooth value and body shade value are present, the format is "(toothValue, bodyShadeValue)".
 * If only tooth value is present, the format is "(toothValue)".
 * If only body shade value is present, the format is "(bodyShadeValue)".
 * If neither tooth value nor body shade value is present, an empty string is returned.
 *
 * @param orderItem - The order item object.
 * @returns The string value representing the shade and tooth of the order item.
 */
export const getShadeAndToothStringValue = (
  orderItem: BaseOrderItem | SearchedOrderItem | CreatedOrderItem | RelatedOrderItem | RelatedRmaOrderItem
) => {
  const bodyShadeValue = getCaseItemAttributeByAttributeName(orderItem, AttributeName.BodyShade)?.value || '';
  const toothValue = getToothValue(orderItem);

  if (toothValue && bodyShadeValue) return `(${toothValue}, ${bodyShadeValue})`;
  if (toothValue && !bodyShadeValue) return `(${toothValue})`;
  if (!toothValue && bodyShadeValue) return `(${bodyShadeValue})`;
  return '';
};

/**
 * Returns the first two attributes of an order item as a string.
 * If the order item has a shade and tooth value, it returns that value.
 * If the order item has no attributes, it returns an empty string.
 * If the order item has only one attribute, it returns that attribute's value.
 * If the order item has two attributes, it returns both attribute values separated by a comma.
 * @param orderItem - The order item object.
 * @returns The first two attributes as a string.
 */
export const firstTwoAttributes = (
  orderItem: BaseOrderItem | SearchedOrderItem | RelatedOrderItem | RelatedRmaOrderItem
) => {
  const shadeAndToothStringValue = getShadeAndToothStringValue(orderItem);
  if (shadeAndToothStringValue) return shadeAndToothStringValue;
  const attributes = orderItem?.attributes || [];
  if (!attributes.length) return '';

  const [firstAttribute, secondAttribute] = attributes;
  const firstAttributeValue = firstAttribute?.value || '';
  const secondAttributeValue = secondAttribute?.value || '';

  const items = [];
  if (firstAttributeValue) {
    items.push(firstAttributeValue);
  }
  if (secondAttributeValue) {
    items.push(secondAttributeValue);
  }
  return `(${items.join(', ')})`;
};

export const isLMSOrder = (originatingSystem: Order['originatingSystem']) => {
  return originatingSystem === OrderOriginatingSystem.Lms;
};

export const convertPendingQueryToPendingOrder = (data: GetDataWithTypename<OrderQuery['order'], 'PendingOrder'>) => {
  const pendingOrder: PendingOrder = {
    ...data,
    orderItems: data.orderItems.map(item => {
      return {
        ...item,
        addOns: item.pendingAddOns,
        services: item.pendingServices,
      };
    }),
    providerId: data.pendingProviderId,
    providerName: data.pendingProviderName,
    originalOrderNumber: data.pendingOriginalOrderNumber,
  };

  return pendingOrder;
};

export const convertRmaOrderQueryToRmaOrder = (
  data: GetDataWithTypename<OrderQuery['order'], 'RmaOrder'> | RelatedRmaOrder
) => {
  let originalOrderNumber = '';

  if ('rmaOriginalOrderNumber' in data) originalOrderNumber = data.rmaOriginalOrderNumber;

  const rmaOrder: RmaOrder = {
    ...getDefaultRmaOrder(),
    ...data,
    orderItems: data.orderItems.map(item => {
      return {
        ...getDefaultOrderItem(),
        ...item,
      };
    }),
    originalOrderNumber,
  };

  return rmaOrder;
};

export const convertToAddress = (item: LegacyAddress | undefined | null): Address => {
  return {
    city: item?.city || '',
    country: item?.country || '',
    division: item?.division || '',
    neighborhood: item?.neighborhood || '',
    state: item?.state || '',
    street1: item?.street1 || '',
    street2: item?.street2 || '',
    street3: item?.street3 || '',
    type: item?.type || AddressType.Shipping,
    zipcode: item?.zipcode || '',
    __typename: 'Address',
  };
};

export const convertLegacyOrderQueryToOrder = (
  data: GetDataWithTypename<OrderQuery['order'], 'LegacyOrder'> | RelatedLegacyOrder
) => {
  const orderItems: Order['orderItems'] =
    data?.legacyOrderItems?.map(orderItem => ({
      ...orderItem,
      __typename: 'OrderItem',
      department: orderItem.department || '',
      manufacturingLocation: orderItem.manufacturingLocation || '',
      productName: orderItem.productName || '',
      addOns: orderItem.addOns || [],
      services: orderItem.services || [],
      daysInLab: orderItem.daysInLab || 0,
    })) || [];

  const order: Order = {
    ...data,
    __typename: 'Order',
    orderItems,
    addresses: [],
    createdDate: data.legacyCreatedDate || '',
    utcConversionTimeZoneCode: data.legacyUtcConversionTimeZoneCode || '',
    providerId: '',
    providerName: data.legacyProviderName || '',
    originFacility: '',
    originFacilityId: 0,
    shipping: undefined,
    enclosedItems: [],
    fileAttachments: [],
    coupons: [],
  };

  // If shipping is a property then is is inferred that we are operating on OrderQuery data.
  if ('shipping' in data) {
    const shippingAddress = convertToAddress(data.shipping?.legacyAddress);
    const addresses = data.legacyAddresses.map(convertToAddress);
    const doctorPickupLocation = data.shipping?.legacyDoctorPickupLocation;

    order.shipping = {
      __typename: 'Shipping',
      inboundTrackingNumbers: data.shipping?.legacyInboundTrackingNumbers || [],
      address: shippingAddress,
      outboundTrackingNumbers: data.shipping?.legacyOutboundTrackingNumbers || [],
      codAmount: data.shipping?.codAmount ?? 0,
    };
    order.addresses = addresses;
    order.departmentId = data.legacyDepartmentId ? +data.legacyDepartmentId : undefined;
    order.providerId = data.legacyProviderId || '';
    order.originFacility = data.legacyOriginFacility || '';
    order.originFacilityId = data.legacyOriginFacilityId || 0;
    order.enclosedItems = data.enclosedItems;
    order.fileAttachments = data.fileAttachments;
    order.coupons = data.coupons;

    if (doctorPickupLocation && order.shipping) {
      const legacyDoctorPickupLocation: DoctorPickupLocation = {
        address: convertToAddress(doctorPickupLocation?.address),
        __typename: 'DoctorPickupLocation',
        labId: doctorPickupLocation?.labId,
        labName: doctorPickupLocation?.labName,
        phone: doctorPickupLocation?.phone,
      };
      order.shipping.doctorPickupLocation = legacyDoctorPickupLocation;
    }
  }

  return order;
};

export const normalizeRelatedCasesQueryResponse = (
  data: GetDataWithTypename<RelatedCasesQuery['relatedCases'], 'RelatedCasesWrapper'>
): { orders: LmsOrder[] } => {
  return { orders: data.orders.map(convertOrderQueryToOrderType).filter((item): item is LmsOrder => !!item) };
};

export const convertOrderQueryToOrderType = (data: OrderQuery['order'] | RelatedOrderData | null): LmsOrder | null => {
  const orderResult: LmsOrder | null = null;

  if (!data) return orderResult;
  if (isLegacyOrderData(data)) {
    return convertLegacyOrderQueryToOrder(data);
  } else if (isRmaOrderData(data)) {
    return convertRmaOrderQueryToRmaOrder(data);
  } else if (isPendingOrderData(data)) {
    return convertPendingQueryToPendingOrder(data);
  } else if (isOrderData(data)) {
    return data;
  }

  return orderResult;
};

export const convertOrderToLocalOrderInput = (params: {
  orderData: OrderQueryToOrderType;
  currentUserName: string;
  coupons?: Array<Coupon>;
}) => {
  const { orderData, currentUserName, coupons = [] } = params;
  if (!orderData) return null;

  const orderItems: Array<(typeof orderData.orderItems)[number]> = orderData.orderItems;
  const filteredOrderItems = orderItems.filter(item => item.productCode !== OrderItemsToFilter.OcReturn);
  const isRma = isRmaOrderTypename(orderData);

  let newData: LocalOrderInput = {
    ...getDefaultOrderInput(),
    createdBy: currentUserName,
    updatedBy: currentUserName,
    ...{
      billingAccountId: orderData.billingAccountId || '',
      enclosedItems: orderData.enclosedItems.map(item => {
        return {
          itemCode: item.itemCode,
          quantity: item.quantity,
          createdBy: item.createdBy,
          createdDate: item.createdDate,
          type: item.type,
          shippingType: item.shippingType,
        };
      }),
      fileAttachments: orderData.fileAttachments.map(attachment => {
        return {
          id: uuidv4(),
          name: attachment.name,
          extension: attachment.extension,
          uploadProperties: attachment.uploadProperties,
          size: attachment.size,
          fileUrl: attachment.fileUrl,
          createdBy: attachment.createdBy,
          createdDate: attachment.createdDate,
          uploadStatus: FileUploadState.Uploaded, // anything getting passed in would be uploaded already
        };
      }),

      // orders which are cancelled and marked for sending back to customer, will have a placeholder orderItem with
      // productCode = 'OCRETURN', in some screens this is considered as an error and an error message is shown accordingly,
      // so filtering out such items out helps avoid false positives
      //
      // for further information refer https://glidewell.atlassian.net/browse/LMS1-5854?focusedCommentId=68680
      orderItems: filteredOrderItems.map(item => {
        // Keeps track of whether special order parts exists as an attribute within this order.
        // If it does, we must ensure that it exists within the list of add-ons for this order.
        let hasSpecialOrderPartsAttribute = false;
        item.attributes.forEach(attribute => {
          if (attribute.name === AttributeType.SpecialOrderParts) {
            hasSpecialOrderPartsAttribute = true;
          }
        });

        const isRmaOrderItem = item.__typename === 'RmaOrderItem';

        const addOns = item.addOns || [];
        const services = item.services || [];
        // Initiates an array of order item add-ons.
        const orderItemAddOns = cloneDeep([...addOns, ...services]);

        // If the special order parts attribute exists, checks to make sure that amount and notes are added to the order.
        if (hasSpecialOrderPartsAttribute) {
          const hasSpecialOrderPartsNotesAddOn = !!orderItemAddOns.find(
            addOn => addOn.name === SpecialOrderParts.Notes
          );
          if (!hasSpecialOrderPartsNotesAddOn) {
            orderItemAddOns.unshift({
              name: SpecialOrderParts.Notes,
              value: '',
              type: AttributeType.AddOn,
              quantity: 1,
              __typename: 'OrderProductAttribute',
            });
          }

          const hasSpecialOrderPartsAmountAddOn = !!orderItemAddOns.find(
            addOn => addOn.name === SpecialOrderParts.Amount
          );
          if (!hasSpecialOrderPartsAmountAddOn) {
            orderItemAddOns.unshift({
              name: SpecialOrderParts.Amount,
              value: '',
              type: AttributeType.AddOn,
              quantity: 1,
              __typename: 'OrderProductAttribute',
            });
          }
        }

        // Workaround fix for alloys so that we don't show "No Value" in the dropdown when loading the attributes.
        const attributes = item.attributes.map(attribute => {
          return {
            ...attribute,
            name: attribute.type === AttributeType.Alloy && attribute.name === ALLOY_NO_VALUE ? '' : attribute.name,
          };
        });

        // TODO: Below mappings for rma fields are temporary.

        return {
          ...getDefaultOrderItemInput(item.itemId),
          ...item,
          attributes,
          returnReasons: isRmaOrderItem ? item.returnReasons || undefined : undefined,
          returnType: isRmaOrderItem ? item.returnType || undefined : undefined,
          newProduct: isRmaOrderItem ? item.newProduct || undefined : undefined,
          isOldProductReturned: isRmaOrderItem ? item.isOldProductReturned ?? undefined : undefined,
          isProcessSwap: isRmaOrderItem ? item.isProcessSwap || undefined : undefined,
          addOns: orderItemAddOns,
          services: services,
        };
      }),
      orderNumber: orderData.orderNumber,
      originalOrderNumber: orderData.originalOrderNumber || null,
      inboundTrackingNumbers:
        orderData.shipping?.inboundTrackingNumbers?.map(item => {
          return {
            trackingNumber: item.trackingNumber,
            insertedBy: item.insertedBy,
            insertionDate: item.insertionDate,
          };
        }) || [],
      shippingAddress: {
        city: orderData.shipping?.address?.city || '',
        country: orderData.shipping?.address?.country || '',
        division: orderData.shipping?.address?.division || '',
        neighborhood: orderData.shipping?.address?.neighborhood || '',
        state: orderData.shipping?.address?.state || '',
        street1: orderData.shipping?.address?.street1 || '',
        street2: orderData.shipping?.address?.street2 || '',
        street3: orderData.shipping?.address?.street3 || '',
        type: orderData.shipping?.address?.type || AddressType.Shipping,
        zipcode: orderData.shipping?.address?.zipcode || '',
      },
      externalOrderNumber: orderData.externalOrderNumber,
      orderSource: orderData.orderSource,
      localMetadata: {
        localOrderType: isRma ? LocalOrderType.RMA : LocalOrderType.Standard,
        linkedOrderNumber: orderData.linkedOrderNumber,
      },
    },
  };

  // In LMS1-6954, we established that the default lab origin ID should be pulled in from the configuration, rather than being hardcoded.
  const defaultOriginFacilityId = parseInt(getDefaultLabOriginId());

  // Sets previous root-level order information.
  if (isCreatedOrder(orderData)) {
    newData = {
      ...newData,
      ...{
        coupons,
        customerDueDate: orderData.customerDueDate ? getDateInShortFormat(orderData.customerDueDate) : undefined,
        notes: orderData.notes,
        originFacilityId: orderData.originFacilityId || defaultOriginFacilityId,
        patientFirstName: orderData.patientFirstName,
        patientId: orderData.patientId,
        patientLastName: orderData.patientLastName,
        providerId: orderData.providerId || '',
        providerName: orderData.providerName || '',
      },
    };

    // Linked order needs to always be set for RMA orders.
    if (isRmaOrderTypename(orderData) && orderData.linkedOrderNumber) {
      newData.linkedOrder = {
        orderNumber: orderData.linkedOrderNumber,
        linkType: OrderLinkType.Rma,
      };
    }
  } else if (isPendingOrderData(orderData)) {
    const orderItems = newData.orderItems.map(item => {
      //If there's a 'quantity' attribute, it's regarded as user-entered, and the orderItem.quantity should be updated accordingly.
      const hasQuantityAttribute = item.attributes.find(
        attribute => attribute.name === AttributeType.Quantity && attribute.type === AttributeType.Quantity
      );
      if (!!hasQuantityAttribute && hasQuantityAttribute.quantity) {
        item.quantity = hasQuantityAttribute.quantity;
      }
      return item;
    });
    newData = {
      ...newData,
      ...{
        originFacilityId: defaultOriginFacilityId,
        patientFirstName: orderData.patientFirstName,
        patientId: orderData.patientId,
        patientLastName: orderData.patientLastName,
        providerId: orderData.providerId || '',
        providerName: orderData.providerName || '',
      },
      orderItems,
    };

    // If linkedOrderNumber is present, then we need to set it in the local order input.
    if (orderData.linkedOrderNumber) {
      newData.linkedOrder = {
        orderNumber: orderData.linkedOrderNumber,
        linkType: OrderLinkType.ExchangeNewCase,
      };
    }
  }

  return newData;
};

/**
 * Converts a local order input to an order object.
 * @param order - The local order input.
 * @returns The converted order object.
 */
export const convertLocalOrderInputToOrder = (order: LocalOrderInput) => {
  const localOrderType = order.localMetadata.localOrderType;
  const standardOrder: Order = {
    ...order,
    __typename: 'Order',
    orderNumber: order.orderNumber || '',
    status: OrderStatus.InLab,
    enclosedItems: order.enclosedItems.map(item => ({
      ...item,
      __typename: 'OrderEnclosedItem',
      id: uuidv4(),
    })),
    fileAttachments: order.fileAttachments.map(attachment => ({
      ...attachment,
      __typename: 'OrderAttachment',
      extension: attachment.extension || '',
      uploadProperties: { ...attachment.uploadProperties, __typename: 'AttachmentUploadProperties' },
    })),
    orderItems: order.orderItems.map(item => ({
      ...item,
      __typename: 'OrderItem',
      attributes: item.attributes.map(attribute => ({
        ...attribute,
        __typename: 'OrderProductAttribute',
      })),
      addOns: item.addOns.map(addOn => ({
        ...addOn,
        __typename: 'OrderProductAttribute',
      })),
      services: item.services.map(service => ({
        ...service,
        __typename: 'OrderProductAttribute',
      })),
      itemId: item.itemId || '',
      overrides: (item.overrides || []).map(override => ({
        ...override,
        __typename: 'OverrideField',
      })),
    })),
    addresses: [],
    createdDate: new Date().toISOString(),
    utcConversionTimeZoneCode: order.utcConversionTimeZone,
    coupons: order.coupons.map(coupon => coupon.code),
  };

  if (localOrderType === LocalOrderType.RMA) {
    const rmaOrder: RmaOrder = {
      ...standardOrder,
      __typename: 'RmaOrder',
      orderItems: order.orderItems.map(item => ({
        ...item,
        __typename: 'RmaOrderItem',
        attributes: item.attributes.map(attribute => ({
          ...attribute,
          __typename: 'OrderProductAttribute',
        })),
        addOns: item.addOns.map(addOn => ({
          ...addOn,
          __typename: 'OrderProductAttribute',
        })),
        services: item.services.map(service => ({
          ...service,
          __typename: 'OrderProductAttribute',
        })),
        itemId: item.itemId || '',
        newProduct: {
          ...item.newProduct,
          __typename: 'RmaExchangeNewProduct',
          productCode: item.newProduct?.productCode || '',
          productName: item.newProduct?.productName || '',
        },
        overrides: (item.overrides || []).map(override => ({
          ...override,
          __typename: 'OverrideField',
        })),
      })),
      originalOrderNumber: order.originalOrderNumber || '',
    };
    return convertRmaOrderQueryToRmaOrder(rmaOrder);
  }
  return standardOrder;
};

export const convertOrderToLocalOrderInputWithCoupons = async (
  orderData: OrderQueryToOrderType,
  currentUserName: string
) => {
  let coupons: Array<Coupon> = [];
  if (isCreatedOrder(orderData)) {
    coupons = await fetchCouponsByCode(orderData.coupons || []);
  }
  return convertOrderToLocalOrderInput({
    orderData,
    coupons,
    currentUserName,
  });
};

/**
 * Calculates the discount amount based on the discount type and amount.
 *
 * @param params - The parameters for calculating the discount amount.
 *  discountType - The type of discount (e.g., Percentage).
 *  discountAmount - The amount of discount.
 *  amount - The original amount.
 *
 * @returns The calculated discount amount.
 */
export const getDiscountAmount = (params: { discountType: DiscountType; discountAmount: number; amount: number }) => {
  const { discountType, discountAmount, amount } = params;
  if (discountType === DiscountType.Percentage) {
    return +(amount * (discountAmount / 100)).toFixed(2);
  }
  return discountAmount;
};

/**
 * Converts a CreditItem object to a Discount object.
 * @param creditItem - The CreditItem to be converted.
 * @returns The converted Discount object.
 */
export const convertCreditItemToDiscount = (creditItem: CreditItem) => {
  const { creditType, discountAmount, productCode, orderItemId, value, description } = creditItem;
  const isPercentage = creditType === CreditType.Percentage;
  const data: Discount = {
    __typename: 'Discount',
    discountAmount: discountAmount || 0,
    productCode: productCode || '',
    discountType: isPercentage ? DiscountType.Percentage : DiscountType.Amount,
    value: value || 0,
    orderItemId: orderItemId || '',
    description: description || '',
  };
  return data;
};

export const convertDiscountToDiscountState = (
  discountItems: CreatedOrder['appliedCredits'] | CreatedOrder['appliedDiscounts']
) => {
  if (!discountItems) return [];
  const mappedDiscountItems = discountItems.flatMap(item => {
    if ('creditItems' in item) {
      return item.creditItems.map(convertCreditItemToDiscount);
    }
    return item.deductions;
  });

  return mappedDiscountItems.map(deduction => {
    const { discountAmount, productCode, discountType, value, orderItemId, description } = deduction;

    const orderItemIdValue = orderItemId || '';
    const descriptionValue = description || '';
    const addonAndServiceUniqueId = getAddonAndServiceUniqueId(orderItemIdValue, descriptionValue);
    const data: DiscountState = {
      isPercentage: discountType === DiscountType.Percentage,
      percentageValue: value || 0,
      dollarAmount: discountAmount || 0,
      orderItemId: orderItemId || '',
      productCode: productCode,
      description: description || '',
      itemId: hasDiscountForAddonAndService(productCode) ? addonAndServiceUniqueId : orderItemIdValue,
      errors: {
        itemId: false,
        percentageValue: false,
        dollarAmount: false,
      },
    };
    return data;
  });
};

export const getAddonAndServiceUniqueId = (orderItemId: string, description: string) => {
  return `${orderItemId}-${description}`.trim();
};

export const hasDiscountForAddonAndService = (productCode: string) => {
  return productCode === AttributeType.AddOn || productCode === AttributeType.Service;
};

/**
 * Calculates the total amount of discount applied to a specific item in an order.
 *
 * @param discountItems - The array of discount items applied to the order.
 * @param itemId - The ID of the item to calculate the discount for.
 * @returns The total amount of discount applied to the item.
 */
export const getAppliedDiscountAmount = (
  discountItems: CreatedOrder['appliedCredits'] | CreatedOrder['appliedDiscounts'],
  itemId: string
) => {
  if (!discountItems?.length || !itemId) return 0;

  const discounts = convertDiscountToDiscountState(discountItems) || [];

  // Filters the addon services discounts based on the provided criteria.
  const filteredDiscounts = discounts.filter(discount => {
    const addonUniqueId = getAddonAndServiceUniqueId(discount.orderItemId, discount.description);
    if (hasDiscountForAddonAndService(discount.productCode)) return addonUniqueId === itemId;
    return discount.orderItemId === itemId;
  });

  const appliedDiscountAmount = filteredDiscounts.reduce((amount, discount) => {
    return amount + discount.dollarAmount;
  }, 0);

  return appliedDiscountAmount;
};

export const convertDeductionToItems = (discounts: Deduction[], isCaseCreation: boolean) => {
  // return a format that fits a table
  const DEFAULT_ENTIRE_CASE_DISCOUNT: Discount = {
    __typename: 'Discount',
    discountAmount: 0,
    productCode: DISCOUNT_TYPE_LABELS.ENTIRE_CASE,
    discountType: DiscountType.Percentage,
    value: 100,
    orderItemId: DISCOUNT_TYPE_LABELS.ENTIRE_CASE,
    description: DISCOUNT_TYPE_LABELS.ENTIRE_CASE,
  };
  const getDeduction = (deduction: Deduction, discount: Discount): CaseDeductionItem => {
    const isEntireCase = deduction.type === CaseDiscountType.EntireCase;
    const isOutboundShipping = discount.orderItemId === PART_OF_CASE_DISCOUNT_TYPE.OUTBOUND_SHIPPING;

    // By Default, the discount type is amount and the amount is the discount amount.
    let amount = discount.discountAmount;
    let discountType = DiscountType.Amount;

    const isCaseCreationWithPercentageAmount = isCaseCreation && discount.discountType === DiscountType.Percentage;

    /**
     * If the discount is an entire case discount or an outbound shipping discount, the discount type and amount are taken from the discount object.
     */
    if (isEntireCase || isOutboundShipping || isCaseCreationWithPercentageAmount) {
      discountType = discount.discountType;
      amount = discount.value;
    }

    return {
      discountId: deduction.discountId || '',
      orderItemId: discount.orderItemId || '',
      description: discount.description || '',
      amount: formatDeductionAmount(amount, discountType === DiscountType.Percentage),
      reason: deduction.reason || '',
      notes: deduction.notes || '',
      addedBy: deduction.createdBy || '',
      dateApplied: new Date(deduction.createdDate).toLocaleDateString('en-US'),
    };
  };

  return discounts.flatMap(discount => {
    const isEntireCase = discount.type === CaseDiscountType.EntireCase;

    if (isEntireCase) {
      return getDeduction(discount, DEFAULT_ENTIRE_CASE_DISCOUNT);
    }

    return discount.deductions.map(deduction => getDeduction(discount, deduction));
  });
};

/**
 * Converts an array of credits into an array of credit items.
 * @param credits - The array of credits to convert.
 * @returns An array of credit items.
 */
export const convertCreditToItems = (credits: Credit[]) => {
  const getCredit = (credit: Credit, amount: string, creditItem?: CreditItem): CaseCreditItem => {
    const reason = creditItem?.reason || credit.reason || '';
    return {
      creditNumber: credit.creditNumber || '',
      amount: amount,
      reason: reason,
      notes: credit.notes || '',
      createdBy: credit.createdBy || '',
      createdDate: new Date(credit.createdDate).toLocaleDateString('en-US'),
      status: 'Credit Applied',
      couponCode: creditItem?.couponCode ?? credit?.couponCode ?? undefined,
    };
  };

  return credits.flatMap(credit => {
    const isEntireCase = credit.caseDiscountType === CaseDiscountType.EntireCase;
    if (isEntireCase) {
      const amountValue = formatDeductionAmount(credit.totalAmount);
      return getCredit(credit, amountValue);
    }

    return credit.creditItems.map(creditItem => {
      // the calculation for the total amount(including taxes) is already done in the backend,
      // we can just use the total amount.

      const amountValue = formatDeductionAmount(creditItem.totalAmount);
      return getCredit(credit, amountValue, creditItem);
    });
  });
};

/**
 * Retrieves the status and status reason details from the old and new data objects.
 * @param oldData - The old data object containing the case history additional details.
 * @param newData - The new data object containing the case history additional details.
 * @returns An object containing the status and status reason details from the old and new data objects.
 */
export const getStatusDetailRecord = (
  oldData: CaseHistoryAdditionalDetailsObject,
  newData: CaseHistoryAdditionalDetailsObject
) => {
  const result = {
    new: {
      status: '',
      statusReason: '',
    },
    old: {
      status: '',
      statusReason: '',
    },
  };
  if ('Status' in oldData) {
    result.old.status = oldData.Status || 'N/A';
  }
  if ('StatusReason' in oldData) {
    result.old.statusReason = oldData.StatusReason || 'N/A';
  }
  if ('Status' in newData) {
    result.new.status = newData.Status || 'N/A';
  }
  if ('StatusReason' in newData) {
    result.new.statusReason = newData.StatusReason || 'N/A';
  }
  return result;
};

/**
 * Returns a record containing the additional details that have changed between the oldData and newData objects.
 * @param oldData - The old data object.
 * @param newData - The new data object.
 * @param utcConversionTimeZoneCode - The timezone for datetime conversion
 * @returns A record containing the additional details that have changed, with each key representing the changed detail and its corresponding value containing the name, new value, and old value.
 */
export const getAdditionalDetailFallbackRecord = (
  oldData: Record<string, unknown> | null | undefined,
  newData: Record<string, unknown> | null | undefined,
  excludeKeys: string[] = [],
  utcConversionTimeZoneCode: LocalCaseHistoryItem['utcConversionTimeZoneCode']
) => {
  const localOldData = oldData || {};
  const localNewData = newData || {};
  const additionalDetailFallbackRecord: Record<string, AdditionalDetailOldAndNewValueItem> = {};
  const newDataKeys = Object.keys(localNewData);
  const oldDataKeys = Object.keys(localOldData);
  const allKeys = new Set([...newDataKeys, ...oldDataKeys]);

  allKeys.forEach(key => {
    if (excludeKeys.includes(key)) return; // Skip the key if it is in the excludeKeys array.
    const newValue = localNewData[key];
    const oldValue = localOldData[key];
    // If the new value is not equal to the old value, add the key to the additionalDetailFallbackRecord.
    if (newValue !== oldValue) {
      additionalDetailFallbackRecord[key] = {
        name: startCase(key),
        newValue: newValue || 'N/A',
        oldValue: oldValue || 'N/A',
        utcConversionTimeZoneCode: utcConversionTimeZoneCode,
      };
    }
  });
  return additionalDetailFallbackRecord;
};

/**
 * Returns the quantity of the product
 *
 * @param product - The product for which quantities are to be calculated.
 *
 * @returns number - The quantity associated with the order item / product.
 */
export const getProductQuantity = (product: CreatedOrderItem): number => {
  return product.quantity;
};

/**
 * Calculates and returns various units related to a product's attributes and add-ons.
 *
 * @param product - The product for which units are to be calculated.
 *
 * @returns object of various units related to the provided product as follows:
 *
 *   productUnits: number,         // The quantity associated with the order item / product.
 *   totalUnits number,            // The product quantity and additional tooth quantity.
 *   toothStringUnits: number,     // The amount of teeth in the tooth string.
 *   additionalToothUnits: number, // The units representing additional teeth in add-ons.
 *   missingToothUnits: number     // The units representing missing teeth in add-ons.
 */
export const getProductUnits = (product: CreatedOrderItem) => {
  const toothStringUnits =
    getCaseItemAttributeByAttributeName(product, AttributeName.ToothString)?.value.split(',').length || 0;
  const additionalToothUnits =
    getCaseAddOnOrServiceByProperty(product, AttributeName.AdditionalTooth, 'addOns')?.quantity || 0;
  const missingToothUnits =
    getCaseAddOnOrServiceByProperty(product, AttributeName.MissingTooth, 'addOns')?.value.split(',').length || 0;

  const productUnits = product.toothUnits;
  const totalUnits = (productUnits || 0) + additionalToothUnits;

  return {
    productUnits,
    totalUnits,
    toothStringUnits,
    additionalToothUnits,
    missingToothUnits,
  };
};

/**
 * Checks if the value of any order property has changed between the initial order and the current order.
 * @param initialOrder - The initial order object.
 * @param currentOrder - The current order object.
 * @returns True if any order property value has changed, false otherwise.
 */
export const checkOrderValueChanged = (initialOrder: LocalOrderInput, currentOrder: LocalOrderInput) => {
  if (!initialOrder || !currentOrder) return false;
  return ORDER_INPUT_VALIDATION_KEYS.some(key => {
    if (key === 'orderItems') {
      const initialOrderItems = initialOrder[key];
      const currentOrderItems = currentOrder[key];
      if (initialOrderItems.length !== currentOrderItems.length) return true;
      const initialItems = removeIdFromItems(initialOrderItems);
      const currentItems = removeIdFromItems(currentOrderItems);
      return JSON.stringify(initialItems) !== JSON.stringify(currentItems);
    }
    return JSON.stringify(initialOrder[key]) !== JSON.stringify(currentOrder[key]);
  });
};

export const addOnsAndServicesText = (addOns: OrderProductAttribute[], services: OrderProductAttribute[]) => {
  const mergedArray = [...(addOns || []), ...(services || [])];
  return mergedArray.map(item => `${item.name}: ${item.value}`).join(', ');
};

/**
 * Filters the deductions by order item ID and optional discount type.
 *
 * @param appliedDiscounts - The array of applied discounts.
 * @param orderItemId - The ID of the order item.
 * @param discountType - The optional discount type.
 * @returns The filtered array of deductions.
 */
export const filterDeductionsByOrderItemId = (
  appliedDiscounts: Order['appliedDiscounts'],
  orderItemId: string,
  discountType?: DiscountType
) => {
  if (!appliedDiscounts?.length) return [];
  // combine all deductions array into a single array
  const deductions = appliedDiscounts.flatMap(discount => discount.deductions);
  // filter deductions for outbound shipping
  const filteredDeductions = deductions.filter(deduction => {
    const isSameOrderItemId = deduction.orderItemId === orderItemId;
    // if discountType is provided, filter deductions by orderItemId and discountType
    if (discountType) {
      return isSameOrderItemId && deduction.discountType === discountType;
    }
    // if discountType is not provided, filter deductions by orderItemId only
    return isSameOrderItemId;
  });
  return filteredDeductions;
};

/**
 * Calculates the outbound discount percentage based on the applied discounts.
 *
 * @param appliedDiscounts - The array of applied discounts for the order.
 * @returns The calculated outbound discount percentage.
 */
export const getOutboundDiscountPercentage = (appliedDiscounts: Order['appliedDiscounts']) => {
  const filteredDeductions = filterDeductionsByOrderItemId(
    appliedDiscounts,
    PART_OF_CASE_DISCOUNT_TYPE.OUTBOUND_SHIPPING
  );
  if (!filteredDeductions.length) return 0;
  // calculate the total discount percentage
  let totalDiscountPercentage = filteredDeductions.reduce((acc, deduction) => acc + deduction.value, 0);
  // if the total discount percentage is greater than 100, cap it to 100
  totalDiscountPercentage = totalDiscountPercentage > 100 ? 100 : totalDiscountPercentage; // Cap the discount percentage to 100%
  return roundDecimal(totalDiscountPercentage);
};

/**
 * Calculates the outbound discount amount based on the outbound amount and discount percentage.
 * @param outboundAmount - The outbound amount.
 * @param discountPercentage - The discount percentage.
 * @returns The calculated outbound discount amount.
 */
export const getOutboundDiscountAmount = (outboundAmount: number, discountPercentage: number) => {
  // calculate the outbound discount amount
  const outboundDiscountAmount = outboundAmount * (discountPercentage / 100);
  return roundDecimal(outboundDiscountAmount < 0 ? 0 : outboundDiscountAmount);
};

/**
 * Returns a unique item ID to differentiate between potentially similar discount items.
 * When orders have multiple selections of the same product, the dropdown used to have duplicates.
 * Please see LMS1-6620.
 * @param description - The description (label) of the discount item.
 * @param itemId - The optional unique item ID.: string
 * @returns a unique item ID to differentiate between potentially similar discount items.
 */
export const uniqueDiscountItem = (description: string, itemId?: string) => {
  // In LMS1-8370 we established with the UX team that the UI should only show the last 4 characters of the item id.
  const itemIdAppend = itemId && itemId !== description ? ` (${itemId.slice(-4)})` : '';
  return `${description}${itemIdAppend}`;
};
