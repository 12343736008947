import { CaseDiscountType, CreateDeductionInput, Deduction, DiscountType } from 'API';
import moment from 'moment';
import { DiscountState, SpecialDiscountReason } from 'stores/useAddDiscountModalStore/createSpecialDiscountSlice';
import { DiscountForRemove } from 'types/common';
const utcConversionTimeZoneCode = moment.tz.guess();
/**
 * Generates the payload for updating a discount.
 *
 * @param discount - The discount object to be updated.
 * @param discountForRemove - The discount deduction that should be removed, if applicable.
 * @param selectedDiscountType - The type of discount being applied.
 * @returns An object containing the discount ID and the input payload for the update request.
 */
export const getUpdateDiscountPayload = (
  discount: Deduction,
  discountForRemove: DiscountForRemove | undefined,
  selectedDiscountType: CaseDiscountType
) => {
  const filteredDeductions = getUpdateDiscountDeductions(discount, discountForRemove);
  const deductions = filteredDeductions.map(item => {
    return {
      ...item,
      description: item.description || '',
      orderItemId: item.orderItemId || '',
    };
  });
  const { discountId, __typename, ...newDiscount } = discount;
  return {
    discountId,
    input: {
      ...newDiscount,
      type: selectedDiscountType,
      deductions,
      utcConversionTimeZoneCode,
    },
  };
};

/**
 * Filters the deductions for an updated discount by removing the specified deduction.
 *
 * @param discount - The discount object containing deductions.
 * @param discountForRemove - The deduction that should be removed, if applicable.
 * @returns A filtered array of deductions excluding the one that matches discountForRemove.
 */
export const getUpdateDiscountDeductions = (discount: Deduction, discountForRemove: DiscountForRemove | undefined) => {
  return discount.deductions.filter(item => item.description !== discountForRemove?.description);
};

/**
 * Generates a new discount input object.
 *
 * @param user - The user applying the discount.
 * @param selectedDiscountType - The selected discount type.
 * @param selectedDiscounts - The list of selected discounts.
 * @param note - Additional notes for the discount.
 * @param orderNumber - The order number where the discount is applied.
 * @param selectedReason - The reason for applying the discount.
 * @returns A formatted CreateDeductionInput object.
 */
export const getNewDiscountInput = (
  createdBy: string | undefined,
  selectedDiscountType: CaseDiscountType,
  selectedDiscounts: DiscountState[],
  note: string,
  orderNumber?: string,
  selectedReason?: SpecialDiscountReason
): CreateDeductionInput => {
  return {
    createdBy: createdBy || '',
    createdDate: new Date().toISOString(),
    type: selectedDiscountType,
    deductions: selectedDiscounts.map(item => ({
      orderItemId: item.orderItemId,
      discountType: item.isPercentage ? DiscountType.Percentage : DiscountType.Amount,
      discountAmount: item.dollarAmount,
      productCode: item.productCode,
      value: item.percentageValue,
      description: item.description,
    })),
    notes: note,
    orderNumber: orderNumber || '',
    reason: selectedReason?.value || '',
    utcConversionTimeZoneCode: utcConversionTimeZoneCode || '',
  };
};

/**
 * Generates an analytics object for applied discounts.
 *
 * @param createdBy - The user who applied the discounts.
 * @param selectedDiscountType - The type of discount being applied.
 * @param selectedDiscounts - The list of selected discounts.
 * @param note - Additional notes related to the discount.
 * @param orderNumber - The order number where the discount is applied (optional).
 * @param selectedReason - The reason for the discounts (optional).
 * @returns An object containing discounts analytics data.
 */
export const getApplyDiscountAnalytics = (
  createdBy: string | undefined,
  selectedDiscountType: CaseDiscountType,
  selectedDiscounts: DiscountState[],
  note: string,
  orderNumber?: string,
  selectedReason?: SpecialDiscountReason
): {
  caseNumber: string;
  discountType: CaseDiscountType;
  deductionReason: string;
  deductionTypes: string[];
  createdBy: string;
  notes: string;
} => {
  return {
    caseNumber: orderNumber || '',
    discountType: selectedDiscountType,
    deductionReason: selectedReason?.value || '',
    deductionTypes: selectedDiscounts.reduce((acc: string[], item) => {
      const deductionType = item.isPercentage ? DiscountType.Percentage : DiscountType.Amount;
      return acc.includes(deductionType) ? acc : [...acc, deductionType];
    }, []),
    createdBy: createdBy || '',
    notes: note,
  };
};
