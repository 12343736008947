import { FC } from 'react';
import { cn } from 'shared/utils';
import { DisplayDiscountItemType } from 'types/common';
import { DisplayDiscountItem } from './DisplayDiscountItem';

/**
 * Props for the DisplayDiscountItems component.
 */
interface DisplayDiscountItemsProps {
  /**
   * An array of items to be displayed.
   */
  items: DisplayDiscountItemType[];

  /**
   * Function to handle deletion of a discount item.
   * @param index - The index of the item to be deleted which is been used on case creation and single order.
   * @param caseId - case id is passed to use in the bundle cases order cases
   * @param discountCode - discountCode is passed to use in the bundle cases order cases
   */
  onDelete?: (index: number, caseId: string, discountCode: string) => void;

  /**
   * Additional CSS class for styling.
   */
  className?: string;
  /**
   * Boolean flag indicating whether to show the case ID.
   */
  showCaseId?: boolean;
  /**
   * Boolean flag indicating whether to show left side loading indicator.
   */
  showLoading?: boolean;
}

/**
 * Component to display a list of discount items.
 * @param items - An array of items to be displayed.
 * @param onDelete - Function to handle deletion of a discount item.
 * @param className - Additional CSS class for styling.
 * @returns JSX.Element
 */
export const DisplayDiscountItems: FC<DisplayDiscountItemsProps> = ({
  items,
  onDelete,
  className,
  showCaseId,
  showLoading,
}) => {
  if (!items.length) return null;

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {items.map((item, index) => (
        <DisplayDiscountItem
          key={index}
          {...item}
          showCaseId={showCaseId}
          onDelete={onDelete ? () => onDelete(index, item.caseId, item.discountCode) : undefined}
          showLoading={showLoading}
        />
      ))}
    </div>
  );
};
