import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { Coupon } from 'API';
import Input from 'components/common/Input/Input';
import InputError from 'components/common/InputError';
import Loader from 'components/common/Loader/Loader';
import { usePrevious } from 'hooks/use-previous';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { couponLookup } from 'shared/api/discount.api';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { CreatedOrder, DropdownModel } from 'shared/models';
import { DisplayDiscountItemType } from 'types/common';
import { CommonActionButtons } from '../CommonActionButtons/CommonActionButtons';
import Dropdown from '../Dropdown/Dropdown';
import Modal from '../Modal';

/**
 * Props for the CouponInput component.
 */
interface BundleCouponInputContentProps {
  /** The billing account ID. */
  billingAccountId: string;
  /** The provider ID. */
  providerId: string;
  /** Callback function to update the coupon. */
  onUpdateCoupon: (coupon: Coupon | null, caseId: string) => void;
  /** Coupons string array to validate already applied coupon */
  coupons: DisplayDiscountItemType[];
  /** bundle cases*/
  bundledCases: CreatedOrder[] | [];
  /** Error message to display. */
  errorMessage?: string;
}

/**
 * Component for handling coupon input.
 *
 * @param isLoading - Indicates if the component is in a loading state.
 * @param billingAccountId - The billing account ID.
 * @param providerId - The provider ID.
 * @param resetCouponAfterValid - Indicates if the coupon should be reset after being validated.
 * @param onUpdateCoupon - Callback function to update the coupon.
 * @param errorMessage - Error message to display.
 * @param coupons - Coupons string array to validate already applied coupon.
 * @param bundledCases - bundle cases
 * @returns JSX.Element - The rendered component.
 */
export const BundleCouponInput: React.FC<BundleCouponInputContentProps> = ({
  onUpdateCoupon,
  errorMessage: initialErrorMessage,
  billingAccountId,
  providerId,
  coupons,
  bundledCases,
}) => {
  const { fetcher, loading } = useLazyQueryFetcher(couponLookup);
  const [errorMessage, setErrorMessage] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [showModal, openModal] = useState(false);
  const onUpdateCouponRef = useRef(onUpdateCoupon);
  const couponTypingRef = useRef(false);
  const lastProvider = usePrevious(providerId);
  const [selectedCase, setSelectedCase] = useState('');
  const [fetchedCoupon, setFetchedCoupon] = useState<Coupon | undefined>(undefined);

  useEffect(() => {
    onUpdateCouponRef.current = onUpdateCoupon;
  }, [onUpdateCoupon]);

  useEffect(() => {
    setErrorMessage(initialErrorMessage ?? '');
  }, [initialErrorMessage]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    setCouponCode(value);
    setErrorMessage('');
    setIsValidCoupon(false);
    onUpdateCoupon(null, '');
    couponTypingRef.current = true;
  };

  const handleBlur = () => {
    couponTypingRef.current = false;
  };

  const fetchCoupon = useCallback(
    async (couponCode: string) => {
      if (!billingAccountId || !providerId) return setErrorMessage('Please select a provider');
      if (!selectedCase) return setErrorMessage('Please select a case first');
      if (!couponCode) return setErrorMessage('Please enter a coupon code');
      const alreadyExist = coupons.some(coupon => coupon.caseId === selectedCase && coupon.discountCode === couponCode);
      if (alreadyExist) {
        return setErrorMessage('The entered coupon is already added.');
      }
      try {
        setErrorMessage('');
        const response = await fetcher({ couponCode });
        setFetchedCoupon(response);
        setIsValidCoupon(true);
      } catch (err) {
        const error = err as Error;
        if (error.name === 'NotFoundError') {
          setErrorMessage('Invalid coupon');
        } else {
          setErrorMessage('Error fetching coupon');
        }
        setIsValidCoupon(false);
      }
    },
    [billingAccountId, providerId, fetcher, coupons, selectedCase]
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchCoupon(couponCode);
  };

  const cases = bundledCases.map(item => {
    return {
      value: item.orderNumber,
      primaryLabel: item.orderNumber,
    };
  });

  const onApplyDiscount = () => {
    if (!fetchedCoupon) return;
    onUpdateCouponRef.current(fetchedCoupon, selectedCase);
    setCouponCode('');
  };
  const selectedCaseOption: DropdownModel = useMemo(() => {
    return {
      value: selectedCase,
      primaryLabel: selectedCase,
    };
  }, [selectedCase]);
  /**
   * Triggers `fetchCoupon` when `couponCode` changes, ensuring unnecessary calls are avoided.
   *
   * - Prevents execution if the user is still typing (`couponTypingRef.current`).
   * - Ensures `fetchCoupon` is only called when the `couponCode` is available.
   * - Avoids redundant calls by checking if `providerId` remains the same as `lastProvider`(fix: LMS1-8501 - Prevents unnecessary API calls when switching providers).
   *
   */
  useEffect(() => {
    if (couponTypingRef.current || !couponCode || lastProvider === providerId) return;
    fetchCoupon(couponCode);
  }, [fetchCoupon, couponCode, lastProvider, providerId]);

  const bundleCouponModal = () => {
    return (
      <Modal onClose={() => openModal(false)} width="w-1/4">
        <>
          <div className="p-6">
            <p className="text-lg font-medium mb-4">Add Coupon to Case</p>
            <Dropdown
              isRequired
              label="Select a case"
              selected={selectedCaseOption}
              setSelected={option => {
                setSelectedCase(option.value);
              }}
              data={cases}
            />
            <form onSubmit={handleSubmit} data-testid="couponForm" className="mt-6">
              <Input
                id="couponCode"
                name="couponCode"
                type="text"
                value={couponCode}
                placeholder="Enter coupon code"
                onChange={handleChange}
                isInvalid={!!errorMessage}
                label="Coupon"
                disabled={loading}
                onBlur={handleBlur}
                icon={
                  isValidCoupon && couponCode ? (
                    <CheckBadgeIcon className="h-5 text-green-500" />
                  ) : (
                    <Loader show={loading} className="text-white" spin />
                  )
                }
                showIcon={loading || isValidCoupon}
                className={!errorMessage ? 'shadow-sm border-gray-200' : undefined}
              />
              <InputError message={errorMessage} testId="coupon" />
            </form>
          </div>
          <CommonActionButtons
            onClose={() => openModal(false)}
            onApply={onApplyDiscount}
            disableApply={loading || !isValidCoupon}
          />
        </>
      </Modal>
    );
  };
  return (
    <>
      <button onClick={() => openModal(true)} className="font-medium inline-flex text-indigo-600 leading-5 text-sm ">
        Add A Coupon Code
      </button>
      {showModal && bundleCouponModal()}
    </>
  );
};
