import { Coupon, CouponStatus, Credit, Deduction, OrderStatus } from 'API';
import { Table } from 'components/common/Table/Table';
import { useCaseDetail } from 'providers/CaseDetailModuleProvider';
import { getCaseCouponColumns } from 'shared/constants/case-detail.constants';
import { getCouponTooltipComponent } from 'shared/helpers/invoice/invoice.helper';
import CaseCredits from './CaseCredits/CaseCredits';
import CaseDiscounts from './CaseDiscounts/CaseDiscounts';

/**
 * Props of the CaseDeductions component.
 */
interface CaseDeductionsProps {
  /**
   * An array of coupons.
   */
  coupons: Coupon[];
  /**
   * An array of discounts.
   */
  discounts: Deduction[];
  /**
   * An array of credits.
   */
  credits: Credit[];
  /**
   * The order status.
   */
  orderStatus?: OrderStatus;
  /**
   * A function to refresh the order.
   */
  orderRefresh: () => void;
  /**
   * A boolean indicating whether the order is processing.
   */
  isOrderProcessing: boolean;
}

/**
 * Component for displaying deductions related to a case.
 * @param coupons - An array of coupons.
 * @param discounts - An array of discounts.
 * @param credits - An array of credits.
 * @param orderStatus - The order status.
 * @param orderRefresh - A function to refresh the order.
 * @param isOrderProcessing - A boolean indicating whether the order is processing.
 * @returns A JSX element representing the CaseDeductions component.
 */
const CaseDeductions: React.FC<CaseDeductionsProps> = ({
  coupons,
  discounts,
  credits,
  orderStatus,
  orderRefresh,
  isOrderProcessing,
}) => {
  const { caseDetails } = useCaseDetail();

  return (
    <div className="p-6">
      <CaseDiscounts discounts={discounts} orderStatus={orderStatus} isOrderProcessing={isOrderProcessing} />
      <div className="mb-6">
        <Table
          isApiPaginated={false}
          pageSize={10}
          label="Coupon"
          columns={getCaseCouponColumns(caseDetails?.utcConversionTimeZoneCode)}
          rows={coupons}
          loading={false}
          showPagination={coupons.length > 10}
          hoverRow
          getRowTooltip={(coupon: Coupon) => getCouponTooltipComponent(coupon)}
          getIsRowDisabled={(coupon: Coupon) => coupon.status !== CouponStatus.Applied}
        />
      </div>
      <CaseCredits
        credits={credits}
        orderStatus={orderStatus}
        orderRefresh={orderRefresh}
        isOrderProcessing={isOrderProcessing}
      />
    </div>
  );
};

export default CaseDeductions;
