import { FC } from 'react';
import { cn } from 'shared/utils';

/**
 * Props for the ToolTip component.
 */
interface ToolTipProps {
  /**
   * The tooltip text to display.
   */
  text: string;
  /**
   * Additional class names for styling.
   */
  className?: string;
}

/**
 * Component for displaying a tooltip.
 * @param text - The tooltip text to display.
 * @param className - Additional class names for styling.
 * @param children - Optional children components.
 * @returns JSX.Element representing the ToolTip component.
 */
export const ToolTip: FC<ToolTipProps> = ({ text, className, children }) => {
  return (
    <div
      className={cn(
        'absolute left-0 bottom-full pointer-events-none items-center bg-gray-300 text-black text-md opacity-0 transition-opacity group-hover:opacity-100 rounded-md p-2 z-50',
        className
      )}
    >
      <div className="flex flex-col">{text}</div>
      {children}
    </div>
  );
};
