import { Order, OrderNotificationType } from 'API';
import { useToast } from 'providers/ToastProvider';
import { Fragment, useEffect, useRef } from 'react';
import { ToastNotificationIds, ToastNotificationType } from 'shared/enums';
import { checkPriceOrTaxBeingCalculated, getPricingMessage } from 'shared/helpers/util.helper';
import { useOrderNotificationAvailable } from 'shared/hooks/useOrderNotificationAvailable';
import { AlertMessage } from '../Alert/AlertMessage';

/**
 * Props for the OrderProcessingNotification component.
 */
interface OrderProcessingNotificationProps {
  /** Metadata of the order. */
  orderMetaData: Order['metadata'];
  /** Order number. */
  orderNumber: string;
  /** Callback function to refresh the order. */
  onRefresh?: () => void;
  /** Boolean indicating whether to hide the alert. */
  isAlertMessageHidden?: boolean;
  /** Indicates where we are using a toast message instead of an HTML component */
  isUsingToast?: boolean;
}

/**
 * Component to display processing notification for an order.
 *
 * @param orderMetaData - Metadata of the order.
 * @param orderNumber - Order number.
 * @param onRefresh - Callback function to refresh the order.
 * @param isUsingToast - Indicates where we are using a toast message instead of an HTML component.
 * @returns JSX.Element - The rendered component.
 */
export const OrderProcessingNotification: React.FC<OrderProcessingNotificationProps> = ({
  orderMetaData,
  orderNumber,
  onRefresh,
  isUsingToast = false,
}) => {
  const { orderNotificationEvent, resetNotificationEvent } = useOrderNotificationAvailable(orderNumber);
  const isOrderProcessing = checkPriceOrTaxBeingCalculated(orderMetaData);
  const toast = useToast();
  const onRefreshRef = useRef(onRefresh);

  const isPricingBeingCalculated = orderMetaData?.isPricingBeingCalculated || false;
  const isTaxBeingCalculated = orderMetaData?.isTaxBeingCalculated || false;

  const messageToShow = getPricingMessage(isPricingBeingCalculated, isTaxBeingCalculated);

  useEffect(() => {
    onRefreshRef.current = onRefresh;
  }, [onRefresh]);

  useEffect(() => {
    if (!orderNotificationEvent) return;

    if (orderNotificationEvent === OrderNotificationType.PricingCalculated) {
      //remove adjusting price toast when price is processed and calculated
      toast.remove(ToastNotificationIds.PRICE_CALCULATION);
      toast.notify("Case's pricing calculation is completed", ToastNotificationType.Success, {
        duration: 2000,
      });
    } else if (orderNotificationEvent === OrderNotificationType.TaxCalculated) {
      toast.remove(ToastNotificationIds.TAX_CALCULATION);
      toast.notify("Case's tax calculation is completed", ToastNotificationType.Success, {
        duration: 2000,
      });
    }
    resetNotificationEvent();
    onRefreshRef.current?.();
  }, [orderNotificationEvent, toast, resetNotificationEvent]);

  useEffect(() => {
    if ((isPricingBeingCalculated || isTaxBeingCalculated) && messageToShow && isUsingToast) {
      // Show until a message comes back.
      toast.notify(messageToShow, ToastNotificationType.Info, {
        duration: Infinity,
        id: isTaxBeingCalculated ? ToastNotificationIds.TAX_CALCULATION : ToastNotificationIds.PRICE_CALCULATION,
      });
    }
  }, [isPricingBeingCalculated, isTaxBeingCalculated, isUsingToast, messageToShow, toast]);

  return isOrderProcessing && !isUsingToast ? (
    <AlertMessage message={messageToShow} type={ToastNotificationType.Info} className="w-full" />
  ) : (
    <Fragment></Fragment>
  );
};
