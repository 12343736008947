import { NoSymbolIcon } from '@heroicons/react/24/outline';
import { OrderStatus } from 'API';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import InputError from 'components/common/InputError';
import Radio from 'components/common/Radio/Radio';
import { useAuth } from 'providers/AuthProvider';
import { useCaseDetail } from 'providers/CaseDetailModuleProvider';
import { useToast } from 'providers/ToastProvider';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { updateOrderStatus } from 'shared/api/order.api';
import { CancelActivity, cancelActivities } from 'shared/constants/case-detail.constants';
import { ToastNotificationType } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { getCaseAndReturnType } from 'shared/helpers/analytics.helper';
import { getInvoiceCreatePath } from 'shared/helpers/route.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { AnalyticsService } from 'shared/services/analytics.service';

interface CancelCaseModalProps {
  showModal: boolean;
  onCloseModal: () => void;
}

const CancelCaseModal: React.FC<CancelCaseModalProps> = ({ showModal, onCloseModal }) => {
  const { id: orderNumber } = useParams();

  const [cancelSelect, setCancelSelect] = useState<CancelActivity | undefined>();
  const [requiredMessage, setRequiredMessage] = useState<boolean>(false);
  const onAttributeChange = (value: CancelActivity) => {
    setCancelSelect(value);
    setRequiredMessage(false);
  };
  const { fetcher, loading } = useLazyQueryFetcher(updateOrderStatus);
  const { setCaseDetails, caseDetails: order } = useCaseDetail();
  const navigate = useNavigate();

  const { user } = useAuth();
  const currentUserName = user?.displayName || '';

  const toast = useToast();

  const handleCaseCancel = async () => {
    if (!orderNumber) return toast.notify('Invalid order number', ToastNotificationType.Error);
    if (cancelSelect) {
      try {
        // Maps the radio button selection to its label, which will be sent as the status reason to the service.
        const statusReason = Object.values(cancelActivities).find(
          activity => activity.value === CancelActivity[cancelSelect].toString()
        )?.label;

        const cancelCaseResponse = await fetcher({
          orderNumber: orderNumber,
          orderStatus: OrderStatus.Cancelled,
          statusReason: statusReason,
          updatedBy: currentUserName,
        });

        const { caseType, returnType } = getCaseAndReturnType(order);

        AnalyticsService.track(AnalyticsEventName.CaseCanceled, {
          caseNumber: orderNumber,
          caseStatus: cancelCaseResponse.status,
          reason: statusReason || '',
          caseType,
          ...(!!returnType?.length && { returnType }),
        });

        if (order) {
          setCaseDetails({
            ...order,
            status: cancelCaseResponse.status,
            statusReason: cancelCaseResponse.statusReason,
          });
        }

        if (cancelSelect === CancelActivity.BackToCustomer) {
          navigate({
            pathname: getInvoiceCreatePath(orderNumber),
          });
        } else {
          onCloseModal();
        }
      } catch (err) {
        const error = err as Error;
        toast.notify(error.message, ToastNotificationType.Error);
      }
    } else {
      return toast.notify('Cancel Option Selection needed', ToastNotificationType.Error);
    }
  };

  const onConfirm = () => {
    if (cancelSelect) {
      setRequiredMessage(false);
      handleCaseCancel();
    } else {
      setRequiredMessage(true);
    }
  };

  return (
    <>
      {showModal && (
        <DialogBox
          title="Cancel this case"
          icon={<NoSymbolIcon className="h-6 w-6 text-red-600" />}
          cancelText="Go Back"
          onCancel={() => {
            setRequiredMessage(false);
            setCancelSelect(undefined);
            onCloseModal();
          }}
          confirmText="Cancel Case"
          onConfirm={onConfirm}
          confirmButtonDisabled={loading}
        >
          <div className="mt-2">
            <p className="text-sm text-gray-500 max-w-xl mb-6">
              By customer request only.
              {/* TODO: Will be added in 2.0. */}
              {/* This case be reactivated at a later time if the customer changes their mind. */}
            </p>
            <Radio
              id="cancelSelect"
              value={cancelSelect ? CancelActivity[cancelSelect].toString() : ''}
              label="Select an option"
              options={cancelActivities}
              onValueChange={value => onAttributeChange(CancelActivity[value as keyof typeof CancelActivity])}
              isRequired={true}
              border={false}
            />
            {requiredMessage && <InputError message="A selection is required" />}
            <p className="text-sm max-w-xl mt-4 mb-2">
              NOTE: If the case was created by accident or is a duplicate, please go back and select DELETE CASE
              instead.
            </p>
          </div>
        </DialogBox>
      )}
    </>
  );
};

export default CancelCaseModal;
