import { CaseDiscountType, Coupon, CouponStatus, Deduction, ListCategory, ListItem } from 'API';
import { CouponInput } from 'components/common/CouponInput/CouponInput';
import Label from 'components/common/Label';
import { SkeletonBox } from 'components/common/Skeleton';
import { DisplayDiscountItems } from 'components/SpecialDiscount/DisplayDiscountItems/DisplayDiscountItems';
import { CaseDetailModuleContext } from 'providers/CaseDetailModuleProvider';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getCategoryList } from 'shared/api/category-list.api';
import { CreditReason } from 'shared/enums/credit-reasons';
import { getDeductionType } from 'shared/helpers/coupon.helper';
import { getCouponText } from 'shared/helpers/invoice/invoice.helper';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { CreatedOrder } from 'shared/models';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { getDefaultDiscountData } from 'stores/useAddDiscountModalStore/createSpecialDiscountSlice';
import { QueryFetchTemplate } from 'templates/QueryFetchTemplate/QueryFetchTemplate';
import { DisplayDiscountItemType } from 'types/common';
import { DiscountModalType } from '../types';
import { ActionButtons } from './ActionButtons/ActionButtons';
import { DiscountFor } from './DiscountFor/DiscountFor';
import { Notes } from './Notes/Notes';
import { ReasonForDiscount } from './ReasonForDiscount/ReasonForDiscount';
import { SelectCaseFromBundle } from './SelectCaseFromBundle/SelectCaseFromBundle';

/**
 * Renders a component with input for Coupon and Discount items
 * @returns JSX.Element - Coupon and Discount Items component
 */
const CouponInputContent: React.FC = () => {
  const { caseDetails } = useContext(CaseDetailModuleContext);
  const setCouponCode = useAddDiscountModalStore(state => state.specialDiscount.setCouponCode);

  const { orderNumber, billingAccountId, providerId } = caseDetails || {};

  const [coupon, setCoupon] = useState<Coupon | undefined>(undefined);

  const handleCouponChange = (coupon: Coupon | null) => {
    if (!coupon) return;

    setCouponCode(coupon.code);
    setCoupon(coupon);
  };

  const handleCouponRemoval = () => {
    setCouponCode(undefined);
    setCoupon(undefined);
  };

  const couponItems = useMemo<DisplayDiscountItemType[]>(() => {
    if (!coupon) return [];
    return [
      {
        caseId: orderNumber || '',
        status: CouponStatus.Applied,
        discountAmount: coupon.discountAmount,
        discountCode: coupon.code,
        discountText: getCouponText(coupon, false),
      },
    ];
  }, [coupon, orderNumber]);

  return (
    <div className="w-full sm:w-80">
      <Label required>Coupon</Label>
      <CouponInput
        providerId={providerId || ''}
        billingAccountId={billingAccountId || ''}
        onUpdateCoupon={handleCouponChange}
        coupons={[...(coupon?.code ? coupon.code : [])]}
      />
      <DisplayDiscountItems className="mt-2" showCaseId={false} onDelete={handleCouponRemoval} items={couponItems} />
    </div>
  );
};

/**
 * Component for rendering loading content.
 * @returns JSX.Element - The LoadingContent component.
 */
const LoadingContent: React.FC = () => {
  return (
    <div className="flex flex-col gap-5">
      <SkeletonBox className="h-8" />
      <SkeletonBox className="h-8" />
      <SkeletonBox className="h-8" />
    </div>
  );
};

/**
 * Component for rendering when no data is available.
 * @returns JSX.Element - The NoDataContent component.
 */
const NoDataContent: React.FC = () => {
  return (
    <div className="flex flex-col justify-center my-8">
      <div className="text-gray-600 text-center">No Data Found.</div>
    </div>
  );
};

/**
 * Props for the DataContent component.
 */
interface DataContentProps {
  /** The list of items to be displayed. */
  listItems: ListItem[];
  // Order details for bundle case
  bundledCases: CreatedOrder[];
}

/**
 * Component for rendering content based on list items.
 *
 * @param listItems - The list of items to display.
 * @param bundledCases - (optional) Order details for bundle case: CreatedOrder Array
 * @returns JSX.Element - The rendered component.
 */
const DataContent: React.FC<DataContentProps> = ({ listItems, bundledCases }) => {
  const specialDiscountReasons = listItems.map(listItem => listItem.name);
  const setCouponCode = useAddDiscountModalStore(state => state.specialDiscount.setCouponCode);
  const discountModalType = useAddDiscountModalStore(state => state.discountModalType);
  const discountReason = useAddDiscountModalStore(state => state.specialDiscount.reason);
  const canShowCouponInput =
    discountModalType === DiscountModalType.Credit && discountReason.value === CreditReason.Discount;

  //boolean to render select case id from the bundled orders
  const isBundle = bundledCases?.length > 1;

  useEffect(() => {
    if (!canShowCouponInput) {
      setCouponCode(undefined);
    }
  }, [canShowCouponInput, setCouponCode]);

  return (
    <div className="flex flex-col gap-5">
      {isBundle && <SelectCaseFromBundle bundledCases={bundledCases} />}
      <ReasonForDiscount reasons={specialDiscountReasons} isBundle={isBundle} />
      {canShowCouponInput && <CouponInputContent />}
      <DiscountFor isBundle={isBundle} />
      <Notes />
    </div>
  );
};

/**
 * Props for the AddSpecialDiscount component.
 */
interface AddSpecialDiscountProps {
  /** The type of discount modal. */
  type: DiscountModalType;
  /** Function to handle closing the modal. */
  onClose: () => void;
  /** Function to handle applying the discount. */
  onApply: () => void;
  /** Boolean indicating whether the component is in a loading state. */
  isLoading?: boolean;
  // Order details for bundle case : CreatedOrder Array
  bundledCases: CreatedOrder[];
  caseEntryAppliedDiscounts?: Deduction[];
}

/**
 * Component for adding a special discount.
 *
 * @param type - The type of discount modal.
 * @param onClose - Function to handle closing the modal.
 * @param onApply - Function to handle applying the discount.
 * @param isLoading - Boolean indicating whether the component is in a loading state.
 * @returns JSX.Element
 */
const AddSpecialDiscount: React.FC<AddSpecialDiscountProps> = ({
  type,
  onClose,
  onApply,
  isLoading,
  bundledCases,
  caseEntryAppliedDiscounts,
}) => {
  const { loading, data, error } = useQueryFetcher(getCategoryList, {
    category: ListCategory.SpecialDiscountReason,
  });

  const setSelectedDiscountType = useAddDiscountModalStore(state => state.specialDiscount.setSelectedDiscountType);
  const setDiscountAppliedFor = useAddDiscountModalStore(state => state.specialDiscount.setDiscountAppliedFor);
  const currentOrder = useAddDiscountModalStore(state => state.currentOrder);
  const setDiscounts = useAddDiscountModalStore(state => state.specialDiscount.setDiscounts);
  const setDiscountModalType = useAddDiscountModalStore(state => state.setDiscountModalType);

  /**
   * Based on the current order appliedDiscounts, set the discount applied for value and selected discount type.
   * If the appliedDiscounts is empty, then set the discount applied for value to empty.
   * If the appliedDiscounts has EntireCase discount, then set the discount applied for value to EntireCase.
   * If the appliedDiscounts has PartOfCase discount, then set the discount applied for value to PartOfCase.
   */
  useEffect(() => {
    if (!data) return;
    const appliedCredits = currentOrder?.appliedCredits || [];
    const appliedDiscounts = caseEntryAppliedDiscounts
      ? caseEntryAppliedDiscounts
      : currentOrder?.appliedDiscounts || [];
    const isCreditModal = type === DiscountModalType.Credit;
    const discountItems = isCreditModal ? appliedCredits : appliedDiscounts;

    const isEntireCaseDiscount = discountItems.some(
      discount => getDeductionType(discount) === CaseDiscountType.EntireCase
    );
    const isPartOfCaseDiscount = discountItems.some(
      discount => getDeductionType(discount) === CaseDiscountType.PartOfCase
    );

    if (isEntireCaseDiscount) {
      setDiscountAppliedFor(CaseDiscountType.EntireCase);
      setSelectedDiscountType(CaseDiscountType.EntireCase);
      setDiscounts([]);
    } else if (isPartOfCaseDiscount) {
      setDiscountAppliedFor(CaseDiscountType.PartOfCase);
      setSelectedDiscountType(CaseDiscountType.PartOfCase);
      setDiscounts([getDefaultDiscountData()]);
    } else {
      setDiscountAppliedFor('');
      setSelectedDiscountType(CaseDiscountType.EntireCase);
    }

    setDiscountModalType(type);
  }, [
    currentOrder,
    data,
    caseEntryAppliedDiscounts,
    setDiscountAppliedFor,
    setDiscountModalType,
    setDiscounts,
    setSelectedDiscountType,
    type,
  ]);

  return (
    <>
      <div className="p-6">
        <p className="text-lg font-medium mb-4">Add {type} to Case</p>
        <QueryFetchTemplate
          loading={loading}
          error={error}
          data={data}
          noDataContent={<NoDataContent />}
          loadingContent={<LoadingContent />}
        >
          {({ data }) => <DataContent listItems={data} bundledCases={bundledCases} />}
        </QueryFetchTemplate>
      </div>
      <ActionButtons onClose={onClose} onApply={onApply} disabled={loading || isLoading} />
    </>
  );
};

export default AddSpecialDiscount;
