import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Deduction } from 'API';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import InputError from 'components/common/InputError';
import AddSpecialDiscountInput from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscount/AddSpecialDiscount';
import { AddSpecialDiscountModal } from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscountModal/AddSpecialDiscountModal';
import { DiscountModalType } from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscountModal/types';
import { useAuth } from 'providers/AuthProvider';
import { OrderModuleContext } from 'providers/OrderModuleProvider';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getNewDiscountInput } from 'shared/helpers/discount.helper';
import { getAppliedSpecialDiscountItem } from 'shared/helpers/invoice/invoice.helper';
import { convertLocalOrderInputToOrder } from 'shared/helpers/order-detail/order-detail.helper';
import { convertDiscountInputToDeduction } from 'shared/helpers/order-entry/order-entry.helper';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { AddedSpecialDiscountItemType } from 'types/common';

/**
 * Props interface for the OrderEntryAddSpecialDiscount component.
 */
interface OrderEntryAddSpecialDiscountProps {
  isLoading: boolean;
}

/**
 * Component for managing special discounts in order entry.
 * Allows adding, displaying, and removing special discounts.
 */
export const OrderEntryAddSpecialDiscount: FC<OrderEntryAddSpecialDiscountProps> = ({ isLoading }) => {
  const { order } = useContext(OrderModuleContext);
  const { user } = useAuth();

  const specialDiscount = useAddDiscountModalStore(state => state.specialDiscount);
  const setCurrentOrder = useAddDiscountModalStore(state => state.setCurrentOrder);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addedSpecialDiscounts, setAddedDiscounts] = useState<AddedSpecialDiscountItemType[]>([]);
  const [appliedDiscounts, setAppliedDiscounts] = useState<Deduction[]>([]);
  const [removingIndex, setRemovingIndex] = useState<number | undefined>(undefined);

  // Clear error message when provider is selected
  useEffect(() => {
    if (order?.providerName) {
      setErrorMessage('');
    }
  }, [order?.providerName]);

  /**
   * Update current order
   */
  const setOrderInputAsCurrentOrder = useCallback(() => {
    if (order) {
      const createdOrder = convertLocalOrderInputToOrder(order);
      setCurrentOrder(createdOrder);
    }
  }, [order, setCurrentOrder]);

  // Update current order in the store when order changes
  useEffect(() => {
    setOrderInputAsCurrentOrder();
  }, [order, setOrderInputAsCurrentOrder]);

  /**
   * Opens the add special discount modal if a provider is selected.
   */
  const onOpenModal = useCallback(() => {
    if (!order?.providerName) {
      setErrorMessage('Please select a provider');

      return;
    }
    setOrderInputAsCurrentOrder();
    setShowModal(true);
  }, [order?.providerName, setOrderInputAsCurrentOrder]);

  /**
   * Closes the add special discount modal and clears the current order.
   */
  const onCloseModal = useCallback(() => {
    setCurrentOrder(null);
    setShowModal(false);
  }, [setCurrentOrder]);

  /**
   * Applies a discount to the order and updates the state.
   */
  const onDiscountApply = useCallback(() => {
    const { selectedDiscountType, discounts: selectedDiscounts, note, reason: selectedReason } = specialDiscount;

    const newDiscountInput = getNewDiscountInput(
      user?.displayName,
      selectedDiscountType,
      selectedDiscounts,
      note,
      order?.orderNumber || '',
      selectedReason
    );
    // Convert newDiscountInput to a Deduction object for further processing.
    const convertedDiscount = convertDiscountInputToDeduction(newDiscountInput);
    // Generate AddedSpecialDiscountItemType from the converted discount.
    // This will be used to render the applied discounts in the UI.
    const newAppliedDiscounts = [...appliedDiscounts, convertedDiscount];

    setAppliedDiscounts(newAppliedDiscounts);

    const addedDiscount = getAppliedSpecialDiscountItem([convertedDiscount], '');
    setAddedDiscounts(prevDiscounts => [...prevDiscounts, ...addedDiscount]);

    setShowModal(false);
  }, [specialDiscount, user?.displayName, order?.orderNumber, appliedDiscounts]);

  /**
   * Removes a special discount at the specified index.
   */
  const removeSpecialDiscount = useCallback(() => {
    if (removingIndex !== undefined) {
      setAddedDiscounts(prevDiscounts => {
        const newDiscounts = [...prevDiscounts];
        newDiscounts.splice(removingIndex, 1);
        return newDiscounts;
      });

      setAppliedDiscounts(prevDiscounts => {
        const newDiscounts = [...prevDiscounts];
        newDiscounts.splice(removingIndex, 1);
        return newDiscounts;
      });
    }
    setShowConfirmModal(false);
  }, [removingIndex]);

  /**
   * Initiates the discount removal process.
   */
  const handleDeleteItem = useCallback((_, __, index: number) => {
    setShowConfirmModal(true);
    setRemovingIndex(index);
  }, []);

  // Memoize the confirm dialog to prevent unnecessary re-renders
  const confirmDialog = useMemo(
    () =>
      showConfirmModal && (
        <DialogBox
          title="Are you sure you want to remove this discount?"
          onCancel={() => setShowConfirmModal(false)}
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" />}
          confirmText="Remove"
          onConfirm={removeSpecialDiscount}
        >
          <div className="text-base text-gray-500"></div>
        </DialogBox>
      ),
    [showConfirmModal, removeSpecialDiscount]
  );

  return (
    <div className="flex flex-col gap-2">
      <AddSpecialDiscountInput
        onAddButtonClick={onOpenModal}
        isLoading={isLoading}
        addedSpecialDiscountsItems={addedSpecialDiscounts}
        onDeleteItem={handleDeleteItem}
        hideTitle
      />
      <InputError message={errorMessage} testId="coupon" />
      <AddSpecialDiscountModal
        showModal={showModal}
        onClose={onCloseModal}
        onApply={onDiscountApply}
        type={DiscountModalType.SpecialDiscount}
        caseEntryAppliedDiscounts={appliedDiscounts}
      />
      {confirmDialog}
    </div>
  );
};
