import { CaseDiscountType } from 'API';
import { CommonActionButtons } from 'components/common/CommonActionButtons/CommonActionButtons';
import { FC, useMemo } from 'react';
import { CreditReason } from 'shared/enums/credit-reasons';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { DiscountModalType } from '../../types';

/**
 * Props for the ActionButtons component.
 */
interface ActionButtonsProps {
  /**
   * Indicates whether the buttons are disabled.
   */
  disabled?: boolean;
  /**
   * Callback function to close the modal.
   */
  onClose: () => void;
  /**
   * Callback function to apply the discounts.
   */
  onApply: () => void;
}

/**
 * Component for action buttons in the discount modal.
 *
 * @param onClose - Callback function to close the modal.
 * @param onApply - Callback function to apply the discounts.
 * @param disabled - Indicates whether the buttons are disabled.
 * @param disableApply - Indicates whether the apply button is disabled.
 * @returns an action buttons component.
 */
export const ActionButtons: FC<ActionButtonsProps> = ({ onClose, onApply, disabled }) => {
  const discounts = useAddDiscountModalStore(state => state.specialDiscount.discounts);
  const reason = useAddDiscountModalStore(state => state.specialDiscount.reason);
  const setReason = useAddDiscountModalStore(state => state.specialDiscount.setReason);
  const selectedDiscountType = useAddDiscountModalStore(state => state.specialDiscount.selectedDiscountType);
  const setDiscountErrors = useAddDiscountModalStore(state => state.specialDiscount.setDiscountErrors);
  const discountAppliedFor = useAddDiscountModalStore(state => state.specialDiscount.discountAppliedFor);
  const selectedCouponCode = useAddDiscountModalStore(state => state.specialDiscount.couponCode);
  const discountModalType = useAddDiscountModalStore(state => state.discountModalType);

  const disableApplyButton = useMemo(() => {
    return (
      disabled ||
      !!(discountAppliedFor && discountAppliedFor === CaseDiscountType.EntireCase) ||
      // for Credit Modals, disable if credit reason is `Discount` and coupon is either empty or invalid
      (discountModalType === DiscountModalType.Credit && reason.value === CreditReason.Discount && !selectedCouponCode)
    );
  }, [disabled, discountAppliedFor, discountModalType, reason.value, selectedCouponCode]);

  const onApplyHandler = () => {
    let isValid = true;
    if (selectedDiscountType === CaseDiscountType.PartOfCase) {
      discounts.forEach((discount, index) => {
        const { isPercentage, percentageValue, dollarAmount, itemId } = discount;
        const isItemIdValid = !!itemId;

        setDiscountErrors(index, { itemId: !isItemIdValid });
        if (isPercentage) {
          setDiscountErrors(index, { percentageValue: percentageValue <= 0 });
        } else {
          setDiscountErrors(index, { dollarAmount: dollarAmount <= 0 });
        }

        if (!isItemIdValid || (isPercentage ? percentageValue <= 0 : dollarAmount <= 0)) {
          isValid = false;
        }
      });
    }

    if (!reason.value) {
      setReason({ error: true });
      isValid = false;
    }

    if (!isValid) return;
    onApply();
  };

  return (
    <CommonActionButtons
      onClose={onClose}
      onApply={onApplyHandler}
      disableApply={disableApplyButton}
      disabled={disabled}
    />
  );
};
