import React, { Fragment, ReactNode } from 'react';
import { cn } from 'shared/utils';
import { SkeletonBox } from '../Skeleton';
import { TBodyCell } from './TBodyCell';
import { LabelRow } from './Table';
import { TableNoDataFound } from './TableNoDataFound';
import { TableColumn, TableRow } from './types';

const TBodyContainer: React.FC = ({ children }) => {
  return (
    <tbody data-testid="table-tbody-container" className="bg-white">
      {children}
    </tbody>
  );
};

const RenderRowText = <TColumn extends TableColumn<TRow>, TRow extends TableRow>({
  column,
  row,
  loading,
}: {
  column: TColumn;
  row: TRow;
  loading: boolean;
}) => {
  if (loading) return <SkeletonBox className="h-6" />;
  if (typeof column.render === 'function') return <Fragment>{column.render({ row, column })}</Fragment>;
  return <Fragment>{row[column.id] || 'N/A'}</Fragment>;
};
/**
 * Props for the TBody component.
 */
interface TBodyProps<TColumn extends TableColumn<TRow>, TRow extends TableRow> {
  /**
   * Array of table rows.
   */
  rows: TRow[];
  /**
   * Array of table columns.
   */
  columns: TColumn[];
  /**
   * Custom render function for cells.
   */
  children?: ({ row, column }: { row: TRow; column: TColumn }) => ReactNode;
  /**
   * Custom component to render when no data is available.
   */
  noDataFound?: ReactNode;
  /**
   * Flag to indicate whether the table is in a loading state.
   */
  loading?: boolean;
  /**
   * Flag to indicate whether rows should highlight on hover.
   */
  hoverRow?: boolean;
  /**
   * Callback function invoked when a row is clicked.
   */
  handleRowClick?: (row: TRow, index: number) => void;
  /**
   * Function to retrieve the tooltip for the row.
   */
  getRowTooltip?: (row: TRow) => ReactNode;
  /**
   * Function to retrieve whether the row should be disabled.
   */
  getIsRowDisabled?: (row: TRow) => boolean;
  /**
   * Label rows configuration.
   */
  labelRows?: LabelRow[];
  /**
   * Additional classes for styling.
   */
  className?: string;
}

/**
 * Component representing the body of a table.
 * @param rows - Array of rows to be displayed in the table body.
 * @param columns - Array of columns to define the structure of the table body.
 * @param children - Optional children function to customize cell rendering.
 * @param noDataFound - Component to display when no data is available.
 * @param loading - Boolean indicating whether the table body is in a loading state.
 * @param hoverRow - Boolean indicating whether rows should change appearance on hover.
 * @param handleRowClick - Function to handle row click events.
 * @param getRowTooltip - Function to retrieve the tooltip for the row.
 * @param getIsRowDisabled - Function to retrieve whether the row should be disabled.
 * @param labelRows - Array of label rows to be displayed above certain rows.
 * @param className - Additional classes for styling.
 * @returns JSX.Element representing the TBody component.
 */
export const TBody = <TColumn extends TableColumn<TRow>, TRow extends TableRow>({
  rows,
  columns,
  children,
  noDataFound = <TableNoDataFound colSpan={columns.length} />,
  loading = false,
  hoverRow,
  handleRowClick,
  getRowTooltip,
  getIsRowDisabled,
  labelRows,
  className,
}: TBodyProps<TColumn, TRow>) => {
  if (!rows.length) {
    return <TBodyContainer>{noDataFound}</TBodyContainer>;
  }
  return (
    <TBodyContainer>
      {rows.map((row, rowIndex) => {
        // If there is a label for the current row, inserts it above it.
        const labelForRow = labelRows?.find(label => label.index === rowIndex)?.label;
        const rowTooltip = getRowTooltip?.(row);
        return (
          <React.Fragment key={`table-row-label-${rowIndex}`}>
            {labelForRow && (
              <tr className="text-gray-500 text-center bg-gray-100 border-t-1 border-gray-300">
                <td className="py-2 font-medium text-sm" colSpan={12}>
                  {labelForRow}
                </td>
              </tr>
            )}
            <tr
              className={cn({
                'hover:bg-indigo-50 cursor-pointer': hoverRow,
                'group relative': !!rowTooltip,
              })}
              onClick={() => {
                handleRowClick && handleRowClick(row, rowIndex);
              }}
            >
              {columns.map((column, index) => (
                <TBodyCell
                  key={`${column.id}-${rowIndex}-${index}`}
                  id={column.id}
                  className={cn(
                    {
                      'text-gray-400': getIsRowDisabled?.(row),
                    },
                    className,
                    column.rowClassName
                  )}
                >
                  {typeof children === 'function' ? (
                    children({ column, row })
                  ) : (
                    <RenderRowText column={column} row={row} loading={loading} />
                  )}
                  {rowTooltip}
                </TBodyCell>
              ))}
            </tr>
          </React.Fragment>
        );
      })}
    </TBodyContainer>
  );
};
