import { PauseCircleIcon } from '@heroicons/react/24/outline';
import { ListCategory, OrderStatus } from 'API';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import Dropdown from 'components/common/Dropdown/Dropdown';
import { useAuth } from 'providers/AuthProvider';
import { useCaseDetail } from 'providers/CaseDetailModuleProvider';
import { useToast } from 'providers/ToastProvider';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCategoryList } from 'shared/api/category-list.api';
import { updateOrderStatus } from 'shared/api/order.api';
import { ToastNotificationType } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { getCaseAndReturnType } from 'shared/helpers/analytics.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { DropdownModel } from 'shared/models';
import { AnalyticsService } from 'shared/services/analytics.service';
import { QueryFetchTemplate } from 'templates/QueryFetchTemplate/QueryFetchTemplate';

interface HoldCaseModalProps {
  showModal: boolean;
  onCloseModal: () => void;
}

/**
 * A modal for selecting to place a case on hold.
 * @param showModal - A boolean for determining whether the modal is shown.
 * @param onCloseModal - A function that gets triggered when the modal is closed.
 * @returns a modal for selecting to place a case on hold.
 */

export const HoldCaseModal: FC<HoldCaseModalProps> = ({ showModal, onCloseModal }) => {
  const { id: orderNumber } = useParams<{ id: string }>();
  const { fetcher, loading: isUpdating } = useLazyQueryFetcher(updateOrderStatus);
  const toast = useToast();
  const { user } = useAuth();
  const currentUserName = user?.displayName || '';
  const { setCaseDetails, caseDetails } = useCaseDetail();
  const [onHoldReason, setOnHoldReason] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Retrieves options for the on hold reason dropdown.
  const { loading, data, error } = useQueryFetcher(getCategoryList, {
    category: ListCategory.CaseOnHoldReason,
  });

  /**
   * Resets the modal state variables on close.
   */
  const handleModalClose = () => {
    setErrorMessage('');
    setOnHoldReason('');
    onCloseModal();
  };

  /**
   * Submits a request to update the order status with the on-hold status and the reason selected.
   * @returns a promise that the hold case request was handled.
   */
  const handleHoldCase = async () => {
    if (!orderNumber) return toast.notify('Invalid order number', ToastNotificationType.Error);
    if (!onHoldReason) return setErrorMessage('Please select a reason');
    try {
      await fetcher({
        orderNumber: orderNumber,
        orderStatus: OrderStatus.OnHold,
        statusReason: onHoldReason,
        updatedBy: currentUserName,
      });

      const { caseType, returnType } = getCaseAndReturnType(caseDetails);

      AnalyticsService.track(AnalyticsEventName.CasePaused, {
        caseType,
        caseNumber: orderNumber,
        caseStatus: OrderStatus.OnHold,
        reason: onHoldReason,
        ...(!!returnType?.length && { returnType }),
      });

      if (caseDetails) {
        setCaseDetails({ ...caseDetails, status: OrderStatus.OnHold, statusReason: onHoldReason });
      }
      toast.notify('Case status has been updated successfully', ToastNotificationType.Success);
      handleModalClose();
    } catch (err) {
      const error = err as Error;
      toast.notify(error.message, ToastNotificationType.Error);
    }
  };

  const onSelectedChange = (value: string) => {
    setErrorMessage('');
    setOnHoldReason(value);
  };

  const getOptions = (): DropdownModel[] => {
    return data
      ? data
          .map(item => {
            return {
              primaryLabel: item.name,
              value: item.name,
            };
          })
          .sort((a, b) => b.primaryLabel.localeCompare(a.primaryLabel))
      : [];
  };

  return (
    <>
      {showModal && (
        <DialogBox
          title="Select a reason for on hold"
          icon={<PauseCircleIcon className="h-5 w-5 text-black" />}
          cancelText="Cancel"
          onCancel={handleModalClose}
          confirmText="Done"
          onConfirm={handleHoldCase}
          confirmButtonVariant="primary"
          iconBgColor="gray"
          confirmButtonDisabled={isUpdating}
          width="w-full max-w-xl"
        >
          <div className="flex-grow">
            <QueryFetchTemplate
              loading={loading}
              error={error}
              data={data}
              noDataContent={<div>Error loading reasons</div>}
            >
              {({ isLoading }) =>
                isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <Dropdown
                    data={getOptions()}
                    label="Reason"
                    selected={
                      onHoldReason
                        ? { primaryLabel: onHoldReason, value: onHoldReason }
                        : { primaryLabel: '', value: '' }
                    }
                    setSelected={select => {
                      onSelectedChange(select.value);
                    }}
                    errorMessage={errorMessage}
                    placeholder="Select"
                    isRequired
                  />
                )
              }
            </QueryFetchTemplate>
          </div>
        </DialogBox>
      )}
    </>
  );
};
