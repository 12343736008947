import { ListCategory } from 'API';
import { AttributeType, CategoryListReasons, DiscountCategory } from 'shared/enums';
import { TabConfig } from 'shared/models';

export const CaseDetailTab = {
  Overview: 'Overview',
  FilesAndItems: 'Files & Enclosed Items',
  Shipping: 'Shipping',
  Bundles: 'Bundles',
  RelatedCases: 'Related Cases',
  CaseHistory: 'Case History',
  Deductions: 'Deductions',
  Alerts: 'Alerts',
};

export const getCaseDetailTabs = (): TabConfig[] => {
  return [
    { title: CaseDetailTab.Overview, current: true },
    { title: CaseDetailTab.FilesAndItems, current: false },
    { title: CaseDetailTab.Shipping, current: false },
    { title: CaseDetailTab.Bundles, current: false },
    { title: CaseDetailTab.RelatedCases, current: false },
    { title: CaseDetailTab.CaseHistory, current: false },
    { title: CaseDetailTab.Deductions, current: false },
    { title: CaseDetailTab.Alerts, current: false },
  ];
};

export const discountTypes = [
  DiscountCategory.Coupon,
  DiscountCategory.Product,
  DiscountCategory.InboundShipping,
  DiscountCategory.OutboundShipping,
  DiscountCategory.OverallCase,
];

export const discountReasons = [
  { label: CategoryListReasons.CaseOnHoldReason, value: ListCategory.CaseOnHoldReason },
  { label: CategoryListReasons.SpecialDiscountReason, value: ListCategory.SpecialDiscountReason },
];

const {
  Alloy,
  Clearance,
  ColorAttribute,
  Facility,
  ImplantAttribute,
  Occlusion,
  PonticDesign,
  ProcessAttribute,
  Quantity,
  Service,
  Shade,
  Thickness,
  TierPricing,
  ToothString,
  ToothStringMissingTooth,
  AddOn,
} = AttributeType;

export const OrderDetailAttributesConfigs: string[] = [
  Alloy,
  Clearance,
  ColorAttribute,
  Facility,
  ImplantAttribute,
  Occlusion,
  PonticDesign,
  ProcessAttribute,
  Quantity,
  Service,
  Shade,
  Thickness,
  TierPricing,
  ToothString,
  ToothStringMissingTooth,
  AddOn,
];
