import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { DropdownModel } from 'shared/models';
import { convertToCamelCase } from 'shared/utils';
import Label from '../Label';
import RequiredMessage from '../RequiredMessage/RequiredMessage';
import { ResetButton } from '../ResetButton';
import { SkeletonBorder } from '../Skeleton';

/**
 * Props for the Dropdown component.
 */
interface DropdownProps {
  /** The label for the dropdown. */
  label?: string;
  /** Whether to show the selected icon on the left side. */
  leftSideSelectedIcon?: boolean;
  /** Custom icon for the selected item. */
  selectedIcon?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  /** The currently selected item. */
  selected: DropdownModel;
  /** Function to update the selected item. */
  setSelected: (value: DropdownModel) => void;
  /** The list of dropdown items. */
  data?: DropdownModel[];
  /** Whether the dropdown is disabled. */
  disabled?: boolean;
  /** Whether the dropdown is required. */
  isRequired?: boolean;
  /** Whether to show an alert on form submit if the dropdown is empty. */
  onSubmitAlert?: boolean;
  /** Whether to show a skeleton loader. */
  skeletonLoader?: boolean;
  /** Additional CSS classes for styling. */
  className?: string;
  /** Whether the dropdown supports resetting to default value. */
  isAbleToReset?: boolean;
  /** Function to reset the dropdown value. */
  onValueReset?: () => void;
  /** Error message to display when there is a validation error. */
  errorMessage?: string;
  /** Whether to show a clear button. */
  showClearButton?: boolean;
  /** Whether to show the label when the dropdown is disabled. */
  isShowLabelWhenDisabled?: boolean;
  /** Placeholder text for the dropdown. */
  placeholder?: string;
  /** Text to render when the list is empty */
  emptyText?: string;
}

/**
 * Component for rendering a dropdown.
 *
 * @param label - The label for the dropdown.
 * @param selected - The currently selected item.
 * @param setSelected - Function to update the selected item.
 * @param selectedIcon - Custom icon for the selected item.
 * @param disabled - Whether the dropdown is disabled.
 * @param isRequired - Whether the dropdown is required.
 * @param onSubmitAlert - Whether to show an alert on form submit if the dropdown is empty.
 * @param data - The list of dropdown items.
 * @param leftSideSelectedIcon - Whether to show the selected icon on the left side.
 * @param skeletonLoader - Whether to show a skeleton loader.
 * @param className - Additional CSS classes for styling.
 * @param isAbleToReset - Whether the dropdown supports resetting to default value.
 * @param onValueReset - Function to reset the dropdown value.
 * @param errorMessage - Error message to display when there is a validation error.
 * @param showClearButton - Whether to show a clear button.
 * @param isShowLabelWhenDisabled - Whether to show the label when the dropdown is disabled.
 * @param placeholder - Placeholder text for the dropdown.
 * @param placeholder - Text to render when the list is empty.
 * @returns JSX.Element - The rendered component.
 */
const Dropdown: React.FC<DropdownProps> = ({
  label,
  selected,
  setSelected,
  selectedIcon,
  disabled,
  isRequired,
  onSubmitAlert,
  data = [],
  leftSideSelectedIcon,
  skeletonLoader,
  className,
  isAbleToReset,
  onValueReset,
  errorMessage,
  showClearButton = false,
  isShowLabelWhenDisabled,
  placeholder,
  emptyText,
}: DropdownProps): JSX.Element => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const hasValidationError =
    errorMessage || (isRequired && ((!disabled && isTouched) || onSubmitAlert) && !selected.primaryLabel?.length);

  const clearValue = () => {
    setSelected({ primaryLabel: '', value: '' });
  };

  const onChange = (value: DropdownModel) => {
    if (selected.value === value.value) {
      clearValue();
    } else {
      setSelected(value);
    }
  };
  const dropdownButtonId = convertToCamelCase(label || '', 'DropdownBtn');
  const resetButton = onValueReset && !!isAbleToReset && <ResetButton onValueReset={onValueReset} className="ml-1" />;
  return (
    <>
      {label && (
        <Label required={isRequired} htmlFor={convertToCamelCase(label, 'Dropdown')} labelClassName="flex">
          {label}
          {resetButton}
        </Label>
      )}
      {!label && resetButton}
      <Listbox value={selected} onChange={onChange}>
        {({ open }) => (
          <>
            <div
              className={classNames('relative')}
              title={selected.primaryLabel + (selected.secondaryLabel ? ' ' + selected.secondaryLabel : '')}
            >
              {skeletonLoader ? (
                <SkeletonBorder />
              ) : (
                <Listbox.Button
                  data-testid={dropdownButtonId}
                  data-qa={dropdownButtonId}
                  className={classNames(
                    className,
                    'relative w-full bg-white ring-1 ring-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-2 sm:text-sm border border-transparent',
                    {
                      'ring-2 ring-indigo-500 focus:ring-indigo-500 ': open,
                      'ring-red-500 focus:ring-red-500 ': hasValidationError && !open,
                      'bg-gray-50 ring-gray-200 text-gray-400': !hasValidationError && disabled,
                      'ring-gray-300 focus:ring-indigo-500 ': !hasValidationError && !disabled && !open,
                      'text-gray-400': selected.primaryLabel === '',
                      'text-black': selected.primaryLabel !== '',
                    }
                  )}
                  onBlur={() => !disabled && setIsTouched(true)}
                >
                  <span className="w-full inline-flex truncate">
                    <span className="truncate" data-testid={convertToCamelCase(label || '', `DropdownBtnPrimaryLabel`)}>
                      {(disabled && !isShowLabelWhenDisabled) || selected.primaryLabel === ''
                        ? placeholder || 'Select'
                        : selected.primaryLabel}
                    </span>
                    {selected.secondaryLabel && (
                      <span
                        className={`ml-2 truncate ${
                          selected.secondaryLabelClassColor ? selected.secondaryLabelClassColor : 'text-gray-500'
                        }`}
                      >
                        {selected.secondaryLabel}
                      </span>
                    )}
                  </span>
                  {/* <span className="absolute inset-y-0 right-0 flex items-center pr-2"></span> */}
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 z-10">
                    {showClearButton && !isShowLabelWhenDisabled && selected && selected.value ? (
                      <XMarkIcon
                        className="h-5 w-5 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        onClick={e => {
                          // Prevent default so the dropdown does not open.
                          e.preventDefault();
                          clearValue();
                        }}
                      />
                    ) : (
                      <ChevronUpDownIcon
                        className={classNames('h-5 w-5 pointer-events-none', {
                          'text-red-500': hasValidationError,
                          'text-gray-400': !hasValidationError,
                        })}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </Listbox.Button>
              )}

              <Transition
                show={disabled ? false : open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  data-qa={convertToCamelCase(`${label}DropdownOptionList`)}
                  onFocus={() => setIsTouched(false)}
                  onBlur={() => !disabled && setIsTouched(true)}
                  data-testid={label && convertToCamelCase(label, 'DropdownBtnOptionList')}
                >
                  {data.length ? (
                    data.map((value, index) =>
                      value.primaryLabel ? (
                        <Listbox.Option
                          key={`${value.value}-${index}`}
                          data-testid={convertToCamelCase(value.value, 'DropdownBtnOption')}
                          className={({ active }) =>
                            classNames('cursor-default select-none relative py-2', {
                              'text-white bg-indigo-600': active,
                              'text-gray-900': !active,
                              'pl-9 pr-3': leftSideSelectedIcon,
                              'pl-3 pr-9': !leftSideSelectedIcon,
                              [`${value.defaultColorClass}`]: !!value.defaultColorClass && !active,
                            })
                          }
                          value={value}
                        >
                          {({ active }) => {
                            // NOTE: Listbox "selected" was not working.
                            const isSelected = value.value === selected.value;
                            return (
                              <>
                                <div
                                  className="flex"
                                  title={value.primaryLabel + (value.secondaryLabel ? ' ' + value.secondaryLabel : '')}
                                >
                                  <span
                                    className={classNames('truncate', {
                                      'font-semibold': isSelected,
                                      'font-normal': !isSelected,
                                    })}
                                  >
                                    {value.primaryLabel}
                                  </span>
                                  {value.secondaryLabel && (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'text-indigo-200'
                                          : value.secondaryLabelClassColor
                                          ? value.secondaryLabelClassColor
                                          : 'text-gray-500',
                                        'ml-2 truncate'
                                      )}
                                    >
                                      {value.secondaryLabel}
                                    </span>
                                  )}
                                </div>

                                {isSelected ? (
                                  <span
                                    className={classNames('absolute inset-y-0 flex items-center', {
                                      'text-white': active,
                                      'text-indigo-600': !active,
                                      'left-0 pl-2': leftSideSelectedIcon,
                                      'right-0 pr-4': !leftSideSelectedIcon,
                                    })}
                                  >
                                    {selectedIcon ?? <CheckIcon className="h-5 w-5" aria-hidden="true" />}
                                  </span>
                                ) : null}
                              </>
                            );
                          }}
                        </Listbox.Option>
                      ) : null
                    )
                  ) : (
                    <div className="text-gray-500 cursor-default select-none relative py-2 pl-3 pr-9">
                      {emptyText ? emptyText : 'No data'}
                    </div>
                  )}
                </Listbox.Options>
              </Transition>
              {hasValidationError && (
                <div className="absolute inset-y-0 right-0 pr-9 flex items-center pointer-events-none">
                  {<ExclamationCircleIcon className="h-5 text-red-500" />}
                </div>
              )}
            </div>
          </>
        )}
      </Listbox>
      {hasValidationError && <RequiredMessage errorMessage={errorMessage} fieldName={errorMessage || label} />}
    </>
  );
};
export default Dropdown;
