import classNames from 'classnames';
import { orderBy } from 'lodash';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { getSortableOrder } from 'shared/helpers/util.helper';
import { TBody } from './TBody';
import { THead } from './THead';
import { TableNoDataFound } from './TableNoDataFound';
import { TablePagination } from './TablePagination';
import { TableColumn, TableRow } from './types';

/**
 * Component for rendering a table container.
 * @returns ReactNode
 */
const TableContainer: React.FC = ({ children }) => {
  return (
    <div className="table-bordered">
      <table className="min-w-full divide-y divide-gray-200 ">{children}</table>
    </div>
  );
};

export interface LabelRow {
  label: string;
  index: number;
}

interface TableProps<TColumn extends TableColumn<TRow>, TRow extends TableRow> {
  columns: TColumn[];
  rows: TRow[];
  page?: number;
  setPage?: (page: number) => void;
  pageSize?: number;
  totalCount?: number;
  isApiPaginated?: boolean;
  showPagination?: boolean;
  label?: string | ReactNode;
  noDataFound?: ReactNode;
  children?: (({ rows, columns }: { rows: TRow[]; columns: TColumn[] }) => ReactNode) | ReactNode;
  loading?: boolean;
  loadingRows?: number;
  hoverRow?: boolean;
  handleRowClick?: (row: TRow, index: number) => void;
  getRowTooltip?: (row: TRow) => ReactNode;
  getIsRowDisabled?: (row: TRow) => boolean;
  handleSortBy?: (column: string) => void;
  labelRows?: LabelRow[];
  header?: ReactNode;
  disableLocalSort?: boolean;
  maxHeight?:
    | '0'
    | 'px'
    | '0.5'
    | '1'
    | '1.5'
    | '2'
    | '2.5'
    | '3'
    | '3.5'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | '14'
    | '16'
    | '20'
    | '24'
    | '28'
    | '32'
    | '36'
    | '40'
    | '44'
    | '48'
    | '52'
    | '56'
    | '60'
    | '64'
    | '72'
    | '80'
    | '96'
    | 'none'
    | 'full'
    | 'screen'
    | 'min'
    | 'max'
    | 'fit';
  borderRounded?: boolean;
  /**
   * columnClassName is a class name that will be applied to all columns.
   */
  columnClassName?: string;
  /**
   * rowClassName is a class name that will be applied to all rows.
   */
  rowClassName?: string;
  /**
   * set to true if overflow is hidden for this table.
   */
  overflowHidden?: boolean;
}

/**
 * Component representing the body of a table.
 * @param rows - Array of table rows.
 * @param columns - Array of table columns.
 * @param children - Render prop function for custom cell rendering.
 * @param noDataFound - JSX element to display when no data is found.
 * @param loading - Indicates whether the table is in a loading state.
 * @param hoverRow - Indicates whether rows should have hover effect.
 * @param handleRowClick - Callback function triggered when a row is clicked.
 * @param labelRows - Array of label rows with their corresponding index in the rows array.
 * @param className - Additional class names for styling.
 * @returns JSX.Element representing the TBody component.
 */
export const DefaultTableLayout = <TColumn extends TableColumn<TRow>, TRow extends TableRow>({
  rows,
  columns,
  label,
  showPagination,
  noDataFound,
  loading,
  page: currentPage = 1,
  setPage: setCurrentPage,
  pageSize = 10,
  loadingRows = 5,
  hoverRow = false,
  handleRowClick = () => null,
  getRowTooltip,
  getIsRowDisabled,
  handleSortBy = () => null,
  labelRows,
  header,
  maxHeight,
  disableLocalSort,
  totalCount = rows.length,
  isApiPaginated,
  borderRounded,
  columnClassName,
  rowClassName,
  overflowHidden,
}: TableProps<TColumn, TRow>) => {
  const [page, setPage] = useState(currentPage);
  const [orderByColumn, setOrderByColumn] = useState('');
  const setCurrentPageRef = useRef(setCurrentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPageRef.current = setCurrentPage;
  }, [setCurrentPage]);

  useEffect(() => {
    setCurrentPageRef.current?.(page);
  }, [page]);

  useEffect(() => {
    handleSortBy(orderByColumn);
  }, [handleSortBy, orderByColumn]);

  const filteredRows = useMemo(() => {
    if (loading) return Array.from({ length: loadingRows }).map(() => ({} as TRow));
    // If the table is paginated by the API, the API will return us which rows to display.
    // If the table is locally paginated, we must slice the rows for the correct page.
    const sliceRows = isApiPaginated ? rows : rows.slice((page - 1) * pageSize, page * pageSize);
    if (disableLocalSort) return sliceRows;

    const selectedSortColumn = columns.find(column => column.id === orderByColumn);
    const type = selectedSortColumn?.type || 'string';
    const { id, order } = getSortableOrder(orderByColumn);

    const sortByFn = (row: TRow) => {
      const value = row[id];
      if (type === 'number') return Number(value);
      if (type === 'date' && value && typeof value === 'string' && value !== 'N/A') return new Date(value).getTime();
      return value;
    };

    const sortedRows = orderByColumn ? orderBy(sliceRows, sortByFn, order) : sliceRows;
    return sortedRows;
  }, [page, pageSize, rows, loading, loadingRows, orderByColumn, columns, disableLocalSort, isApiPaginated]);

  const showTablePagination = showPagination && totalCount !== 0;

  return (
    <div className="table w-full">
      {label && <h3 className="text-medium font-medium text-gray-800 mb-2">{label}</h3>}
      <div
        className={classNames({
          [`max-h-${maxHeight}`]: maxHeight,
          'max-h-130': !maxHeight,
          'rounded-t-xl': borderRounded,
          'overflow-y-auto overflow-x-hidden': overflowHidden,
        })}
      >
        <TableContainer>
          <Table.THead
            rows={filteredRows}
            columns={columns}
            orderBy={orderByColumn}
            onChangeOrderBy={setOrderByColumn}
            header={header}
            borderRounded={borderRounded}
            className={columnClassName}
          />
          <Table.TBody
            rows={filteredRows}
            columns={columns}
            noDataFound={noDataFound}
            loading={loading}
            hoverRow={hoverRow}
            handleRowClick={handleRowClick}
            getRowTooltip={getRowTooltip}
            getIsRowDisabled={getIsRowDisabled}
            labelRows={labelRows}
            className={rowClassName}
          />
        </TableContainer>
      </div>
      {showTablePagination && (
        <TablePagination
          borderRounded={borderRounded}
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          pageSize={pageSize}
        />
      )}
    </div>
  );
};

/**
 * Props for the Table component.
 * @param children - Render prop function or JSX element for custom table rendering.
 * @param rows - Array of table rows.
 * @param columns - Array of table columns.
 * @param showPagination - Indicates whether pagination should be displayed. Default is false.
 * @param noDataFound - JSX element to display when no data is found. Default is a TableNoDataFound component.
 * @param loading - Indicates whether the table is in a loading state. Default is false.
 * @param page - Current page number for pagination. Default is 1.
 * @param pageSize - Number of rows per page. Default is 10.
 * @param loadingRows - Number of loading rows to display when in loading state. Default is 5.
 * @param props - Additional props to be spread to the DefaultTableLayout component.
 * @returns JSX.Element representing the Table component.
 */
export const Table = <TColumn extends TableColumn<TRow>, TRow extends TableRow>({
  children,
  rows,
  columns,
  showPagination = false,
  noDataFound = <TableNoDataFound colSpan={columns.length} />,
  loading = false,
  page = 1,
  pageSize = 10,
  loadingRows = 5,
  ...props
}: TableProps<TColumn, TRow>) => {
  if (typeof children === 'function') return <TableContainer>{children({ rows, columns })}</TableContainer>;

  return (
    <DefaultTableLayout
      {...props}
      rows={rows}
      columns={columns}
      page={page}
      pageSize={pageSize}
      showPagination={showPagination}
      noDataFound={noDataFound}
      loading={loading}
      loadingRows={loadingRows}
    />
  );
};

Table.THead = THead;
Table.TBody = TBody;
