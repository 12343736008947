import { FC } from 'react';
import { cn } from 'shared/utils';
import { AddedSpecialDiscountItemType } from 'types/common';
import { AddedSpecialDiscountItem } from './AddedSpecialDiscountItem';

/**
 * Props for the AddedSpecialDiscountItems component.
 */
interface AddedSpecialDiscountItemsProps {
  /**
   * An array of items to be displayed.
   */
  items: AddedSpecialDiscountItemType[];

  /**
   * Function to handle deletion of a discount item.
   * @param discountId - The discountId of the item to be deleted .
   * @param description - The description of the item to be deleted .
   * @param caseId - The orderNumber of the item to be deleted .
   */
  onDelete?: (discountId: string, description: string, index: number) => void;

  /**
   * Additional CSS class for styling.
   */
  className?: string;
  /**
   * Boolean flag indicating whether to show the case ID.
   */
  showCaseId?: boolean;
}

/**
 * Component to display a list of discount items.
 * @param items - An array of items to be displayed.
 * @param onDelete - Function to handle deletion of a discount item.
 * @param className - Additional CSS class for styling.
 * @returns JSX.Element
 */
export const AddedSpecialDiscountItems: FC<AddedSpecialDiscountItemsProps> = ({
  items,
  onDelete,
  className,
  showCaseId,
}) => {
  if (!items.length) return null;
  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {items.map((item, index) => (
        <AddedSpecialDiscountItem
          key={index}
          {...item}
          showCaseId={showCaseId}
          onDelete={onDelete ? () => onDelete(item.discountId, item.description, index) : undefined}
        />
      ))}
    </div>
  );
};
