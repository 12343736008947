import { Brand } from 'API';
import { useDaysInLab } from 'hooks/use-days-in-lab';
import { usePrevious } from 'hooks/use-previous';
import _ from 'lodash';
import {
  OrderModuleActionsContext,
  OrderModuleContext,
  TechnicalPreferencesByProductCode,
  useOrderEntryContext,
} from 'providers/OrderModuleProvider';
import { ShortcutKeysContext } from 'providers/ShortcutKeysProvider';
import { ToastContext } from 'providers/ToastProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getTechnicalPreferences } from 'shared/api/technical-preferences.api';
import { LAB_HOLIDAY_DATES } from 'shared/constants/constants';
import { ShortCutKeys } from 'shared/constants/shortcut-keys.constants';
import { ToastNotificationType } from 'shared/enums';
import { getDefaultOrderItemInput } from 'shared/helpers/order-entry/order-entry.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { LocalOrderProductAttributeInput } from 'shared/models';
import { addBusinessDaysSkippingHolidays } from 'shared/utils';
import Product from '../Product/Product';
import TabStrip from '../TabStrip/TabStrip';

/**
 * Props for the Products component.
 */
interface ProductsProps {
  /**
   * Flag indicating whether classifications are loading.
   */
  isClassificationsLoading: boolean;
  /**
   * Flag indicating whether it's an order update.
   */
  isOrderUpdate?: boolean;
}

/**
 * Represents the list of products in the order.
 * @param isClassificationsLoading - Flag indicating whether classifications are loading.
 * @param isOrderUpdate - Flag indicating whether it's an order update. (optional)
 * @returns JSX element representing the Products component.
 */
const Products: React.FC<ProductsProps> = ({ isClassificationsLoading, isOrderUpdate }) => {
  const shortcutKeyCtx = useContext(ShortcutKeysContext);
  const { order, isDigitalOrder } = useContext(OrderModuleContext);
  const { patchOrder, setTechnicalPreferencesByProductCode } = useContext(OrderModuleActionsContext);
  const { selectedOrderItemId, setSelectedOrderItemId } = useOrderEntryContext();
  const prevProviderId = usePrevious(order.providerId);
  const toast = useContext(ToastContext);

  const [allProductInfoCached, setAllProductInfoCached] = useState<
    Record<
      string,
      {
        salesCategoryAndBackLogGroupAttributeNames: string[];
        productBrands: Brand[];
      }
    >
  >({});

  const allProductBrandsResult = useMemo(() => {
    const result = Object.entries(allProductInfoCached).reduce((acc, [productCode, productInfo]) => {
      const productBrands = productInfo.productBrands;
      if (!productBrands) return acc;
      acc[productCode] = productBrands;
      return acc;
    }, {} as Record<string, Brand[]>);

    return result;
  }, [allProductInfoCached]);

  const { calculateTotalDaysInLab, calculateMaxDaysInLab } = useDaysInLab(allProductBrandsResult);

  const { fetcher: preferencesByAccountAndProviderFetcher, loading: isPreferencesLoading } =
    useLazyQueryFetcher(getTechnicalPreferences);

  /**
   * Retrieves technical preferences using the provided billing account and provider IDs.
   */
  const loadTechnicalPreferences = useCallback(async () => {
    if (order.billingAccountId && order.providerId && order.providerId !== prevProviderId) {
      try {
        const preferencesByAccountAndProvider = await preferencesByAccountAndProviderFetcher({
          input: {
            billingAccountId: order.billingAccountId,
            providerId: order.providerId,
          },
        });
        const technicalPreferencesByProductCode: TechnicalPreferencesByProductCode = {};
        preferencesByAccountAndProvider.preferences.forEach(preference => {
          const { productCode } = preference;
          // Initializes the array of preferences for this product code.
          if (!technicalPreferencesByProductCode[productCode]) {
            technicalPreferencesByProductCode[productCode] = [];
          }
          technicalPreferencesByProductCode[productCode].push(preference);
        });
        setTechnicalPreferencesByProductCode(technicalPreferencesByProductCode);
      } catch (err) {
        const error = err as Error;
        if (error.name !== 'NotFoundError') {
          // Resets technical preferences on error upon receiving an error condition.
          // That way, existing preferences don't carry over to a new billing account or provider selection.
          setTechnicalPreferencesByProductCode({});
          toast.notify('Error loading technical preferences.', ToastNotificationType.Error);
        }
      }
    }
  }, [
    order.billingAccountId,
    order.providerId,
    preferencesByAccountAndProviderFetcher,
    prevProviderId,
    setTechnicalPreferencesByProductCode,
    toast,
  ]);

  useEffect(() => {
    loadTechnicalPreferences();
  }, [loadTechnicalPreferences]);
  /**
   * Add product to order items, update order context, and set as selected product.
   */
  const addProductHandler = useCallback(() => {
    const newOrder = _.cloneDeep(order);
    const newItem = getDefaultOrderItemInput();
    newOrder.orderItems.push(newItem);

    patchOrder({
      orderItems: newOrder.orderItems,
    });

    setSelectedOrderItemId(newItem.id);
  }, [order, patchOrder, setSelectedOrderItemId]);

  useEffect(() => {
    if (shortcutKeyCtx.keyPressed === ShortCutKeys.AltP) {
      addProductHandler();
      shortcutKeyCtx.reset();
    }
  }, [addProductHandler, shortcutKeyCtx]);

  useEffect(() => {
    // If there are no order items, add one.
    if (!order.orderItems.length) {
      addProductHandler();
    }

    // If there is no selected product or no matching order item with selected product id, set to first in array.
    if (
      !!order.orderItems.length &&
      (!selectedOrderItemId || !order.orderItems.some(item => item.id === selectedOrderItemId))
    ) {
      setSelectedOrderItemId(order.orderItems[0].id);
    }
  }, [order.orderItems, order.orderItems.length, selectedOrderItemId, addProductHandler, setSelectedOrderItemId]);

  /**
   * Remove a product from the tab list
   * @param id - removing item id
   */
  const removeProductHandler = (id: string) => {
    const productCount = order.orderItems.length;
    const newOrder = _.cloneDeep(order);
    const foundOrderItemIndex = newOrder.orderItems.findIndex(item => item.id === id);
    const currSelectedIndex = newOrder.orderItems.findIndex(item => item.id === selectedOrderItemId);

    if (foundOrderItemIndex > -1) {
      // If there is only one item, set current one to default and update selected to new order item id.
      if (newOrder.orderItems.length === 1) {
        const newOrderItemDefault = getDefaultOrderItemInput();
        newOrder.orderItems[foundOrderItemIndex] = newOrderItemDefault;
      } else {
        newOrder.orderItems.splice(foundOrderItemIndex, 1);
        // set a new selection if the user is removing the product they're currently on
        // or to keep the selection on the same item when removing an element in the list
        if (
          (id === selectedOrderItemId && foundOrderItemIndex === productCount - 1) ||
          currSelectedIndex > foundOrderItemIndex
        ) {
          setSelectedOrderItemId(newOrder.orderItems[currSelectedIndex - 1].id);
        } else {
          setSelectedOrderItemId(newOrder.orderItems[currSelectedIndex].id);
        }
      }

      patchOrder({
        orderItems: newOrder.orderItems,
      });
    }
  };

  /**
   * Calculate the total days in lab for the order.
   */
  const orderLevelDaysInLab = useMemo(() => {
    const saleGroupAttributesRecord: Record<string, LocalOrderProductAttributeInput[]> = {};
    const nonSaleGroupAttributesRecord: Record<string, LocalOrderProductAttributeInput[]> = {};

    order.orderItems.forEach(orderItem => {
      const salesOrderItem = allProductInfoCached[orderItem.productCode];
      if (salesOrderItem) {
        const productionFacility = orderItem.manufacturingLocation;
        if (!saleGroupAttributesRecord[productionFacility]) {
          saleGroupAttributesRecord[productionFacility] = [];
        }
        if (!nonSaleGroupAttributesRecord[productionFacility]) {
          nonSaleGroupAttributesRecord[productionFacility] = [];
        }
        orderItem.attributes.forEach(attribute => {
          if (salesOrderItem.salesCategoryAndBackLogGroupAttributeNames.includes(attribute.name)) {
            saleGroupAttributesRecord[productionFacility].push(attribute);
          } else {
            const nonSaleGroupAttributes = nonSaleGroupAttributesRecord[productionFacility];
            const currentIndex = nonSaleGroupAttributes.findIndex(
              a => a.name === attribute.name && a.type === attribute.type
            );
            // if the attribute is not already in the non-sale group, add it
            if (currentIndex === -1) {
              nonSaleGroupAttributes.push(attribute);
            }
          }
        });
      }
    });

    let maxDaysInLab = 0;
    let totalDaysInLab = 0;

    // Calculate the max days in lab for each production facility.
    Object.entries(saleGroupAttributesRecord).forEach(([productionFacility, saleGroupAttributes]) => {
      const maxDaysInLabForFacility = calculateMaxDaysInLab({
        items: saleGroupAttributes,
        productionFacility,
        isDigitalOrder,
      });
      maxDaysInLab = Math.max(maxDaysInLab, maxDaysInLabForFacility);
    });

    // Calculate the total days in lab for each production facility.
    Object.entries(nonSaleGroupAttributesRecord).forEach(([productionFacility, nonSaleGroupAttributes]) => {
      totalDaysInLab += calculateTotalDaysInLab({
        items: nonSaleGroupAttributes,
        productionFacility,
        isDigitalOrder,
      });
    });

    // Return the sum of the max days in lab and the total days in lab.
    return maxDaysInLab + totalDaysInLab;
  }, [order.orderItems, allProductInfoCached, calculateMaxDaysInLab, isDigitalOrder, calculateTotalDaysInLab]);

  /**
   * Update the estimated ship date for the order based on the total days in lab.
   * This effect runs whenever the order level days in lab changes.
   * It calculates the estimated ship date by adding the order level days in lab to the current date,
   * skipping holidays defined in LAB_HOLIDAY_DATES.
   * The estimated ship date is then updated in the order.
   */
  useEffect(() => {
    const currentDate = new Date();
    const estimatedShipDate = addBusinessDaysSkippingHolidays(
      currentDate,
      orderLevelDaysInLab,
      LAB_HOLIDAY_DATES
    ).toISOString();

    // Update the order with the new estimated ship date.
    patchOrder({
      estimatedShipDate,
    });
  }, [orderLevelDaysInLab, patchOrder]);

  return (
    <div className="border-t bg-white flex flex-col flex-1 overflow-auto">
      <TabStrip
        selectedProductId={selectedOrderItemId}
        onTabSelect={(id: string) => {
          setSelectedOrderItemId(id);
        }}
        onTabAdd={addProductHandler}
        onTabRemove={removeProductHandler}
      />
      <div className="bg-gray-50 flex-1 overflow-auto pb-20">
        {order.orderItems.map(orderItem => {
          return (
            <Product
              key={orderItem.id}
              selectedProductId={selectedOrderItemId}
              orderItem={orderItem}
              billingAccountId={order.billingAccountId}
              onProductRemove={() => removeProductHandler(orderItem.id)}
              isOrderUpdate={isOrderUpdate}
              isLoadingProductInformation={isPreferencesLoading || isClassificationsLoading}
              setAllProductInfoCached={setAllProductInfoCached}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Products;
