import { Brand, BrandDaysInLab } from 'API';
import { useCallback, useMemo } from 'react';
import { LocalOrderProductAttributeInput } from 'shared/models';
import ConfigService from 'shared/services/config.service';

/**
 * @param productBrandsResult - The product brands response from the API.
 */
export const useDaysInLab = (productBrandsResult: Record<string, Brand[]>) => {
  const daysInLabAttributeRecord = useMemo(() => {
    const result: { [key: string]: Record<string, BrandDaysInLab> } = {};
    Object.entries(productBrandsResult).forEach(([productCode, productBrands]) => {
      productBrands.forEach(brand => {
        const brandProduct = brand.products.find(product => product.productCode === productCode);
        if (!brandProduct) return;
        result[brand.brandName.toLowerCase()] = brandProduct.attributes.reduce((acc, attribute) => {
          if (attribute.daysInLab) {
            acc[attribute.name.toLowerCase()] = attribute.daysInLab;
          }
          return acc;
        }, {} as Record<string, BrandDaysInLab>);
      });
    });
    return result;
  }, [productBrandsResult]);

  /**
   * Returns the days in lab for a target production facility based on the matching product in the productBrands response and the order type (i.e. digital).
   * @param productionFacility - The target production facility.
   * @returns the days in lab for the target production facility.
   */
  const getDaysInLabForProductionFacility = useCallback(
    (params: { productionFacility: string; isDigitalOrder: boolean; productCode: string }) => {
      const { productionFacility, isDigitalOrder, productCode } = params;
      if (!productionFacility || !productCode) return 0;
      const productBrands = productBrandsResult[productCode];
      if (!productBrands) return 0;
      const productBrand = productBrands.find(b => b.brandName.toLowerCase() === productionFacility.toLowerCase());
      const targetProduct = productBrand?.products.find(product => product.productCode === productCode);
      const daysInLabOptions = targetProduct?.daysInLab;
      // Use DefaultDaysInLab in Settings if no days in lab is set for the product. See comment from Quenton in LMS1-6335.
      const defaultDaysInLab = Number(ConfigService.getConfigValue('Settings', 'DefaultDaysInLab')) || 0;
      if (isDigitalOrder) return daysInLabOptions?.digital ?? defaultDaysInLab;
      return daysInLabOptions?.external ?? defaultDaysInLab;
    },
    [productBrandsResult]
  );

  /**
   * Returns the days in lab for a target production facility based on the matching product in the productBrands response and the order type (i.e. digital).
   * @param item - The target product attribute.
   * @param productionFacility - The target production facility.
   * @returns the days in lab for the target production facility.
   */
  const calculateDaysInLab = useCallback(
    (params: { item: LocalOrderProductAttributeInput; productionFacility: string; isDigitalOrder: boolean }) => {
      const { item, productionFacility, isDigitalOrder } = params;
      if (!item || !productionFacility) return 0;
      const productionFacilityLowerCase = productionFacility.toLowerCase();
      const itemName = item.name.toLowerCase();
      const targetAttribute = daysInLabAttributeRecord[productionFacilityLowerCase]?.[itemName];
      // As per team conversation with Mazen, Quenton and Di, digital orders should use the internal field for days in lab.
      // Internal is being used in the place of the digital field, which we currently would not be able to set in Product Admin.
      const daysInLab = targetAttribute?.[isDigitalOrder ? 'internal' : 'external'] || 0;
      return daysInLab;
    },
    [daysInLabAttributeRecord]
  );

  /**
   * Returns the total days in lab for a list of product attributes based on the matching product in the productBrands response and the order type (i.e. digital).
   * @param items - The list of product attributes.
   * @param productionFacility - The target production facility.
   * @returns the total days in lab for the target production facility.
   */
  const calculateTotalDaysInLab = useCallback(
    (params: { items: LocalOrderProductAttributeInput[]; productionFacility: string; isDigitalOrder: boolean }) => {
      const { items, productionFacility, isDigitalOrder } = params;
      return items.reduce((acc, item) => {
        const daysInLab = calculateDaysInLab({ item, productionFacility, isDigitalOrder });
        return acc + daysInLab;
      }, 0);
    },
    [calculateDaysInLab]
  );

  /**
   * Returns the max days in lab for a list of product attributes based on the matching product in the productBrands response and the order type (i.e. digital).
   * @param items - The list of product attributes.
   * @param productionFacility - The target production facility.
   * @returns the max days in lab for the target production facility.
   */
  const calculateMaxDaysInLab = useCallback(
    (params: { items: LocalOrderProductAttributeInput[]; productionFacility: string; isDigitalOrder: boolean }) => {
      const { items, productionFacility, isDigitalOrder } = params;
      const mappedItems = items.map(item => calculateDaysInLab({ item, productionFacility, isDigitalOrder }));
      if (!mappedItems.length) return 0;
      return Math.max(...mappedItems);
    },
    [calculateDaysInLab]
  );

  return {
    daysInLabAttributeRecord,
    getDaysInLabForProductionFacility,
    calculateTotalDaysInLab,
    calculateMaxDaysInLab,
  };
};
