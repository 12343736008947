import { FC } from 'react';

/**
 * Props for the CommonActionButtons component.
 */
interface CommonActionButtonsProps {
  /**
   * Indicates whether the buttons are disabled.
   */
  disabled?: boolean;
  /**
   * Callback function to close the modal.
   */
  onClose: () => void;
  /**
   * Callback function to apply the discounts.
   */
  onApply: () => void;

  /**
   * Indicates whether the apply buttons is disabled.
   */
  disableApply?: boolean;
}

/**
 * Component for action buttons in the modal.
 *
 * @param onClose - Callback function to close the modal.
 * @param onApply - Callback function to apply the discounts.
 * @param disabled - Indicates whether the buttons are disabled.
 * @param disableApply - Indicates whether the apply button is disabled.
 * @returns an action buttons component.
 */
export const CommonActionButtons: FC<CommonActionButtonsProps> = ({ onClose, onApply, disabled, disableApply }) => {
  return (
    <div className="flex flex-row-reverse h-16 bg-gray-50 px-4 py-3 rounded-b-lg">
      <button
        type="button"
        className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        data-testid="apply"
        onClick={onApply}
        disabled={disableApply}
      >
        Apply
      </button>
      <button
        type="button"
        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={onClose}
        data-testid="cancel"
        disabled={disabled}
      >
        Cancel
      </button>
    </div>
  );
};
