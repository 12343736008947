/**
 * represents various product attribute types
 */
export enum AttributeType {
  ToothString = 'Tooth String',
  MissingTooth = 'Missing Tooth',
  PonticDesign = 'Pontic Design',
  Shade = 'Shade',
  Quantity = 'Quantity',
  ColorAttribute = 'Color Attribute',
  Contacts = 'Contacts',
  Occlusion = 'Occlusion',
  AddOn = 'Add-On',
  Service = 'Service',
  TechnicalPreferences = 'Technical Preferences',
  PerformanceForecastGrouping = 'Performance Forecast Grouping',
  Clearance = 'Clearance',
  IteroProductCode = 'Itero Product Code',
  ProcessAttribute = 'Process Attribute',
  ProcessSteps = 'Process Steps',
  EvaluationForm = 'Evaluation Form',
  ReportGrouping = 'Report Grouping',
  Tax = 'Tax',
  CABYS = 'CABYS',
  ImplantAttribute = 'Implant Attribute',
  InProgressPriceAdjustment = 'in progress price adjustment',
  Reporting = 'Reporting',
  TaxCode = 'Tax Code',
  Facility = 'Facility',
  ReportGroupingDepartment = 'Report Grouping - Department',
  Thickness = 'Thickness',
  TierPricing = 'Tier Pricing',
  ToothStringMissingTooth = 'Tooth String - Missing Tooth',
  Alloy = 'Alloy',
  CADPRESS = 'CAD-Press',
  GeneralLedgerCode = 'General Ledger Code',
  Weight = 'Weight',
  RestorativeAttribute = 'Restorative Attribute',
  ImplantSystem = 'Implant System',
  InclusiveImplantSystem = 'Inclusive Implant System',
  NonInclusiveImplantSystem = 'Non-inclusive Implant System',
  ModifyAbutment = 'Modify Abutment',
  SpecialOrderParts = 'Implant Part',
}

export enum AttributeName {
  BodyShade = 'Body Shade',
  ToothString = 'Tooth String',
  ToothNumberSystem = 'Tooth Number System',
  EvaluationForm = 'Evaluation Form',
  MissingTooth = 'Missing Tooth',
  PonticDesign = 'Pontic Design',
  CABYS = 'CABYS',
  Alloy = 'Alloy',
  Department = 'Department',
  CTScan = 'CT Scan',
  CTScanDI = 'CT Scan DI',
  PrintedModel = 'Printed Model',
  TaxCode = 'Tax Code',
  ProductionFacility = 'Production Facility',
  SalesCategory = 'Sales Category',
  InProgressPriceAdjustment = 'In Progress Price Adjustment',
  PonticModifyLap = 'Pontic Modify Lap',
  AdditionalTooth = 'Additional Tooth',
  NoTooth = 'No Tooth',
  Process = 'Process',
  ModifyAbutment = 'Modify Abutment',
  SpecialOrderParts = 'Special Order Parts',
  AbutmentInMouth = 'Abutment in Mouth',
  NaturalPrep = 'Natural Prep',
  OverCustomAbutment = 'Over Custom Abutment',
  Pontic = 'Pontic',
  ScrewRetained = 'Screw Retained',
  SingleStage = 'Single Stage',
  ImplantPart = 'Implant Part',
  Aggregated = 'Aggregated',
  RestorativeAttribute = 'Restorative Attribute',
}

export enum ReportingAttributeKey {
  SalesCategory = 'Sales Category',
  BacklogGroup = 'Backlog Group',
}
