export enum DiscountCategory {
  Coupon = 'Coupon',
  Product = 'Product',
  InboundShipping = 'Inbound Shipping',
  OutboundShipping = 'Outbound Shipping',
  OverallCase = 'Overall Case',
}

export enum DiscountForType {
  EntireCase = 'Entire Case',
  PartOfCase = 'Part of Case',
}

export enum CategoryListReasons {
  CaseOnHoldReason = 'Case on Hold',
  SpecialDiscountReason = 'Special Discount',
}
