/**
 * represent the nature of toast being shown,
 * so toast theming can be accordingly modified
 */
export enum ToastNotificationType {
  Success,
  Error,
  Warning,
  Info,
}
/**
 * Represents the IDs for toast notifications,
 * allowing specific toasts to be removed using toast.remove(id)
 */
export enum ToastNotificationIds {
  PRICE_CALCULATION = 'price_calculation_pending',
  TAX_CALCULATION = 'tax_calculation_pending',
}
