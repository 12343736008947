import { ShippingOption } from 'API';
import Images from 'assets/images';
import { PackageType, ShippingCarrier, ShippingService } from 'shared/enums';

export const packageTypeConfig: Record<
  string,
  { label: string; value: string; secondaryLabelField?: keyof ShippingOption }
> = {
  [PackageType.Box]: {
    label: PackageType.Box,
    value: PackageType.Box,
  },
  [PackageType.Envelope]: {
    label: PackageType.Envelope,
    value: PackageType.Envelope,
  },
  [PackageType.PredefinedPackage]: {
    label: 'Predefined Package',
    value: PackageType.PredefinedPackage,
    secondaryLabelField: 'predefinedPackage',
  },
};

export const excludedAttributes = ['cabys', 'department'];

export const shippingCarrierLogo: Record<
  string,
  {
    logo: string;
    name: string;
  }
> = {
  [ShippingCarrier.FedEx]: {
    logo: Images.FedexLogo,
    name: ShippingCarrier.FedEx,
  },
  [ShippingCarrier.UPS]: {
    logo: Images.UpsLogo,
    name: ShippingCarrier.UPS,
  },
  [ShippingCarrier.GSO]: {
    logo: Images.GLSLogo,
    name: ShippingCarrier.GSO,
  },
  [ShippingCarrier.FTA]: {
    logo: '',
    name: ShippingCarrier.FTA,
  },
  [ShippingCarrier.OnTrac]: {
    logo: Images.OnTracLogo,
    name: ShippingCarrier.OnTrac,
  },
  [ShippingCarrier.USPS]: {
    logo: Images.USPSLogo,
    name: ShippingCarrier.USPS,
  },
  [ShippingCarrier.DoctorPickup]: {
    logo: '',
    name: ShippingCarrier.DoctorPickup,
  },
};

export const ShippingServiceLabel = {
  [ShippingService.SATURDAY_DELIVERY]: 'Saturday Delivery',
  [ShippingService.EXPRESS]: 'Express',
  [ShippingService.GROUND]: 'Ground',
  [ShippingService.NEXT_DAY_AIR_EARLY_AM]: 'Next Day Early AM',
  [ShippingService.SIGNATURE_REQUIRED]: 'Signature Required',
  [ShippingService.FIRST]: 'First',
  [ShippingService.SECOND_DAY_AIR]: '2nd Day Air',
  [ShippingService.NEXT_DAY_AIR]: 'Next Day Air',
  [ShippingService.NOON_PRIORITY_SERVICE]: 'Noon Priority Service',
  [ShippingService.SUNRISE]: 'Sunrise',
  [ShippingService.NEXT_DAY_AIR_SAVER]: 'Next Day Air Saver',
  [ShippingService.PRIORITY]: 'Priority',
  [ShippingService.CALIFORNIA_PARCEL_SERVICE]: 'California Parcel Service',
  [ShippingService.COD_FEE]: 'COD Fee',
  [ShippingService.INTERNATIONAL_ECONOMY]: 'International Economy',
  [ShippingService.INTERNATIONAL_PRIORITY]: 'International Priority',
  [ShippingService.INTERNATIONAL_FIRST]: 'International First',
  [ShippingService.STANDARD_OVERNIGHT]: 'Standard Overnight',
  [ShippingService.PRIORITY_OVERNIGHT]: 'Priority Overnight',
  [ShippingService.FIRST_OVERNIGHT]: 'First Overnight',
  [ShippingService.ALASKA]: 'Alaska',
  [ShippingService.HAWAII]: 'Hawaii',
  [ShippingService.PUERTO_RICO]: 'Puerto Rico',
  [ShippingService.SECOND_DAY_AIR_ALASKA]: '2nd Day Air Alaska',
  [ShippingService.SECOND_DAY_AIR_HAWAII]: '2nd Day Air Hawaii',
  [ShippingService.SECOND_DAY_AIR_PUERTO_RICO]: '2nd Day Air Puerto Rico',
  [ShippingService.GROUND_ADVANTAGE]: 'Ground Advantage',
  [ShippingService.FEDEX_2_DAY]: 'FedEx 2 Day',
};

/**
 * Returns the mapped value for label and if not found just returns ther input.
 * @param service - The service to map for label.
 * @returns label
 */
export const getShippingServiceLabel = (service: ShippingServiceLabelKey) => {
  return ShippingServiceLabel[service] || service;
};

export type ShippingServiceLabelKey = keyof typeof ShippingServiceLabel;
